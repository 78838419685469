import { useApolloClient } from "@apollo/client";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { assertNever, getDataOrNull, getNameWithCopy } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  GridColDef,
  ListHeader,
  LoadingSpinner,
  MediaCardItem,
  MediaListItem,
  MenuButton,
  Modal,
  ModalOpenButton,
  Tabs,
  TagChips,
  useFormatting,
  useScreenWidth,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import BugReportIcon from "@mui/icons-material/BugReport";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PublishIcon from "@mui/icons-material/Publish";
import WarningIcon from "@mui/icons-material/Warning";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FieldArray, Form, Formik, useFormik } from "formik";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  namedOperations,
  OrganisationQuoteTemplatesFilters,
  OrganisationQuoteTemplatesSortBy,
  OrganisationQuoteTemplatesSorting,
} from "../../../../clients/graphqlTypes";
import { useFeature } from "../../../../common/FeatureFlags";
import { RestrictedByCapabilityWithDebug } from "../../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermissionWithDebug } from "../../../auth/RestrictedByOrganisationPermission";
import { ViewerShapeFragment } from "../../../auth/UserDataProvider.generated";
import { useUserData } from "../../../auth/useUserData";
import { download } from "../../../browserUtils";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton";
import EmptyScreen from "../../../commons/EmptyScreen";
import { FilterButton } from "../../../commons/filters/FilterButton";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import { useAutoSave } from "../../../commons/hooks/useAutoSave";
import { useCardsNumber } from "../../../commons/hooks/useCardsNumber";
import { CollectionView } from "../../../commons/hooks/useCollectionView";
import { useStateWithUrlParam } from "../../../commons/hooks/useStateWithUrlParam";
import { useStateWithUrlParams } from "../../../commons/hooks/useStateWithUrlParams";
import { Page, PageTopbarItem } from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { PaginationModel } from "../../../commons/pagination/usePaginationParams";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants";
import { CraftsmenSelectModal } from "../../../features/craftsman/CraftsmenSelectModal";
import { useJsonExport } from "../../../features/jsonImportExport/useJsonExport";
import { OrganisationAvatar } from "../../../features/organisations/OrganisationAvatar";
import { OrganisationAvatarFragment } from "../../../features/organisations/OrganisationAvatar.generated";
import { useOrganisationTags } from "../../../features/organisations/useOrganisationTags";
import { ImportTemplatesMenuItem } from "../../../features/templates/buttons/ImportTemplatesMenuItem";
import {
  templateFiltersDefaultValue,
  TemplateFiltersFormValues,
  templateFiltersValidationSchema,
} from "../../../features/templates/filters/TemplateFilterFields";
import { TemplateFilters } from "../../../features/templates/filters/TemplateFilters";
import { CreateQuoteTemplateButton } from "../../../features/templates/quote/buttons/CreateTemplateQuoteButton";
import { QuoteTemplateAddToShopButton } from "../../../features/templates/quote/buttons/QuoteTemplateAddToShopButton";
import {
  useAddQuoteTemplateToShopMutation,
  useRemoveQuoteTemplateFromShopMutation,
} from "../../../features/templates/quote/buttons/QuoteTemplateAddToShopButton.generated";
import { usePublishQuoteTemplateMutation } from "../../../features/templates/quote/buttons/QuoteTemplatePublishButton.generated";
import {
  useShareQuoteTemplatePubliclyMutation,
  useShareQuoteTemplateWithOrganisationsMutation,
} from "../../../features/templates/quote/buttons/QuoteTemplateShareButton.generated";
import { QuoteTemplateSubscribeButton } from "../../../features/templates/quote/buttons/QuoteTemplateSubscribeButton";
import { useSubscribeToQuoteTemplateMutation } from "../../../features/templates/quote/buttons/QuoteTemplateSubscribeButton.generated";
import { useUnsubscribeFromQuoteTemplateMutation } from "../../../features/templates/quote/buttons/QuoteTemplateUnsubscribeButton.generated";
import { TemplateQuotePublishingStatus } from "../../../features/templates/quote/TemplateQuotePublishingStatus";
import { useDuplicateQuoteTemplateMutation } from "../../../features/templates/quote/TemplateQuotes.generated";
import { useTemplateQuoteStatuses } from "../../../features/templates/quote/TemplateQuoteStatuses";
import { useTemplateQuoteWarnings } from "../../../features/templates/quote/TemplateQuoteWarnings";
import {
  canShowChips,
  TemplatesQuoteFilterChips,
} from "../../../features/templates/quote/TemplatesQuoteFilterChips";
import { TemplateQuotesFilterModal } from "../../../features/templates/quote/TemplatesQuoteFilterModal";
import { useTemplatesQuoteList } from "../../../features/templates/quote/useTemplatesQuoteList";
import { useDataGridStateStore } from "../../../features/users/useDataGridStateStore";
import { buildDocPath } from "../../../utils";
import {
  TemplateQuotes_TemplateFragment,
  TemplateQuotesQueryVariables,
  useTemplateQuotesQuery,
  useTemplateQuotesSearchQuery,
} from "./TemplateQuotes.generated";

const ALLOWED_VIEWS: CollectionView[] = ["table", "list", "gallery"];

type SectionValue = "ALL" | "MY" | "MEISTER_STORE";
type QuickFilterValue =
  | "ALL_AVAILABLE"
  | "ALL_SHOP"
  | "MY_ALL"
  | "MY_DRAFT"
  | "MY_PRIVATE"
  | "MY_PROTECTED"
  | "MY_PUBLIC"
  | "MEISTER_STORE_ALL"
  | "MEISTER_STORE_SUBSCRIBED";
const QUICK_FILTER_TO_SECTION_MAP: Record<QuickFilterValue, SectionValue> = {
  ALL_AVAILABLE: "ALL",
  ALL_SHOP: "ALL",
  MY_ALL: "MY",
  MY_DRAFT: "MY",
  MY_PRIVATE: "MY",
  MY_PROTECTED: "MY",
  MY_PUBLIC: "MY",
  MEISTER_STORE_ALL: "MEISTER_STORE",
  MEISTER_STORE_SUBSCRIBED: "MEISTER_STORE",
};
const SECTION_TO_DEFAULT_QUICK_FILTER: Record<SectionValue, QuickFilterValue> =
  {
    ALL: "ALL_AVAILABLE",
    MY: "MY_ALL",
    MEISTER_STORE: "MEISTER_STORE_ALL",
  };

type Filters = OrganisationQuoteTemplatesFilters;

interface Props {
  submenuItems: PageTopbarItem[];
  section: SectionValue;
  pathToDocList: string;
}

export const TemplateQuotes = ({
  submenuItems,
  section,
  pathToDocList,
}: Props) => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Templates", "Global", "TemplatesSharing"]);
  const isShopEnabled = useFeature("Shop");

  const { isMinTablet } = useScreenWidth();

  const quickFilterSettings: Record<
    QuickFilterValue,
    { label: string; values: Filters; visible?: boolean }
  > = React.useMemo(
    () => ({
      ALL_AVAILABLE: {
        label: t("All", { ns: "Global" }),
        values: {
          sources: ["available"],
        },
      },
      ALL_SHOP: {
        label: t("Added to Shop", { ns: "Templates" }),
        values: {
          sources: ["available"],
          addedToShop: true,
        },
        visible:
          isShopEnabled && viewer.organisation.capabilities.includes("SHOP"),
      },
      MY_ALL: {
        label: t("All", { ns: "Global" }),
        values: {
          sources: ["mine_published", "mine_unpublished"],
        },
      },
      MY_DRAFT: {
        label: t("Draft", { ns: "Templates" }),
        values: {
          sources: ["mine_unpublished"],
        },
      },
      MY_PRIVATE: {
        label: t("Private", { ns: "TemplatesSharing" }),
        values: {
          sources: ["mine_published"],
          mineShareStatus: "PRIVATE",
        },
      },
      MY_PROTECTED: {
        label: t("Protected", { ns: "TemplatesSharing" }),
        values: {
          sources: ["mine_published"],
          mineShareStatus: "PROTECTED",
        },
      },
      MY_PUBLIC: {
        label: t("Public", { ns: "TemplatesSharing" }),
        values: {
          sources: ["mine_published"],
          mineShareStatus: "PUBLIC",
        },
      },
      MEISTER_STORE_ALL: {
        label: t("All", { ns: "Global" }),
        values: {
          sources: ["subscribable", "subscribed"],
        },
      },
      MEISTER_STORE_SUBSCRIBED: {
        label: t("Subscribed", { ns: "Templates" }),
        values: {
          sources: ["subscribed"],
        },
      },
    }),
    [t, isShopEnabled, viewer.organisation.capabilities]
  );

  const sectionTitles = React.useMemo(
    (): Record<SectionValue, string> => ({
      ALL: t("All Available", { ns: "Templates" }),
      MY: t("My Templates", { ns: "Templates" }),
      MEISTER_STORE: t("Meister Store", { ns: "Templates" }),
    }),
    [t]
  );

  const {
    paginationModel,
    setPaginationModel,
    filters,
    setFilters,
    resetFilters,
    sorting,
    setSorting,
    activeView,
    setActiveView,
    queryBaseVariables,
    toRemoveParams,
    searchTerm,
    setSearchTerm,
  } = useTemplatesQuoteList({
    activeViewStorageKey: "templates",
    activeViewInitialDesktopValue: "table",
    activeViewInitialMobileValue: "list",
    initialFilters: {},
  });

  const [quickFilter, setQuickFilter] = useStateWithUrlParam<QuickFilterValue>(
    "quickfilter",
    SECTION_TO_DEFAULT_QUICK_FILTER[section],
    toRemoveParams
  );

  const [templateFilters, setTemplateFilters] =
    useStateWithUrlParams<TemplateFiltersFormValues>(
      "templateFilters",
      templateFiltersDefaultValue,
      toRemoveParams
    );

  const activeQuickFilterOptions = React.useMemo<
    { label: string; value: QuickFilterValue }[]
  >(
    () =>
      Object.entries(quickFilterSettings)
        .filter(([, value]) => value?.visible !== false)
        .map(([key, value]) => ({
          value: key as QuickFilterValue,
          label: value.label,
        }))
        .filter(o => QUICK_FILTER_TO_SECTION_MAP[o.value] === section),
    [quickFilterSettings, section]
  );

  const variables: TemplateQuotesQueryVariables = {
    ...queryBaseVariables,
    sorting,
    searchTerm,
    filters: {
      ...quickFilterSettings[quickFilter].values,
      ...filters,
      createdAfter: filters?.createdAfter?.format("YYYY-MM-DD"),
      createdBefore: filters?.createdBefore?.format("YYYY-MM-DD"),
    },
  };

  const { customTags } = useOrganisationTags("TEMPLATE");

  const [selectedItems, setSelectedItems] = React.useState<
    TemplateQuotes_TemplateFragment[]
  >([]);
  const resetSelectedItems = React.useCallback(() => setSelectedItems([]), []);
  React.useEffect(() => {
    resetSelectedItems();
  }, [section, quickFilter, resetSelectedItems]);

  const { actionButtons } = useBulkActions(
    quickFilter,
    selectedItems,
    resetSelectedItems
  );

  // we need an inner formik state to not immediately apply filters on the list
  const formikProps = useFormik<TemplateFiltersFormValues>({
    initialValues: templateFilters,
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      setTemplateFilters(newValues);
    },
  });

  useAutoSave({
    enableReinitialize: true,
    initialValues: templateFilters,
    formikProps,
  });

  return (
    <Page
      // title={t("Quote Templates", {
      //   ns: "Templates",
      // })}
      title={sectionTitles[section]}
      subtitle={t("Document Templates", {
        ns: "Templates",
      })}
      submenuItems={submenuItems}
      action={actionButtons}
    >
      <PageContainer>
        <ListHeader
          // hideTitleOnMobile
          // title={t("Document Templates", {
          //   ns: "Templates",
          // })}
          CreateButton={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
              <CreateQuoteTemplateButton pathToDocList={pathToDocList} />
            </RestrictedByOrganisationPermissionWithDebug>
          }
          MenuButton={
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
              <MenuButton>
                <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
                  <ImportTemplatesMenuItem />
                </RestrictedByOrganisationPermissionWithDebug>
              </MenuButton>
            </RestrictedByOrganisationPermissionWithDebug>
          }
          InlineFilters={
            isMinTablet && quickFilter === "ALL_AVAILABLE" ? (
              <TemplateFilters
                formikProps={formikProps}
                templateSearchVariables={{
                  searchTerm: variables.searchTerm,
                  filters: variables.filters,
                }}
                viewType="buttons"
              />
            ) : undefined
          }
          QuickFilter={
            activeQuickFilterOptions.length > 1 ? (
              <Tabs
                condensed
                useSelectOnMobile
                options={activeQuickFilterOptions}
                value={quickFilter}
                onChange={(newQuickFilter: QuickFilterValue) => {
                  setQuickFilter(newQuickFilter);
                }}
              />
            ) : undefined
          }
          FilterButton={
            <ModalOpenButton
              Modal={TemplateQuotesFilterModal}
              modalProps={{
                resetFilters,
                sorting,
                setSorting,
                filters,
                setFilters,
                tags: customTags,
                templateFiltersProps:
                  quickFilter === "ALL_AVAILABLE"
                    ? {
                        formikProps,
                        templateSearchVariables: {
                          searchTerm: variables.searchTerm,
                          filters: variables.filters,
                        },
                      }
                    : undefined,
              }}
            >
              <FilterButton />
            </ModalOpenButton>
          }
          SwitchViewButton={
            <SwitchCollectionViewButton
              allowedViews={ALLOWED_VIEWS}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          }
          FilterChips={
            canShowChips(filters) ? (
              <TemplatesQuoteFilterChips
                filters={filters}
                setFilters={setFilters}
              />
            ) : undefined
          }
          SearchField={
            <DebouncedSearchInput
              placeholder={t("Search", { ns: "Global" })}
              defaultValue={searchTerm}
              onChangeSearchTerm={newValue => setSearchTerm(newValue)}
            />
          }
          mb={2}
        />
        {quickFilter === "ALL_AVAILABLE" ? (
          <TemplateQuotesSearchList
            variables={variables}
            templateFilters={templateFilters}
            section={section}
            pathToDocList={pathToDocList}
            quickFilter={quickFilter}
            activeView={activeView}
            sorting={sorting}
            setSorting={setSorting}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        ) : (
          <TemplateQuotesNormalList
            variables={variables}
            section={section}
            pathToDocList={pathToDocList}
            quickFilter={quickFilter}
            activeView={activeView}
            sorting={sorting}
            setSorting={setSorting}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )}
      </PageContainer>
    </Page>
  );
};

function TemplateQuotesNormalList({
  variables,
  ...props
}: {
  variables: TemplateQuotesQueryVariables;
} & TemplateQuotesListProps) {
  const client = useApolloClient();
  const query = useTemplateQuotesQuery({
    client,
    variables,
  });

  const items = getDataOrNull(
    (query.data ?? query.previousData)?.quoteTemplates
  )?.edges.map(e => e.node);
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.quoteTemplates)
      ?.totalCount ?? 0;

  return (
    <TemplateQuotesList
      items={items ?? []}
      totalCount={totalCount}
      loading={query.loading}
      {...props}
    />
  );
}

function TemplateQuotesSearchList({
  variables,
  templateFilters,
  ...props
}: {
  variables: TemplateQuotesQueryVariables;
  templateFilters: TemplateFiltersFormValues;
} & TemplateQuotesListProps) {
  const client = useApolloClient();
  const query = useTemplateQuotesSearchQuery({
    client,
    variables: {
      ...variables,
      sorting: (variables.sorting ?? []).map(s => ({
        fieldSorting: {
          field: {
            createdAt: "createdAt" as const,
            discountedPrice: "priceNetTotal" as const,
            title: "title" as const,
            totalPrice: "priceTotal" as const,
          }[s.column],
          direction: s.direction,
        },
      })),
      filters: {
        ...variables.filters,
        ...templateFilters,
      },
    },
  });

  const items = getDataOrNull(
    (query.data ?? query.previousData)?.searchTemplates
  )?.edges.map(e => e.node);
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.searchTemplates)
      ?.totalCount ?? 0;

  return (
    <TemplateQuotesList
      items={items ?? []}
      totalCount={totalCount}
      loading={query.loading}
      {...props}
    />
  );
}

type TemplateQuotesListProps = {
  section: SectionValue;
  pathToDocList: string;
  quickFilter: QuickFilterValue;
  activeView: CollectionView;
  sorting: OrganisationQuoteTemplatesSorting[];
  setSorting: (newSorting: OrganisationQuoteTemplatesSorting[]) => void;
  paginationModel: PaginationModel;
  setPaginationModel: (newPaginationModel: PaginationModel) => void;
  selectedItems: TemplateQuotes_TemplateFragment[];
  setSelectedItems: React.Dispatch<
    React.SetStateAction<TemplateQuotes_TemplateFragment[]>
  >;
};

function TemplateQuotesList({
  items,
  totalCount,
  loading,
  section,
  pathToDocList,
  quickFilter,
  activeView,
  sorting,
  setSorting,
  paginationModel,
  setPaginationModel,
  selectedItems,
  setSelectedItems,
}: {
  items: TemplateQuotes_TemplateFragment[];
  totalCount: number;
  loading: boolean;
} & TemplateQuotesListProps) {
  const navigate = useNavigate();

  const viewer = useUserData().currentUser!;
  const { t } = useTranslate(["Templates", "Global", "TemplatesSharing"]);
  const isShopEnabled = useFeature("Shop");
  const { getFormattedDate, getFormattedPrice } = useFormatting();

  const { spacing, sizeMeasureRef, columns } = useCardsNumber();

  const { getTemplateQuoteWarningIcon } = useTemplateQuoteWarnings();
  const { getTemplateQuoteStatusIcons } = useTemplateQuoteStatuses();

  const stateStore = useDataGridStateStore("TemplateQuotesList");

  const quoteTemplateColumns = React.useMemo(
    (): GridColDef<TemplateQuotes_TemplateFragment>[] => [
      {
        field: "title",
        headerName: t("Title", {
          ns: "Templates",
        }),
        flex: 3,
        minWidth: 150,
        renderCell: ({ row: template }) => template.title,
      },
      {
        field: "discountedPrice",
        headerName: t("Price", {
          ns: "Templates",
        }),
        width: 120,
        headerAlign: "right",
        align: "right",
        renderCell: ({ row: template }) =>
          getFormattedPrice(template.proposedCalculation?.priceNetTotal || 0),
      },
      {
        field: "tags",
        headerName: t("Tags", {
          ns: "Templates",
        }),
        flex: 1.5,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: template }) => (
          <TagChips tags={template.customTags} wrap={false} />
        ),
      },
      {
        field: "publishingStatus",
        headerName: t("Publishing", {
          ns: "Templates",
        }),
        flex: 0.75,
        minWidth: 100,
        sortable: false,
        renderCell: ({ row: template }) => (
          <TemplateQuotePublishingStatus
            doc={template}
            variant="body2"
            IconProps={{ fontSize: "small" }}
          />
        ),
      },
      {
        field: "createdAt",
        headerName: t("Created", {
          ns: "Templates",
        }),
        width: 120,
        renderCell: ({ row: template }) => getFormattedDate(template.createdAt),
      },

      ...(section === "MEISTER_STORE" || section === "ALL"
        ? ([
            {
              field: "subscribed",
              headerName: t("Subscribed", {
                ns: "Templates",
              }),
              flex: 0.75,
              minWidth: 140,
              sortable: false,
              renderCell: ({ row: template }) => {
                if (
                  template.owningSystemOrganisationId === viewer.organisation.id
                ) {
                  return null;
                }

                return (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    {!template.subscribedToTemplate ? (
                      <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
                        <QuoteTemplateSubscribeButton
                          doc={template}
                          size="extra-small"
                        />
                      </RestrictedByOrganisationPermissionWithDebug>
                    ) : template.subscribedToTemplate.updateAvailable ? (
                      <Tooltip
                        title={t("New version available", { ns: "Templates" })}
                      >
                        <WarningIcon color="warning" />
                      </Tooltip>
                    ) : (
                      <CheckCircleIcon color="success" />
                    )}
                  </Stack>
                );
              },
            },
          ] as GridColDef<TemplateQuotes_TemplateFragment>[])
        : []),
      ...(section === "ALL" &&
      isShopEnabled &&
      viewer.organisation.capabilities.includes("SHOP")
        ? ([
            {
              field: "shopButton",
              headerName: "",
              flex: 0.75,
              minWidth: 200,
              sortable: false,
              renderCell: ({ row: template }) => (
                <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
                  <QuoteTemplateAddToShopButton
                    doc={template}
                    size="extra-small"
                  />
                </RestrictedByOrganisationPermissionWithDebug>
              ),
            },
          ] as GridColDef<TemplateQuotes_TemplateFragment>[])
        : []),
      {
        field: "actions",
        headerName: "",
        filterable: false,
        maxWidth: 80,
        minWidth: 40,
        renderCell: ({ row: template }) => (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
              <IconButton
                component={Link}
                to={
                  buildDocPath(
                    `${pathToDocList}/${template.id}`,
                    template.resolvedAsReadModelVersionNumber ?? null
                  ) + "/troubleshoot"
                }
                onClick={event => {
                  event.stopPropagation();
                }}
                color={"primary"}
                sx={{ marginLeft: "-10px" }}
              >
                <BugReportIcon />
              </IconButton>
            </RestrictedByOrganisationPermissionWithDebug>
          </Stack>
        ),
      },
    ],
    [
      t,
      section,
      isShopEnabled,
      viewer.organisation.capabilities,
      viewer.organisation.id,
      getFormattedPrice,
      getFormattedDate,
      pathToDocList,
    ]
  );

  if (!loading && items.length === 0)
    return quickFilter === "ALL_AVAILABLE" ? (
      <EmptyScreen slug="templates-quotes" />
    ) : (
      <Box display={"flex"} justifyContent="center" alignItems="center" p={2}>
        <Typography color="grey.500">
          {t("There are no quote templates.", { ns: "Templates" })}
        </Typography>
      </Box>
    );

  switch (activeView) {
    case "table":
      return (
        <DataGrid<TemplateQuotes_TemplateFragment>
          stateStore={stateStore}
          loading={loading}
          hideFooter={totalCount === 0}
          columns={quoteTemplateColumns}
          rows={items}
          sortModel={sorting.map(s => ({
            field: s.column,
            sort: s.direction,
          }))}
          onSortModelChange={newModel => {
            setSorting(
              newModel.map(({ field, sort }) => ({
                column: field as OrganisationQuoteTemplatesSortBy,
                direction: sort ?? "asc",
              }))
            );
          }}
          onRowClick={({ row: template }) => {
            return navigate(
              buildDocPath(
                `${pathToDocList}/${template.id}`,
                template.resolvedAsReadModelVersionNumber ?? null
              )
            );
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={newPaginationModel => {
            setPaginationModel(newPaginationModel);
          }}
          disableColumnFilter
          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
          rowCount={totalCount}
          checkboxSelection
          onRowSelectionModelChange={model => {
            const toAdd = model
              .filter(id => !selectedItems.find(item => item.id === id))
              .map(id => items.find(item => item.id === id))
              .filter(item => item) as TemplateQuotes_TemplateFragment[]; // FIXME
            setSelectedItems([
              ...selectedItems.filter(item => model.includes(item.id)),
              ...toAdd,
            ]);
          }}
          rowSelectionModel={selectedItems.map(item => item.id)}
        />
      );
    case "list": {
      if (loading) {
        return <LoadingSpinner />;
      }
      return (
        <Stack spacing={1}>
          {items.map((item, index) => {
            const statusIcons: React.ReactNode[] = [
              ...getTemplateQuoteStatusIcons(item),
              getTemplateQuoteWarningIcon(`${pathToDocList}/${item.id}`, item),
            ].filter(Boolean);
            return (
              <MediaListItem
                key={item.id}
                // @ts-ignore
                component={Link}
                to={buildDocPath(
                  `${pathToDocList}/${item.id}`,
                  item.resolvedAsReadModelVersionNumber ?? null
                )}
                title={item.title}
                description={item.description}
                price={getFormattedPrice(
                  item.proposedCalculation?.priceNetTotal || 0
                )}
                imageUrl={item.shopCatalogProductImage?.url}
                // ActionButton={[
                //   ...(section === "ALL" && isShopEnabled && viewer.organisation.capabilities.includes("SHOP")
                //     ? [
                //         <QuoteTemplateAddToShopButton
                //           doc={item}
                //           size="extra-small"
                //         />,
                //       ]
                //     : []),
                //   ...(section === "MEISTER_STORE"
                //     ? [
                //         <QuoteTemplateSubscribeButton
                //           doc={item}
                //           size="extra-small"
                //         />,
                //       ]
                //     : []),
                // ]}
                Tags={<TagChips tags={item.customTags} wrap={false} />}
                StatusIcons={statusIcons}
              />
            );
          })}
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      );
    }
    case "gallery": {
      if (loading) {
        return <LoadingSpinner />;
      }
      return (
        <Stack spacing={1} ref={sizeMeasureRef}>
          <Grid container spacing={spacing}>
            {items.map((item, index) => {
              const statusIcons: React.ReactNode[] = [
                ...getTemplateQuoteStatusIcons(item),
                getTemplateQuoteWarningIcon(
                  `${pathToDocList}/${item.id}`,
                  item
                ),
              ].filter(Boolean);
              return (
                <Grid key={item.id} item {...columns}>
                  <MediaCardItem
                    key={item.id}
                    // @ts-ignore
                    component={Link}
                    to={buildDocPath(
                      `${pathToDocList}/${item.id}`,
                      item.resolvedAsReadModelVersionNumber ?? null
                    )}
                    title={item.title}
                    description={item.description}
                    price={getFormattedPrice(
                      item.proposedCalculation?.priceNetTotal || 0
                    )}
                    imageUrl={item.shopCatalogProductImage?.url}
                    // ActionButton={[
                    //   ...(section === "ALL" && isShopEnabled && viewer.organisation.capabilities.includes("SHOP")
                    //     ? [
                    //         <QuoteTemplateAddToShopButton
                    //           doc={item}
                    //           size="extra-small"
                    //         />,
                    //       ]
                    //     : []),
                    //   ...(section === "MEISTER_STORE"
                    //     ? [
                    //         <QuoteTemplateSubscribeButton
                    //           doc={item}
                    //           size="extra-small"
                    //         />,
                    //       ]
                    //     : []),
                    // ]}
                    Tags={<TagChips tags={item.customTags} wrap={true} />}
                    StatusIcons={statusIcons}
                  />
                </Grid>
              );
            })}
          </Grid>
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationModel.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: newPage,
              });
            }}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={event => {
              setPaginationModel({
                pageSize: parseInt(event.target.value, 10),
                page: 0,
              });
            }}
            labelRowsPerPage={t("Per page:", {
              ns: "Global",
            })}
          />
        </Stack>
      );
    }
  }
}

type Action =
  // | "delete"
  | "duplicate"
  | "publish"
  | "share"
  | "subscribe"
  | "unsubscribe"
  | "add_to_shop"
  | "remove_from_shop"
  | "export";

function useBulkActions(
  quickFilter: QuickFilterValue,
  selectedItems: TemplateQuotes_TemplateFragment[],
  resetSelectedItems: () => void
) {
  const viewer = useUserData().currentUser!;

  const { t } = useTranslate([
    "Templates",
    "TemplatesSharing",
    "TemplatesPublishing",
  ]);

  const isShopEnabled = useFeature("Shop");

  const availableActions = getAvailableActionsForQuickFilter(
    quickFilter
  ).filter(action => doesAnyItemSupportAction(viewer, action, selectedItems));

  const buttons: Record<Action, React.ReactNode> = {
    // delete: <Button variant="contained">Delete</Button>,
    duplicate: (
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <DuplicateItemsButton
          key={"duplicate"}
          items={selectedItems}
          handleComplete={resetSelectedItems}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    ),
    publish: (
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <PublishItemsButton
          key={"publish"}
          items={selectedItems}
          handleComplete={resetSelectedItems}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    ),
    share: (
      <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
        <ShareItemsButton
          key={"share"}
          items={selectedItems}
          handleComplete={resetSelectedItems}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    ),
    subscribe: (
      <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
        <SubscribeItemsButton
          key={"subscribe"}
          items={selectedItems}
          handleComplete={resetSelectedItems}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    ),
    unsubscribe: (
      <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
        <UnsubscribeItemsButton
          key={"unsubscribe"}
          items={selectedItems}
          handleComplete={resetSelectedItems}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    ),
    add_to_shop: isShopEnabled ? (
      <RestrictedByCapabilityWithDebug capability="SHOP">
        <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
          <AddItemsToShopButton
            key={"add_to_shop"}
            items={selectedItems}
            handleComplete={resetSelectedItems}
          />
        </RestrictedByOrganisationPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ) : null,
    remove_from_shop: isShopEnabled ? (
      <RestrictedByCapabilityWithDebug capability="SHOP">
        <RestrictedByOrganisationPermissionWithDebug permission="SHARE_TEMPLATE">
          <RemoveItemsFromShopButton
            key={"remove_from_shop"}
            items={selectedItems}
            handleComplete={resetSelectedItems}
          />
        </RestrictedByOrganisationPermissionWithDebug>
      </RestrictedByCapabilityWithDebug>
    ) : null,
    export: (
      <RestrictedByOrganisationPermissionWithDebug permission="MANAGE_TEMPLATE">
        <ExportButton
          key={"export"}
          items={selectedItems}
          handleComplete={resetSelectedItems}
        />
      </RestrictedByOrganisationPermissionWithDebug>
    ),
  };

  const actionButtons =
    selectedItems.length > 0
      ? availableActions.length === 0
        ? t("No actions available", {
            ns: "Templates",
          })
        : availableActions.map(action => buttons[action])
      : [];

  return { actionButtons };
}

function getAvailableActionsForQuickFilter(
  quickFilter: QuickFilterValue
): Action[] {
  const quickFilterAvailableActions: Record<QuickFilterValue, Action[]> = {
    ALL_AVAILABLE: ["add_to_shop", "remove_from_shop"],
    ALL_SHOP: ["remove_from_shop"],
    MY_ALL: [
      "duplicate",
      "publish",
      "share",
      "add_to_shop",
      "remove_from_shop",
      "export",
      // "delete"
    ],
    MY_DRAFT: [
      "duplicate",
      "publish",
      "export",
      // "delete"
    ],
    MY_PRIVATE: [
      "duplicate",
      "share",
      "add_to_shop",
      "remove_from_shop",
      "export",
      // "delete"
    ],
    MY_PROTECTED: [
      "duplicate",
      "share",
      "add_to_shop",
      "remove_from_shop",
      "export",
      // "delete"
    ],
    MY_PUBLIC: [
      "duplicate",
      "share",
      "add_to_shop",
      "remove_from_shop",
      "export",
      // "delete"
    ],
    MEISTER_STORE_ALL: [
      "subscribe",
      "unsubscribe",
      "add_to_shop",
      "remove_from_shop",
    ],
    MEISTER_STORE_SUBSCRIBED: [
      "unsubscribe",
      "add_to_shop",
      "remove_from_shop",
    ],
  };

  return quickFilterAvailableActions[quickFilter];
}

function doesAnyItemSupportAction(
  viewer: ViewerShapeFragment,
  action: Action,
  items: TemplateQuotes_TemplateFragment[]
) {
  switch (action) {
    case "duplicate":
      return items.some(
        item => item.owningSystemOrganisationId === viewer.organisation.id
      );
    case "publish":
      return items.some(item => item.hasAnyPublishedVersion === false);
    case "share":
      return items.some(
        item =>
          item.sharedPublicly === false && item.hasAnyPublishedVersion === true
      );
    case "subscribe":
      return items.some(item => !item.subscribedToTemplate);
    case "unsubscribe":
      return items.some(item => !!item.subscribedToTemplate);
    case "add_to_shop":
      return items.some(
        item =>
          item.addedToShop === false && item.hasAnyPublishedVersion === true
      );
    case "remove_from_shop":
      return items.some(item => item.addedToShop === true);
    case "export":
      return true;
    default:
      return assertNever(action);
  }
}

function ExportButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { t } = useTranslate("Templates");
  const [exportTemplates, { isLoading }] = useJsonExport();

  return (
    <LoadingButton
      variant="contained"
      startIcon={
        <Icon>
          <FontAwesomeIcon icon={faFileExport} />
        </Icon>
      }
      onClick={async () => {
        const { downloadUrl, filename } = await exportTemplates(
          items.map(item => ({
            docId: item.id,
            versionNumber: item.resolvedAsReadModelVersionNumber ?? null,
          }))
        );
        download(downloadUrl, filename);
        if (handleComplete) handleComplete();
      }}
      loading={isLoading}
    >
      {t("Export templates")}
    </LoadingButton>
  );
}

function DuplicateItemsButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["Quote", "Global"]);
  const viewer = useUserData().currentUser!;

  const client = useApolloClient();
  const [duplicateQuoteTemplateMutation, { loading }] =
    useDuplicateQuoteTemplateMutation({
      client,
      refetchQueries: [namedOperations.Query.TemplateQuotes],
    });

  return (
    <BulkActionButton
      text={t("Duplicate", {
        ns: "Quote",
      })}
      action={async item => {
        await duplicateQuoteTemplateMutation({
          variables: {
            input: {
              title: getNameWithCopy(
                item.title,
                t("copy", {
                  ns: "Global",
                })
              ),
              quoteTemplateId: item.id,
            },
          },
        });
      }}
      items={items.filter(
        item => item.owningSystemOrganisationId === viewer.organisation.id
      )}
      startIcon={<ControlPointDuplicateIcon />}
      loading={loading}
      handleComplete={itemCount => {
        if (handleComplete) handleComplete();
        enqueueSnackbar(
          t(
            "{count, plural, =0 {No templates} one {One template} other {# templates}} duplicated",
            {
              ns: "Quote",
              count: itemCount,
            }
          )
        );
      }}
    />
  );
}
function PublishItemsButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesSharing"]);

  const client = useApolloClient();
  const [publish, { loading }] = usePublishQuoteTemplateMutation({
    client,
    refetchQueries: [namedOperations.Query.TemplateQuotes],
  });

  return (
    <BulkActionButton
      text={t("Publish", {
        ns: "TemplatesSharing",
      })}
      action={async item => {
        await publish({ variables: { input: { docId: item.id } } });
      }}
      items={items.filter(item => item.hasAnyPublishedVersion === false)}
      startIcon={<PublishIcon />}
      loading={loading}
      handleComplete={itemCount => {
        if (handleComplete) handleComplete();
        enqueueSnackbar(
          t(
            "{count, plural, =0 {No templates} one {One template} other {# templates}} published",
            {
              ns: "TemplatesSharing",
              count: itemCount,
            }
          )
        );
      }}
    />
  );
}
function SubscribeItemsButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesPublishing"]);

  const client = useApolloClient();
  const [subscribe, { loading }] = useSubscribeToQuoteTemplateMutation({
    client,
    refetchQueries: [namedOperations.Query.TemplateQuotes],
  });
  return (
    <BulkActionButton
      text={t("Subscribe", {
        ns: "TemplatesPublishing",
      })}
      action={async item => {
        await subscribe({ variables: { input: { quoteTemplateId: item.id } } });
      }}
      items={items.filter(item => !item.subscribedToTemplate)}
      startIcon={<BookmarkAddIcon />}
      loading={loading}
      handleComplete={itemCount => {
        if (handleComplete) handleComplete();
        enqueueSnackbar(
          t(
            "{count, plural, =0 {No templates} one {One template} other {# templates}} subscribed",
            {
              ns: "TemplatesPublishing",
              count: itemCount,
            }
          )
        );
      }}
    />
  );
}
function UnsubscribeItemsButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesPublishing"]);

  const client = useApolloClient();
  const [unsubscribe, { loading }] = useUnsubscribeFromQuoteTemplateMutation({
    client,
    refetchQueries: [namedOperations.Query.TemplateQuotes],
  });
  return (
    <BulkActionButton
      text={t("Unsubscribe", {
        ns: "TemplatesPublishing",
      })}
      action={async item => {
        await unsubscribe({
          variables: { input: { quoteTemplateId: item.id } },
        });
      }}
      items={items.filter(item => !!item.subscribedToTemplate)}
      startIcon={<BookmarkRemoveIcon />}
      loading={loading}
      handleComplete={itemCount => {
        if (handleComplete) handleComplete();
        enqueueSnackbar(
          t(
            "{count, plural, =0 {No templates} one {One template} other {# templates}} unsubscribed",
            {
              ns: "TemplatesPublishing",
              count: itemCount,
            }
          )
        );
      }}
    />
  );
}
function AddItemsToShopButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesPublishing"]);

  const client = useApolloClient();
  const [addToShop, { loading }] = useAddQuoteTemplateToShopMutation({
    client,
    refetchQueries: [namedOperations.Query.TemplateQuotes],
  });

  return (
    <BulkActionButton
      text={t("Add to shop", {
        ns: "TemplatesPublishing",
      })}
      action={async item => {
        await addToShop({ variables: { input: { quoteTemplateId: item.id } } });
      }}
      items={items.filter(
        item =>
          item.addedToShop === false && item.hasAnyPublishedVersion === true
      )}
      startIcon={<AddIcon />}
      loading={loading}
      handleComplete={itemCount => {
        if (handleComplete) handleComplete();
        enqueueSnackbar(
          t(
            "{count, plural, =0 {No templates} one {One template} other {# templates}} added to shop",
            {
              ns: "TemplatesPublishing",
              count: itemCount,
            }
          )
        );
      }}
    />
  );
}
function RemoveItemsFromShopButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesPublishing"]);

  const client = useApolloClient();
  const [removeFromShop, { loading }] = useRemoveQuoteTemplateFromShopMutation({
    client,
    refetchQueries: [namedOperations.Query.TemplateQuotes],
  });

  return (
    <BulkActionButton
      text={t("Remove from shop", {
        ns: "TemplatesPublishing",
      })}
      action={async item => {
        await removeFromShop({
          variables: { input: { quoteTemplateId: item.id } },
        });
      }}
      items={items.filter(item => item.addedToShop === true)}
      startIcon={<DeleteIcon />}
      loading={loading}
      handleComplete={itemCount => {
        if (handleComplete) handleComplete?.();
        enqueueSnackbar(
          t(
            "{count, plural, =0 {No templates} one {One template} other {# templates}} removed from shop",
            {
              ns: "TemplatesPublishing",
              count: itemCount,
            }
          )
        );
      }}
    />
  );
}
function BulkActionButton<Item extends { id: string; title: string }>({
  text,
  items,
  action,
  handleComplete,
  ...props
}: {
  text: string;
  items: Item[];
  action: (item: Item) => Promise<void>;
  handleComplete?: (itemCount: number) => void;
} & Omit<LoadingButtonProps, "action">) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <LoadingButton
      variant="contained"
      onClick={async () => {
        try {
          await Promise.all(items.map(action));
          if (handleComplete) handleComplete(items.length);
        } catch (error) {
          if (error instanceof Error)
            enqueueSnackbar(error.message, { variant: "error" });
        }
      }}
      {...props}
    >
      {text}
    </LoadingButton>
  );
}

function ShareItemsButton({
  items,
  handleComplete,
}: {
  items: TemplateQuotes_TemplateFragment[];
  handleComplete?: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesSharing"]);

  return (
    <ModalOpenButton
      Modal={QuoteTemplatesShareModal}
      modalProps={{
        templates: items.filter(
          item =>
            item.sharedPublicly === false &&
            item.hasAnyPublishedVersion === true
        ),
        handleComplete: count => {
          if (handleComplete) handleComplete();
          enqueueSnackbar(
            t(
              "{count, plural, =0 {No templates} one {One template} other {# templates}} sharing updated",
              {
                ns: "TemplatesSharing",
                count,
              }
            )
          );
        },
      }}
    >
      <Button startIcon={<GroupAddIcon />} variant="contained" color="primary">
        {t("Share", {
          ns: "TemplatesSharing",
        })}
      </Button>
    </ModalOpenButton>
  );
}

type ShareType = "public" | "protected";
interface QuoteTemplateShareModalFormValues {
  type: ShareType;
  companies: OrganisationAvatarFragment[];
}

const QuoteTemplatesShareModal = ({
  templates,
  handleComplete,
  handleClose,
}: {
  templates: TemplateQuotes_TemplateFragment[];
  handleComplete?: (updatedItemCount: number) => void;
  handleClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate(["TemplatesSharing", "Global"]);

  const client = useApolloClient();

  const [shareWithOrganisations] =
    useShareQuoteTemplateWithOrganisationsMutation({
      client,
    });
  const [sharePublicly] = useShareQuoteTemplatePubliclyMutation({
    client,
  });

  const formId = React.useMemo(() => uniqueId(), []);

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 10 });

  const initialValues: QuoteTemplateShareModalFormValues = {
    type: "protected",
    companies: [],
  };

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().required(),
        companies: Yup.array()
          .test(
            "notEmptyInCaseOfProtected",
            t("Should not be empty", {
              ns: "TemplatesSharing",
            }),
            function (companies) {
              const type = this.resolve(Yup.ref("type")) as ShareType;
              if (
                type === "protected" &&
                (!companies || companies.length === 0)
              )
                return false;
              return true;
            }
          )
          .required(),
      }),
    [t]
  );

  const handleSubmit = async (values: QuoteTemplateShareModalFormValues) => {
    try {
      const sharedWithSystemOrganisationIds =
        values.type === "protected"
          ? values.companies.map(company => company.id)
          : [];

      await Promise.all(
        templates.map(async template => {
          if (values.type === "public") {
            await sharePublicly({
              variables: {
                input: {
                  quoteTemplateId: template.id,
                },
              },
            });
          } else if (values.type === "protected") {
            await shareWithOrganisations({
              variables: {
                input: {
                  quoteTemplateId: template.id,
                  sharedWithSystemOrganisationIds,
                },
              },
            });
          } else {
            throw new Error("Invalid sharing type");
          }
        })
      );
      handleComplete?.(templates.length);
      handleClose();
    } catch (e) {
      if (e instanceof Error) enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const stateStore = useDataGridStateStore("QuoteTemplatesShareModal");

  return (
    <Formik<QuoteTemplateShareModalFormValues>
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, dirty, isValid, values }) => (
        <Modal
          title={t(
            "Share {count, plural, =0 {no templates} one {one template} other {# templates}}",
            {
              ns: "TemplatesSharing",
              count: templates.length,
            }
          )}
          dialogProps={{ maxWidth: "sm" }}
          handleClose={handleClose}
          actionButtons={[
            {
              label: t("Close", {
                ns: "Global",
              }),
              handleClick: handleClose,
              buttonProps: { variant: "text" },
            },
            {
              label: t("Save", {
                ns: "Global",
              }),
              buttonProps: {
                loading: isSubmitting,
                form: formId,
                type: "submit",
                disabled: !dirty || !isValid,
              },
            },
          ]}
        >
          <Form id={formId}>
            <Stack direction="column" spacing={1}>
              <RadioGroupField
                name="type"
                options={[
                  {
                    label: t("Public", {
                      ns: "TemplatesSharing",
                    }),
                    subLabel: t("Available to all organisations", {
                      ns: "TemplatesSharing",
                    }),
                    value: "public",
                  },
                  {
                    label: t("Protected", {
                      ns: "TemplatesSharing",
                    }),
                    subLabel: t("Available to selected organisations", {
                      ns: "TemplatesSharing",
                    }),
                    value: "protected",
                  },
                ]}
                condensed={false}
              />
              {values.type === "protected" && (
                <FieldArray
                  name="companies"
                  render={arrayHelpers => (
                    <>
                      {values.companies.length > 0 && (
                        <DataGrid<OrganisationAvatarFragment>
                          stateStore={stateStore}
                          sx={{ border: "none" }}
                          density="standard"
                          hideFooter={Number(values.companies.length) === 0}
                          disableColumnFilter
                          columns={[
                            {
                              field: "companyName",
                              headerName: t("Craftsman company", {
                                ns: "TemplatesSharing",
                              }),
                              flex: 1,
                              minWidth: 100,
                              sortable: false,
                              renderCell: ({ row: item }) => (
                                <Stack
                                  alignItems="center"
                                  direction="row"
                                  spacing={1}
                                >
                                  <OrganisationAvatar
                                    size="s"
                                    organisationAvatar={item}
                                  />
                                  <Typography>{item.title}</Typography>
                                </Stack>
                              ),
                            },
                            {
                              field: "actions",
                              headerName: "",
                              flex: 0.1,
                              minWidth: 50,
                              sortable: false,
                              renderCell: ({ row: item }) => (
                                <IconButton
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const index = values.companies.findIndex(
                                      c => c.id === item.id
                                    );
                                    if (index > -1) arrayHelpers.remove(index);
                                  }}
                                  size="small"
                                  color="primary"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ),
                            },
                          ]}
                          rows={values.companies}
                          paginationMode="client"
                          paginationModel={paginationModel}
                          onPaginationModelChange={newPaginationModel => {
                            setPaginationModel(newPaginationModel);
                          }}
                          pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
                        />
                      )}
                      {values.companies.length === 0 && (
                        <Stack direction="row" justifyContent="center" py={1}>
                          <Typography
                            variant="caption"
                            align="center"
                            sx={{ color: theme => theme.palette.grey[400] }}
                          >
                            {t("You have not added any companies yet.", {
                              ns: "TemplatesSharing",
                            })}
                          </Typography>
                        </Stack>
                      )}
                      <Stack direction="row" justifyContent="center">
                        <ModalOpenButton
                          Modal={CraftsmenSelectModal}
                          modalProps={{
                            loading: isSubmitting,
                            handleComplete: organisations => {
                              for (const organisation of organisations)
                                arrayHelpers.push(organisation);
                            },
                            title: t("Add companies", {
                              ns: "TemplatesSharing",
                            }),
                            omitOrganisationIds: values.companies.map(
                              c => c.id
                            ),
                          }}
                        >
                          <Button
                            color="secondary"
                            variant="text"
                            startIcon={<AddIcon />}
                          >
                            {t("Add companies", {
                              ns: "TemplatesSharing",
                            })}
                          </Button>
                        </ModalOpenButton>
                      </Stack>
                    </>
                  )}
                />
              )}
            </Stack>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
