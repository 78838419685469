/* eslint-disable */
import * as Types from '../../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { BuildingRow__BuildingFragmentDoc } from '../Buildings.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuildingCreate__CreateBuildingMutationVariables = Types.Exact<{
  input: Types.BuildingCreateInput;
}>;


export type BuildingCreate__CreateBuildingMutation = { createBuilding: { __typename: 'BuildingCreateResult', building: { __typename: 'Building', id: string, title: string, buildingAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, rootItem: { __typename: 'Item', id: string, originVersionNumber?: number | null } } } };

export type BuildingCreateModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BuildingCreateModalQuery = { quoteTemplates: { __typename: 'MissingCapabilities' } | { __typename: 'MissingPermissions' } | { __typename: 'QuoteTemplateConnection', edges: Array<{ __typename: 'QuoteTemplateEdge', node: { __typename: 'QuoteTemplate', id: string, resolvedAsReadModelVersionNumber?: number | null, title: string } }> } };


export const BuildingCreate__CreateBuildingDocument = gql`
    mutation BuildingCreate__CreateBuilding($input: BuildingCreateInput!) {
  createBuilding(input: $input) {
    building {
      id
      ...BuildingRow__Building
    }
  }
}
    ${BuildingRow__BuildingFragmentDoc}`;
export type BuildingCreate__CreateBuildingMutationFn = Apollo.MutationFunction<BuildingCreate__CreateBuildingMutation, BuildingCreate__CreateBuildingMutationVariables>;

/**
 * __useBuildingCreate__CreateBuildingMutation__
 *
 * To run a mutation, you first call `useBuildingCreate__CreateBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildingCreate__CreateBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildingCreateCreateBuildingMutation, { data, loading, error }] = useBuildingCreate__CreateBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildingCreate__CreateBuildingMutation(baseOptions?: Apollo.MutationHookOptions<BuildingCreate__CreateBuildingMutation, BuildingCreate__CreateBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuildingCreate__CreateBuildingMutation, BuildingCreate__CreateBuildingMutationVariables>(BuildingCreate__CreateBuildingDocument, options);
      }
export type BuildingCreate__CreateBuildingMutationHookResult = ReturnType<typeof useBuildingCreate__CreateBuildingMutation>;
export type BuildingCreate__CreateBuildingMutationResult = Apollo.MutationResult<BuildingCreate__CreateBuildingMutation>;
export type BuildingCreate__CreateBuildingMutationOptions = Apollo.BaseMutationOptions<BuildingCreate__CreateBuildingMutation, BuildingCreate__CreateBuildingMutationVariables>;
export const BuildingCreateModalDocument = gql`
    query BuildingCreateModal {
  quoteTemplates(
    limit: 0
    filters: {sources: [available], applicableFor: BUILDING}
    sorting: {column: title, direction: asc}
  ) {
    ... on QuoteTemplateConnection {
      edges {
        node {
          id
          resolvedAsReadModelVersionNumber
          title
        }
      }
    }
  }
}
    `;

/**
 * __useBuildingCreateModalQuery__
 *
 * To run a query within a React component, call `useBuildingCreateModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingCreateModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingCreateModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuildingCreateModalQuery(baseOptions?: Apollo.QueryHookOptions<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>(BuildingCreateModalDocument, options);
      }
export function useBuildingCreateModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>(BuildingCreateModalDocument, options);
        }
export function useBuildingCreateModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>(BuildingCreateModalDocument, options);
        }
export type BuildingCreateModalQueryHookResult = ReturnType<typeof useBuildingCreateModalQuery>;
export type BuildingCreateModalLazyQueryHookResult = ReturnType<typeof useBuildingCreateModalLazyQuery>;
export type BuildingCreateModalSuspenseQueryHookResult = ReturnType<typeof useBuildingCreateModalSuspenseQuery>;
export type BuildingCreateModalQueryResult = Apollo.QueryResult<BuildingCreateModalQuery, BuildingCreateModalQueryVariables>;