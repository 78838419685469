import { useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { useTranslate } from "@tolgee/react";
import { useParams } from "react-router-dom";
import { Page, PageTopbarItem } from "../../../commons/layout/Page";
import { PageContainer } from "../../../commons/layout/PageContainer";
import { PageGrid } from "../../../commons/layout/PageGrid";
import { TemplateTypeOverviewPropertyBox } from "../../../features/templateTypes/TemplateTypeOverviewPropertyBox";
import { useTemplateTypeOverviewQuery } from "./TemplateTypeOverview.generated";
import { TemplateTypeOverviewSubHeader } from "./TemplateTypeOverviewSubHeader";
import { TemplateTypeOverviewHeaderBox } from "../../../features/templateTypes/TemplateTypeOverviewHeaderBox";

interface Props {
  submenuItems: PageTopbarItem[];
  pathToDocList: string;
}

export const TemplateTypeOverview = ({
  submenuItems,
  pathToDocList,
}: Props) => {
  const { id } = useParams();
  if (!id) throw new Error("TemplateType id is missing");

  const { t } = useTranslate(["TemplateTypes"]);

  const client = useApolloClient();
  const query = useTemplateTypeOverviewQuery({
    client,
    variables: {
      id: id,
    },
  });

  const templateType = getDataOrNull(query.data?.templateType);

  return (
    <Page
      title={templateType?.title ?? ""}
      subtitle={t("TemplateTypes", { ns: "TemplateTypes" })}
      submenuItems={submenuItems}
      subHeader={
        templateType ? (
          <TemplateTypeOverviewSubHeader
            templateType={templateType}
            isHeaderVisible={undefined as never}
            setHeaderVisible={undefined as never}
            pathToDocList={pathToDocList}
          />
        ) : undefined
      }
    >
      {templateType && (
        <PageContainer>
          <PageGrid columns={{ xs: 1, md: 2, xl: 4 }}>
            <>
              <TemplateTypeOverviewHeaderBox templateType={templateType} />
              <TemplateTypeOverviewPropertyBox templateType={templateType} />
            </>
          </PageGrid>
        </PageContainer>
      )}
    </Page>
  );
};
