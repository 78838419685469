import { gql, useApolloClient } from "@apollo/client";
import { getDataOrNull } from "@msys/common";
import { draftStateToHtml } from "@msys/textutils";
import {
  CardContainer,
  CollapseSection,
  DurationType,
  getDurationInSeconds,
  getNormalizedDuration,
  LabeledDateValue,
  LabeledValue,
  Modal,
  ModalOpenButton,
  RichTextEditor,
  TagChips,
} from "@msys/ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Alert,
  AlertTitle,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { convertFromRaw, RawDraftContentState } from "draft-js";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { isEqual, omit, uniqueId, xor } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { CheckboxGroupField } from "../../../commons/form-fields/CheckboxField";
import { ContractTypeRadioGroupField } from "../../../commons/form-fields/ContractTypeRadioGroupField";
import { FormattedFloatField } from "../../../commons/form-fields/FormattedFloatField";
import { FormattedPercentageField } from "../../../commons/form-fields/FormattedPercentageField";
import { RadioGroupField } from "../../../commons/form-fields/RadioGroupField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import { TagsField } from "../../../commons/form-fields/TagsField";
import {
  ContractType,
  namedOperations,
  QuoteMaterialPriceHandling,
  QuoteTemplateMaterialMarginRangeInput,
  TplApplicableFor,
  VatRateType,
} from "../../../../clients/graphqlTypes";
import { buildDocPath, cleanHTML, DURATION_MAX_VALUES } from "../../../utils";
import {
  getVatValues,
  useVatFieldValidationSchema,
  VatField,
  VatFieldFormValues,
} from "../../../vat/form-fields/VatField";
import { useMaterialMarginRangesFieldValidationSchema } from "../../organisations/useMaterialMarginRangesFieldValidationSchema";
import { MaterialMarginRangesSection } from "../../organisations/form-fields/MaterialMarginRangesSection";
import { OrganisationAvatar } from "../../organisations/OrganisationAvatar";
import { useOrganisationTags } from "../../organisations/useOrganisationTags";
import { useQuoteMaterialPriceHandlingOptions } from "../../organisations/useQuoteMaterialPriceHandlingOptions";
import {
  getVerticalFormValues,
  getVerticalInputValues,
  useVerticalsFieldValidationSchema,
  VerticalFormValues,
  VerticalsFormField,
} from "../../verticals/form-fields/VerticalsFormField";
import { QuoteTemplateUpdateAllLinkingVersionsButton } from "./buttons/QuoteTemplateUpdateAllLinkingVersionsButton";
import {
  TemplatesQuoteTemplateSettingsModal_LinkingTemplatesFragment,
  TemplatesQuoteTemplateSettingsModal_QuoteTemplateFragment,
  useImplementsTemplateTypeMutation,
  useModifyQuoteTemplateMutation,
  useModifyQuoteTemplatePublishingInfoMutation,
  useModifyTplApplicableForMutation,
  useTemplatesQuoteTemplateEditSettingsModalQuery,
  useTemplatesQuoteTemplateVersionSettingsModalQuery,
} from "./TemplatesQuoteTemplateSettingsModal.generated";
import { useTemplateAvaliableFor } from "./useTemplateAvailableFor";
import { TemplateTypesSearchModal } from "../../templateTypes/TemplateTypesSearchModal";
import { TemplateIcon } from "../TemplateIcon";
import { TemplateTypeAtVersionWarningButton } from "../../templateTypes/TemplateTypeAtVersionWarningButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

export const TemplatesQuoteTemplateEditSettingsModal = ({
  templateId,
  handleClose,
  canEdit,
  pathToDocList,
  refetchQueries,
}: Omit<
  Props,
  "template" | "linkingTemplates" | "isLoading" | "defaultVerticalSetting"
> & {
  templateId: string;
}) => {
  const client = useApolloClient();
  const query = useTemplatesQuoteTemplateEditSettingsModalQuery({
    client,
    variables: { id: templateId },
  });
  const template = getDataOrNull(
    query.data?.quoteTemplateLatest
  )?.quoteTemplate;
  const linkingTemplates =
    getDataOrNull(query.data?.templateLinkingItemsToTemplate)?.templates ?? [];
  const organisationDefaults = getDataOrNull(query.data?.organisationDefaults);
  const defaultVerticalSetting = React.useMemo(
    () =>
      organisationDefaults?.defaultVerticalSettings.find(
        s => s.vertical === "general"
      ),
    [organisationDefaults]
  );

  return (
    <TemplatesQuoteTemplateSettingsModal
      template={template}
      linkingTemplates={linkingTemplates}
      defaultVerticalSetting={defaultVerticalSetting}
      pathToDocList={pathToDocList}
      refetchQueries={refetchQueries}
      handleClose={handleClose}
      canEdit={canEdit}
      isLoading={query.loading}
    />
  );
};

export const TemplatesQuoteTemplateVersionSettingsModal = ({
  templateId,
  versionNumber,
  handleClose,
  pathToDocList,
  refetchQueries,
}: Omit<
  Props,
  | "template"
  | "linkingTemplates"
  | "canEdit"
  | "isLoading"
  | "defaultVerticalSetting"
> & {
  templateId: string;
  versionNumber: number;
}) => {
  const client = useApolloClient();
  const query = useTemplatesQuoteTemplateVersionSettingsModalQuery({
    client,
    variables: {
      id: templateId,
      versionNumber,
    },
  });
  const template = getDataOrNull(
    query.data?.quoteTemplateVersion
  )?.quoteTemplate;
  const linkingTemplates =
    getDataOrNull(query.data?.templateLinkingItemsToTemplate)?.templates ?? [];

  return (
    <TemplatesQuoteTemplateSettingsModal
      template={template}
      linkingTemplates={linkingTemplates}
      pathToDocList={pathToDocList}
      refetchQueries={refetchQueries}
      handleClose={handleClose}
      canEdit={false}
      isLoading={query.loading}
    />
  );
};

interface Props {
  template:
    | TemplatesQuoteTemplateSettingsModal_QuoteTemplateFragment
    | undefined
    | null;
  canEdit?: boolean;
  refetchQueries?: string[];
  handleClose: () => void;
  defaultVerticalSetting?: {
    averageWorkBuyingPrice: number;
    averageWorkSellingPrice: number;
  };
  pathToDocList: string;
  linkingTemplates: TemplatesQuoteTemplateSettingsModal_LinkingTemplatesFragment[];
  isLoading: boolean;
}

interface FormValues extends VatFieldFormValues {
  contractType: ContractType;
  durationAmount: number;
  durationType: DurationType;
  publicDescription: string;
  defaultMaterialMargin: number;
  materialMarginRanges: QuoteTemplateMaterialMarginRangeInput[];
  materialPriceHandling: QuoteMaterialPriceHandling;
  defaultVerticalSettings: VerticalFormValues[];
  workFactor: number;
  materialFactor: number;
  tplApplicableFor: TplApplicableFor[];
  tags: { type: "CUSTOM" | "SYSTEM"; value: string }[];
  implementsTemplateType: {
    id: string;
    title: string;
    revision: number;
  } | null;
}

const TemplatesQuoteTemplateSettingsModal = ({
  template: doc,
  refetchQueries,
  canEdit = false,
  handleClose,
  defaultVerticalSetting,
  pathToDocList,
  linkingTemplates,
  isLoading,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate([
    "QuoteEditFooter",
    "Quote",
    "Global",
    "OrganisationSettings",
    "Templates",
    "QuoteItem",
  ]);

  const { customTags } = useOrganisationTags("TEMPLATE");

  const client = useApolloClient();
  const [modifyQuoteTemplate] = useModifyQuoteTemplateMutation({
    client,
    refetchQueries: [
      namedOperations.Query.OrganisationTags,
      ...(refetchQueries ?? []),
    ],
    awaitRefetchQueries: true,
  });
  const [modifyQuoteTemplatePublishingInfo] =
    useModifyQuoteTemplatePublishingInfoMutation({
      client,
    });

  const [modifyTplApplicableFor] = useModifyTplApplicableForMutation({
    client,
  });
  const handleChangeTplApplicableFor = async (
    tplApplicableFor: TplApplicableFor[]
  ) => {
    if (!doc) throw new Error("Doc is missing");
    if (!tplApplicableFor) throw new Error("tplApplicableFor is missing");

    await modifyTplApplicableFor({
      variables: {
        input: {
          quoteTemplateId: doc.id,
          values: {
            tplApplicableFor,
          },
        },
      },
    });
  };

  const {
    options: templateApplicableForOptions,
    documents: templateApplicableForDocuments,
  } = useTemplateAvaliableFor();

  const quoteMaterialPriceHandlingOptions =
    useQuoteMaterialPriceHandlingOptions();

  const [implementsTemplateType] = useImplementsTemplateTypeMutation({
    client,
  });

  const initialValues = React.useMemo((): FormValues => {
    const [durationAmount, durationType] = getNormalizedDuration(
      doc?.duration ?? 0
    );
    return {
      contractType: doc?.contractType ?? "fup",
      durationAmount,
      durationType,
      publicDescription: doc?.publicDescription ?? "",
      ...getVatValues(
        doc?.vatInfo?.countryCode,
        doc?.vatInfo?.rateType,
        doc?.vatRate
      ),
      defaultMaterialMargin: doc?.defaultMaterialMargin ?? 0.2,
      materialMarginRanges:
        doc?.materialMarginRanges?.map(r => omit(r, "id", "__typename")) ?? [],
      materialPriceHandling:
        doc?.materialPriceHandling ?? "CALCULATED_SELL_PRICE",
      defaultVerticalSettings: getVerticalFormValues(
        doc?.defaultVerticalSettings ?? []
      ),
      workFactor: doc?.workFactor ?? 1,
      materialFactor: doc?.materialFactor ?? 1,
      tplApplicableFor: doc?.tplApplicableFor ? [...doc.tplApplicableFor] : [],
      tags:
        doc?.customTags.map(tag => ({
          type: "CUSTOM" as const,
          value: tag,
        })) ?? [],
      implementsTemplateType: doc?.implementsTemplateType
        ? {
            id: doc.implementsTemplateType.templateType.id,
            title: doc.implementsTemplateType.templateType.title,
            revision: doc.implementsTemplateType.templateType.revision,
          }
        : null,
    };
  }, [doc]);

  const validationSchema = Yup.object().shape({
    durationAmount: Yup.number().label(
      t("Work duration", { ns: "QuoteEditFooter" })
    ),
    publicDescription: Yup.string().label(
      t("Template description", { ns: "Quote" })
    ),
    durationType: Yup.string()
      .label(t("Units", { ns: "QuoteEditFooter" }))
      .oneOf(["days", "weeks", "months", "none"]),
    contractType: Yup.string()
      .label(t("Contract Type", { ns: "QuoteEditFooter" }))
      .required(),
    ...useVatFieldValidationSchema(),
    defaultMaterialMargin: Yup.number()
      .min(0)
      .label(t("Default material margin", { ns: "OrganisationSettings" }))
      .required(),
    materialMarginRanges: useMaterialMarginRangesFieldValidationSchema(
      "materialMarginRanges"
    ),
    materialPriceHandling: Yup.string()
      .label(t("Material price handling", { ns: "QuoteEditFooter" }))
      .required(),
    defaultVerticalSettings: useVerticalsFieldValidationSchema(
      "defaultVerticalSettings"
    ),
    workFactor: Yup.number()
      .label(t("Work factor", { ns: "QuoteEditFooter" }))
      .min(0)
      .required(),
    materialFactor: Yup.number()
      .label(t("Material factor", { ns: "QuoteEditFooter" }))
      .min(0)
      .required(),
    tplApplicableFor: Yup.array()
      .of(Yup.string().oneOf(templateApplicableForDocuments))
      .label(t("Available for creating", { ns: "Templates" }))
      .required()
      .min(1),
    tags: Yup.array()
      .of(Yup.object().shape({ value: Yup.string() }))
      .label(t("Tags", { ns: "QuoteEditFooter" })),
  });

  async function handleSubmit(values: FormValues) {
    if (!doc) throw new Error("Doc is missing");
    if (!canEdit) throw new Error("Not allowed to edit");

    if (
      initialValues.implementsTemplateType !== values.implementsTemplateType
    ) {
      await implementsTemplateType({
        variables: {
          input: {
            templateId: doc.id,
            templateTypeId: values.implementsTemplateType?.id ?? null,
          },
        },
      });
    }

    try {
      if (initialValues.publicDescription !== values.publicDescription) {
        await modifyQuoteTemplatePublishingInfo({
          variables: {
            input: {
              quoteTemplateId: doc.id,
              values: { publicDescription: values.publicDescription },
            },
          },
        });
      }

      if (
        xor(initialValues.tplApplicableFor, values.tplApplicableFor).length > 0
      ) {
        await handleChangeTplApplicableFor(values.tplApplicableFor);
      }

      await modifyQuoteTemplate({
        variables: {
          input: {
            quoteTemplateId: doc.id,
            values: {
              duration: getDurationInSeconds({
                durationAmount: values.durationAmount,
                durationType: values.durationType,
              }),
              contractType: values.contractType,
              vatRate: values.vatRate,
              vatInfo: {
                countryCode: values.vatInfo.countryCode,
                rateType: values.vatInfo.rateType as VatRateType,
              },
              defaultMaterialMargin: values.defaultMaterialMargin,
              materialMarginRanges: values.materialMarginRanges,
              materialPriceHandling: values.materialPriceHandling,
              defaultVerticalSettings: getVerticalInputValues(
                values.defaultVerticalSettings
              ),
              workFactor: values.workFactor,
              materialFactor: values.materialFactor,
              tags: values.tags
                .filter(tag => tag.type === "CUSTOM")
                .map(tag => tag.value),
            },
          },
          refetchTree:
            initialValues.contractType !== values.contractType ||
            initialValues.workFactor !== values.workFactor ||
            initialValues.materialFactor !== values.materialFactor ||
            initialValues.vatRate !== values.vatRate ||
            !isEqual(
              values.defaultVerticalSettings,
              initialValues.defaultVerticalSettings
            ),
        },
      });
      enqueueSnackbar(t("Settings saved", { ns: "QuoteEditFooter" }));
      handleClose();
    } catch (error) {
      if (error instanceof Error)
        enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  const hasOutdatedIncomingLinks =
    doc &&
    linkingTemplates.some(template =>
      template.linkingItems.some(
        item =>
          item.linkedQuoteTemplateAtVersionNumber !==
          doc.latestAvailableVersionNumber
      )
    );
  const isLatestPublishedVersion =
    !!doc?.latestPublishedVersionNumber &&
    !!doc?.resolvedAsReadModelVersionNumber &&
    doc.resolvedAsReadModelVersionNumber === doc.latestPublishedVersionNumber;

  const formId = React.useMemo(() => uniqueId(), []);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {formikProps => (
        <Modal
          title={t("Template info & settings", { ns: "QuoteEditFooter" })}
          maxWidth="md"
          actionButtons={
            canEdit
              ? [
                  {
                    label: t("Cancel", { ns: "Global" }),
                    handleClick: handleClose,
                    buttonProps: { variant: "text" },
                  },
                  {
                    label: t("Save", { ns: "Global" }),
                    buttonProps: {
                      loading: formikProps.isSubmitting,
                      form: formId,
                      type: "submit",
                      disabled: !formikProps.dirty || !formikProps.isValid,
                    },
                  },
                ]
              : []
          }
          handleClose={handleClose}
          isLoading={isLoading}
        >
          {doc && (
            <Form id={formId}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <CardContainer
                    isExpandable={false}
                    isInitiallyClosed={false}
                    title={t("Details", { ns: "QuoteEditFooter" })}
                    Icon={<InfoOutlinedIcon />}
                  >
                    <Stack direction="column" spacing={1} p={1}>
                      <LabeledValue
                        label={t("Publishing organisation", { ns: "Quote" })}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={0.5}
                        >
                          <OrganisationAvatar
                            organisationAvatar={doc.publishingCompany}
                            size="s"
                          />
                          <Typography variant="body1">
                            {doc.publishingCompany?.title}
                          </Typography>
                        </Stack>
                      </LabeledValue>

                      {doc.resolvedAsReadModelVersionNumber ? (
                        <LabeledDateValue
                          label={t("Published", { ns: "Quote" })}
                          value={doc.resolvedVersionPublishedAt}
                          notSetText="-"
                        />
                      ) : (
                        <LabeledDateValue
                          label={t("Last Published", { ns: "Templates" })}
                          value={doc.latestVersionPublishedAt}
                          notSetText="–"
                        />
                      )}

                      <RichTextEditor
                        label={t("Template description", { ns: "Quote" })}
                        placeholder={
                          <Typography color="grey.500">
                            {t("No description", { ns: "Global" })}
                          </Typography>
                        }
                        htmlContent={formikProps.values.publicDescription}
                        onChange={async (content: RawDraftContentState) => {
                          formikProps.setFieldValue(
                            "publicDescription",
                            cleanHTML(draftStateToHtml(convertFromRaw(content)))
                          );
                        }}
                        canEdit={canEdit}
                        disabled={formikProps.isSubmitting}
                        saveButtonLabel={t("Save", { ns: "Global" })}
                        cancelButtonLabel={t("Cancel", { ns: "Global" })}
                      />

                      {canEdit ? (
                        <TagsField
                          inputLabel={t("Tags", { ns: "QuoteEditFooter" })}
                          name="tags"
                          options={customTags}
                          disabled={!canEdit || formikProps.isSubmitting}
                        />
                      ) : (
                        <LabeledValue
                          label={t("Tags", { ns: "QuoteEditFooter" })}
                        >
                          {doc.customTags.length > 0 ? (
                            <TagChips tags={doc.customTags} />
                          ) : (
                            "–"
                          )}
                        </LabeledValue>
                      )}

                      <Divider />

                      <CheckboxGroupField
                        name="tplApplicableFor"
                        label={t("Available for creating", { ns: "Templates" })}
                        options={templateApplicableForOptions}
                        size="small"
                        disabled={!canEdit || formikProps.isSubmitting}
                      />

                      <Divider />

                      <Typography color="secondary">
                        {t("Template type implementation", {
                          ns: "QuoteItem",
                        })}
                      </Typography>

                      {formikProps.values.implementsTemplateType ? (
                        <ListItem
                          disablePadding
                          secondaryAction={
                            canEdit ? (
                              <Stack
                                flexDirection={"row"}
                                alignItems={"center"}
                              >
                                {doc.implementsTemplateType &&
                                  doc.implementsTemplateType.templateType.id ===
                                    formikProps.values.implementsTemplateType
                                      .id &&
                                  doc.implementsTemplateType.atRevision !==
                                    formikProps.values.implementsTemplateType
                                      .revision && (
                                    <TemplateTypeAtVersionWarningButton
                                      templateTypeRevision={
                                        formikProps.values
                                          .implementsTemplateType.revision
                                      }
                                      atRevision={
                                        doc.implementsTemplateType.atRevision
                                      }
                                    />
                                  )}
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => {
                                    formikProps.setFieldValue(
                                      "implementsTemplateType",
                                      null
                                    );
                                    formikProps.setTouched({
                                      implementsTemplateType: true,
                                    });
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            ) : undefined
                          }
                        >
                          <ListItemButton
                            component={Link}
                            to={`/templates/types/${formikProps.values.implementsTemplateType.id}`}
                            dense
                          >
                            <ListItemIcon
                              sx={theme => ({
                                minWidth: theme.layout.listItemMinWidth.sm,
                              })}
                            >
                              <TemplateIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {formikProps.values.implementsTemplateType.title}
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ) : (
                        <Stack
                          spacing={1}
                          alignItems="center"
                          justifyContent={"center"}
                        >
                          <Typography
                            color={theme => theme.palette.grey[600]}
                            variant="body2"
                          >
                            {t("No template type defined", { ns: "QuoteItem" })}
                          </Typography>
                          <ModalOpenButton
                            Modal={TemplateTypesSearchModal}
                            modalProps={{
                              handleTemplateTypeChoice: templateType => {
                                formikProps.setFieldValue(
                                  "implementsTemplateType",
                                  {
                                    id: templateType.id,
                                    title: templateType.title,
                                    revision: templateType.revision,
                                  }
                                );
                                formikProps.setTouched({
                                  implementsTemplateType: true,
                                });
                              },
                            }}
                          >
                            <Button
                              color="secondary"
                              size="small"
                              startIcon={<AddIcon />}
                            >
                              {t("Add template type", { ns: "QuoteItem" })}
                            </Button>
                          </ModalOpenButton>
                        </Stack>
                      )}

                      <Divider />

                      <Typography color="secondary">
                        {t("Other templates using this template", {
                          ns: "Templates",
                        })}
                      </Typography>

                      {linkingTemplates.flatMap(linkingTemplate =>
                        linkingTemplate.linkingItems.map(item => (
                          <Stack key={item.id} direction={"row"} spacing={1}>
                            <MuiLink
                              component={RouterLink}
                              to={`${
                                buildDocPath(
                                  `${pathToDocList}/${linkingTemplate.doc.id}`,
                                  linkingTemplate.doc
                                    .resolvedAsReadModelVersionNumber ?? null
                                ) +
                                `${
                                  linkingTemplate.doc
                                    .resolvedAsReadModelVersionNumber
                                    ? ""
                                    : "/edit"
                                }`
                              }/items/${item.id}`}
                              target="_blank"
                            >
                              {t("{item} in {template}", {
                                ns: "Templates",
                                item: `${item.pathForPdf} ${item.title}`,
                                template: linkingTemplate.doc.title,
                              })}
                            </MuiLink>
                            {item.linkedQuoteTemplateAtVersionNumber !==
                              doc.latestAvailableVersionNumber && (
                              <Tooltip
                                title={t("New version available", {
                                  ns: "Templates",
                                })}
                              >
                                <WarningAmberIcon
                                  color={"warning"}
                                  fontSize={"small"}
                                />
                              </Tooltip>
                            )}
                          </Stack>
                        ))
                      )}
                      {isLatestPublishedVersion && hasOutdatedIncomingLinks && (
                        <Alert
                          variant="outlined"
                          severity="warning"
                          action={
                            <QuoteTemplateUpdateAllLinkingVersionsButton
                              templateId={doc.id}
                              pathToDocList={pathToDocList}
                            />
                          }
                        >
                          <AlertTitle>
                            {t("Outdated links!", {
                              ns: "Templates",
                            })}
                          </AlertTitle>
                          {t(
                            "Some templates are linking to outdated version of this template.",
                            {
                              ns: "Templates",
                            }
                          )}
                        </Alert>
                      )}
                    </Stack>
                  </CardContainer>
                </Grid>

                <Grid item xs={12} md={6}>
                  <CardContainer
                    title={t("Settings", {
                      ns: "Global",
                    })}
                    isExpandable={false}
                    isInitiallyClosed={false}
                    Icon={<SettingsIcon />}
                  >
                    <CollapseSection
                      title={t("Contract Type", {
                        ns: "QuoteEditFooter",
                      })}
                      isInitiallyExpanded={true}
                      px={1}
                    >
                      <Stack direction="column" spacing={1} p={1}>
                        <ContractTypeRadioGroupField
                          name="contractType"
                          disabled={!canEdit || formikProps.isSubmitting}
                        />
                      </Stack>
                    </CollapseSection>

                    {canEdit && <Divider />}

                    {canEdit && (
                      <CollapseSection
                        title={t("Price calculation settings", {
                          ns: "QuoteEditFooter",
                        })}
                        isInitiallyExpanded={true}
                        px={1}
                      >
                        <Stack spacing={1} p={1}>
                          <Typography>
                            {t("Multiplying factors", {
                              ns: "QuoteEditFooter",
                            })}
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <FormattedFloatField
                              name="workFactor"
                              label={t("Work factor", {
                                ns: "QuoteEditFooter",
                              })}
                              type="float"
                              min={0}
                              defaultValue={1}
                              disabled={!canEdit || formikProps.isSubmitting}
                            />
                            <FormattedFloatField
                              name="materialFactor"
                              label={t("Material factor", {
                                ns: "QuoteEditFooter",
                              })}
                              type="float"
                              min={0}
                              defaultValue={1}
                              disabled={!canEdit || formikProps.isSubmitting}
                            />
                          </Stack>
                          <Divider />

                          <Typography>
                            {t("Work price settings", {
                              ns: "OrganisationSettings",
                            })}
                          </Typography>
                          <VerticalsFormField
                            name="defaultVerticalSettings"
                            disabled={!canEdit || formikProps.isSubmitting}
                            defaultValue={defaultVerticalSetting}
                          />
                          <Divider />
                          <Typography>
                            {t("Material price settings", {
                              ns: "OrganisationSettings",
                            })}
                          </Typography>
                          <FormattedPercentageField
                            name="defaultMaterialMargin"
                            label={t("Default material margin", {
                              ns: "OrganisationSettings",
                            })}
                            disabled={!canEdit || formikProps.isSubmitting}
                          />
                          <MaterialMarginRangesSection
                            title={t("Custom material margin ranges", {
                              ns: "OrganisationSettings",
                            })}
                            value={formikProps.values.materialMarginRanges}
                            defaultMaterialMargin={
                              formikProps.values.defaultMaterialMargin
                            }
                            name="materialMarginRanges"
                            disabled={!canEdit || formikProps.isSubmitting}
                          />
                          <Stack spacing={1}>
                            <Typography>
                              {t("Select material price handling option", {
                                ns: "OrganisationSettings",
                              })}
                            </Typography>
                            <RadioGroupField
                              name={"materialPriceHandling"}
                              options={quoteMaterialPriceHandlingOptions}
                              condensed
                            />
                          </Stack>
                        </Stack>
                      </CollapseSection>
                    )}

                    <Divider />

                    <CollapseSection
                      title={t("Offer conditions", {
                        ns: "QuoteEditFooter",
                      })}
                      isInitiallyExpanded={true}
                      px={1}
                    >
                      <Stack direction="row" spacing={1} p={1}>
                        <FormattedFloatField
                          name="durationAmount"
                          label={t("Work duration", {
                            ns: "QuoteEditFooter",
                          })}
                          type="float"
                          min={0}
                          max={
                            DURATION_MAX_VALUES[formikProps.values.durationType]
                          }
                          disabled={!canEdit || formikProps.isSubmitting}
                        />
                        <SelectField
                          name="durationType"
                          label={t("Units", {
                            ns: "QuoteEditFooter",
                          })}
                          disabled={!canEdit || formikProps.isSubmitting}
                          options={[
                            {
                              label: "–",
                              value: "none",
                            },
                            {
                              label: t("Days", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "days",
                            },
                            {
                              label: t("Weeks", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "weeks",
                            },
                            {
                              label: t("Months", {
                                ns: "QuoteEditFooter",
                              }),
                              value: "months",
                            },
                          ]}
                        />
                      </Stack>
                    </CollapseSection>

                    <Divider />

                    <CollapseSection
                      title={t("VAT settings", {
                        ns: "QuoteEditFooter",
                      })}
                      isInitiallyExpanded={true}
                      px={1}
                    >
                      <Stack direction="column" spacing={1} p={1}>
                        <VatField
                          disabled={!canEdit || formikProps.isSubmitting}
                        />
                      </Stack>
                    </CollapseSection>
                  </CardContainer>
                </Grid>
              </Grid>
            </Form>
          )}
        </Modal>
      )}
    </Formik>
  );
};
