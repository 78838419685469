import { gql } from "@apollo/client";
import { CollapseSection, DataGrid, GridColDef } from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Typography } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { get, omit } from "lodash";
import React from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import { FormattedPriceField } from "../../../commons/form-fields/FormattedPriceField";
import { SelectField } from "../../../commons/form-fields/SelectField";
import {
  OrganisationVerticalSettingFragment,
  QuoteVerticalSettingFragment,
} from "./VerticalsFormField.generated";
import { useTranslate } from "@tolgee/react";
import { useVerticals } from "../hooks/useVerticals";
import { Vertical, verticals } from "../types";

export interface VerticalFormValues {
  id: string;
  index: number;
  vertical: Vertical;
  averageWorkBuyingPrice: number;
  averageWorkSellingPrice: number;
}

export const defaultVerticalFormValues: VerticalFormValues = {
  id: v4(),
  index: 0,
  vertical: "general",
  averageWorkBuyingPrice: 30,
  averageWorkSellingPrice: 45,
};

export const getVerticalFormValues = (
  verticalSettings: (
    | OrganisationVerticalSettingFragment
    | QuoteVerticalSettingFragment
  )[]
): VerticalFormValues[] => {
  const values = verticalSettings.map((setting, index) =>
    omit({ ...setting, index }, "__typename")
  );
  return values.length > 0 ? values : [defaultVerticalFormValues];
};

export const getVerticalInputValues = (
  values: VerticalFormValues[]
): Omit<VerticalFormValues, "index">[] => {
  return values.map(value => omit(value, "index"));
};

export const useVerticalsFieldValidationSchema = (name: string) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);
  return React.useMemo(
    () =>
      Yup.array()
        .of(
          Yup.object().shape({
            vertical: Yup.string()
              .label(
                t("Vertical", {
                  ns: "OrganisationSettings",
                })
              )
              .required()
              .test(
                "unique",
                t("Value must be unique", {
                  ns: "Global",
                }),
                function (value: string | undefined) {
                  // @ts-ignore
                  const verticals = (this.from as any)[1].value[name] as {
                    vertical: string;
                  }[];
                  const sameValues = verticals.filter(
                    v => v.vertical === value
                  );
                  return sameValues.length <= 1;
                }
              ),
            averageWorkBuyingPrice: Yup.number()
              .label(
                t("Average work cost", {
                  ns: "OrganisationSettings",
                })
              )
              .min(0)
              .required(),
            averageWorkSellingPrice: Yup.number()
              .label(
                t("Average work selling price", {
                  ns: "OrganisationSettings",
                })
              )
              .min(0)
              .required(),
          })
        )
        .min(1)
        .test(
          "generalRequired",
          t("At least general vertical should be set", {
            ns: "OrganisationSettings",
          }),
          function (value: { vertical: string | undefined }[] | undefined) {
            return (
              !!value &&
              value.length > 0 &&
              value.some(v => v.vertical === "general")
            );
          }
        )
        .label(
          t("Verticals", {
            ns: "OrganisationSettings",
          })
        ),
    [t, name]
  );
};

interface Props {
  name: string;
  disabled?: boolean;
  defaultValue?: {
    averageWorkBuyingPrice: number;
    averageWorkSellingPrice: number;
  };
}

export const VerticalsFormField = ({ name, disabled, defaultValue }: Props) => {
  const { t } = useTranslate(["OrganisationSettings", "Global"]);

  const { values, errors, setFieldValue } =
    useFormikContext<Record<string, any>>();
  const value = get(values, name) as VerticalFormValues[];
  const error = get(errors, name);

  const { verticalOptions } = useVerticals();
  const verticalOptionsWithoutGeneral = React.useMemo(
    () => verticalOptions.filter(o => o.value !== "general"),
    [verticalOptions]
  );

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        const getNextVertical = (): Vertical | null => {
          const vertical = verticals.find(
            v => !value.find(({ vertical }) => vertical === v)
          );
          return vertical ?? null;
        };
        const handleCreate = () => {
          const newValue: {
            averageWorkBuyingPrice: number;
            averageWorkSellingPrice: number;
          } = defaultValue ??
            value.find(v => v.vertical === "general") ?? {
              averageWorkBuyingPrice: 30,
              averageWorkSellingPrice: 45,
            };
          const newVertical = getNextVertical();
          if (!newVertical) return;

          const newItem =
            value?.length > 0
              ? {
                  id: v4(),
                  index: value[value.length - 1].index + 1,
                  vertical: getNextVertical(),
                  averageWorkBuyingPrice: newValue.averageWorkBuyingPrice,
                  averageWorkSellingPrice: newValue.averageWorkSellingPrice,
                }
              : {
                  id: v4(),
                  index: 0,
                  vertical: "general",
                  averageWorkBuyingPrice: newValue.averageWorkBuyingPrice,
                  averageWorkSellingPrice: newValue.averageWorkSellingPrice,
                };
          arrayHelpers.push(newItem);
        };
        return (
          <CollapseSection
            title={t("Verticals", {
              ns: "OrganisationSettings",
            })}
            titleVariant="h4"
            isInitiallyExpanded={value.length > 0}
            itemCount={value.length}
            hideItemCountOnExpand={true}
            ActionButtons={
              value.length < verticals.length ? (
                <IconButton
                  disabled={disabled}
                  size="small"
                  color="primary"
                  onClick={handleCreate}
                >
                  <AddIcon />
                </IconButton>
              ) : undefined
            }
          >
            <DataGrid
              density="compact"
              hideFooter={true}
              columns={
                [
                  {
                    field: "vertical",
                    headerName: t("Vertical", {
                      ns: "OrganisationSettings",
                    }),
                    flex: 1.5,
                    minWidth: 30,
                    sortable: false,
                    resizable: false,
                    renderCell: ({ row }) => (
                      <SelectField
                        size="extra-small"
                        name={`${name}.${row.index}.vertical`}
                        required
                        readOnly={value[row.index].vertical === "general"}
                        options={
                          value[row.index].vertical === "general"
                            ? verticalOptions
                            : verticalOptionsWithoutGeneral
                        }
                        label={t("Vertical", {
                          ns: "OrganisationSettings",
                        })}
                        disabled={disabled}
                        onKeyDown={e => {
                          e.stopPropagation();
                        }}
                        onKeyPress={e => {
                          e.stopPropagation();
                        }}
                      />
                    ),
                  },
                  {
                    field: "averageWorkBuyingPrice",
                    headerName: t("Average work cost", {
                      ns: "OrganisationSettings",
                    }),
                    flex: 1,
                    minWidth: 30,
                    sortable: false,
                    resizable: false,
                    renderCell: ({ row }) => (
                      <FormattedPriceField
                        size="extra-small"
                        name={`${name}.${row.index}.averageWorkBuyingPrice`}
                        type="price"
                        label={t("Average work cost", {
                          ns: "OrganisationSettings",
                        })}
                        disabled={disabled}
                        onKeyDown={e => {
                          e.stopPropagation();
                        }}
                        onKeyPress={e => {
                          e.stopPropagation();
                        }}
                      />
                    ),
                  },
                  {
                    field: "averageWorkSellingPrice",
                    headerName: t("Average work selling price", {
                      ns: "OrganisationSettings",
                    }),
                    flex: 1,
                    minWidth: 30,
                    sortable: false,
                    resizable: false,
                    renderCell: ({ row }) => (
                      <FormattedPriceField
                        size="extra-small"
                        name={`${name}.${row.index}.averageWorkSellingPrice`}
                        type="price"
                        label={t("Average work selling price", {
                          ns: "OrganisationSettings",
                        })}
                        disabled={disabled}
                        onKeyDown={e => {
                          e.stopPropagation();
                        }}
                        onKeyPress={e => {
                          e.stopPropagation();
                        }}
                      />
                    ),
                  },
                  {
                    field: "actions",
                    headerName: "",
                    width: 20,
                    sortable: false,
                    resizable: false,
                    renderCell: ({ row }) =>
                      value[row.index].vertical === "general" ? null : (
                        <IconButton
                          size="small"
                          color="primary"
                          disabled={disabled}
                          onClick={() => {
                            setFieldValue(
                              name,
                              value
                                .filter(v => v.index !== row.index)
                                .map((v, index) => ({ ...v, index }))
                            );
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      ),
                  },
                ] as GridColDef<VerticalFormValues>[]
              }
              rows={value}
              rowCount={value.length}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableMultipleColumnsFiltering
              disableMultipleRowSelection
              disableMultipleColumnsSorting
              disableRowSelectionOnClick
              disableVirtualization
              disableColumnReorder
              isCellEditable={() => false}
              isRowSelectable={() => false}
              borderless
            />
            {error && typeof error === "string" && (
              <Typography sx={{ mb: 1 }} variant="caption" color="error">
                {error}
              </Typography>
            )}
            {value.length < verticals.length ? (
              <Button
                color="secondary"
                variant="text"
                size="small"
                startIcon={<AddIcon />}
                disabled={disabled}
                onClick={handleCreate}
                sx={{ mt: 0.5 }}
              >
                {t("Add new vertical setting", {
                  ns: "OrganisationSettings",
                })}
              </Button>
            ) : null}
          </CollapseSection>
        );
      }}
    />
  );
};
