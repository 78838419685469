import { useApolloClient } from "@apollo/client";
import type { Nullable } from "@msys/common";
import { assertNever, getDataOrNull, notNull } from "@msys/common";
import {
  Autocomplete,
  ButtonSelect,
  ButtonSelectMultiple,
  Select,
  SelectMultiple,
  getFormattedNumber,
  useDebouncedValue,
} from "@msys/ui";
import { Typography } from "@mui/material";
import { TFunction } from "@tolgee/core";
import { useTolgee, useTranslate } from "@tolgee/react";
import { FormikProps } from "formik";
import { partition, pick } from "lodash";
import { matchSorter } from "match-sorter";
import React from "react";
import * as Yup from "yup";
import type { TemplatePropertiesFilter } from "../../properties/types";
import {
  TemplateFilterFields_AvailableFiltersQueryVariables,
  useTemplateFilterFields_AvailableFiltersQuery,
} from "./TemplateFilterFields.generated";

type TemplateProperty =
  | {
      key: string;
      label: string;
      values: { count: number; value: boolean }[];
      type: "bool";
    }
  | {
      key: string;
      label: string;
      values: { count: number; value: number }[];
      type: "number";
    }
  | {
      key: string;
      label: string;
      values: { count: number; value: number }[];
      type: "number_array";
    }
  | {
      key: string;
      label: string;
      values: { count: number; value: string; label: string }[];
      type: "text";
    }
  | {
      key: string;
      label: string;
      values: { count: number; value: string; label: string }[];
      type: "text_array";
    };

export type TemplateFiltersFormValues = {
  templateTypeIds: string[] | null;
} & Nullable<TemplatePropertiesFilter>;

type FiltersViewType = "inputs" | "buttons";

interface Props {
  templateSearchVariables: TemplateFilterFields_AvailableFiltersQueryVariables;
  formikProps: FormikProps<TemplateFiltersFormValues>;
  hideTemplateTypes?: boolean;
  canChangeTemplateType?: boolean;
  showOnlyPropertyFiltersWithValues?: boolean;
  viewType?: FiltersViewType;
}

export function useTemplateFilterFields({
  templateSearchVariables,
  formikProps,
  hideTemplateTypes,
  canChangeTemplateType = true,
  showOnlyPropertyFiltersWithValues,
  viewType = "inputs",
}: Props): {
  nonPropertyFields: React.ReactElement[];
  propertyFields: React.ReactElement[];
  isLoading: boolean;
} {
  const { t } = useTranslate(["Global", "TemplatesSearch"]);
  const language = useTolgee(["language"]).getLanguage()!;

  const { values, setFieldValue } = formikProps;

  const debouncedValues = useDebouncedValue(values, 500);

  const variables = React.useMemo(
    () => ({
      ...templateSearchVariables,
      filters: { ...templateSearchVariables.filters, ...debouncedValues },
    }),
    [templateSearchVariables, debouncedValues]
  );

  const client = useApolloClient();
  const availableFiltersQuery = useTemplateFilterFields_AvailableFiltersQuery({
    client,
    variables,
  });
  const availableFilters = getDataOrNull(
    (availableFiltersQuery.data ?? availableFiltersQuery.previousData)
      ?.searchTemplatesAvailableFilters
  );

  const availableOptions = React.useMemo(() => {
    const templateTypeOptions = availableFilters?.templateTypeIds?.map(pt => ({
      value: pt.id,
      label: `${pt.templateType.title} (${pt.templateType.key})`,
      counter: pt.count,
    }));
    const templateTypeIds = templateTypeOptions?.map(pt => pt.value) ?? [];
    const templateTypeCounters =
      templateTypeOptions?.reduce(
        (acc, pt) => {
          acc[pt.value] = pt.counter;
          return acc;
        },
        {} as Record<string, number>
      ) ?? {};
    const templateTypeLabels =
      templateTypeOptions?.reduce(
        (acc, pt) => {
          acc[pt.value] = pt.label;
          return acc;
        },
        {} as Record<string, string>
      ) ?? {};
    const filterTemplateTypeOptions = (
      options: { value: string; label: string }[],
      { inputValue }: { inputValue: string }
    ) =>
      matchSorter(options, inputValue, { keys: ["label"] }).sort(
        (o1, o2) =>
          (templateTypeCounters[o2.value] ?? 0) -
          (templateTypeCounters[o1.value] ?? 0)
      );
    const filterTemplateTypeStringOptions = (
      options: string[],
      { inputValue }: { inputValue: string }
    ) =>
      filterTemplateTypeOptions(
        options.map(o => ({ value: o, label: templateTypeLabels[o] })),
        { inputValue }
      ).map(o => o.value);
    const properties =
      availableFilters?.propertiesBool &&
      availableFilters?.propertiesNumber &&
      availableFilters?.propertiesNumberArray &&
      availableFilters?.propertiesText &&
      availableFilters?.propertiesTextArray
        ? ([
            ...availableFilters.propertiesBool.map(p => ({
              ...p,
              type: "bool",
            })),
            ...availableFilters.propertiesNumber.map(p => ({
              ...p,
              type: "number",
            })),
            ...availableFilters.propertiesNumberArray.map(p => ({
              ...p,
              type: "number_array",
            })),
            ...availableFilters.propertiesText.map(p => ({
              ...p,
              type: "text",
            })),
            ...availableFilters.propertiesTextArray.map(p => ({
              ...p,
              type: "text_array",
            })),
          ].sort((p1, p2) =>
            p1.key.localeCompare(p2.key)
          ) as TemplateProperty[])
        : undefined;

    function filterProperties(property: TemplateProperty) {
      switch (property.type) {
        case "bool": {
          return templateSearchVariables.filters?.propertiesBool?.some(
            filter => filter.key === property.key
          );
        }
        case "number": {
          return templateSearchVariables.filters?.propertiesNumber?.some(
            filter => filter.key === property.key
          );
        }
        case "number_array": {
          return templateSearchVariables.filters?.propertiesNumberArray?.some(
            filter => filter.key === property.key
          );
        }
        case "text": {
          return templateSearchVariables.filters?.propertiesText?.some(
            filter => filter.key === property.key
          );
        }
        case "text_array": {
          return templateSearchVariables.filters?.propertiesTextArray?.some(
            filter => filter.key === property.key
          );
        }
      }
    }

    return {
      templateTypeIds,
      templateTypeCounters,
      templateTypeLabels,
      filterTemplateTypeOptions,
      filterTemplateTypeStringOptions,
      properties: showOnlyPropertyFiltersWithValues
        ? properties?.filter(filterProperties) ?? []
        : properties ?? [],
    };
  }, [
    availableFilters?.propertiesBool,
    availableFilters?.propertiesNumber,
    availableFilters?.propertiesNumberArray,
    availableFilters?.propertiesText,
    availableFilters?.propertiesTextArray,
    availableFilters?.templateTypeIds,
    showOnlyPropertyFiltersWithValues,
    templateSearchVariables.filters?.propertiesBool,
    templateSearchVariables.filters?.propertiesNumber,
    templateSearchVariables.filters?.propertiesNumberArray,
    templateSearchVariables.filters?.propertiesText,
    templateSearchVariables.filters?.propertiesTextArray,
  ]);

  const nonPropertyFields = React.useMemo(
    (): React.ReactElement[] =>
      [
        (!hideTemplateTypes ||
          (values.templateTypeIds && values.templateTypeIds.length > 0)) &&
        availableOptions?.templateTypeIds &&
        availableOptions?.templateTypeIds.length > 0 ? (
          viewType === "inputs" ? (
            <Autocomplete
              key="templateTypeIds-input"
              inputLabel={t("Template type", { ns: "TemplatesSearch" })}
              options={availableOptions?.templateTypeIds ?? []}
              getOptionLabel={option =>
                availableOptions?.templateTypeLabels[option] ?? ""
              }
              value={values.templateTypeIds?.[0] ?? null}
              onChange={newValue => {
                setFieldValue("templateTypeIds", newValue ? [newValue] : null);
              }}
              filterOptions={availableOptions?.filterTemplateTypeStringOptions}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {availableOptions?.templateTypeLabels[option] ?? ""}
                  </span>
                  {availableOptions?.templateTypeCounters[option] !==
                  undefined ? (
                    <Typography
                      variant="caption"
                      sx={{ color: "grey.600", ml: 1 }}
                    >
                      {getFormattedNumber(
                        availableOptions?.templateTypeCounters[option],
                        language
                      )}
                    </Typography>
                  ) : null}
                </li>
              )}
              disabled={!canChangeTemplateType}
            />
          ) : viewType === "buttons" ? (
            <ButtonSelect
              key="templateTypeIds-button"
              label={t("Template type", { ns: "TemplatesSearch" })}
              searchLabel={t("Search", { ns: "Global" })}
              noOptionsLabel={t("There are no items to display", {
                ns: "Global",
              })}
              options={(availableOptions?.templateTypeIds ?? []).map(id => ({
                value: id,
                label: availableOptions?.templateTypeLabels[id] ?? "",
                counter: availableOptions?.templateTypeCounters[id],
              }))}
              value={values.templateTypeIds?.[0] ?? null}
              onChange={newValue => {
                setFieldValue("templateTypeIds", newValue ? [newValue] : null);
              }}
              filterOptions={availableOptions?.filterTemplateTypeOptions}
              disabled={!canChangeTemplateType}
              popoverWidth="xl"
              clearable
            />
          ) : null
        ) : null,
      ].filter(notNull),
    [
      hideTemplateTypes,
      values.templateTypeIds,
      availableOptions?.templateTypeIds,
      availableOptions?.filterTemplateTypeStringOptions,
      availableOptions?.filterTemplateTypeOptions,
      availableOptions?.templateTypeLabels,
      availableOptions?.templateTypeCounters,
      viewType,
      t,
      canChangeTemplateType,
      setFieldValue,
      language,
    ]
  );

  const propertyFields = React.useMemo((): React.ReactElement[] => {
    const options = {
      t,
      language,
      values: {
        propertiesText: values.propertiesText,
        propertiesTextArray: values.propertiesTextArray,
        propertiesNumber: values.propertiesNumber,
        propertiesNumberArray: values.propertiesNumberArray,
        propertiesBool: values.propertiesBool,
      },
      setFieldValue,
      viewType,
    };
    return availableOptions.properties && availableOptions.properties.length > 0
      ? availableOptions.properties
          .map(property => renderPropertyInput(property, options))
          .filter(notNull)
      : [];
  }, [
    availableOptions.properties,
    t,
    language,
    values.propertiesBool,
    values.propertiesNumber,
    values.propertiesNumberArray,
    values.propertiesText,
    values.propertiesTextArray,
    setFieldValue,
    viewType,
  ]);

  return {
    nonPropertyFields,
    propertyFields,
    isLoading: availableFiltersQuery.loading,
  };
}

export const templateFiltersValidationSchema = Yup.object().shape({
  templateTypeIds: Yup.array(Yup.string().required()).nullable(),
  propertiesBool: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        operator: Yup.string().oneOf([
          "eq",
          "lt",
          "gt",
          "gte",
          "lte",
          "between",
          "in",
        ]),
        value: Yup.mixed().nullable(),
      })
    )
    .nullable(),
  propertiesNumber: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        operator: Yup.string().oneOf([
          "eq",
          "lt",
          "gt",
          "gte",
          "lte",
          "between",
          "in",
        ]),
        value: Yup.mixed().nullable(),
      })
    )
    .nullable(),
  propertiesNumberArray: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        operator: Yup.string().oneOf(["anyOf", "allOf"]),
        value: Yup.array().of(Yup.number()).nullable(),
      })
    )
    .nullable(),
  propertiesText: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        operator: Yup.string().oneOf([
          "eq",
          "lt",
          "gt",
          "gte",
          "lte",
          "between",
          "in",
        ]),
        value: Yup.mixed().nullable(),
      })
    )
    .nullable(),
  propertiesTextArray: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        operator: Yup.string().oneOf(["anyOf", "allOf"]),
        value: Yup.array().of(Yup.string()).nullable(),
      })
    )
    .nullable(),
});

export const getTemplateFormValues = <T extends TemplateFiltersFormValues>(
  values: T
): TemplateFiltersFormValues =>
  pick(
    values,
    "templateTypeIds",
    "propertiesBool",
    "propertiesText",
    "propertiesTextArray",
    "propertiesNumber",
    "propertiesNumberArray"
  );

export const templateFiltersDefaultValue: TemplateFiltersFormValues = {
  templateTypeIds: null,
  propertiesBool: null,
  propertiesText: null,
  propertiesTextArray: null,
  propertiesNumber: null,
  propertiesNumberArray: null,
};

function renderPropertyInput(
  property: TemplateProperty,
  {
    t,
    language,
    setFieldValue,
    values,
    viewType,
  }: {
    t: TFunction<"Global"[]>;
    language: string;
    setFieldValue: FormikProps<TemplateFiltersFormValues>["setFieldValue"];
    values: Pick<
      TemplateFiltersFormValues,
      | "propertiesText"
      | "propertiesNumber"
      | "propertiesTextArray"
      | "propertiesNumberArray"
      | "propertiesBool"
    >;
    viewType: FiltersViewType;
  }
): React.ReactElement | null {
  switch (property.type) {
    case "bool": {
      const options = property.values.map(v => ({
        label:
          v.value === true
            ? t("Yes", { ns: "Global" })
            : t("No", { ns: "Global" }),
        value: v.value === true ? "true" : "false",
        counter: v.count,
      }));
      const propertyFilter = values.propertiesBool?.find(
        propertyFilter => property.key === propertyFilter.key
      );
      const value =
        typeof propertyFilter?.value === "boolean"
          ? propertyFilter?.value === true
            ? "true"
            : "false"
          : "";
      const onChange = (newValue: string | null) => {
        const newPropertyFilters =
          values.propertiesBool?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue === "true" || newValue === "false") {
          newPropertyFilters.push({
            key: property.key,
            operator: "eq",
            value: newValue === "true" ? true : false,
          });
        }
        setFieldValue("propertiesBool", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <Select
          key={`${property.key}-input`}
          label={property.label}
          options={options ?? []}
          value={value}
          onChange={onChange}
          clearable
          onClear={() => onChange(null)}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelect
          key={`${property.key}-button`}
          label={property.label}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value || null}
          onChange={onChange}
          popoverWidth="md"
          clearable
        />
      ) : null;
    }
    case "number": {
      const options = property.values.map(v => ({
        label: getFormattedNumber(v.value, language),
        value: v.value.toString(),
        counter: v.count,
      }));
      const propertyFilter = values.propertiesNumber?.find(
        propertyFilter => property.key === propertyFilter.key
      );
      const value = propertyFilter
        ? propertyFilter.operator === "between"
          ? property.values
              .filter(
                v =>
                  v.value >= propertyFilter.value.min &&
                  v.value <= propertyFilter.value.max
              )
              .map(v => v.value.toString())
          : propertyFilter.operator === "in"
            ? propertyFilter.value.map(v => v.toString())
            : [propertyFilter.value.toString()]
        : [];
      const onChange = (newValue: string[]) => {
        const newPropertyFilters =
          values.propertiesNumber?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue.length > 0) {
          newPropertyFilters.push({
            key: property.key,
            operator: "in",
            value: newValue.map(v => parseFloat(v)),
          });
        }
        setFieldValue("propertiesNumber", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <SelectMultiple
          key={`${property.key}-input`}
          label={property.label}
          options={options ?? []}
          value={value}
          onChange={onChange}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelectMultiple
          key={`${property.key}-button`}
          label={property.label}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value}
          onChange={onChange}
          popoverWidth="md"
        />
      ) : null;
    }
    case "number_array": {
      const options = property.values.map(v => ({
        label: getFormattedNumber(v.value, language),
        value: v.value.toString(),
        counter: v.count,
      }));
      const propertyFilter = values.propertiesNumberArray?.find(
        propertyFilter => property.key === propertyFilter.key
      );
      const value =
        propertyFilter && Array.isArray(propertyFilter.value)
          ? propertyFilter.value.map(v => v.toString())
          : [];
      const onChange = (newValue: string[]) => {
        const newPropertyFilters =
          values.propertiesNumberArray?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue.length > 0) {
          newPropertyFilters.push({
            key: property.key,
            operator: "anyOf",
            value: newValue.map(v => parseFloat(v)),
          });
        }
        setFieldValue("propertiesNumberArray", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <SelectMultiple
          key={`${property.key}-input`}
          label={property.label}
          options={options ?? []}
          value={value}
          onChange={onChange}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelectMultiple
          key={`${property.key}-button`}
          label={property.label}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value}
          onChange={onChange}
          popoverWidth="md"
        />
      ) : null;
    }
    case "text": {
      const propertyFilter = values.propertiesText?.find(
        propertyFilter => property.key === propertyFilter.key
      );
      const options = property.values.map(v => ({
        label: v.label,
        value: v.value,
        counter: v.count,
      }));
      const value =
        typeof propertyFilter?.value === "string"
          ? [propertyFilter.value.toString()]
          : propertyFilter && Array.isArray(propertyFilter?.value)
            ? propertyFilter.value.map(v => v.toString())
            : [];
      const onChange = (newValue: string[]) => {
        const newPropertyFilters =
          values.propertiesText?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue.length > 0) {
          newPropertyFilters.push({
            key: property.key,
            operator: "in",
            value: newValue,
          });
        }
        setFieldValue("propertiesText", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <SelectMultiple
          key={`${property.key}-input`}
          label={property.label}
          options={options ?? []}
          value={value}
          onChange={onChange}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelectMultiple
          key={`${property.key}-button`}
          label={property.label}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value}
          onChange={onChange}
          popoverWidth="lg"
        />
      ) : null;
    }
    case "text_array": {
      const propertyFilter = values.propertiesTextArray?.find(
        propertyFilter => property.key === propertyFilter.key
      );
      const options = property.values.map(v => ({
        label: v.label,
        value: v.value,
        counter: v.count,
      }));
      const value =
        propertyFilter && Array.isArray(propertyFilter.value)
          ? propertyFilter.value.map(v => v.toString())
          : [];
      const onChange = (newValue: string[]) => {
        const newPropertyFilters =
          values.propertiesTextArray?.filter(
            propertyFilter => property.key !== propertyFilter.key
          ) ?? [];
        if (newValue.length > 0) {
          newPropertyFilters.push({
            key: property.key,
            operator: "anyOf",
            value: newValue,
          });
        }
        setFieldValue("propertiesTextArray", newPropertyFilters);
      };
      return viewType === "inputs" ? (
        <SelectMultiple
          key={`${property.key}-input`}
          label={property.label}
          options={options ?? []}
          value={value}
          onChange={onChange}
        />
      ) : viewType === "buttons" ? (
        <ButtonSelectMultiple
          key={`${property.key}-button`}
          label={property.label}
          searchLabel={t("Search", { ns: "Global" })}
          noOptionsLabel={t("There are no items to display", {
            ns: "Global",
          })}
          options={options ?? []}
          value={value}
          onChange={onChange}
          popoverWidth="lg"
        />
      ) : null;
    }
    default:
      return assertNever(property);
  }
}

export function isAnyFilterSet(filters: TemplateFiltersFormValues) {
  return (
    isArrayFilterSet(filters.templateTypeIds) ||
    isArrayFilterSet(filters.propertiesBool) ||
    isArrayFilterSet(filters.propertiesNumber) ||
    isArrayFilterSet(filters.propertiesText)
  );
}

function isArrayFilterSet(filter: any[] | null) {
  return filter && filter.length > 0;
}
