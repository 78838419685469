/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EmailAddress: { input: any; output: any; }
  File: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type Absence = {
  __typename: 'Absence';
  author: User;
  from: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  note: Scalars['String']['output'];
  organisation: Organisation;
  reason: AbsenceReason;
  till: Scalars['Date']['output'];
  who: User;
};

export type AbsenceConnection = {
  __typename: 'AbsenceConnection';
  edges: Array<AbsenceEdge>;
  totalCount: Scalars['Int']['output'];
};

export type AbsenceEdge = {
  __typename: 'AbsenceEdge';
  node: Absence;
};

export type AbsenceReason =
  | 'OTHER'
  | 'SICK_LEAVE'
  | 'TRAINING'
  | 'VACATION';

export type AbsencesFilters = {
  rangeEnd?: InputMaybe<Scalars['Date']['input']>;
  rangeStart?: InputMaybe<Scalars['Date']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  whoIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AcceptChangesInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  signature: SignatureExtendedInput;
};

export type AcceptChangesResult = {
  __typename: 'AcceptChangesResult';
  project: Project;
  quote: Quote;
};

export type AcceptCrmLinkingInvitationResult = {
  __typename: 'AcceptCrmLinkingInvitationResult';
  ok: Scalars['Boolean']['output'];
};

export type AcceptDocShareInput = {
  acceptInProjectId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type AcceptDocShareResult = {
  __typename: 'AcceptDocShareResult';
  docId: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
};

export type AcceptOrganisationMembershipInvitationInput = {
  email: Scalars['EmailAddress']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type AcceptOrganisationMembershipInvitationResult = {
  __typename: 'AcceptOrganisationMembershipInvitationResult';
  user: Viewer;
};

export type AcceptProjectContracteeInvitationInput = {
  /** @deprecated Field no longer supported */
  acceptAsOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  invitationToken: Scalars['String']['input'];
};

export type AcceptProjectContracteeInvitationResult = {
  __typename: 'AcceptProjectContracteeInvitationResult';
  invitation: ProjectContracteeInvitation;
};

export type AcceptQuoteInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  signature: SignatureExtendedInput;
};

export type AcceptQuoteResult = {
  __typename: 'AcceptQuoteResult';
  project: Project;
  quote: Quote;
};

export type ActualCalculation = {
  __typename: 'ActualCalculation';
  itemCalculation?: Maybe<ItemCalculation>;
  itemId: Scalars['ID']['output'];
};

export type AddCompanyOrganisationCredentialsInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  msOrganisationId: Scalars['ID']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AddCompanyOrganisationCredentialsResult = {
  __typename: 'AddCompanyOrganisationCredentialsResult';
  itekCompanyOrganisationCredentials: Array<ItekCompanyCredentials>;
};

export type AddInvoiceExtraItemInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: AddInvoiceExtraItemValuesInput;
};

export type AddInvoiceExtraItemResult = {
  __typename: 'AddInvoiceExtraItemResult';
  invoice: Invoice;
  invoiceExtraItem: InvoiceExtraItem;
};

export type AddInvoiceExtraItemValuesInput = {
  description: Scalars['String']['input'];
  pricePerUnit: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  quantityUnit: QuantityUnit;
  title: Scalars['String']['input'];
};

export type AddItemAttachmentsResult = {
  __typename: 'AddItemAttachmentsResult';
  attachments: Array<AttachmentSnapshot>;
};

export type AddItemLinkCrmOrganisationInput = {
  crmOrganisationId: Scalars['ID']['input'];
  data: Scalars['JSON']['input'];
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  linkAs: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddItemLinkCrmOrganisationResult = {
  __typename: 'AddItemLinkCrmOrganisationResult';
  crmOrganisation: Organisation;
  data: Scalars['JSON']['output'];
  item: Item;
  linkAs: Scalars['String']['output'];
};

export type AddItemLinkCrmUserInput = {
  crmUserId: Scalars['ID']['input'];
  data: Scalars['JSON']['input'];
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  linkAs: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddItemLinkCrmUserResult = {
  __typename: 'AddItemLinkCrmUserResult';
  crmUser: User;
  data: Scalars['JSON']['output'];
  item: Item;
  linkAs: Scalars['String']['output'];
};

export type AddItemRecommendedTemplateInput = {
  bindProps: Array<ItemRecommendedTemplateBindPropsInput>;
  docId: Scalars['ID']['input'];
  eligibleIfExpr: Scalars['String']['input'];
  includeIfExpr: Scalars['String']['input'];
  itemId: Scalars['ID']['input'];
  templateId: Scalars['String']['input'];
  templateVersionNumber: Scalars['Int']['input'];
};

export type AddItemRecommendedTemplateResult = {
  __typename: 'AddItemRecommendedTemplateResult';
  doc: Document;
  item: Item;
};

export type AddItemRecommendedTemplateTypeInput = {
  docId: Scalars['ID']['input'];
  eligibleIfExpr: Scalars['String']['input'];
  includeIfExpr: Scalars['String']['input'];
  itemId: Scalars['ID']['input'];
  templateSearchFilterDefinitions: TemplateSearchFilterDefinitionsInput;
  templateSearchFilterExpressions: TemplateSearchFilterExpressionsInput;
  templateSearchSortingDefinitions: Array<TemplateSearchSortingDefinitionInput>;
  templateTypeId: Scalars['ID']['input'];
};

export type AddItemRecommendedTemplateTypeResult = {
  __typename: 'AddItemRecommendedTemplateTypeResult';
  doc: Document;
  item: Item;
};

export type AddOrderItemItemInput = {
  originProductArticleNumber?: InputMaybe<Scalars['String']['input']>;
  originProductSupplierId?: InputMaybe<Scalars['ID']['input']>;
  originQuoteItemId?: InputMaybe<Scalars['ID']['input']>;
  productArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productManufacturerArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productTitle: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  quantityUnit: QuantityUnit;
  unitPrice: Scalars['Float']['input'];
};

export type AddOrderItemsInput = {
  items: Array<AddOrderItemItemInput>;
  orderId: Scalars['ID']['input'];
};

export type AddOrderItemsResult = {
  __typename: 'AddOrderItemsResult';
  items: Array<OrderItem>;
  order: Order;
};

export type AddOrganisationCapabilityInput = {
  capability: Capabilities;
  organisationId: Scalars['ID']['input'];
};

export type AddOrganisationCapabilityResult = {
  __typename: 'AddOrganisationCapabilityResult';
  ok: Scalars['Boolean']['output'];
};

export type AddOrganisationContractingPdfInput = {
  attachment?: InputMaybe<AttachmentInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  needInsertPriceTable?: InputMaybe<Scalars['Boolean']['input']>;
  replacePages?: InputMaybe<Scalars['Int']['input']>;
  replacements?: InputMaybe<Array<ContractingPdfReplacementInput>>;
  startPage?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type AddOrganisationContractingPdfResult = {
  __typename: 'AddOrganisationContractingPdfResult';
  contractingPdf: OrganisationContractingPdf;
};

export type AddOrganisationEmailTemplateInput = {
  attachments: Array<AttachmentInputWithId>;
  content: Scalars['String']['input'];
  context: EmailTemplateContext;
  description: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type AddOrganisationEmailTemplateResult = {
  __typename: 'AddOrganisationEmailTemplateResult';
  emailTemplate: OrganisationEmailTemplate;
};

export type AddOrganisationIntegrationInput = {
  foreignAccountId?: InputMaybe<Scalars['String']['input']>;
  integrationId: IntegrationId;
};

export type AddOrganisationIntegrationResult = {
  __typename: 'AddOrganisationIntegrationResult';
  organisationIntegration: OrganisationIntegration;
};

export type AddOrganisationMembershipInvitationInput = {
  attachments: Array<AttachmentInput>;
  defaultProjectRoleIds: Array<Scalars['String']['input']>;
  inviteeEmail: Scalars['EmailAddress']['input'];
  inviteeFamilyname: Scalars['String']['input'];
  inviteeFirstname: Scalars['String']['input'];
  inviteeJobtitle: Scalars['String']['input'];
  inviteeSalutation: Salutation;
  messageBody: Scalars['String']['input'];
  messageSubject: Scalars['String']['input'];
  organisationRoleIds: Array<Scalars['ID']['input']>;
};

export type AddOrganisationMembershipInvitationResult = {
  __typename: 'AddOrganisationMembershipInvitationResult';
  invitation: OrganisationMembershipInvitation;
  organisation: Organisation;
};

export type AddOrganisationProfileInput = {
  about?: InputMaybe<Scalars['JSON']['input']>;
  background?: InputMaybe<AttachmentInput>;
  contactAddress?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhones?: InputMaybe<Array<PhoneInput>>;
  contactWebsite?: InputMaybe<Scalars['String']['input']>;
  employeesNumber?: InputMaybe<Scalars['Int']['input']>;
  isMeister?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<AttachmentInput>;
  openingHours?: InputMaybe<Scalars['JSON']['input']>;
  skillset?: InputMaybe<Array<Skill>>;
  slug: Scalars['String']['input'];
  yearFounded?: InputMaybe<Scalars['Int']['input']>;
};

export type AddOrganisationProfileResult = {
  __typename: 'AddOrganisationProfileResult';
  organisation: Organisation;
  profile: OrganisationProfile;
};

export type AddOrganisationProjectPhaseInput = {
  name: Scalars['String']['input'];
  state: ProjectStateMachineStatus;
};

export type AddOrganisationProjectPhaseResult = {
  __typename: 'AddOrganisationProjectPhaseResult';
  organisationSettings: OrganisationSetting;
};

export type AddOrganisationsFromItekCompaniesInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreItekCompanyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  onlyItekCompanyIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddOrganisationsFromItekCompaniesResult = {
  __typename: 'AddOrganisationsFromItekCompaniesResult';
  itekCompanyId: Scalars['String']['output'];
  organisationId: Scalars['ID']['output'];
};

export type AddPimImportInput = {
  configuration?: InputMaybe<Scalars['String']['input']>;
  filename: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type AddPimImportationResult = {
  __typename: 'AddPimImportationResult';
  importation: PimImportation;
};

export type AddPimManufacturerProductImportInput = {
  filename: Scalars['String']['input'];
  manufacturerOrganisationId: Scalars['ID']['input'];
  type: PimManufacturerProductImportationType;
  url: Scalars['String']['input'];
};

export type AddPimManufacturerProductImportResult = {
  __typename: 'AddPimManufacturerProductImportResult';
  manufacturerProductImportationId: Scalars['ID']['output'];
};

export type AddPimSearchCacheInput = {
  pimProductId: Scalars['ID']['input'];
  searchQuery: Scalars['String']['input'];
};

export type AddPimSearchCacheResult = {
  __typename: 'AddPimSearchCacheResult';
  ok: Scalars['Boolean']['output'];
};

export type AddPimSupplierProductCategoryImportationInput = {
  csvConfigurations?: InputMaybe<Scalars['JSON']['input']>;
  filename: Scalars['String']['input'];
  supplierOrganisationId: Scalars['ID']['input'];
  type: PimSupplierProductCategoryImportationType;
  url: Scalars['String']['input'];
};

export type AddPimSupplierProductCategoryImportationResult = {
  __typename: 'AddPimSupplierProductCategoryImportationResult';
  supplierProductCategoryImportationId: Scalars['ID']['output'];
};

export type AddPimSupplierProductImportInput = {
  csvConfigurations?: InputMaybe<Scalars['JSON']['input']>;
  filename: Scalars['String']['input'];
  supplierCatalogueId?: InputMaybe<Scalars['ID']['input']>;
  supplierOrganisationId: Scalars['ID']['input'];
  type: PimSupplierProductImportationType;
  url: Scalars['String']['input'];
};

export type AddPimSupplierProductImportResult = {
  __typename: 'AddPimSupplierProductImportResult';
  supplierProductImportationId: Scalars['ID']['output'];
};

export type AddProductAttachmentsResult = {
  __typename: 'AddProductAttachmentsResult';
  attachments: Array<AttachmentSnapshot>;
};

export type AddProjectAttachmentsResult = {
  __typename: 'AddProjectAttachmentsResult';
  attachments: Array<AttachmentSnapshot>;
};

export type AddProjectContracteeInputAttachment = {
  uploadUrl: Scalars['String']['input'];
};

export type AddProjectContracteeInputInput = {
  attachments: Array<AddProjectContracteeInputAttachment>;
  comments: Scalars['String']['input'];
};

export type AddProjectContracteeInputResult = {
  __typename: 'AddProjectContracteeInputResult';
  ok: Scalars['Boolean']['output'];
};

export type AddProjectContracteeInvitationInput = {
  attachments: Array<AttachmentInput>;
  contactOrganisationId: Scalars['ID']['input'];
  contactUserId: Scalars['ID']['input'];
  messageBody: Scalars['String']['input'];
  messageSubject: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type AddProjectContracteeInvitationResult = {
  __typename: 'AddProjectContracteeInvitationResult';
  invitation: ProjectContracteeInvitation;
};

export type AddProjectLinkCrmOrganisationInput = {
  crmOrganisationId: Scalars['ID']['input'];
  data: Scalars['JSON']['input'];
  linkAs: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type AddProjectLinkCrmOrganisationResult = {
  __typename: 'AddProjectLinkCrmOrganisationResult';
  crmOrganisation: Organisation;
  data: Scalars['JSON']['output'];
  linkAs: Scalars['String']['output'];
  project: Project;
};

export type AddProjectLinkCrmUserInput = {
  crmUserId: Scalars['ID']['input'];
  data: Scalars['JSON']['input'];
  linkAs: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type AddProjectLinkCrmUserResult = {
  __typename: 'AddProjectLinkCrmUserResult';
  crmUser: User;
  data: Scalars['JSON']['output'];
  linkAs: Scalars['String']['output'];
  project: Project;
};

export type AddProjectMemberFromOrganisationMembersResult = {
  __typename: 'AddProjectMemberFromOrganisationMembersResult';
  project: Project;
};

export type AddQuoteDocActorInput = {
  crmCompanyId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type AddQuoteDocActorResult = {
  __typename: 'AddQuoteDocActorResult';
  docActor: DocActor;
};

export type AddQuoteTemplateToShopInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type AddQuoteTemplateToShopResult = {
  __typename: 'AddQuoteTemplateToShopResult';
  quoteTemplate: QuoteTemplate;
};

export type AddRequestAttachmentsResult = {
  __typename: 'AddRequestAttachmentsResult';
  attachments: Array<AttachmentSnapshot>;
};

export type AddRequestRecipientsInput = {
  projectId: Scalars['ID']['input'];
  recipients: Array<AddRequestRecipientsOrganisationInput>;
  requestId: Scalars['ID']['input'];
};

export type AddRequestRecipientsOrganisationInput = {
  systemOrganisationId: Scalars['ID']['input'];
};

export type AddRequestRecipientsResult = {
  __typename: 'AddRequestRecipientsResult';
  request: Request;
  requestRecipients: Array<RequestRecipient>;
};

export type AddRequirementDocActorInput = {
  crmCompanyId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type AddRequirementDocActorResult = {
  __typename: 'AddRequirementDocActorResult';
  docActor: DocActor;
};

export type Address = {
  __typename: 'Address';
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  postalCode: Scalars['String']['output'];
  streetLines1: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  postalCode: Scalars['String']['input'];
  streetLines1: Scalars['String']['input'];
};

export type AddressSnapshot = {
  __typename: 'AddressSnapshot';
  city: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  streetLines1: Scalars['String']['output'];
  streetLines2: Scalars['String']['output'];
  streetLines3: Scalars['String']['output'];
  streetLines4: Scalars['String']['output'];
};

export type AgreedCalculation = {
  __typename: 'AgreedCalculation';
  itemCalculation?: Maybe<ItemCalculation>;
  itemId: Scalars['ID']['output'];
};

export type AgreedOrProposedCalculation =
  | 'agreed'
  | 'proposed';

export type Agreement =
  | 'NO'
  | 'NONE'
  | 'YES';

export type AiDescriptionResult = {
  __typename: 'AiDescriptionResult';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AiRephraseResult = {
  __typename: 'AiRephraseResult';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AppInfo = {
  __typename: 'AppInfo';
  android: AppInfoAndroid;
  ios: AppInfoIos;
};

export type AppInfoAndroid = {
  __typename: 'AppInfoAndroid';
  bundleId: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type AppInfoIos = {
  __typename: 'AppInfoIOS';
  appId: Scalars['Int']['output'];
  appName: Scalars['String']['output'];
  bundleId: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type ApplyItemActionInput = {
  setDecisionContingencyPreselection?: InputMaybe<ApplyItemActionSetDecisionContingencyPreselectionInput>;
  setDecisionSubitemsPreselection?: InputMaybe<ApplyItemActionSetDecisionSubitemsPreselectionInput>;
  setEnterPropsValueEntries?: InputMaybe<ApplyItemActionSetEnterPropsValueEntriesInput>;
  setSelectProduct?: InputMaybe<ApplyItemActionSetSelectProductInput>;
};

export type ApplyItemActionSetDecisionContingencyPreselectionInput = {
  decisionContingentItemPreselection?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['ID']['input'];
};

export type ApplyItemActionSetDecisionSubitemsPreselectionInput = {
  decisionSubitemsPreselection: Array<Scalars['ID']['input']>;
  itemId: Scalars['ID']['input'];
};

export type ApplyItemActionSetEnterPropsValueEntriesInput = {
  entries: Array<EnterItemProps2ValueEntry>;
  itemId: Scalars['ID']['input'];
};

export type ApplyItemActionSetSelectProductInput = {
  itemId: Scalars['ID']['input'];
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
};

export type ApplyQuoteProductsDistributionInput = {
  docId: Scalars['ID']['input'];
  opts?: InputMaybe<DistributeProductsInQuoteOptsInput>;
  projectId: Scalars['ID']['input'];
  quoteProductsDistributionId: Scalars['ID']['input'];
};

export type ApplyQuoteProductsDistributionOptsInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  rootSubTreeItemId?: InputMaybe<Scalars['ID']['input']>;
  setExpensiveProductFirst?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApplyQuoteProductsDistributionResult = {
  __typename: 'ApplyQuoteProductsDistributionResult';
  quoteProductsDistributionMappings: Array<QuoteProductsDistributionMapping>;
  rootItemProps2: Array<Scalars['JSON']['output']>;
};

export type ApproveTaskItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ApproveTaskItemResult = {
  __typename: 'ApproveTaskItemResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
};

export type ApproveTimeReportInput = {
  id: Scalars['ID']['input'];
};

export type ApproveTimeReportResult = {
  __typename: 'ApproveTimeReportResult';
  timeReport: TimeReport;
};

export type AskWhen =
  | 'onEmbeddableWizard'
  | 'onQuoteCreate'
  | 'onQuoteRefinement';

export type AskWhom =
  | 'contractee'
  | 'contractor';

export type AssignBuildingInput = {
  buildingItemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type Attachment = BaseAttachment & {
  __typename: 'Attachment';
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AttachmentInput = {
  group?: InputMaybe<Scalars['String']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type AttachmentInputWithId = {
  group: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AttachmentSnapshot = {
  __typename: 'AttachmentSnapshot';
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AttributeExpressionInput = {
  attribute: Scalars['String']['input'];
  expr: Scalars['String']['input'];
};

export type AvailableUser = WithAvatar & {
  __typename: 'AvailableUser';
  active: Scalars['Boolean']['output'];
  avatar: AttachmentSnapshot;
  email: Scalars['EmailAddress']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organisationTitle: Scalars['String']['output'];
};

export type BaseAttachment = {
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BillOfMaterialsDataFormat =
  | 'csv'
  | 'pdf'
  | 'xlsx';

export type BillOfMaterialsField =
  | 'discountValue'
  | 'installationTime'
  | 'itemStatus'
  | 'listPrice'
  | 'netPrice'
  | 'productArticleNumber'
  | 'productManufacturerArticleNumber'
  | 'productTitle'
  | 'quantityOrdered'
  | 'quantityRequiredTotal'
  | 'quantityUnit'
  | 'supplier';

export type BillOfMaterialsGeneratePdfResult = {
  __typename: 'BillOfMaterialsGeneratePdfResult';
  file?: Maybe<AttachmentSnapshot>;
};

export type BillOfMaterialsItem = {
  __typename: 'BillOfMaterialsItem';
  discountValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  itemStatus: BillOfMaterialsItemStatus;
  itemType: BillOfMaterialsItemType;
  listPrice?: Maybe<Scalars['Float']['output']>;
  maxInstallationTime?: Maybe<Scalars['Float']['output']>;
  minInstallationTime?: Maybe<Scalars['Float']['output']>;
  netPrice?: Maybe<Scalars['Float']['output']>;
  orderItems: Array<OrderItem>;
  product?: Maybe<Product>;
  productArticleNumber?: Maybe<Scalars['String']['output']>;
  productManufacturerArticleNumber?: Maybe<Scalars['String']['output']>;
  productSupplier?: Maybe<Company>;
  quantityArrived: Scalars['Float']['output'];
  quantityConsumed: Scalars['Float']['output'];
  quantityConsumedTotal: Scalars['Float']['output'];
  quantityDamagedDelivery: Scalars['Float']['output'];
  quantityDamagedOnsite: Scalars['Float']['output'];
  quantityOrdered: Scalars['Float']['output'];
  quantityRequired: Scalars['Float']['output'];
  quantityRequiredAccepted: Scalars['Float']['output'];
  quantityRequiredChange: Scalars['Float']['output'];
  quantityRequiredDraft: Scalars['Float']['output'];
  quantityRequiredPending: Scalars['Float']['output'];
  quantityRequiredTotal: Scalars['Float']['output'];
  quantityUnit: QuantityUnit;
  quoteItemId?: Maybe<Scalars['ID']['output']>;
  relatedQuoteItems: Array<RelatedQuoteItem>;
  title: Scalars['String']['output'];
};

export type BillOfMaterialsItemStatus =
  | 'OPEN'
  | 'ORDERED'
  | 'PARTIAL';

export type BillOfMaterialsItemType =
  | 'custom_product'
  | 'product'
  | 'quote_item';

export type BillOfMaterialsQuoteItemStatusFilter =
  | 'ACCEPTED'
  | 'DRAFT'
  | 'PENDING';

export type BillOfMaterialsSection =
  | 'contactInformation'
  | 'listOfQuotes'
  | 'projectAddress'
  | 'projectTitleAndNumber';

export type Branding = {
  __typename: 'Branding';
  brandColor: Scalars['String']['output'];
  brandLogo?: Maybe<AttachmentSnapshot>;
  brandTextColor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type BrandingThemeType =
  | 'dark'
  | 'light';

export type Building = WithAttachments & WithAuthor & WithContents & WithCustomFields & WithDocumentInfo & {
  __typename: 'Building';
  attachments: Array<Attachment>;
  author: User;
  buildingAddress?: Maybe<Address>;
  containsError: Scalars['Boolean']['output'];
  contents: Array<Content>;
  createdAt: Scalars['DateTime']['output'];
  crmCompanyId?: Maybe<Scalars['ID']['output']>;
  customFields: Array<CustomFieldField>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<Item>;
  number: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  projectsInBuilding: Array<Project>;
  rootItem: Item;
  title: Scalars['String']['output'];
};


export type BuildingAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type BuildingItemsArgs = {
  itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type BuildingConnection = {
  __typename: 'BuildingConnection';
  edges: Array<BuildingEdge>;
  totalCount: Scalars['Int']['output'];
};

export type BuildingCreateInput = {
  buildingAddress: AddressInput;
  buildingOwningOrganisationId: Scalars['ID']['input'];
  templateQuoteId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BuildingCreateResult = {
  __typename: 'BuildingCreateResult';
  building: Building;
  rootItem: Item;
};

export type BuildingEdge = {
  __typename: 'BuildingEdge';
  node: Building;
};

export type BuildingInfo = {
  __typename: 'BuildingInfo';
  buildingAddress?: Maybe<Address>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type BuildingsFilters = {
  owningOrganisationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CacheType =
  | 'local'
  | 'remote'
  | 'request';

export type CancelInvoiceInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: CancelInvoiceInputValues;
};

export type CancelInvoiceInputValues = {
  reason: Scalars['String']['input'];
};

export type CancelInvoiceResult = {
  __typename: 'CancelInvoiceResult';
  invoice: Invoice;
};

export type CancelOrganisationMembershipInvitationResult = {
  __typename: 'CancelOrganisationMembershipInvitationResult';
  invitation: OrganisationMembershipInvitation;
  organisation: Organisation;
};

export type CancelPendingShareDocInput = {
  id: Scalars['ID']['input'];
};

export type CancelPendingShareDocResult = {
  __typename: 'CancelPendingShareDocResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CancelProjectContracteeInvitationResult = {
  __typename: 'CancelProjectContracteeInvitationResult';
  invitation: ProjectContracteeInvitation;
};

export type Capabilities =
  | 'ADVANCED_SETTINGS'
  | 'BRANDING'
  | 'BUILDINGS'
  | 'EXECUTING'
  | 'FEATURE_FLAGS'
  | 'INTEGRATIONS'
  | 'INVOICING'
  | 'MARKETPLACE'
  | 'ORDERING'
  | 'PIM'
  | 'PLANNING'
  | 'PUBLIC_PROFILE'
  | 'QUOTING'
  | 'REFERRAL'
  | 'SHOP'
  | 'TEMPLATING'
  | 'TIME_TRACKING';

export type Cart = Quote | Requirement;

export type CartConnection = {
  __typename: 'CartConnection';
  edges: Array<CartEdge>;
  totalCount: Scalars['Int']['output'];
};

export type CartDocument = {
  __typename: 'CartDocument';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agreedTotalPrice?: Maybe<Scalars['Float']['output']>;
  agreement: Agreement;
  clientName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  docType: DocType;
  id: Scalars['ID']['output'];
  isBinding: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  proposedTotalPrice: Scalars['Float']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rootItemId: Scalars['ID']['output'];
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type CartEdge = {
  __typename: 'CartEdge';
  node: CartDocument;
};

export type ChangeAgreement =
  | 'NO'
  | 'NONE';

export type ChangeEmailInput = {
  newEmail: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
};

export type ChangeEmailResult = {
  __typename: 'ChangeEmailResult';
  user: Viewer;
};

export type ChangeItemTypeInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  newType: ItemType;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type ChangeItemTypeResult = {
  __typename: 'ChangeItemTypeResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type ChangeProjectPhaseInput = {
  newPhaseId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ChangeProjectPhaseResult = {
  __typename: 'ChangeProjectPhaseResult';
  project: Project;
};

export type Channel = {
  __typename: 'Channel';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  logo: AttachmentSnapshot;
  messages: MessageConnection;
  messagesSinceLastRead: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};


export type ChannelMessagesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ChannelMessageInput = {
  attachment?: InputMaybe<AttachmentInput>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  channelId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type ChannelType =
  | 'CHANNEL'
  | 'GROUP';

export type ChannelsFilters = {
  channelType?: InputMaybe<ChannelType>;
};

export type CloseRequestForBiddingInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type CloseRequestForBiddingResult = {
  __typename: 'CloseRequestForBiddingResult';
  request: Request;
};

export type CloseRequestInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type CloseRequestResult = {
  __typename: 'CloseRequestResult';
  request: Request;
};

export type Company = WithBranchAddress & WithCompanyInfo & WithContactInfo & WithLogo & {
  __typename: 'Company';
  billingAddress?: Maybe<Address>;
  branchAddress?: Maybe<Address>;
  contactPerson?: Maybe<Person>;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  isClientOrganisation: Scalars['Boolean']['output'];
  isCraftsmanOrganisation: Scalars['Boolean']['output'];
  isManufacturerOrganisation: Scalars['Boolean']['output'];
  isMeister: Scalars['Boolean']['output'];
  isSupplierOrganisation: Scalars['Boolean']['output'];
  logo: AttachmentSnapshot;
  organisationType: OrganisationType;
  phones: Array<Phone>;
  profile?: Maybe<OrganisationProfile>;
  skillset: Array<Skill>;
  title: Scalars['String']['output'];
  website: Scalars['String']['output'];
};

export type CompileDocInput = {
  deep: Scalars['Boolean']['input'];
  docId: Scalars['ID']['input'];
  overrides?: InputMaybe<Array<CompileDocOverrideInput>>;
};

export type CompileDocIsolatedExpressionInput = {
  deep: Scalars['Boolean']['input'];
  docId: Scalars['ID']['input'];
  filterItemId?: InputMaybe<Scalars['ID']['input']>;
  overrides?: InputMaybe<Array<CompileDocOverrideInput>>;
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type CompileDocIsolatedExpressionResult = {
  __typename: 'CompileDocIsolatedExpressionResult';
  results: Array<CompileDocIsolatedExpressionResultDiagnostic>;
};

export type CompileDocIsolatedExpressionResultDiagnostic = CompileDocIsolatedExpressionResultDiagnosticAttribute | CompileDocIsolatedExpressionResultDiagnosticComputedProp | CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression | CompileDocIsolatedExpressionResultDiagnosticPropertyMapping | CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf | CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf;

export type CompileDocIsolatedExpressionResultDiagnosticAttribute = {
  __typename: 'CompileDocIsolatedExpressionResultDiagnosticAttribute';
  attribute: Scalars['String']['output'];
  code: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  expr: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  itemPdfPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  messageText: Scalars['String']['output'];
  source: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type CompileDocIsolatedExpressionResultDiagnosticComputedProp = {
  __typename: 'CompileDocIsolatedExpressionResultDiagnosticComputedProp';
  code: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  expr: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  itemPdfPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  messageText: Scalars['String']['output'];
  source: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression = {
  __typename: 'CompileDocIsolatedExpressionResultDiagnosticProductSearchFilterExpression';
  allowedOperators: Array<EntitySearchFilterOperator>;
  code: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  expr: Scalars['String']['output'];
  filterKey: ProductSearchFilterExpressionFilterKey;
  filterKind: ProductSearchFilterExpressionFilterKind;
  itemId: Scalars['ID']['output'];
  itemPdfPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  messageText: Scalars['String']['output'];
  operator: EntitySearchFilterOperator;
  propertyKey?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type CompileDocIsolatedExpressionResultDiagnosticPropertyMapping = {
  __typename: 'CompileDocIsolatedExpressionResultDiagnosticPropertyMapping';
  code: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  fromExpr: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  itemPdfPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  messageText: Scalars['String']['output'];
  source: Scalars['String']['output'];
  toKey: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf = {
  __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedEligibleIf';
  code: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  expr: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  itemPdfPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  messageText: Scalars['String']['output'];
  source: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf = {
  __typename: 'CompileDocIsolatedExpressionResultDiagnosticRecommendedIncludeIf';
  code: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  expr: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  itemPdfPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  messageText: Scalars['String']['output'];
  source: Scalars['String']['output'];
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type CompileDocOverrideAttributeExpressionInput = {
  attribute: Scalars['String']['input'];
  expr: Scalars['String']['input'];
};

export type CompileDocOverrideComputedPropInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CompileDocOverrideInput = {
  attribute?: InputMaybe<CompileDocOverrideAttributeExpressionInput>;
  computedProp?: InputMaybe<CompileDocOverrideComputedPropInput>;
  itemId: Scalars['ID']['input'];
  propertyMapping?: InputMaybe<CompileDocOverridePropertyMappingInput>;
  propertySelectionSetter?: InputMaybe<CompileDocOverridePropertySelectionSetterInput>;
  recommended?: InputMaybe<CompileDocOverrideRecommendedInput>;
  versionNumber?: InputMaybe<CompileDocOverrideVersionNumberInput>;
};

export type CompileDocOverridePropertyMappingInput = {
  fromExpr: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CompileDocOverridePropertySelectionSetterInput = {
  fromExpr: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CompileDocOverrideRecommendedInput = {
  eligibleIf?: InputMaybe<Scalars['String']['input']>;
  includeIf?: InputMaybe<Scalars['String']['input']>;
  recommendationId: Scalars['ID']['input'];
};

export type CompileDocOverrideVersionNumberInput = {
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type Content = {
  __typename: 'Content';
  attachments: Array<ContentAttachment>;
  description: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
  keywords: Scalars['String']['output'];
  links: Array<ContentLink>;
  previewImageUrl: Scalars['String']['output'];
  richTexts: Array<ContentRichText>;
  title: Scalars['String']['output'];
  youtubeVideos: Array<ContentYoutube>;
};

export type ContentAttachment = {
  __typename: 'ContentAttachment';
  description: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  previewImageUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ContentAttachmentInput = {
  description: Scalars['String']['input'];
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  previewImageUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ContentInput = {
  attachments: Array<ContentAttachmentInput>;
  description: Scalars['String']['input'];
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  keywords: Scalars['String']['input'];
  links: Array<ContentLinkInput>;
  previewImageUrl: Scalars['String']['input'];
  richTexts: Array<ContentRichTextInput>;
  title: Scalars['String']['input'];
  youtubeVideos: Array<ContentYoutubeInput>;
};

export type ContentLink = {
  __typename: 'ContentLink';
  description: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
  previewImageUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ContentLinkInput = {
  description: Scalars['String']['input'];
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  previewImageUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ContentRichText = {
  __typename: 'ContentRichText';
  description: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
  previewImageUrl: Scalars['String']['output'];
  richText: Scalars['JSON']['output'];
  title: Scalars['String']['output'];
};

export type ContentRichTextInput = {
  description: Scalars['String']['input'];
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  previewImageUrl: Scalars['String']['input'];
  richText: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
};

export type ContentYoutube = {
  __typename: 'ContentYoutube';
  description: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
  previewImageUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
};

export type ContentYoutubeInput = {
  description: Scalars['String']['input'];
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  previewImageUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

export type ContractType =
  | 'fmbp_fr'
  | 'fmsp_fr'
  | 'fup'
  | 'lumpsum';

export type ContractingPdfReplacement = {
  __typename: 'ContractingPdfReplacement';
  formFieldName: Scalars['String']['output'];
  replacementValue: Scalars['String']['output'];
};

export type ContractingPdfReplacementInput = {
  formFieldName: Scalars['String']['input'];
  replacementValue: Scalars['String']['input'];
};

export type CopyQuoteIntoNewQuoteInput = {
  projectId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
};

export type CopyQuoteIntoNewQuoteResult = {
  __typename: 'CopyQuoteIntoNewQuoteResult';
  quote: Quote;
};

export type CopyQuoteSapS4HanaSalesQuoteInput = {
  contracteeCrmOrganisationIds: Array<Scalars['ID']['input']>;
  docId: Scalars['ID']['input'];
  onlySalesQuotationItems?: InputMaybe<Array<Scalars['String']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type CopyQuoteSapS4HanaSalesQuoteResult = {
  __typename: 'CopyQuoteSapS4HanaSalesQuoteResult';
  failedForCrmCompanies: Array<FailedForCrmCompany>;
};

export type CopySupplierOrganisationAsCrmInput = {
  systemOrganisationId: Scalars['ID']['input'];
};

export type CopySupplierOrganisationAsCrmResult = {
  __typename: 'CopySupplierOrganisationAsCrmResult';
  crmOrganisation: CrmCompany;
};

export type CorrectWorkSessionInput = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  date: Scalars['Date']['input'];
  duration: Scalars['Int']['input'];
  itemId: Scalars['ID']['input'];
};

export type CorrectWorkSessionResult = {
  __typename: 'CorrectWorkSessionResult';
  item: Item;
  project: Project;
  workSession?: Maybe<WorkSession>;
};

export type CraftsmanSearchResult = {
  __typename: 'CraftsmanSearchResult';
  organisation: Company;
};

export type CraftsmanSearchResultConnection = {
  __typename: 'CraftsmanSearchResultConnection';
  edges: Array<CraftsmanSearchResultEdge>;
  totalCount: Scalars['Int']['output'];
};

export type CraftsmanSearchResultEdge = {
  __typename: 'CraftsmanSearchResultEdge';
  node: CraftsmanSearchResult;
};

export type CreateAbsenceInput = {
  from: Scalars['Date']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  reason: AbsenceReason;
  till: Scalars['Date']['input'];
  whoId: Scalars['ID']['input'];
};

export type CreateAbsenceResult = {
  __typename: 'CreateAbsenceResult';
  absence: Absence;
};

export type CreateCrmOrganisationResult = {
  __typename: 'CreateCrmOrganisationResult';
  crmOrganisations: Array<CrmCompany>;
};

export type CreateEmbedabbleWizardLeadInput = {
  address?: InputMaybe<AddressInput>;
  applyItemActions: Array<ApplyItemActionInput>;
  contractorId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  itemUuidSeed: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  salutation?: InputMaybe<Salutation>;
  templateId: Scalars['ID']['input'];
};

export type CreateEmbedabbleWizardLeadResult = {
  __typename: 'CreateEmbedabbleWizardLeadResult';
  ok: Scalars['Boolean']['output'];
};

export type CreateIdsConnectOrderInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  fallbackRedirectUrl: Scalars['String']['input'];
  msOrganisationId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  redirectUrl: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIdsConnectOrderResult = {
  __typename: 'CreateIdsConnectOrderResult';
  data: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export type CreateInvoiceInput = {
  docId: Scalars['ID']['input'];
  invoiceType: InvoiceType;
  projectId: Scalars['ID']['input'];
};

export type CreateInvoiceResult = {
  __typename: 'CreateInvoiceResult';
  invoice: Invoice;
};

export type CreateItemFromItemRecommendationInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  itemRecommendationId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateItemFromItemRecommendationResult = {
  __typename: 'CreateItemFromItemRecommendationResult';
  createdItems: Array<Item>;
  doc: Document;
  item: Item;
};

export type CreateItemsFromTemplateTypesInput = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  parentDocId: Scalars['ID']['input'];
  parentItemId: Scalars['ID']['input'];
  parentProjectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  templateTypes: Array<CreateItemsFromTemplateTypesTemplateTypeInput>;
};

export type CreateItemsFromTemplateTypesResult = {
  __typename: 'CreateItemsFromTemplateTypesResult';
  createdItems: Array<Item>;
  doc: Document;
  items: Array<Item>;
  parentItem: Item;
  parentPathItems: Array<Item>;
};

export type CreateItemsFromTemplateTypesTemplateTypeInput = {
  templateTypeId: Scalars['ID']['input'];
};

export type CreateItemsFromTemplatesInput = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  parentDocId: Scalars['ID']['input'];
  parentItemId: Scalars['ID']['input'];
  parentProjectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  templates: Array<CreateItemsFromTemplatesQuotesInput>;
};

export type CreateItemsFromTemplatesQuotesInput = {
  templateEstimatedQuantity?: InputMaybe<Scalars['Float']['input']>;
  templateQuoteId: Scalars['ID']['input'];
  templateVersionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateItemsFromTemplatesResult = {
  __typename: 'CreateItemsFromTemplatesResult';
  createdItems: Array<Item>;
  doc: Document;
  items: Array<Item>;
  parentItem: Item;
  parentPathItems: Array<Item>;
};

export type CreateItemsInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  items: Array<CreateItemsValues>;
  parentItemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateItemsResult = {
  __typename: 'CreateItemsResult';
  doc: Document;
  items: Array<Item>;
  parentItem: Item;
  parentPathItems: Array<Item>;
};

export type CreateItemsValues = {
  approvalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  binding?: InputMaybe<ItemBinding>;
  childrenVisibility?: InputMaybe<ItemChildrenVisibility>;
  decisionBehaviorOfSubitems: DecisionBehaviorOfSubitems;
  decisionContingentItemPreselection?: InputMaybe<Scalars['Boolean']['input']>;
  decisionIsContingentItem?: InputMaybe<Scalars['Boolean']['input']>;
  decisionSubitemsPreselection?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  estimatedQuantity?: InputMaybe<Scalars['Float']['input']>;
  estimatedTime?: InputMaybe<Scalars['Float']['input']>;
  materialBuyingPrice?: InputMaybe<Scalars['Float']['input']>;
  materialMargin?: InputMaybe<Scalars['Float']['input']>;
  materialSellingPrice?: InputMaybe<Scalars['Float']['input']>;
  photoApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<ItemProductInput>;
  productValues?: InputMaybe<ModifyItemProductValuesInput>;
  qaApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  quantityUnit?: InputMaybe<QuantityUnit>;
  templateQuoteId?: InputMaybe<Scalars['ID']['input']>;
  timeTrackingRequired?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type: ItemType;
  workBuyingRate?: InputMaybe<Scalars['Float']['input']>;
  workSellingRate?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateManufacturerInput = {
  billingAddress?: InputMaybe<AddressInput>;
  branchAddress?: InputMaybe<AddressInput>;
  pickupAddress?: InputMaybe<AddressInput>;
  title: Scalars['String']['input'];
};

export type CreateManufacturerResult = {
  __typename: 'CreateManufacturerResult';
  organisation: ViewerOrganisation;
};

export type CreateOrganisationAndUserInput = {
  organisation: CreateOrganisationAndUserOrganisationInput;
  refToken?: InputMaybe<Scalars['String']['input']>;
  user: CreateOrganisationAndUserUserInput;
};

export type CreateOrganisationAndUserOrganisationInput = {
  billingAddress?: InputMaybe<AddressInput>;
  branchAddress?: InputMaybe<AddressInput>;
  isMeister?: InputMaybe<Scalars['Boolean']['input']>;
  organisationType: OrganisationType;
  phones: Array<PhoneInput>;
  pickupAddress?: InputMaybe<AddressInput>;
  skillset: Array<Skill>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateOrganisationAndUserResult = {
  __typename: 'CreateOrganisationAndUserResult';
  organisation: ViewerOrganisation;
  user: Viewer;
};

export type CreateOrganisationAndUserUserInput = {
  locale: Scalars['String']['input'];
  phones: Array<PhoneInput>;
};

export type CreatePlanSessionInput = {
  from: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isTentative?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
  till: Scalars['DateTime']['input'];
  whoId: Scalars['ID']['input'];
  workload: Scalars['Float']['input'];
};

export type CreatePlanSessionResult = {
  __typename: 'CreatePlanSessionResult';
  planSession: PlanSession;
  project: Project;
};

export type CreateProjectForQuoteProductsDistributionInput = {
  project: CreateProjectForQuoteProductsDistributionProjectInput;
  quote: CreateProjectForQuoteProductsDistributionQuoteInput;
  quoteProductsDistribution: CreateProjectForQuoteProductsDistributionQuoteProductsDistributionInput;
};

export type CreateProjectForQuoteProductsDistributionProjectInput = {
  budgetAmount?: InputMaybe<Scalars['Float']['input']>;
  buildingItemId?: InputMaybe<Scalars['ID']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  crmOrganisationId: Scalars['ID']['input'];
  deadline?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  earliestStart?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: ProjectType;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProjectForQuoteProductsDistributionQuoteInput = {
  contractType?: InputMaybe<ContractType>;
  defaultVerticalSettings?: InputMaybe<Array<VerticalSettingInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPercentage: Scalars['Float']['input'];
  earliestDate?: InputMaybe<Scalars['Date']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  materialFactor?: InputMaybe<Scalars['Float']['input']>;
  quoteTexts?: InputMaybe<QuoteTextsInput>;
  templateQuoteId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatInfo?: InputMaybe<VatInfoInput>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  workFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateProjectForQuoteProductsDistributionQuoteProductsDistributionInput = {
  id: Scalars['ID']['input'];
  opts?: InputMaybe<DistributeProductsInQuoteOptsInput>;
};

export type CreateProjectForQuoteProductsDistributionResult = {
  __typename: 'CreateProjectForQuoteProductsDistributionResult';
  project: Project;
  quote: Quote;
  rootItem: Item;
};

export type CreateProjectForRequestInput = {
  requestId: Scalars['ID']['input'];
};

export type CreateProjectForRequestResult = {
  __typename: 'CreateProjectForRequestResult';
  project: Project;
  request: Request;
};

export type CreateProjectFromGaebImportInput = {
  filename: Scalars['String']['input'];
  gaebType: CreateProjectFromGaebType;
  projectParams?: InputMaybe<CreateProjectFromImportInput>;
};

export type CreateProjectFromGaebImportResult = {
  __typename: 'CreateProjectFromGaebImportResult';
  project: Project;
};

export type CreateProjectFromGaebType =
  | 'p84_custom'
  | 'p94';

export type CreateProjectFromImportInput = {
  isTentative?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateProjectFromXiopdImportInput = {
  filename: Scalars['String']['input'];
  projectParams?: InputMaybe<CreateProjectFromImportInput>;
};

export type CreateProjectFromXiopdImportResult = {
  __typename: 'CreateProjectFromXiopdImportResult';
  project: Project;
};

export type CreateQuoteFromGaebImportInput = {
  filename: Scalars['String']['input'];
  gaebType: CreateQuoteFromGaebType;
  projectId: Scalars['ID']['input'];
};

export type CreateQuoteFromGaebImportResult = {
  __typename: 'CreateQuoteFromGaebImportResult';
  doc: Quote;
  rootItem: Item;
};

export type CreateQuoteFromGaebType =
  | 'p84_custom'
  | 'p94';

export type CreateQuoteFromSapS4HanaSalesQuoteInput = {
  onlySalesQuotationItems?: InputMaybe<Array<Scalars['String']['input']>>;
  sapS4HanaSalesQuoteId: Scalars['String']['input'];
};

export type CreateQuoteFromSapS4HanaSalesQuoteResult = {
  __typename: 'CreateQuoteFromSapS4HanaSalesQuoteResult';
  project: Project;
  quote: Quote;
  rootItem: Item;
};

export type CreateQuoteFromXiopdImportInput = {
  filename: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type CreateQuoteFromXiopdImportResult = {
  __typename: 'CreateQuoteFromXiopdImportResult';
  doc: Quote;
  rootItem: Item;
};

export type CreateQuoteInput = {
  applyItemActions?: InputMaybe<Array<ApplyItemActionInput>>;
  contractType?: InputMaybe<ContractType>;
  discountPercentage: Scalars['Float']['input'];
  importRequirement?: InputMaybe<Scalars['Boolean']['input']>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  itemUuidSeed?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  quoteForRequirementId?: InputMaybe<Scalars['ID']['input']>;
  requestId?: InputMaybe<Scalars['ID']['input']>;
  templateQuoteId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatInfo?: InputMaybe<VatInfoInput>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateQuoteProductsDistributionInput = {
  clientEmail?: InputMaybe<Scalars['String']['input']>;
  products: Array<CreateQuoteProductsDistributionProductInput>;
};

export type CreateQuoteProductsDistributionProductInput = {
  articleNumber: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  supplierId: Scalars['String']['input'];
};

export type CreateQuoteProductsDistributionResult = {
  __typename: 'CreateQuoteProductsDistributionResult';
  quoteProductsDistribution: QuoteProductsDistribution;
};

export type CreateQuoteRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  budgetAmount?: InputMaybe<Scalars['Float']['input']>;
  categories: Array<Skill>;
  deadline?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  earliestStart?: InputMaybe<Scalars['String']['input']>;
  hideExactAddress?: InputMaybe<Scalars['Boolean']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  projectType?: InputMaybe<ProjectType>;
  recipientSystemOrganisationIds: Array<Scalars['ID']['input']>;
  requestorDocId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateQuoteRequestResult = {
  __typename: 'CreateQuoteRequestResult';
  request: Request;
};

export type CreateQuoteResult = {
  __typename: 'CreateQuoteResult';
  quote: Quote;
  rootItem: Item;
};

export type CreateQuoteTemplateInput = {
  applyTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPercentage: Scalars['Float']['input'];
  earliestDate?: InputMaybe<Scalars['Date']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  materialFactor?: InputMaybe<Scalars['Float']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  rootItemType: ItemType;
  templateQuoteId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatInfo?: InputMaybe<VatInfoInput>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  workFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateQuoteTemplateResult = {
  __typename: 'CreateQuoteTemplateResult';
  quoteTemplate: QuoteTemplate;
  rootItem: Item;
};

export type CreateRequirementInput = {
  applyItemActions?: InputMaybe<Array<ApplyItemActionInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  itemUuidSeed?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  templateQuoteId?: InputMaybe<Scalars['ID']['input']>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateRequirementResult = {
  __typename: 'CreateRequirementResult';
  requirement: Requirement;
  rootItem: Item;
};

export type CreateSapS4HanaSalesQuoteFromQuoteInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type CreateSapS4HanaSalesQuoteFromQuoteResult = {
  __typename: 'CreateSapS4HanaSalesQuoteFromQuoteResult';
  quote: Quote;
};

export type CreateSupplierInput = {
  billingAddress?: InputMaybe<AddressInput>;
  branchAddress?: InputMaybe<AddressInput>;
  pickupAddress?: InputMaybe<AddressInput>;
  title: Scalars['String']['input'];
};

export type CreateSupplierResult = {
  __typename: 'CreateSupplierResult';
  organisation: ViewerOrganisation;
};

export type CreateTemplateFromItemInput = {
  originDocId: Scalars['ID']['input'];
  originItemId?: InputMaybe<Scalars['ID']['input']>;
  originProjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateTemplateFromItemResult = {
  __typename: 'CreateTemplateFromItemResult';
  item: Item;
  templateDoc: QuoteTemplate;
};

export type CreateTimeReportInput = {
  fromDate: Scalars['Date']['input'];
  projectId: Scalars['ID']['input'];
  tasksDescription?: InputMaybe<Scalars['JSON']['input']>;
  tillDate: Scalars['Date']['input'];
};

export type CreateTimeReportResult = {
  __typename: 'CreateTimeReportResult';
  project: Project;
  timeReport: TimeReport;
};

export type CreateTodoItemInput = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  linkedCrmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  linkedCrmUserId?: InputMaybe<Scalars['ID']['input']>;
  linkedProjectId?: InputMaybe<Scalars['ID']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
};

export type CreateTodoItemResult = {
  __typename: 'CreateTodoItemResult';
  todoItem: TodoItem;
};

export type CreateWorkSessionsInput = {
  inputs: Array<CreateWorkSessionsSingleInput>;
  itemId: Scalars['ID']['input'];
};

export type CreateWorkSessionsResult = {
  __typename: 'CreateWorkSessionsResult';
  item: Item;
  workSessions: Array<WorkSession>;
};

export type CreateWorkSessionsSingleInput = {
  assigneeId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  till?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CrmCompaniesFilters = {
  abcCategory?: InputMaybe<Array<CrmOrganisationAbcCategory>>;
  customFields?: InputMaybe<Array<CustomFieldFilter>>;
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  linkedSystemOrganisationId?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Array<Scalars['String']['input']>>;
  projectCategories?: InputMaybe<Array<Skill>>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  projectState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  projectType?: InputMaybe<Array<ProjectType>>;
  tagsAll?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CrmCompaniesRecord = WithLogo & {
  __typename: 'CrmCompaniesRecord';
  abcCategory: CrmOrganisationAbcCategory;
  billingAddress?: Maybe<Address>;
  businessPartnerStatus?: Maybe<CrmCompanyBusinessPartnerStatus>;
  contactPerson?: Maybe<CrmPerson>;
  crmDuplicateOfId?: Maybe<Scalars['ID']['output']>;
  customTags: Array<Scalars['String']['output']>;
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  isInvited: Scalars['Boolean']['output'];
  linkedSystemOrganisationId?: Maybe<Scalars['ID']['output']>;
  logo: AttachmentSnapshot;
  number: Scalars['String']['output'];
  systemTags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type CrmCompany = WithBillingAddress & WithBranchAddress & WithCompanyInfo & WithContactInfo & WithContents & WithCustomFields & WithLogo & {
  __typename: 'CrmCompany';
  abcCategory: CrmOrganisationAbcCategory;
  bankAccount: Scalars['String']['output'];
  bankCode: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  billingAddress?: Maybe<Address>;
  branchAddress?: Maybe<Address>;
  buildings: Array<Building>;
  channels: Array<Channel>;
  contactPerson?: Maybe<CrmPerson>;
  contactType: CrmOrganisationContactType;
  contents: Array<Content>;
  createdAt: Scalars['DateTime']['output'];
  crmDuplicateOfId?: Maybe<Scalars['ID']['output']>;
  crmOwningOrganisationClientId?: Maybe<Scalars['String']['output']>;
  customFields: Array<CustomFieldField>;
  customTags: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  fullLegalName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isClientOrganisation: Scalars['Boolean']['output'];
  isCraftsmanOrganisation: Scalars['Boolean']['output'];
  isEmailValid?: Maybe<Scalars['Boolean']['output']>;
  isManufacturerOrganisation: Scalars['Boolean']['output'];
  isMeister: Scalars['Boolean']['output'];
  isSupplierOrganisation: Scalars['Boolean']['output'];
  linkedItems: Array<QuoteItemLinkCrmOrganisation>;
  linkedProjects: Array<ProjectLinkCrmOrganisation>;
  linkedSystemOrganisationId?: Maybe<Scalars['ID']['output']>;
  logo: AttachmentSnapshot;
  mailMessages: Array<CrmMailMessage>;
  managingDirector: Scalars['String']['output'];
  members: Array<CrmPerson>;
  notices: Scalars['String']['output'];
  number: Scalars['String']['output'];
  numberOfProjects: Scalars['Int']['output'];
  phones: Array<Phone>;
  pickupAddress?: Maybe<Address>;
  registrationNumber: Scalars['String']['output'];
  registrationOffice: Scalars['String']['output'];
  skillset: Array<Skill>;
  systemTags: Array<Scalars['String']['output']>;
  taxNumber: Scalars['String']['output'];
  title: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
  website: Scalars['String']['output'];
};


export type CrmCompanyChannelsArgs = {
  filters?: InputMaybe<ChannelsFilters>;
};

export type CrmCompanyBusinessPartnerStatus =
  | 'GLOBAL_BLOCK'
  | 'ORDER_BLOCK';

export type CrmCompanyConnection = {
  __typename: 'CrmCompanyConnection';
  edges: Array<CrmCompanyEdge>;
  totalCount: Scalars['Int']['output'];
};

export type CrmCompanyEdge = {
  __typename: 'CrmCompanyEdge';
  node: CrmCompaniesRecord;
};

export type CrmCompanyResult = {
  __typename: 'CrmCompanyResult';
  crmCompany?: Maybe<CrmCompany>;
};

export type CrmEmailMessageConnection = {
  __typename: 'CrmEmailMessageConnection';
  edges: Array<CrmEmailMessageEdge>;
  totalCount: Scalars['Int']['output'];
};

export type CrmEmailMessageContextInput = {
  id: Scalars['ID']['input'];
  type: SendCrmEmailContextType;
};

export type CrmEmailMessageEdge = {
  __typename: 'CrmEmailMessageEdge';
  node: CrmMailMessage;
};

export type CrmEmailMessagesFilters = {
  context?: InputMaybe<CrmEmailMessageContextInput>;
  crmCompanyId?: InputMaybe<Scalars['ID']['input']>;
  crmPersonId?: InputMaybe<Scalars['ID']['input']>;
};

export type CrmLinkingInvitation = {
  __typename: 'CrmLinkingInvitation';
  authorOrganisation: CrmLinkingInvitationAuthorOrganisation;
  contact: CrmLinkingInvitationContact;
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CrmLinkingInvitationAuthorOrganisation = {
  __typename: 'CrmLinkingInvitationAuthorOrganisation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CrmLinkingInvitationContact = {
  __typename: 'CrmLinkingInvitationContact';
  company: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  person?: Maybe<CrmLinkingInvitationContactPerson>;
};

export type CrmLinkingInvitationContactPerson = {
  __typename: 'CrmLinkingInvitationContactPerson';
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  title: Salutation;
};

export type CrmMailMessage = {
  __typename: 'CrmMailMessage';
  attachments: Array<CrmMailMessageAttachment>;
  body: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  direction: EmailMessageDirection;
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
  threadId: Scalars['String']['output'];
  to: Scalars['String']['output'];
};

export type CrmMailMessageAttachment = {
  __typename: 'CrmMailMessageAttachment';
  filename: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CrmOrganisationAbcCategory =
  | 'a'
  | 'b'
  | 'c'
  | 'none';

export type CrmOrganisationContactType =
  | 'COMPANY'
  | 'INDIVIDUAL';

export type CrmOrganisationCreateInput = {
  billingAddress?: InputMaybe<AddressInput>;
  contactType?: InputMaybe<CrmOrganisationContactType>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  notices?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  title: Scalars['String']['input'];
};

export type CrmOrganisationModifyInput = {
  abcCategory?: InputMaybe<CrmOrganisationAbcCategory>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingAddress?: InputMaybe<AddressInput>;
  branchAddress?: InputMaybe<AddressInput>;
  contactPersonId?: InputMaybe<Scalars['ID']['input']>;
  contactType?: InputMaybe<CrmOrganisationContactType>;
  crmOwningOrganisationClientId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  fullLegalName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  logo?: InputMaybe<AttachmentInput>;
  managingDirector?: InputMaybe<Scalars['String']['input']>;
  notices?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  pickupAddress?: InputMaybe<AddressInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  registrationOffice?: InputMaybe<Scalars['String']['input']>;
  skillset?: InputMaybe<Array<Skill>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CrmOrganisationModifyResult = {
  __typename: 'CrmOrganisationModifyResult';
  crmOrganisations: Array<CrmCompany>;
};

export type CrmPerson = WithAvatar & WithContactInfo & WithContents & WithCustomFields & WithPersonInfo & {
  __typename: 'CrmPerson';
  avatar: AttachmentSnapshot;
  contents: Array<Content>;
  crmCompany: CrmCompany;
  customFields: Array<CustomFieldField>;
  customTags: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  homeAddress?: Maybe<Address>;
  id: Scalars['ID']['output'];
  isEmailValid?: Maybe<Scalars['Boolean']['output']>;
  isMeister: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  linkedItems: Array<QuoteItemLinkCrmUser>;
  linkedProjects: Array<ProjectLinkCrmUser>;
  locale: Scalars['String']['output'];
  mailMessages: Array<CrmMailMessage>;
  notices: Scalars['String']['output'];
  phones: Array<Phone>;
  projectContracteeInvitations: Array<ProjectContracteeInvitation>;
  skillset: Array<Skill>;
  title: Salutation;
  website?: Maybe<Scalars['String']['output']>;
};


export type CrmPersonProjectContracteeInvitationsArgs = {
  filterState?: InputMaybe<Array<InvitationState>>;
};

export type CrmPersonConnection = {
  __typename: 'CrmPersonConnection';
  edges: Array<CrmPersonEdge>;
  totalCount: Scalars['Int']['output'];
};

export type CrmPersonEdge = {
  __typename: 'CrmPersonEdge';
  node: CrmPerson;
};

export type CrmPersonResult = {
  __typename: 'CrmPersonResult';
  crmPerson?: Maybe<CrmPerson>;
};

export type CrmPersonsFilters = {
  crmCompanyId?: InputMaybe<Scalars['ID']['input']>;
  customFields?: InputMaybe<Array<CustomFieldFilter>>;
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  tagsAll?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CrmUserCreateInput = {
  address?: InputMaybe<AddressInput>;
  defaultProjectRoleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  familyname: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notices?: InputMaybe<Scalars['String']['input']>;
  organisationId: Scalars['ID']['input'];
  phones?: InputMaybe<Array<PhoneInput>>;
  title: Salutation;
};

export type CrmUserCreateResult = {
  __typename: 'CrmUserCreateResult';
  users: Array<CrmPerson>;
};

export type CrmUserModifyInput = {
  avatar?: InputMaybe<AttachmentInput>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  familyname?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  homeAddress?: InputMaybe<AddressInput>;
  id: Scalars['ID']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  notices?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Salutation>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CrmUserModifyResult = {
  __typename: 'CrmUserModifyResult';
  users: Array<CrmPerson>;
};

export type CustomField = {
  __typename: 'CustomField';
  fields: Array<CustomFieldField>;
  id: Scalars['ID']['output'];
};

export type CustomFieldConfig = {
  __typename: 'CustomFieldConfig';
  allowedValues: Scalars['String']['output'];
  dataType: CustomFieldDataType;
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isUnique: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  mandatory: Scalars['Boolean']['output'];
  objectType: CustomFieldObjectType;
  showAsColumnInList: Scalars['Boolean']['output'];
  showInCreateForm: Scalars['Boolean']['output'];
};

export type CustomFieldDataType =
  | 't_boolean'
  | 't_cm'
  | 't_date'
  | 't_external_url'
  | 't_m'
  | 't_mm'
  | 't_number'
  | 't_text'
  | 't_youtube_video_id';

export type CustomFieldField = {
  __typename: 'CustomFieldField';
  dataType: CustomFieldDataType;
  group: Scalars['String']['output'];
  isUnique: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
};

export type CustomFieldFilter = {
  eq?: InputMaybe<Scalars['JSON']['input']>;
  exist?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  ilike?: InputMaybe<Scalars['JSON']['input']>;
  key: Scalars['String']['input'];
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
};

export type CustomFieldObjectType =
  | 'Building'
  | 'Invoice'
  | 'InvoiceItem'
  | 'Order'
  | 'OrderItem'
  | 'Organisation'
  | 'PlanSession'
  | 'Project'
  | 'Quote'
  | 'QuoteItem'
  | 'User'
  | 'UserProjectMembership';

export type DatanormOnlineAvailability = {
  __typename: 'DatanormOnlineAvailability';
  clientIdRequired: Scalars['Boolean']['output'];
  itekCompanyId: Scalars['ID']['output'];
  passwordRequired: Scalars['Boolean']['output'];
  usernameRequired: Scalars['Boolean']['output'];
};

export type DatanormOnlineEntriesResult = {
  __typename: 'DatanormOnlineEntriesResult';
  entries: Array<DatanormOnlineEntry>;
};

export type DatanormOnlineEntry = {
  __typename: 'DatanormOnlineEntry';
  dataDate?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DatanormOnlineEntryInput = {
  dataDate?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  filename?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type DebugAllProp = {
  __typename: 'DebugAllProp';
  attributeExpressions: Array<ItemAttributeExpression>;
  itemId: Scalars['ID']['output'];
  itemPath: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  propertyMappings: Array<PropertyMapping>;
  props: Array<Props2>;
};

export type DecisionBehaviorOfSubitems =
  | 'NONE'
  | 'SELECT_MANY'
  | 'SELECT_ONE';

export type DecisionItemRootType =
  | 'paid'
  | 'section';

export type DecisionOptionElimination = {
  __typename: 'DecisionOptionElimination';
  authorId: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
};

export type DeclineChangesInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type DeclineChangesResult = {
  __typename: 'DeclineChangesResult';
  project: Project;
  quote: Quote;
};

export type DeclineProjectContracteeInvitationResult = {
  __typename: 'DeclineProjectContracteeInvitationResult';
  invitation: ProjectContracteeInvitation;
};

export type DeclineQuoteInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type DeclineQuoteResult = {
  __typename: 'DeclineQuoteResult';
  quote: Quote;
};

export type DefineItemAttributeExpressionInput = {
  attributeExpressions: Array<AttributeExpressionInput>;
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DefineItemAttributeExpressionResult = {
  __typename: 'DefineItemAttributeExpressionResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type DefineItemProductSearchFilterDefinitionsForDecisionInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DefineItemProductSearchFilterDefinitionsInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  itemProductSearchFilterBrandFilterDefinition?: InputMaybe<ItemProductSearchFilterBrandFilterDefinitionInput>;
  itemProductSearchFilterBrandLineFilterDefinition?: InputMaybe<ItemProductSearchFilterBrandLineFilterDefinitionInput>;
  itemProductSearchFilterFavouritesFilterDefinition?: InputMaybe<ItemProductSearchFilterFavouritesFilterDefinitionInput>;
  itemProductSearchFilterListPriceFilterDefinition?: InputMaybe<ItemProductSearchFilterPriceFilterDefinitionInput>;
  itemProductSearchFilterProductTypeIdFilterDefinition?: InputMaybe<ItemProductSearchFilterPropertyIdFilterDefinitionInput>;
  itemProductSearchFilterPropertyFilterDefinitions: Array<ItemProductSearchFilterPropertyFilterDefinitionInput>;
  itemProductSearchFilterSupplierIdFilterDefinition?: InputMaybe<ItemProductSearchFilterOrganisationIdFilterDefinitionInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DefineItemProductSearchFilterDefinitionsResult = {
  __typename: 'DefineItemProductSearchFilterDefinitionsResult';
  doc: Document;
  item: Item;
};

export type DefineItemProductSearchFilterExpressionsInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  itemProductSearchFilterBrandFilterExpression?: InputMaybe<ItemProductSearchFilterBrandFilterExpressionInput>;
  itemProductSearchFilterBrandLineFilterExpression?: InputMaybe<ItemProductSearchFilterBrandLineFilterExpressionInput>;
  itemProductSearchFilterFavouritesFilterExpression?: InputMaybe<ItemProductSearchFilterFavouritesFilterExpressionInput>;
  itemProductSearchFilterListPriceFilterExpression?: InputMaybe<ItemProductSearchFilterPriceFilterExpressionInput>;
  itemProductSearchFilterPropertyFilterExpressions: Array<ItemProductSearchFilterPropertyFilterExpressionInput>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DefineItemProductSearchFilterExpressionsResult = {
  __typename: 'DefineItemProductSearchFilterExpressionsResult';
  doc: Document;
  item: Item;
};

export type DefineItemProps2Entry = {
  bool?: InputMaybe<DefineItemProps2EntryBool>;
  boolComputed?: InputMaybe<DefineItemProps2EntryBoolComputed>;
  number?: InputMaybe<DefineItemProps2EntryNumber>;
  numberArray?: InputMaybe<DefineItemProps2EntryNumberArray>;
  numberArrayComputed?: InputMaybe<DefineItemProps2EntryNumberArrayComputed>;
  numberComputed?: InputMaybe<DefineItemProps2EntryNumberComputed>;
  text?: InputMaybe<DefineItemProps2EntryText>;
  textArray?: InputMaybe<DefineItemProps2EntryTextArray>;
  textArrayComputed?: InputMaybe<DefineItemProps2EntryTextArrayComputed>;
  textComputed?: InputMaybe<DefineItemProps2EntryTextComputed>;
};

export type DefineItemProps2EntryBool = {
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueBool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DefineItemProps2EntryBoolComputed = {
  clientVisibility: Scalars['Boolean']['input'];
  expr: Scalars['String']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type DefineItemProps2EntryNumber = {
  allowedValuesNumber: Array<Props2AllowedValuesNumberInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  range?: InputMaybe<Props2NumberRangeInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type DefineItemProps2EntryNumberArray = {
  allowedValuesNumber: Array<Props2AllowedValuesNumberInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  range?: InputMaybe<Props2NumberRangeInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumberArray?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type DefineItemProps2EntryNumberArrayComputed = {
  clientVisibility: Scalars['Boolean']['input'];
  expr: Scalars['String']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type DefineItemProps2EntryNumberComputed = {
  clientVisibility: Scalars['Boolean']['input'];
  expr: Scalars['String']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type DefineItemProps2EntryText = {
  allowedValuesText: Array<Props2AllowedValuesTextInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueText?: InputMaybe<Scalars['String']['input']>;
};

export type DefineItemProps2EntryTextArray = {
  allowedValuesText: Array<Props2AllowedValuesTextInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueTextArray?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DefineItemProps2EntryTextArrayComputed = {
  clientVisibility: Scalars['Boolean']['input'];
  expr: Scalars['String']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type DefineItemProps2EntryTextComputed = {
  clientVisibility: Scalars['Boolean']['input'];
  expr: Scalars['String']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type DefineItemProps2Input = {
  docId: Scalars['ID']['input'];
  entries: Array<DefineItemProps2Entry>;
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DefineItemProps2Result = {
  __typename: 'DefineItemProps2Result';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type DefineItemTemplateSearchFilterDefinitionsInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  templateSearchFilterDefinitions: TemplateSearchFilterDefinitionsInput;
};

export type DefineItemTemplateSearchFilterDefinitionsResult = {
  __typename: 'DefineItemTemplateSearchFilterDefinitionsResult';
  doc: Document;
  item: Item;
};

export type DefineItemTemplateSearchFilterExpressionsInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  templateSearchFilterExpressions: TemplateSearchFilterExpressionsInput;
};

export type DefineItemTemplateSearchFilterExpressionsResult = {
  __typename: 'DefineItemTemplateSearchFilterExpressionsResult';
  doc: Document;
  item: Item;
};

export type DefineItemTemplateSearchSortingDefinitionsInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  templateSearchSortingDefinitions: Array<TemplateSearchSortingDefinitionInput>;
};

export type DefineItemTemplateSearchSortingDefinitionsResult = {
  __typename: 'DefineItemTemplateSearchSortingDefinitionsResult';
  doc: Document;
  item: Item;
};

export type DeleteBuildingInput = {
  buildingId: Scalars['ID']['input'];
};

export type DeleteBuildingResult = {
  __typename: 'DeleteBuildingResult';
  ok: Scalars['Boolean']['output'];
};

export type DeleteCompanyOrganisationCredentialsInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCompanyOrganisationCredentialsResult = {
  __typename: 'DeleteCompanyOrganisationCredentialsResult';
  ok: Scalars['Boolean']['output'];
};

export type DeleteInvoiceInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DeleteInvoiceResult = {
  __typename: 'DeleteInvoiceResult';
  invoiceId: Scalars['ID']['output'];
};

export type DeleteItemInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteItemResult = {
  __typename: 'DeleteItemResult';
  doc: Document;
  parentItem?: Maybe<Item>;
  parentPathItems: Array<Item>;
};

export type DeleteOrderResult = {
  __typename: 'DeleteOrderResult';
  ok: Scalars['Boolean']['output'];
};

export type DeletePimImportInput = {
  importId: Scalars['ID']['input'];
};

export type DeletePimImportationResult = {
  __typename: 'DeletePimImportationResult';
  importation: PimImportation;
};

export type DeletePimSupplierImportationsInput = {
  supplierId: Scalars['ID']['input'];
};

export type DeletePimSupplierImportationsResult = {
  __typename: 'DeletePimSupplierImportationsResult';
  importations: Array<PimImportation>;
};

export type DeletePlanSessionResult = {
  __typename: 'DeletePlanSessionResult';
  ok: Scalars['Boolean']['output'];
  project: Project;
};

export type DeleteQuoteInput = {
  projectId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
};

export type DeleteQuoteResult = {
  __typename: 'DeleteQuoteResult';
  ok: Scalars['Boolean']['output'];
};

export type DeleteQuoteTemplateInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type DeleteQuoteTemplateResult = {
  __typename: 'DeleteQuoteTemplateResult';
  ok: Scalars['Boolean']['output'];
};

export type DeleteRequirementInput = {
  projectId: Scalars['ID']['input'];
  requirementId: Scalars['ID']['input'];
};

export type DeleteRequirementResult = {
  __typename: 'DeleteRequirementResult';
  ok: Scalars['Boolean']['output'];
};

export type DeleteWorkSessionResult = {
  __typename: 'DeleteWorkSessionResult';
  item: Item;
  result: Scalars['Boolean']['output'];
};

export type DeliveryAddressType =
  | 'delivery'
  | 'pickup';

export type DesiredProjectInitialStatus =
  | 'contracted'
  | 'opportunity';

export type DisableUserCalendarInput = {
  userId: Scalars['ID']['input'];
};

export type DisableUserCalendarResult = {
  __typename: 'DisableUserCalendarResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DisapproveTaskItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DisapproveTaskItemResult = {
  __typename: 'DisapproveTaskItemResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
};

export type DiscardPendingChangesInput = {
  deep?: InputMaybe<Scalars['Boolean']['input']>;
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DiscardPendingChangesResult = {
  __typename: 'DiscardPendingChangesResult';
  doc: Quote;
  item: Item;
};

export type DiscardUnpublishedChangesInput = {
  docId: Scalars['ID']['input'];
};

export type DistributeProductsInQuoteInput = {
  docId: Scalars['ID']['input'];
  opts?: InputMaybe<DistributeProductsInQuoteOptsInput>;
  products: Array<DistributeProductsInQuoteProductInput>;
  projectId: Scalars['ID']['input'];
};

export type DistributeProductsInQuoteOptsInput = {
  deselectSiblingDecisionSelections?: InputMaybe<Scalars['Boolean']['input']>;
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  matchByImplicitTags?: InputMaybe<Scalars['Boolean']['input']>;
  rootSubTreeItemId?: InputMaybe<Scalars['ID']['input']>;
  setExpensiveProductFirst?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DistributeProductsInQuoteProductInput = {
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
};

export type DistributeProductsInQuoteResult = {
  __typename: 'DistributeProductsInQuoteResult';
  quoteProductsDistributionMappings: Array<QuoteProductsDistributionMapping>;
  rootItemProps2: Array<Scalars['JSON']['output']>;
};

export type DocActor = {
  __typename: 'DocActor';
  address?: Maybe<AddressSnapshot>;
  companyName: Scalars['String']['output'];
  companyOrIndividual: DocActorCompanyOrIndividual;
  contact?: Maybe<DocActorContact>;
  email: Scalars['String']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  isMyOrganisation: Scalars['Boolean']['output'];
  phone: Scalars['String']['output'];
  salutation: Salutation;
  type: DocActorType;
  viewerCrmCompany?: Maybe<CrmCompany>;
};

export type DocActorCompanyOrIndividual =
  | 'COMPANY'
  | 'INDIVIDUAL';

export type DocActorContact = {
  __typename: 'DocActorContact';
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  myOrganisationUserId?: Maybe<Scalars['ID']['output']>;
  salutation: Salutation;
  viewerCrmPerson?: Maybe<CrmPerson>;
};

export type DocActorContactModifyInput = {
  familyname?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
};

export type DocActorFilterInput = {
  crmOrganisationId: Scalars['ID']['input'];
  type: DocActorType;
};

export type DocActorModifyInput = {
  address?: InputMaybe<AddressInput>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyOrIndividual?: InputMaybe<DocActorCompanyOrIndividual>;
  contact?: InputMaybe<DocActorContactModifyInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  familyname?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
};

export type DocActorType =
  | 'CLIENT'
  | 'CONTRACTEE'
  | 'CONTRACTOR';

export type DocExportAsExcelInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DocExportAsExcelResult = {
  __typename: 'DocExportAsExcelResult';
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type DocExportAsM1ImpexJsonInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type DocExportAsM1ImpexJsonResult = {
  __typename: 'DocExportAsM1ImpexJsonResult';
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type DocItemState =
  | 'ACCEPTED'
  | 'DECLINED'
  | 'DRAFT'
  | 'PRESENTING';

export type DocProposedCalculation = {
  __typename: 'DocProposedCalculation';
  documentCalculation: ItemCalculation;
  itemCalculations: Array<ProposedCalculation>;
};

export type DocShare = {
  __typename: 'DocShare';
  docOwningSystemOrganisationId: Scalars['ID']['output'];
  docOwningSystemOrganisationTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sharedWithPermissions: Array<PermissionName>;
  sharedWithProjectId: Scalars['ID']['output'];
  sharedWithProject__DEBUGONLY__Title: Scalars['ID']['output'];
  sharedWithSystemOrganisationId: Scalars['ID']['output'];
  sharedWithSystemOrganisationTitle: Scalars['String']['output'];
};

export type DocType =
  | 'BUILDING'
  | 'QUOTE'
  | 'REQUIREMENT'
  | 'TEMPLATE';

export type Document = Building | Quote | QuoteTemplate | Requirement;

export type DocumentBulkFinaliseFinalizedDecision = {
  __typename: 'DocumentBulkFinaliseFinalizedDecision';
  itemId: Scalars['ID']['output'];
  itemPath: Scalars['String']['output'];
  type: DocumentBulkFinaliseFinalizedDecisionType;
};

export type DocumentBulkFinaliseFinalizedDecisionType =
  | 'CONTINGENCY'
  | 'SELECT_MANY'
  | 'SELECT_ONE';

export type DocumentBulkFinaliseInput = {
  docId: Scalars['ID']['input'];
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  excludeItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fromItemId?: InputMaybe<Scalars['ID']['input']>;
  onlyItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DocumentBulkFinaliseResult = {
  __typename: 'DocumentBulkFinaliseResult';
  /** Changed document */
  doc: Document;
  finalizedDecisions: Array<DocumentBulkFinaliseFinalizedDecision>;
};

export type DocumentRecomputePropertiesAndExpressionsInput = {
  docId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type DocumentRecomputePropertiesAndExpressionsResult = {
  __typename: 'DocumentRecomputePropertiesAndExpressionsResult';
  doc: Document;
};

export type DocumentResolveItemLinks =
  | 'STOP_ON_DECISION_OPTION'
  | 'YES';

export type DocumentSettings = {
  __typename: 'DocumentSettings';
  id: Scalars['ID']['output'];
  invoiceAttachments: Array<AttachmentSnapshot>;
  invoiceTexts: OrganisationPdfTexts;
  materialListTexts: OrganisationPdfTexts;
  purchaseOrderTexts: OrganisationPdfTexts;
  quoteTexts: OrganisationPdfTexts;
  timeReportTexts: OrganisationPdfTexts;
};

export type DuplicateQuoteTemplateInput = {
  quoteTemplateId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type DuplicateQuoteTemplateResult = {
  __typename: 'DuplicateQuoteTemplateResult';
  quoteTemplate: QuoteTemplate;
  rootItem: Item;
};

export type EliminateDecisionOptionInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  reason: Scalars['String']['input'];
};

export type EliminateDecisionOptionResult = {
  __typename: 'EliminateDecisionOptionResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
  parentItem: Item;
};

export type EliminateItemInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type EliminateItemResult = {
  __typename: 'EliminateItemResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
  parentItem: Item;
};

export type EmailMessageDirection =
  | 'in'
  | 'out';

export type EmailTemplateContext =
  | 'CRM'
  | 'PROJECT'
  | 'QUOTE'
  | 'REQUIREMENT';

export type EnableUserCalendarInput = {
  userId: Scalars['ID']['input'];
};

export type EnableUserCalendarResult = {
  __typename: 'EnableUserCalendarResult';
  token: Scalars['String']['output'];
};

export type EnterItemProps2ValueEntry = {
  bool?: InputMaybe<EnterItemProps2ValueEntryBool>;
  number?: InputMaybe<EnterItemProps2ValueEntryNumber>;
  numberArray?: InputMaybe<EnterItemProps2ValueEntryNumberArray>;
  text?: InputMaybe<EnterItemProps2ValueEntryText>;
  textArray?: InputMaybe<EnterItemProps2ValueEntryTextArray>;
};

export type EnterItemProps2ValueEntryBool = {
  key: Scalars['String']['input'];
  valueBool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnterItemProps2ValueEntryNumber = {
  key: Scalars['String']['input'];
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type EnterItemProps2ValueEntryNumberArray = {
  key: Scalars['String']['input'];
  valueNumberArray?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type EnterItemProps2ValueEntryText = {
  key: Scalars['String']['input'];
  valueText?: InputMaybe<Scalars['String']['input']>;
};

export type EnterItemProps2ValueEntryTextArray = {
  key: Scalars['String']['input'];
  valueTextArray?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EnterItemProps2ValueInput = {
  docId: Scalars['ID']['input'];
  entries: Array<EnterItemProps2ValueEntry>;
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type EnterItemProps2ValueResult = {
  __typename: 'EnterItemProps2ValueResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type EntityFieldAllowedValue = {
  __typename: 'EntityFieldAllowedValue';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type EntityFieldMetadata = {
  __typename: 'EntityFieldMetadata';
  allowedValues?: Maybe<Array<EntityFieldAllowedValue>>;
  editable: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type EntitySearchArrayOfFilterOperator =
  | 'allOf'
  | 'anyOf';

export type EntitySearchBoolFilter = {
  __typename: 'EntitySearchBoolFilter';
  operatorBool: EntitySearchBoolFilterOperator;
  valueBool: Scalars['Boolean']['output'];
};

export type EntitySearchBoolFilterComputed = {
  __typename: 'EntitySearchBoolFilterComputed';
  expr: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorBool: EntitySearchBoolFilterOperator;
  valueBoolComputed?: Maybe<Scalars['Boolean']['output']>;
};

export type EntitySearchBoolFilterOperator =
  | 'eq';

export type EntitySearchBoolInFilterOperator =
  | 'in';

export type EntitySearchFilterOperator =
  | 'allOf'
  | 'anyOf'
  | 'between'
  | 'eq'
  | 'gt'
  | 'gte'
  | 'in'
  | 'lt'
  | 'lte';

export type EntitySearchFilterPropertyFilterInput = {
  key: Scalars['String']['input'];
  operator: EntitySearchFilterOperator;
  value: Scalars['JSON']['input'];
};

export type EntitySearchIdFilter = {
  __typename: 'EntitySearchIdFilter';
  operatorId: EntitySearchIdFilterOperator;
  valueId: Scalars['ID']['output'];
};

export type EntitySearchIdFilterOperator =
  | 'eq';

export type EntitySearchIdInFilter = {
  __typename: 'EntitySearchIdInFilter';
  operatorIdIn: EntitySearchIdInFilterOperator;
  valueIdIn: Array<Scalars['ID']['output']>;
};

export type EntitySearchIdInFilterOperator =
  | 'in';

export type EntitySearchNumberBetweenFilter = {
  __typename: 'EntitySearchNumberBetweenFilter';
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
  valueNumberBetween: ValueNumberBetween;
};

export type EntitySearchNumberBetweenFilterComputed = {
  __typename: 'EntitySearchNumberBetweenFilterComputed';
  expr: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
  valueNumberBetweenComputed?: Maybe<ValueNumberBetween>;
};

export type EntitySearchNumberBetweenFilterOperator =
  | 'between';

export type EntitySearchNumberFilter = {
  __typename: 'EntitySearchNumberFilter';
  operatorNumber: EntitySearchNumberFilterOperator;
  valueNumber: Scalars['Float']['output'];
};

export type EntitySearchNumberFilterComputed = {
  __typename: 'EntitySearchNumberFilterComputed';
  expr: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorNumber: EntitySearchNumberFilterOperator;
  valueNumberComputed?: Maybe<Scalars['Float']['output']>;
};

export type EntitySearchNumberFilterOperator =
  | 'eq'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte';

export type EntitySearchNumberInFilterOperator =
  | 'in';

export type EntitySearchPropertyFilterBoolFilter = {
  __typename: 'EntitySearchPropertyFilterBoolFilter';
  key: Scalars['String']['output'];
  operatorBool: EntitySearchBoolFilterOperator;
  valueBool: Scalars['Boolean']['output'];
};

export type EntitySearchPropertyFilterBoolFilterComputed = {
  __typename: 'EntitySearchPropertyFilterBoolFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorBool: EntitySearchBoolFilterOperator;
  valueBoolComputed?: Maybe<Scalars['Boolean']['output']>;
};

export type EntitySearchPropertyFilterBoolInFilter = {
  __typename: 'EntitySearchPropertyFilterBoolInFilter';
  key: Scalars['String']['output'];
  operatorBoolIn: EntitySearchBoolInFilterOperator;
  valueBoolIn: Array<Scalars['Boolean']['output']>;
};

export type EntitySearchPropertyFilterBoolInFilterComputed = {
  __typename: 'EntitySearchPropertyFilterBoolInFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorBoolIn: EntitySearchBoolInFilterOperator;
  valueBoolInComputed?: Maybe<Array<Scalars['Boolean']['output']>>;
};

export type EntitySearchPropertyFilterNumberArrayOfFilter = {
  __typename: 'EntitySearchPropertyFilterNumberArrayOfFilter';
  key: Scalars['String']['output'];
  operatorNumberArrayOf: EntitySearchArrayOfFilterOperator;
  valueNumberArrayOf: Array<Scalars['Float']['output']>;
};

export type EntitySearchPropertyFilterNumberArrayOfFilterComputed = {
  __typename: 'EntitySearchPropertyFilterNumberArrayOfFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorNumberArrayOf: EntitySearchArrayOfFilterOperator;
  valueNumberArrayOfComputed?: Maybe<Array<Scalars['Float']['output']>>;
};

export type EntitySearchPropertyFilterNumberBetweenFilter = {
  __typename: 'EntitySearchPropertyFilterNumberBetweenFilter';
  key: Scalars['String']['output'];
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
  valueNumberBetween: ValueNumberBetween;
};

export type EntitySearchPropertyFilterNumberBetweenFilterComputed = {
  __typename: 'EntitySearchPropertyFilterNumberBetweenFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
  valueNumberBetweenComputed?: Maybe<ValueNumberBetween>;
};

export type EntitySearchPropertyFilterNumberFilter = {
  __typename: 'EntitySearchPropertyFilterNumberFilter';
  key: Scalars['String']['output'];
  operatorNumber: EntitySearchNumberFilterOperator;
  valueNumber: Scalars['Float']['output'];
};

export type EntitySearchPropertyFilterNumberFilterComputed = {
  __typename: 'EntitySearchPropertyFilterNumberFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorNumber: EntitySearchNumberFilterOperator;
  valueNumberComputed?: Maybe<Scalars['Float']['output']>;
};

export type EntitySearchPropertyFilterNumberInFilter = {
  __typename: 'EntitySearchPropertyFilterNumberInFilter';
  key: Scalars['String']['output'];
  operatorNumberIn: EntitySearchNumberInFilterOperator;
  valueNumberIn: Array<Scalars['Float']['output']>;
};

export type EntitySearchPropertyFilterNumberInFilterComputed = {
  __typename: 'EntitySearchPropertyFilterNumberInFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorNumberIn: EntitySearchNumberInFilterOperator;
  valueNumberInComputed?: Maybe<Array<Scalars['Float']['output']>>;
};

export type EntitySearchPropertyFilterTextArrayOfFilter = {
  __typename: 'EntitySearchPropertyFilterTextArrayOfFilter';
  key: Scalars['String']['output'];
  operatorTextArrayOf: EntitySearchArrayOfFilterOperator;
  valueTextArrayOf: Array<Scalars['String']['output']>;
};

export type EntitySearchPropertyFilterTextArrayOfFilterComputed = {
  __typename: 'EntitySearchPropertyFilterTextArrayOfFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorTextArrayOf: EntitySearchArrayOfFilterOperator;
  valueTextArrayOfComputed?: Maybe<Array<Scalars['String']['output']>>;
};

export type EntitySearchPropertyFilterTextFilter = {
  __typename: 'EntitySearchPropertyFilterTextFilter';
  key: Scalars['String']['output'];
  operatorText: EntitySearchTextFilterOperator;
  valueText: Scalars['String']['output'];
};

export type EntitySearchPropertyFilterTextFilterComputed = {
  __typename: 'EntitySearchPropertyFilterTextFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorText: EntitySearchTextFilterOperator;
  valueTextComputed?: Maybe<Scalars['String']['output']>;
};

export type EntitySearchPropertyFilterTextInFilter = {
  __typename: 'EntitySearchPropertyFilterTextInFilter';
  key: Scalars['String']['output'];
  operatorTextIn: EntitySearchTextInFilterOperator;
  valueTextIn: Array<Scalars['String']['output']>;
};

export type EntitySearchPropertyFilterTextInFilterComputed = {
  __typename: 'EntitySearchPropertyFilterTextInFilterComputed';
  expr: Scalars['String']['output'];
  key: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorTextIn: EntitySearchTextInFilterOperator;
  valueTextInComputed?: Maybe<Array<Scalars['String']['output']>>;
};

export type EntitySearchSortingDirection =
  | 'asc'
  | 'desc';

export type EntitySearchSortingPropertyValueSorting = {
  __typename: 'EntitySearchSortingPropertyValueSorting';
  direction: EntitySearchSortingDirection;
  key: Scalars['String']['output'];
  kind: EntitySearchSortingPropertyValueSortingKind;
};

export type EntitySearchSortingPropertyValueSortingKind =
  | 'number';

export type EntitySearchTextFilter = {
  __typename: 'EntitySearchTextFilter';
  operatorText: EntitySearchTextFilterOperator;
  valueText: Scalars['String']['output'];
};

export type EntitySearchTextFilterComputed = {
  __typename: 'EntitySearchTextFilterComputed';
  expr: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorText: EntitySearchTextFilterOperator;
  valueTextComputed?: Maybe<Scalars['String']['output']>;
};

export type EntitySearchTextFilterOperator =
  | 'eq';

export type EntitySearchTextInFilter = {
  __typename: 'EntitySearchTextInFilter';
  operatorTextIn: EntitySearchTextInFilterOperator;
  valueTextIn: Array<Scalars['String']['output']>;
};

export type EntitySearchTextInFilterComputed = {
  __typename: 'EntitySearchTextInFilterComputed';
  expr: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  operatorTextIn: EntitySearchTextInFilterOperator;
  valueTextInComputed?: Maybe<Array<Scalars['String']['output']>>;
};

export type EntitySearchTextInFilterOperator =
  | 'in';

export type EssentialItemProps = {
  __typename: 'EssentialItemProps';
  item: ItemRef;
  props: Array<Props2NonComputed>;
  unanswered: Scalars['Boolean']['output'];
};

export type ExportAsGaebInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  type: ExportAsGaebType;
};

export type ExportAsGaebResult = {
  __typename: 'ExportAsGaebResult';
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type ExportAsGaebType =
  | 'p84'
  | 'p94';

export type ExportAsJsonDocIdInput = {
  docId: Scalars['ID']['input'];
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type ExportAsJsonInput = {
  ids?: InputMaybe<Array<ExportAsJsonDocIdInput>>;
};

export type ExportAsJsonResult = {
  __typename: 'ExportAsJsonResult';
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type ExportAsXiopdInput = {
  docId: Scalars['ID']['input'];
  exportDestination: XiopdExportDestination;
  projectId: Scalars['ID']['input'];
};

export type ExportAsXiopdResult = {
  __typename: 'ExportAsXiopdResult';
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type Exportation = {
  __typename: 'Exportation';
  contentUrl: Scalars['String']['output'];
  createAt: Scalars['Date']['output'];
  failed: Scalars['Boolean']['output'];
  finishedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  progress: Scalars['Int']['output'];
  startedAt?: Maybe<Scalars['Date']['output']>;
};

export type ExtractOrganisationContractingPdfFormFieldsInput = {
  contractingPdfId: Scalars['ID']['input'];
};

export type ExtractOrganisationContractingPdfFormFieldsResult = {
  __typename: 'ExtractOrganisationContractingPdfFormFieldsResult';
  formFieldKeys: Array<Scalars['String']['output']>;
};

export type FailedForCrmCompany = {
  __typename: 'FailedForCrmCompany';
  crmCompany: CrmCompany;
  errorMessage: Scalars['String']['output'];
};

export type FilterModelInput = {
  items: Array<FilterModelItemInput>;
  linkOperator?: InputMaybe<Scalars['String']['input']>;
};

export type FilterModelItemInput = {
  columnField: Scalars['String']['input'];
  id?: InputMaybe<Scalars['JSON']['input']>;
  operatorValue?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type FinalizeContingencyDecisionInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type FinalizeContingencyDecisionResult = {
  __typename: 'FinalizeContingencyDecisionResult';
  doc: Document;
  item?: Maybe<Item>;
  otherChangedItems: Array<Item>;
};

export type FinalizeSubitemDecisionInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type FinalizeSubitemDecisionResult = {
  __typename: 'FinalizeSubitemDecisionResult';
  doc: Document;
  item?: Maybe<Item>;
  otherChangedItems: Array<Item>;
};

export type FinishProjectWorkSessionInput = {
  isBreak: Scalars['Boolean']['input'];
  projectId: Scalars['ID']['input'];
};

export type FinishProjectWorkSessionResult = {
  __typename: 'FinishProjectWorkSessionResult';
  project: Project;
  workSession: WorkSession;
};

export type GenerateExamplePdfDocumentInput = {
  marginBottom: Scalars['Int']['input'];
  marginTopFirstPage: Scalars['Int']['input'];
  marginTopSubsequentPage: Scalars['Int']['input'];
  pdfLetterheadUrl: Scalars['String']['input'];
  textPrimaryColor: Scalars['String']['input'];
  textSecondaryColor: Scalars['String']['input'];
};

export type GenerateExamplePdfDocumentResult = {
  __typename: 'GenerateExamplePdfDocumentResult';
  url: Scalars['String']['output'];
};

export type GenerateOrganisationPdfLetterheadInput = {
  pdfFooterColumn1?: InputMaybe<Scalars['String']['input']>;
  pdfFooterColumn2?: InputMaybe<Scalars['String']['input']>;
  pdfFooterColumn3?: InputMaybe<Scalars['String']['input']>;
  pdfFooterVisibility?: InputMaybe<PdfVisibility>;
  pdfLogo?: InputMaybe<AttachmentInput>;
  pdfTextPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  pdfTextSecondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateOrganisationPdfLetterheadResult = {
  __typename: 'GenerateOrganisationPdfLetterheadResult';
  url: Scalars['String']['output'];
};

export type GetCsvHeaderInput = {
  filename: Scalars['String']['input'];
  separator?: InputMaybe<Scalars['String']['input']>;
  supplierId: Scalars['ID']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GetCsvHeaderResult = {
  __typename: 'GetCsvHeaderResult';
  headerAndValues: Array<HeaderAndValues>;
  systemLabels: Array<SystemLabels>;
};

export type HeaderAndValues = {
  __typename: 'HeaderAndValues';
  header: Scalars['String']['output'];
  values: Scalars['String']['output'];
};

export type IdsConnectAvailability = {
  __typename: 'IdsConnectAvailability';
  clientIdRequired: Scalars['Boolean']['output'];
  itekCompanyId: Scalars['ID']['output'];
  passwordRequired: Scalars['Boolean']['output'];
  usernameRequired: Scalars['Boolean']['output'];
};

export type IdsOrderInfo = {
  __typename: 'IdsOrderInfo';
  idsConnectIncomingPurchaseCartOrdered?: Maybe<Scalars['Boolean']['output']>;
  idsConnectIncomingPurchaseCartReceivedAt?: Maybe<Scalars['Date']['output']>;
  idsConnectPurchaseCartSendAt: Scalars['Date']['output'];
};

export type ImplementsTemplateTypeInput = {
  templateId: Scalars['ID']['input'];
  templateTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type ImplementsTemplateTypeResult = {
  __typename: 'ImplementsTemplateTypeResult';
  ok: Scalars['Boolean']['output'];
};

export type ImportCrmEntryInput = {
  organisation: ImportCrmOrganisationInput;
  resolveDuplicate: ImportResolveDuplicateStrategy;
  users: Array<ImportCrmUserInput>;
};

export type ImportCrmOrganisationInput = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingAddress?: InputMaybe<Scalars['String']['input']>;
  branchAddress?: InputMaybe<Scalars['String']['input']>;
  contactType: CrmOrganisationContactType;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  notices?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  phones?: InputMaybe<Array<PhoneInput>>;
  title: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ImportCrmOrganisationsResult = {
  __typename: 'ImportCrmOrganisationsResult';
  ok: Scalars['Boolean']['output'];
};

export type ImportCrmUserInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  familyname?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  notices?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  title: Salutation;
};

export type ImportDatanormOnlineEntryInput = {
  authentication: ItekAuthenticateInput;
  datanormOnlineEntry: DatanormOnlineEntryInput;
};

export type ImportDatanormOnlineEntryResult = {
  __typename: 'ImportDatanormOnlineEntryResult';
  importationId: Scalars['ID']['output'];
};

export type ImportFromJsonInput = {
  duplicate?: InputMaybe<Scalars['Boolean']['input']>;
  filename: Scalars['String']['input'];
};

export type ImportFromJsonResult = {
  __typename: 'ImportFromJsonResult';
  ok: Scalars['Boolean']['output'];
};

export type ImportRequirementInput = {
  destQuoteId: Scalars['ID']['input'];
  omitSourceItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
  sourceRequirementId: Scalars['ID']['input'];
};

export type ImportRequirementResult = {
  __typename: 'ImportRequirementResult';
  project: Project;
  quote: Quote;
};

export type ImportResolveDuplicateStrategy =
  | 'ADD_NEW'
  | 'DO_NOTHING'
  | 'UPDATE_EXISTING';

export type Importation = {
  __typename: 'Importation';
  contentUrl: Scalars['String']['output'];
  createAt: Scalars['Date']['output'];
  failed: Scalars['Boolean']['output'];
  finishedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  progress: Scalars['Int']['output'];
  startedAt?: Maybe<Scalars['Date']['output']>;
};

export type IncomingInvoice = {
  __typename: 'IncomingInvoice';
  contractorName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dueDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  issueDate?: Maybe<Scalars['Date']['output']>;
  number: Scalars['String']['output'];
  pdfUrl: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  projectTitle: Scalars['String']['output'];
  status: InvoiceStatus;
  title: Scalars['String']['output'];
  totalGrossPrice: Scalars['Float']['output'];
  totalNetPrice: Scalars['Float']['output'];
  type: InvoiceType;
};

export type IncomingInvoiceConnection = {
  __typename: 'IncomingInvoiceConnection';
  edges: Array<IncomingInvoiceEdge>;
  totalAmountOpen: Scalars['Float']['output'];
  totalAmountOverdue: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
};

export type IncomingInvoiceEdge = {
  __typename: 'IncomingInvoiceEdge';
  node: IncomingInvoice;
};

export type IncomingInvoicesFilters = {
  crmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InvoiceStatus>>;
};

export type IncomingInvoicesSortBy =
  | 'createdAt'
  | 'number'
  | 'title';

export type IncomingInvoicesSorting = {
  column: IncomingInvoicesSortBy;
  direction: SortDirection;
};

export type IncomingQuote = {
  __typename: 'IncomingQuote';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agreedTotalPrice?: Maybe<Scalars['Float']['output']>;
  agreement: Agreement;
  createdAt: Scalars['DateTime']['output'];
  docActorClientName: Scalars['String']['output'];
  docActorContracteeName: Scalars['String']['output'];
  docActorContractorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isBinding: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  needsAgreement: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  proposedTotalPrice: Scalars['Float']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  request?: Maybe<Request>;
  rootItemId: Scalars['ID']['output'];
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type IncomingQuoteConnection = {
  __typename: 'IncomingQuoteConnection';
  edges: Array<IncomingQuoteEdge>;
  totalCount: Scalars['Int']['output'];
};

export type IncomingQuoteEdge = {
  __typename: 'IncomingQuoteEdge';
  node: IncomingQuote;
};

export type IncomingQuoteResult = {
  __typename: 'IncomingQuoteResult';
  quote?: Maybe<Quote>;
};

export type IncomingQuotesFilters = {
  agreement?: InputMaybe<Agreement>;
  docActors?: InputMaybe<Array<DocActorFilterInput>>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  isChangePending?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<FilterModelInput>;
};

export type IncomingQuotesSortBy =
  | 'acceptedAt'
  | 'agreedTotalPrice'
  | 'createdAt'
  | 'docActorContractorName'
  | 'number'
  | 'projectNumber'
  | 'proposedTotalPrice'
  | 'publishedAt'
  | 'requestTitle'
  | 'title';

export type IncomingQuotesSorting = {
  column: IncomingQuotesSortBy;
  direction: SortDirection;
};

export type Integration = {
  __typename: 'Integration';
  id: Scalars['ID']['output'];
  integrationId: IntegrationId;
  isForeignAccountIdRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type IntegrationConnection = {
  __typename: 'IntegrationConnection';
  edges: Array<IntegrationEdge>;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationEdge = {
  __typename: 'IntegrationEdge';
  node: Integration;
};

export type IntegrationId =
  | 'crm'
  | 'meister1'
  | 'pim_products'
  | 'sap_s4hana';

export type InvitationContact = {
  __typename: 'InvitationContact';
  company: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo: AttachmentSnapshot;
  title: Salutation;
};

export type InvitationState =
  | 'ACCEPTED'
  | 'DECLINED'
  | 'DELETED'
  | 'PENDING';

export type Invoice = WithContents & {
  __typename: 'Invoice';
  author: User;
  calculation: InvoiceCalculation;
  cancellationReason: Scalars['String']['output'];
  cancelledAt?: Maybe<Scalars['Date']['output']>;
  contactPerson: Person;
  contents: Array<Content>;
  createdAt: Scalars['DateTime']['output'];
  deliveryOfServiceEnd?: Maybe<Scalars['Date']['output']>;
  deliveryOfServiceStart?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['Date']['output']>;
  file?: Maybe<AttachmentSnapshot>;
  id: Scalars['ID']['output'];
  invoiceExtraItems: Array<InvoiceExtraItem>;
  invoiceItems: Array<InvoiceItem>;
  invoicePaymentItems: Array<InvoicePaymentItem>;
  invoiceReferences: Array<InvoiceReference>;
  invoiceTexts?: Maybe<InvoiceTexts>;
  invoiceableQuoteItemIds: Array<Scalars['ID']['output']>;
  markedAsPaidAt?: Maybe<Scalars['Date']['output']>;
  nonInvoicedQuoteAmount: Scalars['Float']['output'];
  number?: Maybe<Scalars['String']['output']>;
  openedAt?: Maybe<Scalars['Date']['output']>;
  owningSystemOrganisationId: Scalars['ID']['output'];
  paidAt?: Maybe<Scalars['Date']['output']>;
  payee: DocActor;
  payer: DocActor;
  paymentTermDuration: Scalars['Int']['output'];
  project: Project;
  quote: Quote;
  showLaborCostSeparately: Scalars['Boolean']['output'];
  status: InvoiceStatus;
  title: Scalars['String']['output'];
  type: InvoiceType;
};


export type InvoiceInvoiceExtraItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type InvoiceInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type InvoiceInvoiceableQuoteItemIdsArgs = {
  ignoreSelf: Scalars['Boolean']['input'];
};

export type InvoiceCalculation = {
  __typename: 'InvoiceCalculation';
  discountRate: Scalars['Float']['output'];
  extraItemsPriceNetTotal: Scalars['Float']['output'];
  extraItemsPriceSubTotal: Scalars['Float']['output'];
  extraItemsPriceTotal: Scalars['Float']['output'];
  extraItemsPriceVatTotal: Scalars['Float']['output'];
  finalPriceNetTotal: Scalars['Float']['output'];
  finalPriceTotal: Scalars['Float']['output'];
  finalPriceVatTotal: Scalars['Float']['output'];
  materialBuyingPricePerUnit: Scalars['Float']['output'];
  materialFactor: Scalars['Float']['output'];
  materialMargin: Scalars['Float']['output'];
  materialPriceDiscountedSubTotal: Scalars['Float']['output'];
  materialPricePerUnit: Scalars['Float']['output'];
  materialPriceSubTotal: Scalars['Float']['output'];
  openPriceNetTotal: Scalars['Float']['output'];
  openPriceTotal: Scalars['Float']['output'];
  openPriceVatTotal: Scalars['Float']['output'];
  paymentItemsPriceNetTotal: Scalars['Float']['output'];
  paymentItemsPriceSubTotal: Scalars['Float']['output'];
  paymentItemsPriceTotal: Scalars['Float']['output'];
  paymentItemsPriceVatTotal: Scalars['Float']['output'];
  priceNetTotal: Scalars['Float']['output'];
  pricePerUnit: Scalars['Float']['output'];
  priceSubTotal: Scalars['Float']['output'];
  priceTotal: Scalars['Float']['output'];
  priceVatTotal: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  quantityUnit: QuantityUnit;
  timePerUnit: Scalars['Float']['output'];
  timeTotal: Scalars['Float']['output'];
  vatRate: Scalars['Float']['output'];
  workBuyingPricePerUnit: Scalars['Float']['output'];
  workFactor: Scalars['Float']['output'];
  workPriceDiscountedSubTotal: Scalars['Float']['output'];
  workPricePerUnit: Scalars['Float']['output'];
  workPriceSubTotal: Scalars['Float']['output'];
  workRate: Scalars['Float']['output'];
};

export type InvoiceExtraItem = {
  __typename: 'InvoiceExtraItem';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pricePerUnit: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  quantityUnit: QuantityUnit;
  title: Scalars['String']['output'];
};

export type InvoiceItem = WithContents & {
  __typename: 'InvoiceItem';
  calculation: ItemCalculation;
  contents: Array<Content>;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  item: Item;
  itemSnapshot: ItemSnapshot;
  title: Scalars['String']['output'];
};

export type InvoicePaymentItem = {
  __typename: 'InvoicePaymentItem';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  totalPricePercentage: Scalars['Float']['output'];
};

export type InvoiceReference = {
  __typename: 'InvoiceReference';
  id: Scalars['ID']['output'];
  invoiceCalculation: InvoiceCalculation;
  invoiceCreatedAt: Scalars['DateTime']['output'];
  invoiceNumber: Scalars['String']['output'];
  invoiceOpenedAt: Scalars['DateTime']['output'];
  invoiceTitle: Scalars['String']['output'];
  isPaid: Scalars['Boolean']['output'];
};

export type InvoiceSettings = {
  __typename: 'InvoiceSettings';
  invoiceAttachments: Array<AttachmentSnapshot>;
  invoiceTexts: OrganisationPdfTexts;
};

export type InvoiceStatus =
  | 'cancelled'
  | 'draft'
  | 'open'
  | 'paid';

export type InvoiceTexts = {
  __typename: 'InvoiceTexts';
  closing: Scalars['String']['output'];
  id: Scalars['String']['output'];
  opening: Scalars['String']['output'];
};

export type InvoiceTextsInput = {
  closing?: InputMaybe<Scalars['String']['input']>;
  opening?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceType =
  | 'advance_payment_invoice'
  | 'down_payment_invoice'
  | 'final_invoice'
  | 'partial_invoice';

export type ItekAuthenticateInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  msOrganisationId: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ItekCompanyCredentials = {
  __typename: 'ItekCompanyCredentials';
  clientId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  itekCompanyId: Scalars['ID']['output'];
  latestUsedAt: Scalars['Date']['output'];
  userId: Scalars['ID']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type Item = WithContents & WithCustomFields & WithItemAttachments & {
  __typename: 'Item';
  actualQuantity: Scalars['Float']['output'];
  actualTime: Scalars['Float']['output'];
  agreedCalculation?: Maybe<ItemCalculation>;
  agreement: Agreement;
  agreementSignature?: Maybe<SignatureExtended>;
  approvalRequired: Scalars['Boolean']['output'];
  assignee?: Maybe<User>;
  attachments: Array<ItemAttachment>;
  attributeExpressions: Array<ItemAttributeExpression>;
  author: User;
  authorId: Scalars['String']['output'];
  authorOrganisation: Organisation;
  authorOrganisationId: Scalars['String']['output'];
  averageMaterialBuyingPrice: Scalars['Float']['output'];
  binding: ItemBinding;
  canBeWorkedOn: Scalars['Boolean']['output'];
  changeAgreement: ChangeAgreement;
  changeDecliningReason: Scalars['String']['output'];
  children: Array<Item>;
  childrenVisibility: ItemChildrenVisibility;
  containsError: Scalars['Boolean']['output'];
  contents: Array<Content>;
  createdAt: Scalars['DateTime']['output'];
  createdByRecommendedTemplateIncludeIf: Scalars['Boolean']['output'];
  crmLinks: Array<ItemCrmLink>;
  crmOrganisationLinks: Array<QuoteItemLinkCrmOrganisation>;
  crmUserLinks: Array<QuoteItemLinkCrmUser>;
  customFields: Array<CustomFieldField>;
  decisionBehaviorOfSubitems: DecisionBehaviorOfSubitems;
  decisionContingentItemPreselection?: Maybe<Scalars['Boolean']['output']>;
  decisionIsContingentItem: Scalars['Boolean']['output'];
  decisionOptionBehaviour: DecisionBehaviorOfSubitems;
  decisionOptionElimination?: Maybe<DecisionOptionElimination>;
  decisionOptionIsPreselected?: Maybe<Scalars['Boolean']['output']>;
  decisionSubitemsPreselection: Array<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  docId: Scalars['ID']['output'];
  doneDate?: Maybe<Scalars['Date']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  essentialProps2: Array<EssentialItemProps>;
  estimatedQuantity: Scalars['Float']['output'];
  estimatedTime: Scalars['Float']['output'];
  has3dShape: Scalars['Boolean']['output'];
  hasChildren: Scalars['Boolean']['output'];
  hasPendingChanges: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inheritedVertical?: Maybe<Vertical>;
  invoiceAgreedOrProposed: AgreedOrProposedCalculation;
  invoiceOverriddenActualMaterialBuyingPrice?: Maybe<Scalars['Float']['output']>;
  invoiceOverriddenActualQuantity?: Maybe<Scalars['Float']['output']>;
  invoiceOverriddenActualTime?: Maybe<Scalars['Float']['output']>;
  isAnyParentItemEliminated: Scalars['Boolean']['output'];
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDecisionOption: Scalars['Boolean']['output'];
  isDone: Scalars['Boolean']['output'];
  isExplicitHidden: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isItemEliminated: Scalars['Boolean']['output'];
  isOptional: Scalars['Boolean']['output'];
  isParentContingencyNotPreselected: Scalars['Boolean']['output'];
  isParentDecisionNotPreselected: Scalars['Boolean']['output'];
  isParentDecisionPreselected: Scalars['Boolean']['output'];
  isPriceHidden: Scalars['Boolean']['output'];
  isQaApproved?: Maybe<Scalars['Boolean']['output']>;
  isRootItem: Scalars['Boolean']['output'];
  isTreePreviewItemVisible: Scalars['Boolean']['output'];
  isVisibleToOtherSide: Scalars['Boolean']['output'];
  latestAgreedPrice?: Maybe<Scalars['Float']['output']>;
  level: Scalars['Int']['output'];
  linkedQuoteTemplate?: Maybe<QuoteTemplate>;
  linkedQuoteTemplateAtVersionNumber?: Maybe<Scalars['Int']['output']>;
  materialBuyingPrice: Scalars['Float']['output'];
  materialMargin: Scalars['Float']['output'];
  needsAgreement: Scalars['Boolean']['output'];
  orderItems: Array<OrderItem>;
  originDocId: Scalars['ID']['output'];
  originItemId: Scalars['ID']['output'];
  originVersionNumber?: Maybe<Scalars['Int']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  pathForPdf: Scalars['String']['output'];
  pathSortable: Scalars['String']['output'];
  pendingChangeAttributes: Scalars['JSON']['output'];
  photoApprovalRequired: Scalars['Boolean']['output'];
  photoApproved?: Maybe<AttachmentSnapshot>;
  placedItems: Array<ItemRef>;
  placeholderForTemplateType?: Maybe<TemplateTypeAtRevision>;
  placement?: Maybe<ItemRef>;
  priority: Scalars['Int']['output'];
  product?: Maybe<ItemProduct>;
  productSearchFilterDefaults: ItemProductSearchFilterDefaults;
  productSearchFilterDefinitions: ItemProductSearchFilterDefinitions;
  productSearchFilterExpressions: ItemProductSearchFilterExpressions;
  projectsInBuildingItem: Array<ProjectRef>;
  propertyMappings: Array<PropertyMapping>;
  propertySelectionSetters: Array<PropertySelectionSetter>;
  proposedCalculation?: Maybe<ItemCalculation>;
  props2: Array<Props2>;
  props2WithoutValue: Array<Props2NonComputed>;
  qaApprovalRequired: Scalars['Boolean']['output'];
  quantityUnit: QuantityUnit;
  rank: Scalars['Int']['output'];
  recommendations: Array<ItemRecommendation>;
  recommendationsConfig: Array<ItemRecommendationConfig>;
  resolvedAsReadModelVersionNumber?: Maybe<Scalars['Int']['output']>;
  rootItemId: Scalars['ID']['output'];
  selfOrClosestSubcontract?: Maybe<Subcontract>;
  templateOriginQuoteId?: Maybe<Scalars['ID']['output']>;
  templateSearchFilterDefaults: ItemTemplateSearchFilterDefaults;
  templateSearchFilterDefinitions: ItemTemplateSearchFilterDefinitions;
  templateSearchFilterExpressions: ItemTemplateSearchFilterExpressions;
  templateSearchSortingDefaults: Array<ItemTemplateSearchSortingDefault>;
  templateSearchSortingDefinitions: Array<ItemTemplateSearchSortingDefinition>;
  timeTrackingRequired: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: ItemType;
  vertical?: Maybe<Vertical>;
  wizardSettings: ItemWizardSettings;
  workBuyingRate: Scalars['Float']['output'];
  workSellingRate: Scalars['Float']['output'];
  workSessions: Array<WorkSession>;
  workSessionsDuration: Scalars['Int']['output'];
};


export type ItemAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ItemEssentialProps2Args = {
  deep: Scalars['Boolean']['input'];
  unanswered: Scalars['Boolean']['input'];
};


export type ItemIsTreePreviewItemVisibleArgs = {
  showDecisionOptions: Scalars['Boolean']['input'];
};


export type ItemPlacedItemsArgs = {
  docType: DocType;
};

export type ItemAttachment = BaseAttachment & {
  __typename: 'ItemAttachment';
  clientVisibility: Scalars['Boolean']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isProductAttachment: Scalars['Boolean']['output'];
  mimeType: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ItemAttributeExpression = {
  __typename: 'ItemAttributeExpression';
  attribute: Scalars['String']['output'];
  expr: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  result: Scalars['JSON']['output'];
};

export type ItemBinding =
  | 'binding'
  | 'draft'
  | 'non_binding';

export type ItemCalculation = {
  __typename: 'ItemCalculation';
  discountRate: Scalars['Float']['output'];
  materialBuyingPricePerUnit: Scalars['Float']['output'];
  materialFactor: Scalars['Float']['output'];
  materialMargin: Scalars['Float']['output'];
  materialPriceDiscountedSubTotal: Scalars['Float']['output'];
  materialPricePerUnit: Scalars['Float']['output'];
  materialPriceSubTotal: Scalars['Float']['output'];
  priceNetTotal: Scalars['Float']['output'];
  pricePerUnit: Scalars['Float']['output'];
  priceSubTotal: Scalars['Float']['output'];
  priceTotal: Scalars['Float']['output'];
  priceVatTotal: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  quantityUnit: QuantityUnit;
  timePerUnit: Scalars['Float']['output'];
  timeTotal: Scalars['Float']['output'];
  vatRate: Scalars['Float']['output'];
  workBuyingPricePerUnit: Scalars['Float']['output'];
  workBuyingRate: Scalars['Float']['output'];
  workFactor: Scalars['Float']['output'];
  workPriceDiscountedSubTotal: Scalars['Float']['output'];
  workPricePerUnit: Scalars['Float']['output'];
  workPriceSubTotal: Scalars['Float']['output'];
  workRate: Scalars['Float']['output'];
  workSellingRate: Scalars['Float']['output'];
};

export type ItemCalculationCalculationType =
  | 'invoice'
  | 'quote_actual'
  | 'quote_agreed'
  | 'quote_proposed';

export type ItemCalculationDecisionMode =
  | 'max'
  | 'min'
  | 'selected';

export type ItemCalculationItemOverrideInput = {
  itemId: Scalars['ID']['input'];
  materialBuyingPrice?: InputMaybe<Scalars['Float']['input']>;
  materialMargin?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['Float']['input']>;
  workBuyingRate?: InputMaybe<Scalars['Float']['input']>;
  workSellingRate?: InputMaybe<Scalars['Float']['input']>;
};

export type ItemChildrenVisibility =
  | 'HIDE_CHILDREN'
  | 'SHOW_CHILDREN'
  | 'SHOW_CHILDREN_HIDE_PRICE'
  | 'SHOW_ONLY_MAINPRODUCT_HIDE_PRICE';

export type ItemCrmLink = QuoteItemLinkCrmOrganisation | QuoteItemLinkCrmUser;

export type ItemInvoiceCalculation = {
  __typename: 'ItemInvoiceCalculation';
  itemCalculation?: Maybe<ItemCalculation>;
  itemId: Scalars['ID']['output'];
};

export type ItemNextAndPreviousDecisionItemResult = {
  __typename: 'ItemNextAndPreviousDecisionItemResult';
  nextItem?: Maybe<Item>;
  previousItem?: Maybe<Item>;
};

export type ItemNextPossibleDecisionItemResult = {
  __typename: 'ItemNextPossibleDecisionItemResult';
  nextPossibleItem?: Maybe<Item>;
};

export type ItemPossibleItemTypesResult = {
  __typename: 'ItemPossibleItemTypesResult';
  types: Array<ItemType>;
  warnings: Array<ItemType>;
};

export type ItemProduct = {
  __typename: 'ItemProduct';
  articleNumber?: Maybe<Scalars['String']['output']>;
  brand: Scalars['String']['output'];
  brandClientVisibility: Visibility;
  brandLine: Scalars['String']['output'];
  description: Scalars['String']['output'];
  descriptionClientVisibility: Visibility;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturerArticleNumber?: Maybe<Scalars['String']['output']>;
  productCategory: Scalars['String']['output'];
  productTypeId?: Maybe<Scalars['ID']['output']>;
  productTypeTitle?: Maybe<Scalars['String']['output']>;
  props2: Array<Props2NonComputed>;
  supplier?: Maybe<Company>;
  supplierId?: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  titleClientVisibility: Visibility;
};

export type ItemProductInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type ItemProductSearchFilterBrandFilter = EntitySearchTextFilter | EntitySearchTextInFilter;

export type ItemProductSearchFilterBrandFilterComputed = EntitySearchTextFilterComputed | EntitySearchTextInFilterComputed;

export type ItemProductSearchFilterBrandFilterDefinitionInput = {
  textFilter?: InputMaybe<SearchFilterTextFilterDefinitionInput>;
  textInFilter?: InputMaybe<SearchFilterTextInFilterDefinitionInput>;
};

export type ItemProductSearchFilterBrandFilterExpressionInput = {
  textFilter?: InputMaybe<SearchFilterTextFilterExpressionInput>;
  textInFilter?: InputMaybe<SearchFilterTextInFilterExpressionInput>;
};

export type ItemProductSearchFilterBrandLineFilter = EntitySearchTextFilter | EntitySearchTextInFilter;

export type ItemProductSearchFilterBrandLineFilterComputed = EntitySearchTextFilterComputed | EntitySearchTextInFilterComputed;

export type ItemProductSearchFilterBrandLineFilterDefinitionInput = {
  textFilter?: InputMaybe<SearchFilterTextFilterDefinitionInput>;
  textInFilter?: InputMaybe<SearchFilterTextInFilterDefinitionInput>;
};

export type ItemProductSearchFilterBrandLineFilterExpressionInput = {
  textFilter?: InputMaybe<SearchFilterTextFilterExpressionInput>;
  textInFilter?: InputMaybe<SearchFilterTextInFilterExpressionInput>;
};

export type ItemProductSearchFilterDefaults = {
  __typename: 'ItemProductSearchFilterDefaults';
  brandFilter?: Maybe<ItemProductSearchFilterBrandFilter>;
  brandLineFilter?: Maybe<ItemProductSearchFilterBrandLineFilter>;
  favouritesFilter?: Maybe<ItemProductSearchFilterFavouritesFilter>;
  itemId: Scalars['ID']['output'];
  listPriceFilter?: Maybe<ItemProductSearchFilterPriceFilter>;
  netPriceFilter?: Maybe<ItemProductSearchFilterPriceFilter>;
  productTypeIdFilter?: Maybe<EntitySearchIdFilter>;
  propertyFilters: Array<ItemProductSearchFilterPropertyFilter>;
  supplierIdFilter?: Maybe<ItemProductSearchFilterIdFilter>;
};

export type ItemProductSearchFilterDefinitions = {
  __typename: 'ItemProductSearchFilterDefinitions';
  brandFilter?: Maybe<ItemProductSearchFilterBrandFilter>;
  brandLineFilter?: Maybe<ItemProductSearchFilterBrandLineFilter>;
  favouritesFilter?: Maybe<ItemProductSearchFilterFavouritesFilter>;
  itemId: Scalars['ID']['output'];
  listPriceFilter?: Maybe<ItemProductSearchFilterPriceFilter>;
  netPriceFilter?: Maybe<ItemProductSearchFilterPriceFilter>;
  productTypeIdFilter?: Maybe<EntitySearchIdFilter>;
  propertyFilters: Array<ItemProductSearchFilterPropertyFilter>;
  supplierIdFilter?: Maybe<ItemProductSearchFilterIdFilter>;
};

export type ItemProductSearchFilterExpressions = {
  __typename: 'ItemProductSearchFilterExpressions';
  brandFilter?: Maybe<ItemProductSearchFilterBrandFilterComputed>;
  brandLineFilter?: Maybe<ItemProductSearchFilterBrandLineFilterComputed>;
  favouritesFilter?: Maybe<ItemProductSearchFilterFavouritesFilterComputed>;
  itemId: Scalars['ID']['output'];
  listPriceFilter?: Maybe<ItemProductSearchFilterPriceFilterComputed>;
  propertyFilters: Array<ItemProductSearchFilterPropertyFilterComputed>;
};

export type ItemProductSearchFilterFavouritesFilter = EntitySearchBoolFilter;

export type ItemProductSearchFilterFavouritesFilterComputed = EntitySearchBoolFilterComputed;

export type ItemProductSearchFilterFavouritesFilterDefinitionInput = {
  boolFilter?: InputMaybe<SearchFilterBoolFilterDefinitionInput>;
};

export type ItemProductSearchFilterFavouritesFilterExpressionInput = {
  boolFilter?: InputMaybe<SearchFilterBoolFilterExpressionInput>;
};

export type ItemProductSearchFilterIdFilter = EntitySearchIdFilter | EntitySearchIdInFilter;

export type ItemProductSearchFilterOrganisationIdFilterDefinitionInput = {
  idFilter?: InputMaybe<SearchFilterIdFilterDefinitionInput>;
  idInFilter?: InputMaybe<SearchFilterIdInFilterDefinitionInput>;
};

export type ItemProductSearchFilterPriceFilter = EntitySearchNumberBetweenFilter | EntitySearchNumberFilter;

export type ItemProductSearchFilterPriceFilterComputed = EntitySearchNumberBetweenFilterComputed | EntitySearchNumberFilterComputed;

export type ItemProductSearchFilterPriceFilterDefinitionInput = {
  numberBetweenFilter?: InputMaybe<SearchFilterNumberBetweenFilterDefinitionInput>;
  numberFilter?: InputMaybe<SearchFilterNumberFilterDefinitionInput>;
};

export type ItemProductSearchFilterPriceFilterExpressionInput = {
  numberBetweenFilter?: InputMaybe<SearchFilterNumberBetweenFilterExpressionInput>;
  numberFilter?: InputMaybe<SearchFilterNumberFilterExpressionInput>;
};

export type ItemProductSearchFilterPropertyFilter = EntitySearchPropertyFilterBoolFilter | EntitySearchPropertyFilterBoolInFilter | EntitySearchPropertyFilterNumberBetweenFilter | EntitySearchPropertyFilterNumberFilter | EntitySearchPropertyFilterNumberInFilter | EntitySearchPropertyFilterTextFilter | EntitySearchPropertyFilterTextInFilter;

export type ItemProductSearchFilterPropertyFilterComputed = EntitySearchPropertyFilterBoolFilterComputed | EntitySearchPropertyFilterBoolInFilterComputed | EntitySearchPropertyFilterNumberBetweenFilterComputed | EntitySearchPropertyFilterNumberFilterComputed | EntitySearchPropertyFilterNumberInFilterComputed | EntitySearchPropertyFilterTextFilterComputed | EntitySearchPropertyFilterTextInFilterComputed;

export type ItemProductSearchFilterPropertyFilterDefinitionInput = {
  boolFilter?: InputMaybe<SearchFilterPropertyBoolFilterDefinitionInput>;
  boolInFilter?: InputMaybe<SearchFilterPropertyBoolInFilterDefinitionInput>;
  numberBetweenFilter?: InputMaybe<SearchFilterPropertyNumberBetweenFilterDefinitionInput>;
  numberFilter?: InputMaybe<SearchFilterPropertyNumberFilterDefinitionInput>;
  numberInFilter?: InputMaybe<SearchFilterPropertyNumberInFilterDefinitionInput>;
  textFilter?: InputMaybe<SearchFilterPropertyTextFilterDefinitionInput>;
  textInFilter?: InputMaybe<SearchFilterPropertyTextInFilterDefinitionInput>;
};

export type ItemProductSearchFilterPropertyFilterExpressionInput = {
  boolFilter?: InputMaybe<SearchFilterPropertyBoolFilterExpressionInput>;
  boolInFilter?: InputMaybe<SearchFilterPropertyBoolInFilterExpressionInput>;
  numberBetweenFilter?: InputMaybe<SearchFilterPropertyNumberBetweenFilterExpressionInput>;
  numberFilter?: InputMaybe<SearchFilterPropertyNumberFilterExpressionInput>;
  numberInFilter?: InputMaybe<SearchFilterPropertyNumberInFilterExpressionInput>;
  textFilter?: InputMaybe<SearchFilterPropertyTextFilterExpressionInput>;
  textInFilter?: InputMaybe<SearchFilterPropertyTextInFilterExpressionInput>;
};

export type ItemProductSearchFilterPropertyIdFilterDefinitionInput = {
  idFilter?: InputMaybe<SearchFilterIdFilterDefinitionInput>;
};

export type ItemProductSnapshot = {
  __typename: 'ItemProductSnapshot';
  articleNumber?: Maybe<Scalars['String']['output']>;
  brand: Scalars['String']['output'];
  brandClientVisibility: Visibility;
  brandLine: Scalars['String']['output'];
  description: Scalars['String']['output'];
  descriptionClientVisibility: Visibility;
  props2: Array<Props2NonComputed>;
  title: Scalars['String']['output'];
  titleClientVisibility: Visibility;
};

export type ItemProps2WithoutValue = {
  __typename: 'ItemProps2WithoutValue';
  itemId: Scalars['ID']['output'];
  props: Array<Props2NonComputed>;
};

export type ItemRecommendation = {
  __typename: 'ItemRecommendation';
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  recommendedEntity: ItemRecommendationRecommendedEntity;
  teaserImages: Array<Attachment>;
  teaserTitle: Scalars['String']['output'];
};

export type ItemRecommendationConfig = {
  __typename: 'ItemRecommendationConfig';
  eligibleIfExpr: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  includeIfExpr: Scalars['String']['output'];
  itemId: Scalars['ID']['output'];
  recommendedEntityConfig: ItemRecommendationRecommendedEntityConfig;
};

export type ItemRecommendationRecommendedEntity = RecommendedTemplate | RecommendedTemplateType;

export type ItemRecommendationRecommendedEntityConfig = RecommendedTemplateConfig | RecommendedTemplateTypeConfig;

export type ItemRecommendedTemplateBindPropsInput = {
  destKey: Scalars['String']['input'];
  sourceKey: Scalars['String']['input'];
};

export type ItemRef = {
  __typename: 'ItemRef';
  docId: Scalars['ID']['output'];
  docTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pathForPdf: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ItemSapS4HanaData = {
  __typename: 'ItemSapS4HanaData';
  docId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  itemTexts: Array<ItemSapS4HanaText>;
  pricingData: Array<ItemSapS4HanaPricingData>;
  xId?: Maybe<Scalars['String']['output']>;
  xReferenceItemId?: Maybe<Scalars['String']['output']>;
  xSalesDocumentRejectionReason?: Maybe<Scalars['String']['output']>;
};

export type ItemSapS4HanaPricingData = {
  __typename: 'ItemSapS4HanaPricingData';
  conditionAmount?: Maybe<Scalars['Float']['output']>;
  conditionBaseValue?: Maybe<Scalars['Float']['output']>;
  conditionCalculationType?: Maybe<Scalars['String']['output']>;
  conditionCategory?: Maybe<Scalars['String']['output']>;
  conditionCurrency?: Maybe<Scalars['String']['output']>;
  conditionInactiveReason?: Maybe<Scalars['String']['output']>;
  conditionIsForStatistics?: Maybe<Scalars['Boolean']['output']>;
  conditionQuantity?: Maybe<Scalars['Float']['output']>;
  conditionQuantityUnit?: Maybe<Scalars['String']['output']>;
  conditionRateValue?: Maybe<Scalars['Float']['output']>;
  conditionScaleBasisCurrency?: Maybe<Scalars['String']['output']>;
  conditionScaleBasisUnit?: Maybe<Scalars['String']['output']>;
  conditionScaleBasisValue?: Maybe<Scalars['Float']['output']>;
  conditionSequentialNumber?: Maybe<Scalars['String']['output']>;
  conditionType?: Maybe<Scalars['String']['output']>;
  factorForConditionBasisValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  priceConditionDeterminationDate?: Maybe<Scalars['DateTime']['output']>;
  pricingProcedureCounter: Scalars['String']['output'];
  pricingProcedureStep: Scalars['String']['output'];
  pricingScaleBasis?: Maybe<Scalars['String']['output']>;
  pricingScaleType?: Maybe<Scalars['String']['output']>;
  transactionCurrency?: Maybe<Scalars['String']['output']>;
};

export type ItemSapS4HanaText = {
  __typename: 'ItemSapS4HanaText';
  id: Scalars['ID']['output'];
  xLongTextId: Scalars['String']['output'];
  xLongTextValue: Scalars['String']['output'];
};

export type ItemSnapshot = {
  __typename: 'ItemSnapshot';
  attachments: Array<ItemAttachment>;
  childrenVisibility: ItemChildrenVisibility;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  estimatedQuantity: Scalars['Float']['output'];
  isExplicitHidden: Scalars['Boolean']['output'];
  pathForPdf: Scalars['String']['output'];
  product?: Maybe<ItemProductSnapshot>;
  props2: Array<Props2>;
  quantityUnit: QuantityUnit;
  title: Scalars['String']['output'];
  type: ItemType;
  workSellingRate: Scalars['Float']['output'];
};

export type ItemTemplateSearchFilterDefaults = {
  __typename: 'ItemTemplateSearchFilterDefaults';
  itemId: Scalars['ID']['output'];
  propertyFilters: Array<ItemTemplateSearchFilterPropertyFilter>;
  templateTypeIdFilter?: Maybe<EntitySearchIdFilter>;
};

export type ItemTemplateSearchFilterDefinitions = {
  __typename: 'ItemTemplateSearchFilterDefinitions';
  itemId: Scalars['ID']['output'];
  propertyFilters: Array<ItemTemplateSearchFilterPropertyFilter>;
};

export type ItemTemplateSearchFilterExpressions = {
  __typename: 'ItemTemplateSearchFilterExpressions';
  itemId: Scalars['ID']['output'];
  propertyFilters: Array<ItemTemplateSearchFilterPropertyFilterComputed>;
};

export type ItemTemplateSearchFilterPropertyFilter = EntitySearchPropertyFilterBoolFilter | EntitySearchPropertyFilterBoolInFilter | EntitySearchPropertyFilterNumberArrayOfFilter | EntitySearchPropertyFilterNumberBetweenFilter | EntitySearchPropertyFilterNumberFilter | EntitySearchPropertyFilterNumberInFilter | EntitySearchPropertyFilterTextArrayOfFilter | EntitySearchPropertyFilterTextFilter | EntitySearchPropertyFilterTextInFilter;

export type ItemTemplateSearchFilterPropertyFilterComputed = EntitySearchPropertyFilterBoolFilterComputed | EntitySearchPropertyFilterBoolInFilterComputed | EntitySearchPropertyFilterNumberArrayOfFilterComputed | EntitySearchPropertyFilterNumberBetweenFilterComputed | EntitySearchPropertyFilterNumberFilterComputed | EntitySearchPropertyFilterNumberInFilterComputed | EntitySearchPropertyFilterTextArrayOfFilterComputed | EntitySearchPropertyFilterTextFilterComputed | EntitySearchPropertyFilterTextInFilterComputed;

export type ItemTemplateSearchSortingDefault = EntitySearchSortingPropertyValueSorting;

export type ItemTemplateSearchSortingDefinition = EntitySearchSortingPropertyValueSorting;

export type ItemType =
  | 'decision'
  | 'defect'
  | 'paid'
  | 'section'
  | 'unpaid';

export type ItemWizardSettings = {
  __typename: 'ItemWizardSettings';
  askWhenContingent: Array<AskWhen>;
  askWhenDecision: Array<AskWhen>;
  askWhenPaidAndProduct: Array<AskWhen>;
  askWhomContingent: Array<AskWhom>;
  askWhomDecision: Array<AskWhom>;
  askWhomPaidAndProduct: Array<AskWhom>;
  promptContingent: Scalars['String']['output'];
  promptDecision: Scalars['String']['output'];
  promptPaidAndProduct: Scalars['String']['output'];
};

export type ItemsResult = {
  __typename: 'ItemsResult';
  items: Array<Item>;
};

export type KeyValueUnit = {
  __typename: 'KeyValueUnit';
  detail: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  uom: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValueUnitInput = {
  detail?: InputMaybe<Scalars['String']['input']>;
  uom?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageKey = {
  __typename: 'LanguageKey';
  countryCode?: Maybe<Scalars['String']['output']>;
  languageCode: Scalars['String']['output'];
};

export type LanguageKeyInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  languageCode: Scalars['String']['input'];
};

export type LikeQuoteTemplateInput = {
  docId: Scalars['ID']['input'];
};

export type LikeQuoteTemplateResult = {
  __typename: 'LikeQuoteTemplateResult';
  doc: QuoteTemplate;
};

export type LikeShopTemplateInput = {
  docId: Scalars['ID']['input'];
};

export type LikeShopTemplateResult = {
  __typename: 'LikeShopTemplateResult';
  doc: ShoppableTemplate;
};

export type LinkedTemplateRef = {
  __typename: 'LinkedTemplateRef';
  linkedItem: ItemRef;
  linkedItemVersionNumber?: Maybe<Scalars['Int']['output']>;
  linkingItem: ItemRef;
  linkingItemVersionNumber?: Maybe<Scalars['Int']['output']>;
};

export type LinkingTemplateRef = {
  __typename: 'LinkingTemplateRef';
  linkedItem: ItemRef;
  linkedItemVersionNumber?: Maybe<Scalars['Int']['output']>;
  linkingItem: ItemRef;
  linkingItemVersionNumber?: Maybe<Scalars['Int']['output']>;
};

export type Locale =
  | 'de'
  | 'en'
  | 'fr'
  | 'nl'
  | 'pl'
  | 'ru';

export type Location = {
  __typename: 'Location';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type LocationInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type M1 = {
  __typename: 'M1';
  m1CalculatorCategoryCraftId?: Maybe<Scalars['String']['output']>;
  m1Input?: Maybe<Array<Maybe<M1InputGroup>>>;
};

export type M1InputGroup = {
  __typename: 'M1InputGroup';
  fields?: Maybe<Array<Maybe<M1InputGroupField>>>;
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type M1InputGroupField = {
  __typename: 'M1InputGroupField';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Maybe<M1InputGroupFieldValue>>>;
};

export type M1InputGroupFieldValue = {
  __typename: 'M1InputGroupFieldValue';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MainProductGroup = {
  __typename: 'MainProductGroup';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type ManufacturerSearchResult = {
  __typename: 'ManufacturerSearchResult';
  organisation: Company;
};

export type ManufacturerSearchResultConnection = {
  __typename: 'ManufacturerSearchResultConnection';
  edges: Array<ManufacturerSearchResultEdge>;
  totalCount: Scalars['Int']['output'];
};

export type ManufacturerSearchResultEdge = {
  __typename: 'ManufacturerSearchResultEdge';
  node: ManufacturerSearchResult;
};

export type MarkChannelAsReadResult = {
  __typename: 'MarkChannelAsReadResult';
  channel: Channel;
};

export type MarketplaceOpportunitiesFilters = {
  buildingZip?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Skill>>;
  minBudget?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<ProjectType>>;
  withinRadiusKm?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceOpportunitiesSortBy =
  | 'budgetAmount'
  | 'createdAt'
  | 'title';

export type MarketplaceOpportunitiesSorting = {
  column: MarketplaceOpportunitiesSortBy;
  direction: SortDirection;
};

export type MarketplaceOpportunityConnection = {
  __typename: 'MarketplaceOpportunityConnection';
  edges: Array<MarketplaceOpportunityEdge>;
  totalCount: Scalars['Int']['output'];
};

export type MarketplaceOpportunityEdge = {
  __typename: 'MarketplaceOpportunityEdge';
  node: Request;
};

export type MarketplaceOpportunityResult = {
  __typename: 'MarketplaceOpportunityResult';
  request?: Maybe<Request>;
};

export type MarketplaceStatus =
  | 'INITIAL'
  | 'NO';

export type Meister1ExportAsXiopdInput = {
  projectId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
};

export type Meister1ExportAsXiopdResult = {
  __typename: 'Meister1ExportAsXiopdResult';
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type Meister1Flow = {
  __typename: 'Meister1Flow';
  calculatorCategoryCraftId?: Maybe<Scalars['String']['output']>;
  calculatorId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  identName: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type Meister1ImportMultiBrandLeadInputInExistingProjectInput = {
  leadId: Scalars['Int']['input'];
  projectId: Scalars['ID']['input'];
  quoteTitlePrefix: Scalars['String']['input'];
};

export type Meister1InstallPluginInput = {
  accountId: Scalars['String']['input'];
};

export type Meister1PollForDocOfLeadIdInput = {
  m1LeadId: Scalars['Int']['input'];
  projectId: Scalars['String']['input'];
};

export type Message = {
  __typename: 'Message';
  attachments: Array<Attachment>;
  author?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  extraAttributes: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  invoice?: Maybe<Invoice>;
  project?: Maybe<Project>;
  quote?: Maybe<Quote>;
  text: Scalars['String']['output'];
};

export type MessageConnection = {
  __typename: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MessageEdge = {
  __typename: 'MessageEdge';
  cursor: Scalars['ID']['output'];
  node: Message;
};

export type MissingCapabilities = {
  __typename: 'MissingCapabilities';
  missingCapabilities: Array<Capabilities>;
};

export type MissingPermissions = {
  __typename: 'MissingPermissions';
  missingPermissions: Array<PermissionName>;
};

export type ModifyAbsenceInput = {
  from?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<AbsenceReason>;
  till?: InputMaybe<Scalars['Date']['input']>;
  whoId?: InputMaybe<Scalars['ID']['input']>;
};

export type ModifyAbsenceResult = {
  __typename: 'ModifyAbsenceResult';
  absence: Absence;
};

export type ModifyBuildingInput = {
  id: Scalars['ID']['input'];
  values: ModifyBuildingValuesInput;
};

export type ModifyBuildingResult = {
  __typename: 'ModifyBuildingResult';
  building: Building;
};

export type ModifyBuildingValuesInput = {
  buildingAddress: AddressInput;
};

export type ModifyInvoiceExtraItemInput = {
  docId: Scalars['ID']['input'];
  invoiceExtraItemId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: ModifyInvoiceExtraItemValuesInput;
};

export type ModifyInvoiceExtraItemResult = {
  __typename: 'ModifyInvoiceExtraItemResult';
  invoice: Invoice;
  invoiceExtraItem: InvoiceExtraItem;
};

export type ModifyInvoiceExtraItemValuesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  pricePerUnit?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  quantityUnit?: InputMaybe<QuantityUnit>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyInvoiceInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: ModifyInvoiceValuesInput;
};

export type ModifyInvoicePaymentItemInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  invoicePaymentItemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: ModifyInvoicePaymentItemValuesInput;
};

export type ModifyInvoicePaymentItemResult = {
  __typename: 'ModifyInvoicePaymentItemResult';
  invoice: Invoice;
  invoicePaymentItem: InvoicePaymentItem;
};

export type ModifyInvoicePaymentItemValuesInput = {
  price: Scalars['Float']['input'];
  totalPricePercentage: Scalars['Float']['input'];
};

export type ModifyInvoiceResult = {
  __typename: 'ModifyInvoiceResult';
  invoice: Invoice;
};

export type ModifyInvoiceValuesInput = {
  contactPersonId?: InputMaybe<Scalars['ID']['input']>;
  deliveryOfServiceEnd?: InputMaybe<Scalars['Date']['input']>;
  deliveryOfServiceStart?: InputMaybe<Scalars['Date']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  invoiceTexts?: InputMaybe<InvoiceTextsInput>;
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentTermDuration?: InputMaybe<Scalars['Int']['input']>;
  showLaborCostSeparately?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyItemActualCalculationInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemActualCalculationValuesInput;
};

export type ModifyItemActualCalculationResult = {
  __typename: 'ModifyItemActualCalculationResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  otherChangedItems: Array<Item>;
};

export type ModifyItemActualCalculationValuesInput = {
  actualQuantity?: InputMaybe<Scalars['Float']['input']>;
  actualTime?: InputMaybe<Scalars['Float']['input']>;
};

export type ModifyItemAttachmentResult = {
  __typename: 'ModifyItemAttachmentResult';
  attachment: AttachmentSnapshot;
};

export type ModifyItemContingencyInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemContingencyValuesInput;
};

export type ModifyItemContingencyResult = {
  __typename: 'ModifyItemContingencyResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  /** Optionally changed another items */
  otherChangedItems: Array<Item>;
};

export type ModifyItemContingencyValuesInput = {
  decisionContingentItemPreselection?: InputMaybe<Scalars['Boolean']['input']>;
  decisionIsContingentItem?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModifyItemDecisionInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemDecisionValuesInput;
};

export type ModifyItemDecisionResult = {
  __typename: 'ModifyItemDecisionResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  /** Optionally changed another items */
  otherChangedItems: Array<Item>;
};

export type ModifyItemDecisionValuesInput = {
  decisionBehaviorOfSubitems?: InputMaybe<DecisionBehaviorOfSubitems>;
  decisionSubitemsPreselection?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ModifyItemEstimatedCalculationInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemEstimatedCalculationValuesInput;
};

export type ModifyItemEstimatedCalculationResult = {
  __typename: 'ModifyItemEstimatedCalculationResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  otherChangedItems: Array<Item>;
};

export type ModifyItemEstimatedCalculationValuesInput = {
  estimatedQuantity?: InputMaybe<Scalars['Float']['input']>;
  estimatedTime?: InputMaybe<Scalars['Float']['input']>;
  materialBuyingPrice?: InputMaybe<Scalars['Float']['input']>;
  materialMargin?: InputMaybe<Scalars['Float']['input']>;
  quantityUnit?: InputMaybe<QuantityUnit>;
  vertical?: InputMaybe<Vertical>;
  workBuyingRate?: InputMaybe<Scalars['Float']['input']>;
  workSellingRate?: InputMaybe<Scalars['Float']['input']>;
};

export type ModifyItemInfoInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemInfoValuesInput;
};

export type ModifyItemInfoResult = {
  __typename: 'ModifyItemInfoResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
};

export type ModifyItemInfoValuesInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyItemProductInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemProductValuesInput;
};

export type ModifyItemProductResult = {
  __typename: 'ModifyItemProductResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
};

export type ModifyItemProductValuesInput = {
  productArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productBrand?: InputMaybe<Scalars['String']['input']>;
  productBrandClientVisibility?: InputMaybe<Visibility>;
  productBrandLine?: InputMaybe<Scalars['String']['input']>;
  productCategory?: InputMaybe<Scalars['String']['input']>;
  productDescription?: InputMaybe<Scalars['String']['input']>;
  productDescriptionClientVisibility?: InputMaybe<Visibility>;
  productGtin?: InputMaybe<Scalars['String']['input']>;
  productManufacturerArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productManufacturerId?: InputMaybe<Scalars['ID']['input']>;
  productProps2?: InputMaybe<Array<ProductProps2Entry>>;
  productSupplierId?: InputMaybe<Scalars['ID']['input']>;
  productTitle?: InputMaybe<Scalars['String']['input']>;
  productTitleClientVisibility?: InputMaybe<Visibility>;
  productTypeId?: InputMaybe<Scalars['ID']['input']>;
  productTypeTitle?: InputMaybe<Scalars['String']['input']>;
  productVariantGroupId?: InputMaybe<Scalars['ID']['input']>;
};

export type ModifyItemRecommendedTemplateInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  templateId: Scalars['String']['input'];
  values: ModifyItemRecommendedTemplateValuesInput;
};

export type ModifyItemRecommendedTemplateResult = {
  __typename: 'ModifyItemRecommendedTemplateResult';
  doc: Document;
  item: Item;
};

export type ModifyItemRecommendedTemplateTypeInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  templateTypeId: Scalars['ID']['input'];
  values: ModifyItemRecommendedTemplateTypeValuesInput;
};

export type ModifyItemRecommendedTemplateTypeResult = {
  __typename: 'ModifyItemRecommendedTemplateTypeResult';
  doc: Document;
  item: Item;
};

export type ModifyItemRecommendedTemplateTypeValuesInput = {
  eligibleIfExpr?: InputMaybe<Scalars['String']['input']>;
  includeIfExpr?: InputMaybe<Scalars['String']['input']>;
  templateSearchFilterDefinitions?: InputMaybe<TemplateSearchFilterDefinitionsInput>;
  templateSearchFilterExpressions?: InputMaybe<TemplateSearchFilterExpressionsInput>;
  templateSearchSortingDefinitions?: InputMaybe<Array<TemplateSearchSortingDefinitionInput>>;
};

export type ModifyItemRecommendedTemplateValuesInput = {
  bindProps?: InputMaybe<Array<ItemRecommendedTemplateBindPropsInput>>;
  eligibleIfExpr?: InputMaybe<Scalars['String']['input']>;
  includeIfExpr?: InputMaybe<Scalars['String']['input']>;
  templateVersionNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type ModifyItemTaskInfoInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemTaskInfoValuesInput;
};

export type ModifyItemTaskInfoResult = {
  __typename: 'ModifyItemTaskInfoResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
};

export type ModifyItemTaskInfoValuesInput = {
  approvalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  dueDate?: InputMaybe<Scalars['String']['input']>;
  photoApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  qaApprovalRequired?: InputMaybe<Scalars['Boolean']['input']>;
  timeTrackingRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModifyItemVerticalInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  vertical?: InputMaybe<Vertical>;
};

export type ModifyItemVerticalResult = {
  __typename: 'ModifyItemVerticalResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  otherChangedItems: Array<Item>;
};

export type ModifyItemWizardSettingsInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: ModifyItemWizardSettingsValuesInput;
};

export type ModifyItemWizardSettingsResult = {
  __typename: 'ModifyItemWizardSettingsResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
};

export type ModifyItemWizardSettingsValuesInput = {
  askWhenContingent?: InputMaybe<Array<AskWhen>>;
  askWhenDecision?: InputMaybe<Array<AskWhen>>;
  askWhenPaidAndProduct?: InputMaybe<Array<AskWhen>>;
  askWhomContingent?: InputMaybe<Array<AskWhom>>;
  askWhomDecision?: InputMaybe<Array<AskWhom>>;
  askWhomPaidAndProduct?: InputMaybe<Array<AskWhom>>;
  promptContingent?: InputMaybe<Scalars['String']['input']>;
  promptDecision?: InputMaybe<Scalars['String']['input']>;
  promptPaidAndProduct?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyMyProjectWorkSessionInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  isBreak: Scalars['Boolean']['input'];
  projectId: Scalars['ID']['input'];
  till?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ModifyOrderInput = {
  crmSupplierId?: InputMaybe<Scalars['ID']['input']>;
  deliveryAddress?: InputMaybe<AddressInput>;
  deliveryAddressType?: InputMaybe<DeliveryAddressType>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  orderId: Scalars['ID']['input'];
  paymentDueDate?: InputMaybe<Scalars['Date']['input']>;
  purchaseOrderTexts?: InputMaybe<PurchaseOrderTextsInput>;
};

export type ModifyOrderItemInput = {
  itemId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  productArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productManufacturerArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productTitle?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  quantityUnit?: InputMaybe<QuantityUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type ModifyOrderItemResult = {
  __typename: 'ModifyOrderItemResult';
  item: OrderItem;
  order: Order;
};

export type ModifyOrderResult = {
  __typename: 'ModifyOrderResult';
  order: Order;
};

export type ModifyOrganisationContractingPdfInput = {
  contractingPdfId: Scalars['ID']['input'];
  values: ModifyOrganisationContractingPdfValuesInput;
};

export type ModifyOrganisationContractingPdfResult = {
  __typename: 'ModifyOrganisationContractingPdfResult';
  contractingPdf: OrganisationContractingPdf;
};

export type ModifyOrganisationContractingPdfValuesInput = {
  attachment?: InputMaybe<AttachmentInput>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  needInsertPriceTable?: InputMaybe<Scalars['Boolean']['input']>;
  replacePages?: InputMaybe<Scalars['Int']['input']>;
  replacements?: InputMaybe<Array<ContractingPdfReplacementInput>>;
  startPage?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyOrganisationEmailTemplateInput = {
  attachments: Array<AttachmentInputWithId>;
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type ModifyOrganisationEmailTemplateResult = {
  __typename: 'ModifyOrganisationEmailTemplateResult';
  emailTemplate: OrganisationEmailTemplate;
};

export type ModifyOrganisationMembershipInput = {
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  homeAddress?: InputMaybe<AddressInput>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  memberId: Scalars['ID']['input'];
  notices?: InputMaybe<Scalars['String']['input']>;
  notifyEventsByEmail?: InputMaybe<Array<Scalars['String']['input']>>;
  phones?: InputMaybe<Array<PhoneInput>>;
};

export type ModifyOrganisationMembershipResult = {
  __typename: 'ModifyOrganisationMembershipResult';
  member: User;
  organisation: Organisation;
};

export type ModifyOrganisationProfileInput = {
  about?: InputMaybe<Scalars['JSON']['input']>;
  background?: InputMaybe<AttachmentInput>;
  contactAddress?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['EmailAddress']['input']>;
  contactPhones?: InputMaybe<Array<PhoneInput>>;
  contactWebsite?: InputMaybe<Scalars['String']['input']>;
  employeesNumber?: InputMaybe<Scalars['Int']['input']>;
  isMeister?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<AttachmentInput>;
  openingHours?: InputMaybe<Scalars['JSON']['input']>;
  skillset?: InputMaybe<Array<Skill>>;
  slug: Scalars['String']['input'];
  yearFounded?: InputMaybe<Scalars['Int']['input']>;
};

export type ModifyOrganisationProfileResult = {
  __typename: 'ModifyOrganisationProfileResult';
  profile: OrganisationProfile;
};

export type ModifyPlanSessionInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isTentative?: InputMaybe<Scalars['Boolean']['input']>;
  till?: InputMaybe<Scalars['DateTime']['input']>;
  whoId?: InputMaybe<Scalars['ID']['input']>;
};

export type ModifyPlanSessionResult = {
  __typename: 'ModifyPlanSessionResult';
  planSession: PlanSession;
  project: Project;
};

export type ModifyProductAttachmentResult = {
  __typename: 'ModifyProductAttachmentResult';
  attachment: AttachmentSnapshot;
};

export type ModifyProjectAttachmentResult = {
  __typename: 'ModifyProjectAttachmentResult';
  attachment: AttachmentSnapshot;
};

export type ModifyProjectMembershipInput = {
  membershipId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
};

export type ModifyProjectMembershipResult = {
  __typename: 'ModifyProjectMembershipResult';
  project: Project;
};

export type ModifyProjectWorkSessionInput = {
  assigneeId: Scalars['ID']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  isBreak: Scalars['Boolean']['input'];
  projectId: Scalars['ID']['input'];
  till?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ModifyProjectWorkSessionResult = {
  __typename: 'ModifyProjectWorkSessionResult';
  project: Project;
  workSession?: Maybe<WorkSession>;
};

export type ModifyQuoteDocActorInput = {
  docActor: DocActorModifyInput;
  docActorId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ModifyQuoteDocActorResult = {
  __typename: 'ModifyQuoteDocActorResult';
  docActor: DocActor;
};

export type ModifyQuoteItemInvoiceOverridesInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: ModifyQuoteItemInvoiceOverridesValuesInput;
};

export type ModifyQuoteItemInvoiceOverridesResult = {
  __typename: 'ModifyQuoteItemInvoiceOverridesResult';
  /** Changed document */
  doc: Quote;
  /** Changed invoice */
  invoice: Invoice;
  /** Changed item */
  item: Item;
};

export type ModifyQuoteItemInvoiceOverridesValuesInput = {
  invoiceAgreedOrProposed?: InputMaybe<AgreedOrProposedCalculation>;
  invoiceOverriddenActualMaterialBuyingPrice?: InputMaybe<Scalars['Float']['input']>;
  invoiceOverriddenActualQuantity?: InputMaybe<Scalars['Float']['input']>;
  invoiceOverriddenActualTime?: InputMaybe<Scalars['Float']['input']>;
};

export type ModifyQuoteResult = {
  __typename: 'ModifyQuoteResult';
  quote: Quote;
};

export type ModifyQuoteTemplateInput = {
  quoteTemplateId: Scalars['ID']['input'];
  values: ModifyQuoteTemplateValuesInput;
};

export type ModifyQuoteTemplateResult = {
  __typename: 'ModifyQuoteTemplateResult';
  quoteTemplate: QuoteTemplate;
};

export type ModifyQuoteTemplateValuesInput = {
  contractType?: InputMaybe<ContractType>;
  defaultMaterialMargin?: InputMaybe<Scalars['Float']['input']>;
  defaultVerticalSettings?: InputMaybe<Array<VerticalSettingInput>>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  materialFactor?: InputMaybe<Scalars['Float']['input']>;
  materialMarginRanges?: InputMaybe<Array<QuoteTemplateMaterialMarginRangeInput>>;
  materialPriceHandling?: InputMaybe<QuoteMaterialPriceHandling>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  quoteTexts?: InputMaybe<QuoteTextsInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  tplApplicableFor?: InputMaybe<Array<TplApplicableFor>>;
  vatInfo?: InputMaybe<VatInfoInput>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  vatVisibility?: InputMaybe<VatVisibility>;
  workFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type ModifyRequestAttachmentResult = {
  __typename: 'ModifyRequestAttachmentResult';
  attachment: AttachmentSnapshot;
};

export type ModifyRequestInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  budgetAmount?: InputMaybe<Scalars['Float']['input']>;
  categories?: InputMaybe<Array<Skill>>;
  deadline?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  earliestStart?: InputMaybe<Scalars['String']['input']>;
  hideExactAddress?: InputMaybe<Scalars['Boolean']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  projectAddress?: InputMaybe<AddressInput>;
  projectId: Scalars['ID']['input'];
  projectType?: InputMaybe<ProjectType>;
  requestId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyRequestResult = {
  __typename: 'ModifyRequestResult';
  request: Request;
};

export type ModifyRequirementDocActorInput = {
  docActor: DocActorModifyInput;
  docActorId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ModifyRequirementDocActorResult = {
  __typename: 'ModifyRequirementDocActorResult';
  docActor: DocActor;
};

export type ModifySapS4HanaSalesQuoteDataBusinessPartner = {
  crmOrganisationId: Scalars['ID']['input'];
  partnerFunction: Scalars['String']['input'];
  partnerType: SapS4HanaBusinessPartnerPartnerType;
};

export type ModifySapS4HanaSalesQuoteDataHeaderText = {
  xLongTextId: Scalars['String']['input'];
  xLongTextValue: Scalars['String']['input'];
};

export type ModifySapS4HanaSalesQuoteDataInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: ModifySapS4HanaSalesQuoteDataValuesInput;
};

export type ModifySapS4HanaSalesQuoteDataResult = {
  __typename: 'ModifySapS4HanaSalesQuoteDataResult';
  quote: Quote;
  quoteSapS4HanaData: QuoteSapS4HanaData;
};

export type ModifySapS4HanaSalesQuoteDataValuesInput = {
  businessPartners?: InputMaybe<Array<ModifySapS4HanaSalesQuoteDataBusinessPartner>>;
  headerTexts?: InputMaybe<Array<ModifySapS4HanaSalesQuoteDataHeaderText>>;
  salesOffice?: InputMaybe<Scalars['String']['input']>;
  xBindingPeriodValidityStartDate?: InputMaybe<Scalars['Date']['input']>;
  xPurchaseOrderByCustomer?: InputMaybe<Scalars['String']['input']>;
  xResubmissionDate?: InputMaybe<Scalars['Date']['input']>;
  xSdDocumentReason?: InputMaybe<Scalars['String']['input']>;
  xStatus?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyTimeReportInput = {
  contracteeSignature?: InputMaybe<SignatureExtendedInput>;
  contractorSignature?: InputMaybe<SignatureExtendedInput>;
  id: Scalars['ID']['input'];
  tasksDescription?: InputMaybe<Scalars['JSON']['input']>;
  timeReportTexts?: InputMaybe<TimeReportTextsInput>;
};

export type ModifyTimeReportResult = {
  __typename: 'ModifyTimeReportResult';
  timeReport: TimeReport;
};

export type ModifyTodoItemInput = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<AttachmentInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  linkedCrmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  linkedCrmUserId?: InputMaybe<Scalars['ID']['input']>;
  linkedProjectId?: InputMaybe<Scalars['ID']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ModifyTodoItemResult = {
  __typename: 'ModifyTodoItemResult';
  todoItem: TodoItem;
};

export type ModifyWorkSessionInput = {
  date?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  till?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ModifyWorkSessionResult = {
  __typename: 'ModifyWorkSessionResult';
  item: Item;
  workSession: WorkSession;
};

export type Money = {
  __typename: 'Money';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  _3d_addProductData: _3d_AddProductDataResult;
  _3d_addSnapshots: _3d_AddSnapshotsResult;
  _3d_createLeadInNewProject: _3d_CreateLeadInNewProjectResult;
  _3d_modifyRoomShape: _3d_ModifyRoomShapeResult;
  _3d_removeProductData: _3d_RemoveProductDataResult;
  _3d_requestAddProductDataUrl: RequestUploadUrlResult;
  _noop?: Maybe<Scalars['Boolean']['output']>;
  acceptChanges: AcceptChangesResult;
  acceptCrmLinkingInvitation: AcceptCrmLinkingInvitationResult;
  acceptDocShare: AcceptDocShareResult;
  acceptOrganisationMembershipInvitation: AcceptOrganisationMembershipInvitationResult;
  acceptProjectContracteeInvitation: AcceptProjectContracteeInvitationResult;
  acceptQuote: AcceptQuoteResult;
  addCompanyOrganisationCredentials: AddCompanyOrganisationCredentialsResult;
  addInvoiceExtraItem: AddInvoiceExtraItemResult;
  addItemAttachments: AddItemAttachmentsResult;
  addItemLinkCrmOrganisation: AddItemLinkCrmOrganisationResult;
  addItemLinkCrmUser: AddItemLinkCrmUserResult;
  addItemRecommendedTemplate: AddItemRecommendedTemplateResult;
  addItemRecommendedTemplateType: AddItemRecommendedTemplateTypeResult;
  addOrderItems: AddOrderItemsResult;
  addOrganisationCapability: AddOrganisationCapabilityResult;
  addOrganisationContractingPdf: AddOrganisationContractingPdfResult;
  addOrganisationEmailTemplate: AddOrganisationEmailTemplateResult;
  addOrganisationIntegration: AddOrganisationIntegrationResult;
  addOrganisationMembershipInvitation: AddOrganisationMembershipInvitationResult;
  addOrganisationProfile: AddOrganisationProfileResult;
  addOrganisationProjectPhase: AddOrganisationProjectPhaseResult;
  addOrganisationsFromItekCompanies: Array<AddOrganisationsFromItekCompaniesResult>;
  addPimImport: AddPimImportationResult;
  addPimManufacturerProductImportation: AddPimManufacturerProductImportResult;
  addPimSearchCache: AddPimSearchCacheResult;
  addPimSupplierProductCategoryImportation: AddPimSupplierProductCategoryImportationResult;
  addPimSupplierProductImportation: AddPimSupplierProductImportResult;
  addProductAttachments: AddProductAttachmentsResult;
  addProjectAttachments: AddProjectAttachmentsResult;
  addProjectContracteeInput: AddProjectContracteeInputResult;
  addProjectContracteeInvitation: AddProjectContracteeInvitationResult;
  addProjectLinkCrmOrganisation: AddProjectLinkCrmOrganisationResult;
  addProjectLinkCrmUser: AddProjectLinkCrmUserResult;
  addProjectMemberFromOrganisationMembers: AddProjectMemberFromOrganisationMembersResult;
  addPushNotificationToken: Scalars['Boolean']['output'];
  addQuoteDocActor: AddQuoteDocActorResult;
  addQuoteTemplateToShop: AddQuoteTemplateToShopResult;
  addRequestAttachments: AddRequestAttachmentsResult;
  addRequestRecipients: AddRequestRecipientsResult;
  addRequirementDocActor: AddRequirementDocActorResult;
  applyQuoteProductsDistribution: ApplyQuoteProductsDistributionResult;
  approveTaskItem: ApproveTaskItemResult;
  approveTimeReport: ApproveTimeReportResult;
  assignBuilding: ProjectAssignBuildingResult;
  cancelInvoice: CancelInvoiceResult;
  cancelOrganisationMembershipInvitation: CancelOrganisationMembershipInvitationResult;
  cancelPendingShareDoc: CancelPendingShareDocResult;
  cancelProjectContracteeInvitation: CancelProjectContracteeInvitationResult;
  changeEmail: ChangeEmailResult;
  changeItemType: ChangeItemTypeResult;
  changePassword: Scalars['Boolean']['output'];
  changeProjectPhase: ChangeProjectPhaseResult;
  closeRequest: CloseRequestResult;
  closeRequestForBidding: CloseRequestForBiddingResult;
  copyQuoteIntoNewQuote: CopyQuoteIntoNewQuoteResult;
  copyQuoteSapS4HanaSalesQuote: CopyQuoteSapS4HanaSalesQuoteResult;
  copySupplierOrganisationAsCrm: CopySupplierOrganisationAsCrmResult;
  correctWorkSession: CorrectWorkSessionResult;
  createAbsence: CreateAbsenceResult;
  createBuilding: BuildingCreateResult;
  createCrmOrganisation: CreateCrmOrganisationResult;
  createCrmUser: CrmUserCreateResult;
  createEmbedabbleWizardLead: CreateEmbedabbleWizardLeadResult;
  createIdsConnectOrder: CreateIdsConnectOrderResult;
  createInvoice: CreateInvoiceResult;
  createItemFromItemRecommendation: CreateItemFromItemRecommendationResult;
  createItems: CreateItemsResult;
  createItemsDefect: CreateItemsResult;
  createItemsFromTemplateTypes: CreateItemsFromTemplateTypesResult;
  createItemsFromTemplates: CreateItemsFromTemplatesResult;
  createItemsUnpaid: CreateItemsResult;
  createManufacturer: CreateManufacturerResult;
  createOrder: OrderCreateResult;
  createOrganisationAndUser: CreateOrganisationAndUserResult;
  createPlanSession: CreatePlanSessionResult;
  createProject: ProjectCreateResult;
  createProjectForQuoteProductsDistribution: CreateProjectForQuoteProductsDistributionResult;
  createProjectForRequest: CreateProjectForRequestResult;
  createProjectFromGaebImport: CreateProjectFromGaebImportResult;
  createProjectFromXiopdImport: CreateProjectFromXiopdImportResult;
  createQuote: CreateQuoteResult;
  createQuoteFromGaebImport: CreateQuoteFromGaebImportResult;
  createQuoteFromSapS4HanaSalesQuote: CreateQuoteFromSapS4HanaSalesQuoteResult;
  createQuoteFromXiopdImport: CreateQuoteFromXiopdImportResult;
  createQuoteProductsDistribution: CreateQuoteProductsDistributionResult;
  createQuoteRequest: CreateQuoteRequestResult;
  createQuoteTemplate: CreateQuoteTemplateResult;
  createRequirement: CreateRequirementResult;
  createSapS4HanaSalesQuoteFromQuote: CreateSapS4HanaSalesQuoteFromQuoteResult;
  createSupplier: CreateSupplierResult;
  createTemplateFromItem: CreateTemplateFromItemResult;
  createTimeReport: CreateTimeReportResult;
  createTodoItem: CreateTodoItemResult;
  createWorkSessions: CreateWorkSessionsResult;
  declineChanges: DeclineChangesResult;
  declineProjectContracteeInvitation: DeclineProjectContracteeInvitationResult;
  declineQuote: DeclineQuoteResult;
  defineItemAttributeExpression: DefineItemAttributeExpressionResult;
  defineItemProductSearchFilterDefinitions: DefineItemProductSearchFilterDefinitionsResult;
  defineItemProductSearchFilterDefinitionsForDecision: DefineItemProductSearchFilterDefinitionsResult;
  defineItemProductSearchFilterExpressions: DefineItemProductSearchFilterExpressionsResult;
  defineItemProps2: DefineItemProps2Result;
  defineItemTemplateSearchFilterDefinitions: DefineItemTemplateSearchFilterDefinitionsResult;
  defineItemTemplateSearchFilterExpressions: DefineItemTemplateSearchFilterExpressionsResult;
  defineItemTemplateSearchSortingDefinitions: DefineItemTemplateSearchSortingDefinitionsResult;
  deleteBuilding: DeleteBuildingResult;
  deleteCompanyOrganisationCredentials: DeleteCompanyOrganisationCredentialsResult;
  deleteInvoice: DeleteInvoiceResult;
  deleteItem: DeleteItemResult;
  deleteOrder: DeleteOrderResult;
  deletePimImport: DeletePimImportationResult;
  deletePimSupplierImportations: DeletePimSupplierImportationsResult;
  deletePlanSession: DeletePlanSessionResult;
  deleteQuote: DeleteQuoteResult;
  deleteQuoteTemplate: DeleteQuoteTemplateResult;
  deleteRequirement: DeleteRequirementResult;
  deleteWorkSession: DeleteWorkSessionResult;
  disableUserCalendar: DisableUserCalendarResult;
  disapproveTaskItem: DisapproveTaskItemResult;
  discardPendingChanges: DiscardPendingChangesResult;
  discardUnpublishedChanges: Scalars['Boolean']['output'];
  distributeProductsInQuote: DistributeProductsInQuoteResult;
  docExportAsExcel: DocExportAsExcelResult;
  docExportAsM1ImpexJson: DocExportAsM1ImpexJsonResult;
  documentBulkFinalise: DocumentBulkFinaliseResult;
  documentRecomputePropertiesAndExpressions: DocumentRecomputePropertiesAndExpressionsResult;
  duplicateQuoteTemplate: DuplicateQuoteTemplateResult;
  eliminateDecisionOption: EliminateDecisionOptionResult;
  eliminateItem: EliminateItemResult;
  enableUserCalendar: EnableUserCalendarResult;
  enterItemProps2Value: EnterItemProps2ValueResult;
  enterItemProps2ValueForDecision: EnterItemProps2ValueResult;
  exportAsGaeb: ExportAsGaebResult;
  exportAsJson: ExportAsJsonResult;
  exportAsXiopd: ExportAsXiopdResult;
  extractOrganisationContractingPdfFormFields: ExtractOrganisationContractingPdfFormFieldsResult;
  finalizeContingencyDecision: FinalizeContingencyDecisionResult;
  finalizeSubitemDecision: FinalizeSubitemDecisionResult;
  finishProjectWorkSession: FinishProjectWorkSessionResult;
  generateExamplePdfDocument: GenerateExamplePdfDocumentResult;
  generateOrganisationPdfLetterhead: GenerateOrganisationPdfLetterheadResult;
  getCsvHeader: GetCsvHeaderResult;
  implementsTemplateType: ImplementsTemplateTypeResult;
  importCrmOrganisations: ImportCrmOrganisationsResult;
  importDatanormOnlineEntry: ImportDatanormOnlineEntryResult;
  importFromJson: ImportFromJsonResult;
  importRequirement: ImportRequirementResult;
  invalidate?: Maybe<Scalars['Boolean']['output']>;
  invalidateAll?: Maybe<Scalars['Boolean']['output']>;
  invalidateAllGlobalLocal?: Maybe<Scalars['Boolean']['output']>;
  invalidateAllGlobalRemote?: Maybe<Scalars['Boolean']['output']>;
  invalidateGlobalLocal?: Maybe<Scalars['Boolean']['output']>;
  invalidateGlobalRemote?: Maybe<Scalars['Boolean']['output']>;
  likeQuoteTemplate: LikeQuoteTemplateResult;
  likeShopTemplate: LikeShopTemplateResult;
  markChannelAsRead: MarkChannelAsReadResult;
  modifyAbsence: ModifyAbsenceResult;
  modifyBuilding: ModifyBuildingResult;
  modifyCrmOrganisation: CrmOrganisationModifyResult;
  modifyCrmUser: CrmUserModifyResult;
  modifyInvoice: ModifyInvoiceResult;
  modifyInvoiceExtraItem: ModifyInvoiceExtraItemResult;
  modifyInvoicePaymentItem: ModifyInvoicePaymentItemResult;
  modifyItemActualCalculation: ModifyItemActualCalculationResult;
  modifyItemAttachment: ModifyItemAttachmentResult;
  modifyItemContingency: ModifyItemContingencyResult;
  modifyItemDecision: ModifyItemDecisionResult;
  modifyItemEstimatedCalculation: ModifyItemEstimatedCalculationResult;
  modifyItemInfo: ModifyItemInfoResult;
  modifyItemProduct: ModifyItemProductResult;
  modifyItemRecommendedTemplate: ModifyItemRecommendedTemplateResult;
  modifyItemRecommendedTemplateType: ModifyItemRecommendedTemplateTypeResult;
  modifyItemTaskInfo: ModifyItemTaskInfoResult;
  modifyItemVertical: ModifyItemVerticalResult;
  modifyItemWizardSettings: ModifyItemWizardSettingsResult;
  modifyMyProjectWorkSession: ModifyProjectWorkSessionResult;
  modifyOrder: ModifyOrderResult;
  modifyOrderItem: ModifyOrderItemResult;
  modifyOrganisationContractingPdf: ModifyOrganisationContractingPdfResult;
  modifyOrganisationEmailTemplate: ModifyOrganisationEmailTemplateResult;
  modifyOrganisationMembership: ModifyOrganisationMembershipResult;
  modifyOrganisationProfile: ModifyOrganisationProfileResult;
  modifyPlanSession: ModifyPlanSessionResult;
  modifyProductAttachment: ModifyProductAttachmentResult;
  modifyProject: ProjectModifyResult;
  modifyProjectAttachment: ModifyProjectAttachmentResult;
  modifyProjectMembership: ModifyProjectMembershipResult;
  modifyProjectWorkSession: ModifyProjectWorkSessionResult;
  modifyQuote: ModifyQuoteResult;
  modifyQuoteDocActor: ModifyQuoteDocActorResult;
  modifyQuoteItemInvoiceOverrides: ModifyQuoteItemInvoiceOverridesResult;
  modifyQuoteTemplate: ModifyQuoteTemplateResult;
  modifyRequest: ModifyRequestResult;
  modifyRequestAttachment: ModifyRequestAttachmentResult;
  modifyRequirementDocActor: ModifyRequirementDocActorResult;
  modifySapS4HanaSalesQuoteData: ModifySapS4HanaSalesQuoteDataResult;
  modifyTimeReport: ModifyTimeReportResult;
  modifyTodoItem: ModifyTodoItemResult;
  modifyWorkSession: ModifyWorkSessionResult;
  openInvoice: OpenInvoiceResult;
  openMasterdataAuthenticate: OpenMasterdataAuthenticateResult;
  openMasterdataUpdateSupplierProduct: OpenMasterdataUpdateSupplierProductResult;
  pasteItem: PasteItemResult;
  payInvoice: PayInvoiceResult;
  photoApproveTaskItem: PhotoApproveTaskItemResult;
  photoDisapproveTaskItem: PhotoDisapproveTaskItemResult;
  pimAddSupplierDiscountGroupImportation: PimAddSupplierDiscountGroupImportationResult;
  pimAddSupplierProductViewed: PimAddSupplierProductViewedResult;
  pimAssignManufacturer: PimCustomSupplierProduct;
  pimAssignManufacturerProduct: PimCustomSupplierProduct;
  pimAssignSupplierProductsProductType: PimAssignSupplierProductsProductTypeResult;
  pimCreateProduct?: Maybe<PimCustomSupplierProduct>;
  pimCreateSupplierDiscountGroupVariant: PimCreateSupplierDiscountGroupVariantResult;
  pimCreateSupplierProduct: PimCreateSupplierProductResult;
  pimCreateThreedModel: PimCreateThreedModelResult;
  pimDeleteCustomSupplierProduct: PimDeleteCustomSupplierProductResult;
  pimDeleteSupplierDiscountGroupVariant: PimDeleteSupplierDiscountGroupVariantResult;
  pimDeleteSupplierProduct: PimDeleteSupplierProductResult;
  pimExtractProductProperties: Product;
  pimModifySupplierDiscountGroup: PimModifySupplierDiscountGroupResult;
  pimModifySupplierProduct: PimModifySupplierProductResult;
  pimReenableCustomSupplierProduct: PimReenableCustomSupplierProductResult;
  pimReenableSupplierProduct: PimReenableSupplierProductResult;
  pimRequestImportationUploadUrl: RequestUploadUrlResult;
  pimRunPropertyExtraction: Array<Props2NonComputed>;
  pimSetManufacturerProductProductType: PimSetManufacturerProductProductTypeResult;
  pimSetProductTypePropertyTypeFavourites: PimSetProductTypePropertyTypeFavouritesResult;
  pimSetSupplierProductBranding: PimSetSupplierProductBrandingResult;
  pimSetSupplierProductProductType: PimSetSupplierProductProductTypeResult;
  pimSetSupplierProductPropertySuggestions: PimSetSupplierProductPropertySuggestionsResult;
  pimSetSupplierProductPropertyValues: PimSetSupplierProductPropertyValuesResult;
  pimSetSupplierProductsFavourite: PimSetSupplierProductsFavouriteResult;
  pimSubscribeToSupplierCatalogue: PimSubscribeToSupplierCatalogueResult;
  pimUnassignManufacturer: PimCustomSupplierProduct;
  pimUnassignManufacturerProduct: PimCustomSupplierProduct;
  pimUnsubscribeFromSupplierCatalogue: PimUnsubscribeFromSupplierCatalogueResult;
  pimUpdateProduct: PimCustomSupplierProduct;
  pimUpdateProductTypesFromEtim: PimUpdateProductTypesFromEtimResult;
  provideRequestFeedback: ProvideRequestFeedbackResult;
  publishQuote: PublishQuoteResult;
  publishRequest: PublishRequestResult;
  publishRequestToMarketplace: PublishRequestToMarketplaceResult;
  publishTemplateVersion: PublishTemplateVersionResult;
  qaApproveTaskItem: QaApproveTaskItemResult;
  qaDisapproveTaskItem: QaDisapproveTaskItemResult;
  quoteBulkUpdateProducts: QuoteBulkUpdateProductsResult;
  rearrangeOrganisationProjectPhase: RearrangeOrganisationProjectPhaseResult;
  recalculateQuote: RecalculateQuoteResult;
  recalculateQuoteTemplate: RecalculateQuoteTemplateResult;
  recommendifyLinkedItem: RecommendifyLinkedItemResult;
  redeemLinkingInvitationToken: Scalars['Boolean']['output'];
  rejectAllQuotesInRequest: RejectAllQuotesInRequestResult;
  removeAbsence: RemoveAbsenceResult;
  removeInvoiceExtraItem: RemoveInvoiceExtraItemResult;
  removeItemAttachments: RemoveItemAttachmentsResult;
  removeItemLinkCrmOrganisation: Scalars['Boolean']['output'];
  removeItemLinkCrmUser: Scalars['Boolean']['output'];
  removeItemProduct: RemoveItemProductResult;
  removeItemRecommendedTemplate: RemoveItemRecommendedTemplateResult;
  removeItemRecommendedTemplateType: RemoveItemRecommendedTemplateTypeResult;
  removeOrderItems: RemoveOrderItemsResult;
  removeOrganisationCapability: RemoveOrganisationCapabilityResult;
  removeOrganisationContractingPdf: RemoveOrganisationContractingPdfResult;
  removeOrganisationEmailTemplate: RemoveOrganisationEmailTemplateResult;
  removeOrganisationIntegration: RemoveOrganisationIntegrationResult;
  removeOrganisationProjectPhase: RemoveOrganisationProjectPhaseResult;
  removeProductAttachments: RemoveProductAttachmentsResult;
  removeProjectAttachments: RemoveProjectAttachmentsResult;
  removeProjectLinkCrmOrganisation: Scalars['Boolean']['output'];
  removeProjectLinkCrmUser: Scalars['Boolean']['output'];
  removeProjectMembership: RemoveProjectMembershipResult;
  removeQuoteDocActor: RemoveQuoteDocActorResult;
  removeQuoteTemplateFromShop: RemoveQuoteTemplateFromShopResult;
  removeRequestAttachments: RemoveRequestAttachmentsResult;
  removeRequestRecipient: RemoveRequestRecipientResult;
  removeRequirementDocActor: RemoveRequirementDocActorResult;
  renameOrganisationProjectPhase: RenameOrganisationProjectPhaseResult;
  renameProps2Key: RenameProps2KeyResult;
  requestAccountDeletion: Scalars['Boolean']['output'];
  requestCrmEmailAttachmentUploadUrl: RequestUploadUrlResult;
  requestGaebImportUploadUrl: RequestUploadUrlResult;
  requestImportFromJsonUrl: RequestUploadUrlResult;
  requestOrganisationPdfLetterheadUploadUrl: RequestOrganisationLetterheadUploadUrlResult;
  requestPimCreateThreedModelUploadUrl: RequestUploadUrlResult;
  requestProjectAttachmentUploadUrl: RequestProjectAttachmentUploadUrlResult;
  requestSupplierOrganisation: RequestSupplierOrganisationResult;
  requestXiopdImportUploadUrl: RequestUploadUrlResult;
  resetEliminateDecisionOption: ResetEliminateDecisionOptionResult;
  resetEliminateItem: ResetEliminateItemResult;
  resetQuoteDocActor: ResetQuoteDocActorResult;
  resetRequirementDocActor: ResetRequirementDocActorResult;
  resolveTemplate: ResolveTemplateResult;
  restoreTemplateVersion: RestoreTemplateVersionResult;
  runNonProjectDocumentAttributeExpressions: Scalars['Boolean']['output'];
  runProjectDocumentAttributeExpressions: Scalars['Boolean']['output'];
  sapCreateBusinessPartner: SapCreateBusinessPartnerResult;
  sapS4HanaCancelItem: SapS4HanaCancelItemResult;
  sapS4HanaCancelQuote: SapS4HanaCancelQuoteResult;
  sapS4HanaGenerateQuotePdf: SapS4HanaGenerateQuotePdfResult;
  sapS4HanaModifyHanaItemData: SapS4HanaModifyHanaItemDataResult;
  sapS4HanaUncancelItem: SapS4HanaUncancelItemResult;
  sapS4HanaUpdateQuoteFromRemote: SapS4HanaUpdateQuoteFromRemoteResult;
  sendChannelMessage?: Maybe<SendChannelMessageResult>;
  sendCrmEmail: Scalars['Boolean']['output'];
  sendCrmLinkingInvitation: SendCrmLinkingInvitationResult;
  sendProjectStateEvent: Project;
  setContents: SetContentsResult;
  setCustomFieldConfig: Array<CustomFieldConfig>;
  setCustomFields: SetCustomFieldsResult;
  setItemAttachmentClientVisibility: SetItemAttachmentClientVisibilityResult;
  setItemBinding: SetItemBindingResult;
  setItemChildrenVisibility: SetItemChildrenVisibilityResult;
  setItemManufacturerProduct: SetItemManufacturerProductResult;
  setItemPlacement: SetItemPlacementResult;
  setItemProduct: SetItemProductResult;
  setItemProductForDecision: SetItemProductResult;
  setItemProductType: SetItemProductTypeResult;
  setItemProps2Mappings: SetItemProps2MappingsResult;
  setItemProps2SelectionSetters: SetItemProps2SelectionSettersResult;
  setItemRecommendedTemplateRank: SetItemRecommendedTemplateRankResult;
  setItemRecommendedTemplateTypeRank: SetItemRecommendedTemplateTypeRankResult;
  setItemTemplatePlaceholderTemplate: SetItemTemplatePlaceholderTemplateResult;
  setOrderArrived: SetOrderArrivedResult;
  setOrderItemArrived: SetOrderItemArrivedResult;
  setOrderItemDamagedDelivery: SetOrderItemDamagedDeliveryResult;
  setOrderItemDamagedOnsite: SetOrderItemDamagedOnsiteResult;
  setOrderPaid: SetOrderPaidResult;
  setOrganisationMemberDefaultProjectRoles: SetOrganisationMemberDefaultProjectRolesResult;
  setOrganisationMemberOrganisationRoles: SetOrganisationMemberOrganisationRolesResult;
  setOrganisationPdfLetterhead?: Maybe<SetOrganisationPdfLetterheadPayload>;
  setPlaceholderForTemplateType: SetPlaceholderForTemplateTypeResult;
  setProjectAssignee: SetProjectAssigneeResult;
  setQuoteContracteeAddress: SetQuoteContracteeAddressResult;
  setQuoteExternalIdentifier: SetQuoteExternalIdentifierResult;
  setTaskItemDone: SetTaskItemDoneResult;
  setTaskItemNotDone: SetTaskItemNotDoneResult;
  setTreeHierarchy: SetTreeHierarchyResult;
  setUnnestedTemplateItemDecisionContingentItemPreselection: SetUnnestedTemplateItemDecisionContingentItemPreselectionResult;
  setUnnestedTemplateItemDecisionSubitemsPreselection: SetUnnestedTemplateItemDecisionSubitemsPreselectionResult;
  setUserPreference: SetUserPreferenceResult;
  shareDoc: ShareDocResult;
  shareQuoteTemplatePublicly: ShareQuoteTemplatePubliclyResult;
  shareQuoteTemplateWithOrganisations: ShareQuoteTemplateWithOrganisationsResult;
  simulateSapS4HanaSalesQuoteFromQuote: SimulateSapS4HanaSalesQuoteFromQuoteResult;
  startProjectWorkSession: StartProjectWorkSessionResult;
  subcontract: SubcontractResult;
  submitOrder: SubmitOrderResult;
  subscribeToQuoteTemplate: SubscribeToQuoteTemplateResult;
  syncProductsInQuote: SyncProductsInQuoteResult;
  syncProductsInQuoteFromGaebP94: SyncProductsInQuoteFromGaebP94Result;
  templateBulkUpdateProducts: TemplateBulkUpdateProductsResult;
  toggleDecisionOptionPreselection: ToggleDecisionOptionPreselectionResult;
  toggleOrganisationMembershipActive: ToggleOrganisationMembershipActiveResult;
  unlikeQuoteTemplate: UnlikeQuoteTemplateResult;
  unlikeShopTemplate: UnlikeShopTemplateResult;
  unpublishQuote: UnpublishQuoteResult;
  unpublishRequestToMarketplace: UnpublishRequestToMarketplaceResult;
  unshareDoc: UnshareDocResult;
  unsubscribeFromQuoteTemplate: UnsubscribeFromQuoteTemplateResult;
  updateAllLinkingItemsToTemplateToLatestAvailableVersion: UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResult;
  updateItekData: UpdateItekDataResult;
  updateLinkedItemToLatestAvailablePublishedVersion: UpdateLinkedItemToLatestAvailablePublishedVersionResult;
  updateOrganisation: OrganisationUpdateResult;
  updateOrganisationCounters: OrganisationCountersUpdateResult;
  updateOrganisationDefaults: OrganisationDefaultsUpdateResult;
  updateOrganisationDocumentSettings: OrganisationDocumentSettingsUpdateResult;
  updateOrganisationEmailSettings: OrganisationEmailSettingsUpdateResult;
  updateOrganisationIntegration: UpdateOrganisationIntegrationResult;
  updateOrganisationPdfSettings: OrganisationPdfSettingsUpdateResult;
  updateOrganisationSettings: OrganisationSettingsUpdateResult;
  updatePlanSessions: UpdatePlanSessionsResult;
  updateProductPricingsInQuote: UpdateProductPricingsInQuoteResult;
  updateSapS4HanaSalesQuoteFromQuote: UpdateSapS4HanaSalesQuoteFromQuoteResult;
  updateUser: UpdateUserResult;
  withdrawRequest: WithdrawRequestResult;
};


export type Mutation_3d_AddProductDataArgs = {
  input: _3d_AddProductDataInput;
};


export type Mutation_3d_AddSnapshotsArgs = {
  input: _3d_AddSnapshotsInput;
};


export type Mutation_3d_CreateLeadInNewProjectArgs = {
  input: _3d_CreateLeadInNewProjectInput;
};


export type Mutation_3d_ModifyRoomShapeArgs = {
  input: _3d_ModifyRoomShapeInput;
};


export type Mutation_3d_RemoveProductDataArgs = {
  input: _3d_RemoveProductDataInput;
};


export type Mutation_3d_RequestAddProductDataUrlArgs = {
  input: _3d_RequestAddProductDataUrlInput;
};


export type MutationAcceptChangesArgs = {
  input: AcceptChangesInput;
};


export type MutationAcceptCrmLinkingInvitationArgs = {
  invitationToken: Scalars['String']['input'];
};


export type MutationAcceptDocShareArgs = {
  input: AcceptDocShareInput;
};


export type MutationAcceptOrganisationMembershipInvitationArgs = {
  input: AcceptOrganisationMembershipInvitationInput;
};


export type MutationAcceptProjectContracteeInvitationArgs = {
  input: AcceptProjectContracteeInvitationInput;
};


export type MutationAcceptQuoteArgs = {
  input: AcceptQuoteInput;
};


export type MutationAddCompanyOrganisationCredentialsArgs = {
  input: AddCompanyOrganisationCredentialsInput;
};


export type MutationAddInvoiceExtraItemArgs = {
  input: AddInvoiceExtraItemInput;
};


export type MutationAddItemAttachmentsArgs = {
  attachments: Array<AttachmentInput>;
  itemId: Scalars['ID']['input'];
};


export type MutationAddItemLinkCrmOrganisationArgs = {
  input: AddItemLinkCrmOrganisationInput;
};


export type MutationAddItemLinkCrmUserArgs = {
  input: AddItemLinkCrmUserInput;
};


export type MutationAddItemRecommendedTemplateArgs = {
  input: AddItemRecommendedTemplateInput;
};


export type MutationAddItemRecommendedTemplateTypeArgs = {
  input: AddItemRecommendedTemplateTypeInput;
};


export type MutationAddOrderItemsArgs = {
  input: AddOrderItemsInput;
};


export type MutationAddOrganisationCapabilityArgs = {
  input: AddOrganisationCapabilityInput;
};


export type MutationAddOrganisationContractingPdfArgs = {
  input: AddOrganisationContractingPdfInput;
};


export type MutationAddOrganisationEmailTemplateArgs = {
  input: AddOrganisationEmailTemplateInput;
};


export type MutationAddOrganisationIntegrationArgs = {
  input: AddOrganisationIntegrationInput;
};


export type MutationAddOrganisationMembershipInvitationArgs = {
  input: AddOrganisationMembershipInvitationInput;
};


export type MutationAddOrganisationProfileArgs = {
  input: AddOrganisationProfileInput;
};


export type MutationAddOrganisationProjectPhaseArgs = {
  input: AddOrganisationProjectPhaseInput;
};


export type MutationAddOrganisationsFromItekCompaniesArgs = {
  input: AddOrganisationsFromItekCompaniesInput;
};


export type MutationAddPimImportArgs = {
  input: AddPimImportInput;
};


export type MutationAddPimManufacturerProductImportationArgs = {
  input: AddPimManufacturerProductImportInput;
};


export type MutationAddPimSearchCacheArgs = {
  input: AddPimSearchCacheInput;
};


export type MutationAddPimSupplierProductCategoryImportationArgs = {
  input: AddPimSupplierProductCategoryImportationInput;
};


export type MutationAddPimSupplierProductImportationArgs = {
  input: AddPimSupplierProductImportInput;
};


export type MutationAddProductAttachmentsArgs = {
  attachments: Array<AttachmentInput>;
  productId: Scalars['ID']['input'];
};


export type MutationAddProjectAttachmentsArgs = {
  attachments: Array<AttachmentInput>;
  projectId: Scalars['ID']['input'];
};


export type MutationAddProjectContracteeInputArgs = {
  input: AddProjectContracteeInputInput;
  token: Scalars['String']['input'];
};


export type MutationAddProjectContracteeInvitationArgs = {
  input: AddProjectContracteeInvitationInput;
};


export type MutationAddProjectLinkCrmOrganisationArgs = {
  input: AddProjectLinkCrmOrganisationInput;
};


export type MutationAddProjectLinkCrmUserArgs = {
  input: AddProjectLinkCrmUserInput;
};


export type MutationAddProjectMemberFromOrganisationMembersArgs = {
  projectId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationAddPushNotificationTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationAddQuoteDocActorArgs = {
  input: AddQuoteDocActorInput;
};


export type MutationAddQuoteTemplateToShopArgs = {
  input: AddQuoteTemplateToShopInput;
};


export type MutationAddRequestAttachmentsArgs = {
  attachments: Array<AttachmentInput>;
  requestId: Scalars['ID']['input'];
};


export type MutationAddRequestRecipientsArgs = {
  input: AddRequestRecipientsInput;
};


export type MutationAddRequirementDocActorArgs = {
  input: AddRequirementDocActorInput;
};


export type MutationApplyQuoteProductsDistributionArgs = {
  input: ApplyQuoteProductsDistributionInput;
};


export type MutationApproveTaskItemArgs = {
  input: ApproveTaskItemInput;
};


export type MutationApproveTimeReportArgs = {
  input: ApproveTimeReportInput;
};


export type MutationAssignBuildingArgs = {
  input: AssignBuildingInput;
};


export type MutationCancelInvoiceArgs = {
  input: CancelInvoiceInput;
};


export type MutationCancelOrganisationMembershipInvitationArgs = {
  organisationMembershipInvitationId: Scalars['ID']['input'];
};


export type MutationCancelPendingShareDocArgs = {
  input: CancelPendingShareDocInput;
};


export type MutationCancelProjectContracteeInvitationArgs = {
  projectContracteeInvitationId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationChangeEmailArgs = {
  input: ChangeEmailInput;
};


export type MutationChangeItemTypeArgs = {
  input: ChangeItemTypeInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangeProjectPhaseArgs = {
  input: ChangeProjectPhaseInput;
};


export type MutationCloseRequestArgs = {
  input: CloseRequestInput;
};


export type MutationCloseRequestForBiddingArgs = {
  input: CloseRequestForBiddingInput;
};


export type MutationCopyQuoteIntoNewQuoteArgs = {
  input: CopyQuoteIntoNewQuoteInput;
};


export type MutationCopyQuoteSapS4HanaSalesQuoteArgs = {
  input: CopyQuoteSapS4HanaSalesQuoteInput;
};


export type MutationCopySupplierOrganisationAsCrmArgs = {
  input: CopySupplierOrganisationAsCrmInput;
};


export type MutationCorrectWorkSessionArgs = {
  input: CorrectWorkSessionInput;
};


export type MutationCreateAbsenceArgs = {
  input: CreateAbsenceInput;
};


export type MutationCreateBuildingArgs = {
  input: BuildingCreateInput;
};


export type MutationCreateCrmOrganisationArgs = {
  input: Array<CrmOrganisationCreateInput>;
};


export type MutationCreateCrmUserArgs = {
  input: Array<CrmUserCreateInput>;
};


export type MutationCreateEmbedabbleWizardLeadArgs = {
  input: CreateEmbedabbleWizardLeadInput;
};


export type MutationCreateIdsConnectOrderArgs = {
  input: CreateIdsConnectOrderInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateItemFromItemRecommendationArgs = {
  input: CreateItemFromItemRecommendationInput;
};


export type MutationCreateItemsArgs = {
  input: CreateItemsInput;
};


export type MutationCreateItemsDefectArgs = {
  input: CreateItemsInput;
};


export type MutationCreateItemsFromTemplateTypesArgs = {
  input: CreateItemsFromTemplateTypesInput;
};


export type MutationCreateItemsFromTemplatesArgs = {
  input: CreateItemsFromTemplatesInput;
};


export type MutationCreateItemsUnpaidArgs = {
  input: CreateItemsInput;
};


export type MutationCreateManufacturerArgs = {
  input: CreateManufacturerInput;
};


export type MutationCreateOrderArgs = {
  input: OrderCreateInput;
};


export type MutationCreateOrganisationAndUserArgs = {
  input: CreateOrganisationAndUserInput;
};


export type MutationCreatePlanSessionArgs = {
  input: CreatePlanSessionInput;
};


export type MutationCreateProjectArgs = {
  input: ProjectCreateInput;
};


export type MutationCreateProjectForQuoteProductsDistributionArgs = {
  input: CreateProjectForQuoteProductsDistributionInput;
};


export type MutationCreateProjectForRequestArgs = {
  input: CreateProjectForRequestInput;
};


export type MutationCreateProjectFromGaebImportArgs = {
  input: CreateProjectFromGaebImportInput;
};


export type MutationCreateProjectFromXiopdImportArgs = {
  input: CreateProjectFromXiopdImportInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateQuoteFromGaebImportArgs = {
  input: CreateQuoteFromGaebImportInput;
};


export type MutationCreateQuoteFromSapS4HanaSalesQuoteArgs = {
  input: CreateQuoteFromSapS4HanaSalesQuoteInput;
};


export type MutationCreateQuoteFromXiopdImportArgs = {
  input: CreateQuoteFromXiopdImportInput;
};


export type MutationCreateQuoteProductsDistributionArgs = {
  input: CreateQuoteProductsDistributionInput;
};


export type MutationCreateQuoteRequestArgs = {
  input: CreateQuoteRequestInput;
};


export type MutationCreateQuoteTemplateArgs = {
  input: CreateQuoteTemplateInput;
};


export type MutationCreateRequirementArgs = {
  input: CreateRequirementInput;
};


export type MutationCreateSapS4HanaSalesQuoteFromQuoteArgs = {
  input: CreateSapS4HanaSalesQuoteFromQuoteInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationCreateTemplateFromItemArgs = {
  input: CreateTemplateFromItemInput;
};


export type MutationCreateTimeReportArgs = {
  input: CreateTimeReportInput;
};


export type MutationCreateTodoItemArgs = {
  input: CreateTodoItemInput;
};


export type MutationCreateWorkSessionsArgs = {
  input: CreateWorkSessionsInput;
};


export type MutationDeclineChangesArgs = {
  input: DeclineChangesInput;
};


export type MutationDeclineProjectContracteeInvitationArgs = {
  invitationToken: Scalars['String']['input'];
};


export type MutationDeclineQuoteArgs = {
  input: DeclineQuoteInput;
};


export type MutationDefineItemAttributeExpressionArgs = {
  input: DefineItemAttributeExpressionInput;
};


export type MutationDefineItemProductSearchFilterDefinitionsArgs = {
  input: DefineItemProductSearchFilterDefinitionsInput;
};


export type MutationDefineItemProductSearchFilterDefinitionsForDecisionArgs = {
  input: DefineItemProductSearchFilterDefinitionsForDecisionInput;
};


export type MutationDefineItemProductSearchFilterExpressionsArgs = {
  input: DefineItemProductSearchFilterExpressionsInput;
};


export type MutationDefineItemProps2Args = {
  input: DefineItemProps2Input;
};


export type MutationDefineItemTemplateSearchFilterDefinitionsArgs = {
  input: DefineItemTemplateSearchFilterDefinitionsInput;
};


export type MutationDefineItemTemplateSearchFilterExpressionsArgs = {
  input: DefineItemTemplateSearchFilterExpressionsInput;
};


export type MutationDefineItemTemplateSearchSortingDefinitionsArgs = {
  input: DefineItemTemplateSearchSortingDefinitionsInput;
};


export type MutationDeleteBuildingArgs = {
  input: DeleteBuildingInput;
};


export type MutationDeleteCompanyOrganisationCredentialsArgs = {
  input: DeleteCompanyOrganisationCredentialsInput;
};


export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput;
};


export type MutationDeleteItemArgs = {
  input: DeleteItemInput;
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationDeletePimImportArgs = {
  input: DeletePimImportInput;
};


export type MutationDeletePimSupplierImportationsArgs = {
  input: DeletePimSupplierImportationsInput;
};


export type MutationDeletePlanSessionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteQuoteArgs = {
  input: DeleteQuoteInput;
};


export type MutationDeleteQuoteTemplateArgs = {
  input: DeleteQuoteTemplateInput;
};


export type MutationDeleteRequirementArgs = {
  input: DeleteRequirementInput;
};


export type MutationDeleteWorkSessionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisableUserCalendarArgs = {
  input: DisableUserCalendarInput;
};


export type MutationDisapproveTaskItemArgs = {
  input: DisapproveTaskItemInput;
};


export type MutationDiscardPendingChangesArgs = {
  input: DiscardPendingChangesInput;
};


export type MutationDiscardUnpublishedChangesArgs = {
  input: DiscardUnpublishedChangesInput;
};


export type MutationDistributeProductsInQuoteArgs = {
  input: DistributeProductsInQuoteInput;
};


export type MutationDocExportAsExcelArgs = {
  input: DocExportAsExcelInput;
};


export type MutationDocExportAsM1ImpexJsonArgs = {
  input: DocExportAsM1ImpexJsonInput;
};


export type MutationDocumentBulkFinaliseArgs = {
  input: DocumentBulkFinaliseInput;
};


export type MutationDocumentRecomputePropertiesAndExpressionsArgs = {
  input: DocumentRecomputePropertiesAndExpressionsInput;
};


export type MutationDuplicateQuoteTemplateArgs = {
  input: DuplicateQuoteTemplateInput;
};


export type MutationEliminateDecisionOptionArgs = {
  input: EliminateDecisionOptionInput;
};


export type MutationEliminateItemArgs = {
  input: EliminateItemInput;
};


export type MutationEnableUserCalendarArgs = {
  input: EnableUserCalendarInput;
};


export type MutationEnterItemProps2ValueArgs = {
  input: EnterItemProps2ValueInput;
};


export type MutationEnterItemProps2ValueForDecisionArgs = {
  input: EnterItemProps2ValueInput;
};


export type MutationExportAsGaebArgs = {
  input: ExportAsGaebInput;
};


export type MutationExportAsJsonArgs = {
  input: ExportAsJsonInput;
};


export type MutationExportAsXiopdArgs = {
  input: ExportAsXiopdInput;
};


export type MutationExtractOrganisationContractingPdfFormFieldsArgs = {
  input: ExtractOrganisationContractingPdfFormFieldsInput;
};


export type MutationFinalizeContingencyDecisionArgs = {
  input: FinalizeContingencyDecisionInput;
};


export type MutationFinalizeSubitemDecisionArgs = {
  input: FinalizeSubitemDecisionInput;
};


export type MutationFinishProjectWorkSessionArgs = {
  input: FinishProjectWorkSessionInput;
};


export type MutationGenerateExamplePdfDocumentArgs = {
  input: GenerateExamplePdfDocumentInput;
};


export type MutationGenerateOrganisationPdfLetterheadArgs = {
  input: GenerateOrganisationPdfLetterheadInput;
};


export type MutationGetCsvHeaderArgs = {
  input: GetCsvHeaderInput;
};


export type MutationImplementsTemplateTypeArgs = {
  input: ImplementsTemplateTypeInput;
};


export type MutationImportCrmOrganisationsArgs = {
  input: Array<ImportCrmEntryInput>;
};


export type MutationImportDatanormOnlineEntryArgs = {
  input: ImportDatanormOnlineEntryInput;
};


export type MutationImportFromJsonArgs = {
  input: ImportFromJsonInput;
};


export type MutationImportRequirementArgs = {
  input: ImportRequirementInput;
};


export type MutationInvalidateArgs = {
  docId: Scalars['ID']['input'];
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationInvalidateGlobalLocalArgs = {
  docId: Scalars['ID']['input'];
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationInvalidateGlobalRemoteArgs = {
  docId: Scalars['ID']['input'];
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationLikeQuoteTemplateArgs = {
  input: LikeQuoteTemplateInput;
};


export type MutationLikeShopTemplateArgs = {
  input: LikeShopTemplateInput;
};


export type MutationMarkChannelAsReadArgs = {
  channelId: Scalars['ID']['input'];
};


export type MutationModifyAbsenceArgs = {
  input: ModifyAbsenceInput;
};


export type MutationModifyBuildingArgs = {
  input: ModifyBuildingInput;
};


export type MutationModifyCrmOrganisationArgs = {
  input: Array<CrmOrganisationModifyInput>;
};


export type MutationModifyCrmUserArgs = {
  input: Array<CrmUserModifyInput>;
};


export type MutationModifyInvoiceArgs = {
  input: ModifyInvoiceInput;
};


export type MutationModifyInvoiceExtraItemArgs = {
  input: ModifyInvoiceExtraItemInput;
};


export type MutationModifyInvoicePaymentItemArgs = {
  input: ModifyInvoicePaymentItemInput;
};


export type MutationModifyItemActualCalculationArgs = {
  input: ModifyItemActualCalculationInput;
};


export type MutationModifyItemAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  input: AttachmentInput;
  itemId: Scalars['ID']['input'];
};


export type MutationModifyItemContingencyArgs = {
  input: ModifyItemContingencyInput;
};


export type MutationModifyItemDecisionArgs = {
  input: ModifyItemDecisionInput;
};


export type MutationModifyItemEstimatedCalculationArgs = {
  input: ModifyItemEstimatedCalculationInput;
};


export type MutationModifyItemInfoArgs = {
  input: ModifyItemInfoInput;
};


export type MutationModifyItemProductArgs = {
  input: ModifyItemProductInput;
};


export type MutationModifyItemRecommendedTemplateArgs = {
  input: ModifyItemRecommendedTemplateInput;
};


export type MutationModifyItemRecommendedTemplateTypeArgs = {
  input: ModifyItemRecommendedTemplateTypeInput;
};


export type MutationModifyItemTaskInfoArgs = {
  input: ModifyItemTaskInfoInput;
};


export type MutationModifyItemVerticalArgs = {
  input: ModifyItemVerticalInput;
};


export type MutationModifyItemWizardSettingsArgs = {
  input: ModifyItemWizardSettingsInput;
};


export type MutationModifyMyProjectWorkSessionArgs = {
  input: ModifyMyProjectWorkSessionInput;
};


export type MutationModifyOrderArgs = {
  input: ModifyOrderInput;
};


export type MutationModifyOrderItemArgs = {
  input: ModifyOrderItemInput;
};


export type MutationModifyOrganisationContractingPdfArgs = {
  input: ModifyOrganisationContractingPdfInput;
};


export type MutationModifyOrganisationEmailTemplateArgs = {
  input: ModifyOrganisationEmailTemplateInput;
  templateId: Scalars['ID']['input'];
};


export type MutationModifyOrganisationMembershipArgs = {
  input: ModifyOrganisationMembershipInput;
};


export type MutationModifyOrganisationProfileArgs = {
  input: ModifyOrganisationProfileInput;
};


export type MutationModifyPlanSessionArgs = {
  input: ModifyPlanSessionInput;
};


export type MutationModifyProductAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  input: AttachmentInput;
  productId: Scalars['ID']['input'];
};


export type MutationModifyProjectArgs = {
  input: ProjectModifyInput;
};


export type MutationModifyProjectAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  input: AttachmentInput;
  projectId: Scalars['ID']['input'];
};


export type MutationModifyProjectMembershipArgs = {
  input: ModifyProjectMembershipInput;
};


export type MutationModifyProjectWorkSessionArgs = {
  input: ModifyProjectWorkSessionInput;
};


export type MutationModifyQuoteArgs = {
  input: QuoteModifyInput;
};


export type MutationModifyQuoteDocActorArgs = {
  input: ModifyQuoteDocActorInput;
};


export type MutationModifyQuoteItemInvoiceOverridesArgs = {
  input: ModifyQuoteItemInvoiceOverridesInput;
};


export type MutationModifyQuoteTemplateArgs = {
  input: ModifyQuoteTemplateInput;
};


export type MutationModifyRequestArgs = {
  input: ModifyRequestInput;
};


export type MutationModifyRequestAttachmentArgs = {
  attachmentId: Scalars['ID']['input'];
  input: AttachmentInput;
  requestId: Scalars['ID']['input'];
};


export type MutationModifyRequirementDocActorArgs = {
  input: ModifyRequirementDocActorInput;
};


export type MutationModifySapS4HanaSalesQuoteDataArgs = {
  input: ModifySapS4HanaSalesQuoteDataInput;
};


export type MutationModifyTimeReportArgs = {
  input: ModifyTimeReportInput;
};


export type MutationModifyTodoItemArgs = {
  input: ModifyTodoItemInput;
};


export type MutationModifyWorkSessionArgs = {
  input: ModifyWorkSessionInput;
};


export type MutationOpenInvoiceArgs = {
  input: OpenInvoiceInput;
};


export type MutationOpenMasterdataAuthenticateArgs = {
  input: OpenMasterdataAuthenticateInput;
};


export type MutationOpenMasterdataUpdateSupplierProductArgs = {
  input: OpenMasterdataUpdateSupplierProductInput;
};


export type MutationPasteItemArgs = {
  input: PasteItemInput;
};


export type MutationPayInvoiceArgs = {
  input: PayInvoiceInput;
};


export type MutationPhotoApproveTaskItemArgs = {
  input: PhotoApproveTaskItemInput;
};


export type MutationPhotoDisapproveTaskItemArgs = {
  input: PhotoDisapproveTaskItemInput;
};


export type MutationPimAddSupplierDiscountGroupImportationArgs = {
  input: PimAddSupplierDiscountGroupImportationInput;
};


export type MutationPimAddSupplierProductViewedArgs = {
  input: PimAddSupplierProductViewedInput;
};


export type MutationPimAssignManufacturerArgs = {
  input: PimAssignManufacturerInput;
};


export type MutationPimAssignManufacturerProductArgs = {
  input: PimAssignManufacturerProductInput;
};


export type MutationPimAssignSupplierProductsProductTypeArgs = {
  input: PimAssignSupplierProductsProductTypeInput;
};


export type MutationPimCreateProductArgs = {
  input: PimCreateProductInput;
};


export type MutationPimCreateSupplierDiscountGroupVariantArgs = {
  input: PimCreateSupplierDiscountGroupVariantInput;
};


export type MutationPimCreateSupplierProductArgs = {
  input: PimCreateSupplierProductInput;
};


export type MutationPimCreateThreedModelArgs = {
  input: PimCreateThreedModelInput;
};


export type MutationPimDeleteCustomSupplierProductArgs = {
  input: PimDeleteCustomSupplierProductInput;
};


export type MutationPimDeleteSupplierDiscountGroupVariantArgs = {
  input: PimDeleteSupplierDiscountGroupVariantInput;
};


export type MutationPimDeleteSupplierProductArgs = {
  input: PimDeleteSupplierProductInput;
};


export type MutationPimExtractProductPropertiesArgs = {
  input: PimExtractProductPropertiesInput;
};


export type MutationPimModifySupplierDiscountGroupArgs = {
  input: PimModifySupplierDiscountGroupInput;
};


export type MutationPimModifySupplierProductArgs = {
  input: PimModifySupplierProductInput;
};


export type MutationPimReenableCustomSupplierProductArgs = {
  input: PimReenableCustomSupplierProductInput;
};


export type MutationPimReenableSupplierProductArgs = {
  input: PimReenableSupplierProductInput;
};


export type MutationPimRequestImportationUploadUrlArgs = {
  input: PimRequestImportationUploadUrlInput;
};


export type MutationPimRunPropertyExtractionArgs = {
  input: PimRunPropertyExtractionInput;
};


export type MutationPimSetManufacturerProductProductTypeArgs = {
  input: PimSetManufacturerProductProductTypeInput;
};


export type MutationPimSetProductTypePropertyTypeFavouritesArgs = {
  input: PimSetProductTypePropertyTypeFavouritesInput;
};


export type MutationPimSetSupplierProductBrandingArgs = {
  input: PimSetSupplierProductBrandingInput;
};


export type MutationPimSetSupplierProductProductTypeArgs = {
  input: PimSetSupplierProductProductTypeInput;
};


export type MutationPimSetSupplierProductPropertySuggestionsArgs = {
  input: PimSetSupplierProductPropertySuggestionsInput;
};


export type MutationPimSetSupplierProductPropertyValuesArgs = {
  input: PimSetSupplierProductPropertyValuesInput;
};


export type MutationPimSetSupplierProductsFavouriteArgs = {
  input: PimSetSupplierProductsFavouriteInput;
};


export type MutationPimSubscribeToSupplierCatalogueArgs = {
  input: PimSubscribeToSupplierCatalogueInput;
};


export type MutationPimUnassignManufacturerArgs = {
  input: PimUnassignManufacturerInput;
};


export type MutationPimUnassignManufacturerProductArgs = {
  input: PimUnassignManufacturerProductInput;
};


export type MutationPimUnsubscribeFromSupplierCatalogueArgs = {
  input: PimUnsubscribeFromSupplierCatalogueInput;
};


export type MutationPimUpdateProductArgs = {
  input: PimUpdateProductInput;
};


export type MutationProvideRequestFeedbackArgs = {
  input: ProvideRequestFeedbackInput;
};


export type MutationPublishQuoteArgs = {
  input: PublishQuoteInput;
};


export type MutationPublishRequestArgs = {
  input: PublishRequestInput;
};


export type MutationPublishRequestToMarketplaceArgs = {
  input: PublishRequestToMarketplaceInput;
};


export type MutationPublishTemplateVersionArgs = {
  input: PublishTemplateVersionInput;
};


export type MutationQaApproveTaskItemArgs = {
  input: QaApproveTaskItemInput;
};


export type MutationQaDisapproveTaskItemArgs = {
  input: QaDisapproveTaskItemInput;
};


export type MutationQuoteBulkUpdateProductsArgs = {
  input: QuoteBulkUpdateProductsInput;
};


export type MutationRearrangeOrganisationProjectPhaseArgs = {
  input: RearrangeOrganisationProjectPhaseInput;
};


export type MutationRecalculateQuoteArgs = {
  input: RecalculateQuoteInput;
};


export type MutationRecalculateQuoteTemplateArgs = {
  input: RecalculateQuoteTemplateInput;
};


export type MutationRecommendifyLinkedItemArgs = {
  input: RecommendifyLinkedItemInput;
};


export type MutationRedeemLinkingInvitationTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationRejectAllQuotesInRequestArgs = {
  input: RejectAllQuotesInRequestInput;
};


export type MutationRemoveAbsenceArgs = {
  input: RemoveAbsenceInput;
};


export type MutationRemoveInvoiceExtraItemArgs = {
  input: RemoveInvoiceExtraItemInput;
};


export type MutationRemoveItemAttachmentsArgs = {
  attachmentIds: Array<Scalars['ID']['input']>;
  itemId: Scalars['ID']['input'];
};


export type MutationRemoveItemLinkCrmOrganisationArgs = {
  input: RemoveItemLinkCrmOrganisationInput;
};


export type MutationRemoveItemLinkCrmUserArgs = {
  input: RemoveItemLinkCrmUserInput;
};


export type MutationRemoveItemProductArgs = {
  input: RemoveItemProductInput;
};


export type MutationRemoveItemRecommendedTemplateArgs = {
  input: RemoveItemRecommendedTemplateInput;
};


export type MutationRemoveItemRecommendedTemplateTypeArgs = {
  input: RemoveItemRecommendedTemplateTypeInput;
};


export type MutationRemoveOrderItemsArgs = {
  input: RemoveOrderItemsInput;
};


export type MutationRemoveOrganisationCapabilityArgs = {
  input: RemoveOrganisationCapabilityInput;
};


export type MutationRemoveOrganisationContractingPdfArgs = {
  input: RemoveOrganisationContractingPdfInput;
};


export type MutationRemoveOrganisationEmailTemplateArgs = {
  templateId: Scalars['ID']['input'];
};


export type MutationRemoveOrganisationIntegrationArgs = {
  input: RemoveOrganisationIntegrationInput;
};


export type MutationRemoveOrganisationProjectPhaseArgs = {
  input: RemoveOrganisationProjectPhaseInput;
};


export type MutationRemoveProductAttachmentsArgs = {
  attachmentIds: Array<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};


export type MutationRemoveProjectAttachmentsArgs = {
  attachmentIds: Array<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};


export type MutationRemoveProjectLinkCrmOrganisationArgs = {
  input: RemoveProjectLinkCrmOrganisationInput;
};


export type MutationRemoveProjectLinkCrmUserArgs = {
  input: RemoveProjectLinkCrmUserInput;
};


export type MutationRemoveProjectMembershipArgs = {
  input: RemoveProjectMembershipInput;
};


export type MutationRemoveQuoteDocActorArgs = {
  input: RemoveQuoteDocActorInput;
};


export type MutationRemoveQuoteTemplateFromShopArgs = {
  input: RemoveQuoteTemplateFromShopInput;
};


export type MutationRemoveRequestAttachmentsArgs = {
  attachmentIds: Array<Scalars['ID']['input']>;
  requestId: Scalars['ID']['input'];
};


export type MutationRemoveRequestRecipientArgs = {
  input: RemoveRequestRecipientInput;
};


export type MutationRemoveRequirementDocActorArgs = {
  input: RemoveRequirementDocActorInput;
};


export type MutationRenameOrganisationProjectPhaseArgs = {
  input: RenameOrganisationProjectPhaseInput;
};


export type MutationRenameProps2KeyArgs = {
  input: RenameProps2KeyInput;
};


export type MutationRequestCrmEmailAttachmentUploadUrlArgs = {
  input: RequestCrmEmailAttachmentUploadUrlInput;
};


export type MutationRequestGaebImportUploadUrlArgs = {
  input: RequestGaebImportUploadUrlInput;
};


export type MutationRequestImportFromJsonUrlArgs = {
  input: RequestImportFromJsonUrlInput;
};


export type MutationRequestPimCreateThreedModelUploadUrlArgs = {
  input: RequestPimCreateThreedModelUploadUrlInput;
};


export type MutationRequestProjectAttachmentUploadUrlArgs = {
  filename: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRequestSupplierOrganisationArgs = {
  input: RequestSupplierOrganisationInput;
};


export type MutationRequestXiopdImportUploadUrlArgs = {
  input: RequestXiopdImportUploadUrlInput;
};


export type MutationResetEliminateDecisionOptionArgs = {
  input: ResetEliminateDecisionOptionInput;
};


export type MutationResetEliminateItemArgs = {
  input: ResetEliminateItemInput;
};


export type MutationResetQuoteDocActorArgs = {
  input: ResetQuoteDocActorInput;
};


export type MutationResetRequirementDocActorArgs = {
  input: ResetRequirementDocActorInput;
};


export type MutationResolveTemplateArgs = {
  input: ResolveTemplateInput;
};


export type MutationRestoreTemplateVersionArgs = {
  input: RestoreTemplateVersionInput;
};


export type MutationRunNonProjectDocumentAttributeExpressionsArgs = {
  input: RunNonProjectDocumentAttributeExpressionsInput;
};


export type MutationRunProjectDocumentAttributeExpressionsArgs = {
  input: RunProjectDocumentAttributeExpressionsInput;
};


export type MutationSapCreateBusinessPartnerArgs = {
  input: SapCreateBusinessPartnerInput;
};


export type MutationSapS4HanaCancelItemArgs = {
  input: SapS4HanaCancelItemInput;
};


export type MutationSapS4HanaCancelQuoteArgs = {
  input: SapS4HanaCancelQuoteInput;
};


export type MutationSapS4HanaGenerateQuotePdfArgs = {
  input: SapS4HanaGenerateQuotePdfInput;
};


export type MutationSapS4HanaModifyHanaItemDataArgs = {
  input: SapS4HanaModifyHanaItemDataInput;
};


export type MutationSapS4HanaUncancelItemArgs = {
  input: SapS4HanaUncancelItemInput;
};


export type MutationSapS4HanaUpdateQuoteFromRemoteArgs = {
  input: SapS4HanaUpdateQuoteFromRemoteInput;
};


export type MutationSendChannelMessageArgs = {
  message: ChannelMessageInput;
};


export type MutationSendCrmEmailArgs = {
  input: SendCrmEmailInput;
};


export type MutationSendCrmLinkingInvitationArgs = {
  input: SendCrmLinkingInvitationInput;
};


export type MutationSendProjectStateEventArgs = {
  input: SendProjectStateEventInput;
};


export type MutationSetContentsArgs = {
  input: SetContentsInput;
};


export type MutationSetCustomFieldConfigArgs = {
  input: Array<SetCustomFieldConfigInput>;
};


export type MutationSetCustomFieldsArgs = {
  input: SetCustomFieldsInput;
};


export type MutationSetItemAttachmentClientVisibilityArgs = {
  input: SetItemAttachmentClientVisibilityInput;
};


export type MutationSetItemBindingArgs = {
  input: SetItemBindingInput;
};


export type MutationSetItemChildrenVisibilityArgs = {
  input: SetItemChildrenVisibilityInput;
};


export type MutationSetItemManufacturerProductArgs = {
  input: SetItemManufacturerProductInput;
};


export type MutationSetItemPlacementArgs = {
  input: SetItemPlacementInput;
};


export type MutationSetItemProductArgs = {
  input: SetItemProductInput;
};


export type MutationSetItemProductForDecisionArgs = {
  input: SetItemProductInput;
};


export type MutationSetItemProductTypeArgs = {
  input: SetItemProductTypeInput;
};


export type MutationSetItemProps2MappingsArgs = {
  input: SetItemProps2MappingsInput;
};


export type MutationSetItemProps2SelectionSettersArgs = {
  input: SetItemProps2SelectionSettersInput;
};


export type MutationSetItemRecommendedTemplateRankArgs = {
  input: SetItemRecommendedTemplateRankInput;
};


export type MutationSetItemRecommendedTemplateTypeRankArgs = {
  input: SetItemRecommendedTemplateTypeRankInput;
};


export type MutationSetItemTemplatePlaceholderTemplateArgs = {
  input: SetItemTemplatePlaceholderTemplateInput;
};


export type MutationSetOrderArrivedArgs = {
  input: SetOrderArrivedInput;
};


export type MutationSetOrderItemArrivedArgs = {
  input: SetOrderItemArrivedInput;
};


export type MutationSetOrderItemDamagedDeliveryArgs = {
  input: SetOrderItemDamagedDeliveryInput;
};


export type MutationSetOrderItemDamagedOnsiteArgs = {
  input: SetOrderItemDamagedOnsiteInput;
};


export type MutationSetOrderPaidArgs = {
  input: SetOrderPaidInput;
};


export type MutationSetOrganisationMemberDefaultProjectRolesArgs = {
  input: SetOrganisationMemberDefaultProjectRolesInput;
};


export type MutationSetOrganisationMemberOrganisationRolesArgs = {
  input: SetOrganisationMemberOrganisationRolesInput;
};


export type MutationSetOrganisationPdfLetterheadArgs = {
  input: SetOrganisationPdfLetterheadInput;
};


export type MutationSetPlaceholderForTemplateTypeArgs = {
  input: SetPlaceholderForTemplateTypeInput;
};


export type MutationSetProjectAssigneeArgs = {
  membershipId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
};


export type MutationSetQuoteContracteeAddressArgs = {
  input: SetQuoteContracteeAddressInput;
};


export type MutationSetQuoteExternalIdentifierArgs = {
  input: SetQuoteExternalIdentifierInput;
};


export type MutationSetTaskItemDoneArgs = {
  input: SetTaskItemDoneInput;
};


export type MutationSetTaskItemNotDoneArgs = {
  input: SetTaskItemNotDoneInput;
};


export type MutationSetTreeHierarchyArgs = {
  input: SetTreeHierarchyInput;
};


export type MutationSetUnnestedTemplateItemDecisionContingentItemPreselectionArgs = {
  input: SetUnnestedTemplateItemDecisionContingentItemPreselectionInput;
};


export type MutationSetUnnestedTemplateItemDecisionSubitemsPreselectionArgs = {
  input: SetUnnestedTemplateItemDecisionSubitemsPreselectionInput;
};


export type MutationSetUserPreferenceArgs = {
  input: SetUserPreferenceInput;
};


export type MutationShareDocArgs = {
  input: ShareDocInput;
};


export type MutationShareQuoteTemplatePubliclyArgs = {
  input: ShareQuoteTemplatePubliclyInput;
};


export type MutationShareQuoteTemplateWithOrganisationsArgs = {
  input: ShareQuoteTemplateWithOrganisationsInput;
};


export type MutationSimulateSapS4HanaSalesQuoteFromQuoteArgs = {
  input: SimulateSapS4HanaSalesQuoteFromQuoteInput;
};


export type MutationStartProjectWorkSessionArgs = {
  input: StartProjectWorkSessionInput;
};


export type MutationSubcontractArgs = {
  input: SubcontractInput;
};


export type MutationSubmitOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationSubscribeToQuoteTemplateArgs = {
  input: SubscribeToQuoteTemplateInput;
};


export type MutationSyncProductsInQuoteArgs = {
  input: SyncProductsInQuoteInput;
};


export type MutationSyncProductsInQuoteFromGaebP94Args = {
  input: SyncProductsInQuoteFromGaebP94Input;
};


export type MutationTemplateBulkUpdateProductsArgs = {
  input: TemplateBulkUpdateProductsInput;
};


export type MutationToggleDecisionOptionPreselectionArgs = {
  input: ToggleDecisionOptionPreselectionInput;
};


export type MutationToggleOrganisationMembershipActiveArgs = {
  input: ToggleOrganisationMembershipActiveInput;
};


export type MutationUnlikeQuoteTemplateArgs = {
  input: UnlikeQuoteTemplateInput;
};


export type MutationUnlikeShopTemplateArgs = {
  input: UnlikeShopTemplateInput;
};


export type MutationUnpublishQuoteArgs = {
  input: UnpublishQuoteInput;
};


export type MutationUnpublishRequestToMarketplaceArgs = {
  input: UnpublishRequestToMarketplaceInput;
};


export type MutationUnshareDocArgs = {
  input: UnshareDocInput;
};


export type MutationUnsubscribeFromQuoteTemplateArgs = {
  input: UnsubscribeFromQuoteTemplateInput;
};


export type MutationUpdateAllLinkingItemsToTemplateToLatestAvailableVersionArgs = {
  input: UpdateAllLinkingItemsToTemplateToLatestAvailableVersionInput;
};


export type MutationUpdateLinkedItemToLatestAvailablePublishedVersionArgs = {
  input: UpdateLinkedItemToLatestAvailablePublishedVersionInput;
};


export type MutationUpdateOrganisationArgs = {
  input: OrganisationUpdateInput;
};


export type MutationUpdateOrganisationCountersArgs = {
  input: OrganisationCountersUpdateInput;
};


export type MutationUpdateOrganisationDefaultsArgs = {
  input: OrganisationDefaultsUpdateInput;
};


export type MutationUpdateOrganisationDocumentSettingsArgs = {
  input: OrganisationDocumentSettingsUpdateInput;
};


export type MutationUpdateOrganisationEmailSettingsArgs = {
  input: OrganisationEmailSettingsUpdateInput;
};


export type MutationUpdateOrganisationIntegrationArgs = {
  input: UpdateOrganisationIntegrationInput;
};


export type MutationUpdateOrganisationPdfSettingsArgs = {
  input: OrganisationPdfSettingsUpdateInput;
};


export type MutationUpdateOrganisationSettingsArgs = {
  input: OrganisationSettingsUpdateInput;
};


export type MutationUpdatePlanSessionsArgs = {
  input: UpdatePlanSessionsInput;
};


export type MutationUpdateProductPricingsInQuoteArgs = {
  input: UpdateProductPricingsInQuoteInput;
};


export type MutationUpdateSapS4HanaSalesQuoteFromQuoteArgs = {
  input: UpdateSapS4HanaSalesQuoteFromQuoteInput;
};


export type MutationUpdateUserArgs = {
  user: UserModifyInput;
};


export type MutationWithdrawRequestArgs = {
  input: WithdrawRequestInput;
};

export type OpenInvoiceInput = {
  deliveryOfServiceEnd: Scalars['Date']['input'];
  deliveryOfServiceStart: Scalars['Date']['input'];
  docId: Scalars['ID']['input'];
  dueDate: Scalars['Date']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type OpenInvoiceResult = {
  __typename: 'OpenInvoiceResult';
  invoice: Invoice;
};

export type OpenMasterdataAuthenticateInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  msOrganisationId: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type OpenMasterdataAuthenticateResult = {
  __typename: 'OpenMasterdataAuthenticateResult';
  ok: Scalars['Boolean']['output'];
};

export type OpenMasterdataAvailability = {
  __typename: 'OpenMasterdataAvailability';
  clientIdRequired: Scalars['Boolean']['output'];
  isAuthenticated: Scalars['Boolean']['output'];
  itekCompanyId: Scalars['ID']['output'];
  passwordRequired: Scalars['Boolean']['output'];
  usernameRequired: Scalars['Boolean']['output'];
};

export type OpenMasterdataUpdateSupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  msOrganisationId: Scalars['ID']['input'];
};

export type OpenMasterdataUpdateSupplierProductResult = {
  __typename: 'OpenMasterdataUpdateSupplierProductResult';
  product: Scalars['JSON']['output'];
};

export type OpportunityFlag =
  | 'CONNECTED'
  | 'DIRECT_REQUEST'
  | 'MARKETPLACE'
  | 'SELECTED';

export type OpportunityQuoteRequestResult = {
  __typename: 'OpportunityQuoteRequestResult';
  request?: Maybe<Request>;
};

export type OpportunityStatus =
  | 'INBOX'
  | 'QUOTING';

export type Order = WithContents & {
  __typename: 'Order';
  arrivalDate?: Maybe<Scalars['Date']['output']>;
  author: User;
  calculationOrdered: OrderCalculationOrdered;
  calculationPrepared: OrderCalculationPrepared;
  contents: Array<Content>;
  createdAt: Scalars['DateTime']['output'];
  crmSupplier?: Maybe<CrmCompany>;
  deliveryAddress?: Maybe<Address>;
  deliveryAddressType: DeliveryAddressType;
  dueDate?: Maybe<Scalars['Date']['output']>;
  file?: Maybe<AttachmentSnapshot>;
  id: Scalars['ID']['output'];
  idsOrderInfo?: Maybe<IdsOrderInfo>;
  items: Array<OrderItem>;
  number: Scalars['String']['output'];
  orderedDate?: Maybe<Scalars['Date']['output']>;
  ownClientId?: Maybe<Scalars['String']['output']>;
  paidDate?: Maybe<Scalars['Date']['output']>;
  paymentDueDate?: Maybe<Scalars['Date']['output']>;
  purchaseOrderTexts?: Maybe<PurchaseOrderTexts>;
  supplier?: Maybe<Company>;
  vatRate: Scalars['Float']['output'];
};


export type OrderItemsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type OrderCalculationOrdered = {
  __typename: 'OrderCalculationOrdered';
  id?: Maybe<Scalars['String']['output']>;
  priceOrdered: Scalars['Float']['output'];
  priceTotalOrdered: Scalars['Float']['output'];
  priceVatOrdered: Scalars['Float']['output'];
  quantityArrived?: Maybe<Scalars['Int']['output']>;
  quantityDamagedDelivery?: Maybe<Scalars['Int']['output']>;
  quantityDamagedOnsite?: Maybe<Scalars['Int']['output']>;
  quantityOrdered: Scalars['Int']['output'];
};

export type OrderCalculationPrepared = {
  __typename: 'OrderCalculationPrepared';
  id?: Maybe<Scalars['String']['output']>;
  priceOrdered: Scalars['Float']['output'];
  priceTotalOrdered: Scalars['Float']['output'];
  priceVatOrdered: Scalars['Float']['output'];
  quantityOrdered: Scalars['Int']['output'];
};

export type OrderConnection = {
  __typename: 'OrderConnection';
  edges: Array<OrderEdge>;
  totalCount: Scalars['Int']['output'];
};

export type OrderCreateInput = {
  crmSupplierId?: InputMaybe<Scalars['ID']['input']>;
  deliveryAddress?: InputMaybe<AddressInput>;
  deliveryAddressType?: InputMaybe<DeliveryAddressType>;
  projectId: Scalars['ID']['input'];
  vatRate: Scalars['Float']['input'];
};

export type OrderCreateResult = {
  __typename: 'OrderCreateResult';
  order: Order;
};

export type OrderEdge = {
  __typename: 'OrderEdge';
  node: OrderNode;
};

export type OrderGeneratePdfResult = {
  __typename: 'OrderGeneratePdfResult';
  file?: Maybe<AttachmentSnapshot>;
};

export type OrderItem = WithContents & {
  __typename: 'OrderItem';
  PimCustomSupplierProduct?: Maybe<PimCustomSupplierProduct>;
  contents: Array<Content>;
  id: Scalars['ID']['output'];
  order: Order;
  originProductArticleNumber?: Maybe<Scalars['String']['output']>;
  originProductSupplier?: Maybe<Company>;
  originProductSupplierId?: Maybe<Scalars['ID']['output']>;
  originQuoteItemId?: Maybe<Scalars['ID']['output']>;
  productArticleNumber?: Maybe<Scalars['String']['output']>;
  productBrand: Scalars['String']['output'];
  productBrandLine: Scalars['String']['output'];
  productCategory: Scalars['String']['output'];
  productDescription: Scalars['String']['output'];
  productGtin?: Maybe<Scalars['String']['output']>;
  productManufacturerArticleNumber?: Maybe<Scalars['String']['output']>;
  productProps2: Array<Props2NonComputed>;
  productTitle: Scalars['String']['output'];
  quantityArrived?: Maybe<Scalars['Int']['output']>;
  quantityDamagedDelivery?: Maybe<Scalars['Int']['output']>;
  quantityDamagedOnsite?: Maybe<Scalars['Int']['output']>;
  quantityOrdered: Scalars['Int']['output'];
  quantityUnit: QuantityUnit;
  quoteItems: Array<Item>;
  unitPrice: Scalars['Float']['output'];
};

export type OrderNode = {
  __typename: 'OrderNode';
  clientName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  orderedDate?: Maybe<Scalars['Date']['output']>;
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  supplierName?: Maybe<Scalars['String']['output']>;
};

export type OrderStatus =
  | 'draft'
  | 'ordered';

export type OrdersSortBy =
  | 'createdAt'
  | 'title';

export type OrdersSorting = {
  column: OrdersSortBy;
  direction: SortDirection;
};

export type Organisation = WithBillingAddress & WithBranchAddress & WithCompanyInfo & WithContactInfo & WithLogo & {
  __typename: 'Organisation';
  bankAccount: Scalars['String']['output'];
  bankCode: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  billingAddress?: Maybe<Address>;
  branchAddress?: Maybe<Address>;
  contactPerson?: Maybe<Person>;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  fullLegalName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isClientOrganisation: Scalars['Boolean']['output'];
  isCraftsmanOrganisation: Scalars['Boolean']['output'];
  isManufacturerOrganisation: Scalars['Boolean']['output'];
  isMeister: Scalars['Boolean']['output'];
  isSupplierOrganisation: Scalars['Boolean']['output'];
  logo: AttachmentSnapshot;
  managingDirector: Scalars['String']['output'];
  organisationType: OrganisationType;
  phones: Array<Phone>;
  pickupAddress?: Maybe<Address>;
  profile?: Maybe<OrganisationProfile>;
  registrationNumber: Scalars['String']['output'];
  registrationOffice: Scalars['String']['output'];
  representive?: Maybe<User>;
  skillset: Array<Skill>;
  taxNumber: Scalars['String']['output'];
  title: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
  website: Scalars['String']['output'];
};

export type OrganisationBelonging =
  | 'MY'
  | 'OTHER';

export type OrganisationContractingPdf = {
  __typename: 'OrganisationContractingPdf';
  attachment?: Maybe<AttachmentSnapshot>;
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  needInsertPriceTable: Scalars['Boolean']['output'];
  replacePages: Scalars['Int']['output'];
  replacements: Array<ContractingPdfReplacement>;
  startPage: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type OrganisationContractingPdfsFilters = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganisationContractingPdfsResult = {
  __typename: 'OrganisationContractingPdfsResult';
  contractingPdfs: Array<OrganisationContractingPdf>;
};

export type OrganisationCounter = {
  __typename: 'OrganisationCounter';
  crmOrganisationCounter: Scalars['Int']['output'];
  crmUserCounter: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  invoiceCounter: Scalars['Int']['output'];
  purchaseOrderCounter: Scalars['Int']['output'];
  quoteCounter: Scalars['Int']['output'];
  requirementCounter: Scalars['Int']['output'];
  timeReportCounter: Scalars['Int']['output'];
};

export type OrganisationCountersUpdateInput = {
  crmOrganisationCounter?: InputMaybe<Scalars['Int']['input']>;
  crmUserCounter?: InputMaybe<Scalars['Int']['input']>;
  invoiceCounter?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderCounter?: InputMaybe<Scalars['Int']['input']>;
  quoteCounter?: InputMaybe<Scalars['Int']['input']>;
  requirementCounter?: InputMaybe<Scalars['Int']['input']>;
  timeReportCounter?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganisationCountersUpdateResult = {
  __typename: 'OrganisationCountersUpdateResult';
  organisationCounters: OrganisationCounter;
};

export type OrganisationCrmOrganisationsSortBy =
  | 'createdAt'
  | 'organisationName'
  | 'organisationNumber';

export type OrganisationCrmOrganisationsSorting = {
  column: OrganisationCrmOrganisationsSortBy;
  direction: SortDirection;
};

export type OrganisationCrmUsersSortBy =
  | 'familyname'
  | 'firstname';

export type OrganisationCrmUsersSorting = {
  column: OrganisationCrmUsersSortBy;
  direction: SortDirection;
};

export type OrganisationDefaultMaterialMarginRangeInput = {
  materialMargin: Scalars['Float']['input'];
  priceFrom: Scalars['Float']['input'];
  priceTo: Scalars['Float']['input'];
};

export type OrganisationDefaults = {
  __typename: 'OrganisationDefaults';
  defaultContractType: ContractType;
  defaultEndWorkDay?: Maybe<Scalars['String']['output']>;
  defaultInvoicePaymentTermDuration: Scalars['Int']['output'];
  defaultInvoiceShowLaborCostSeparately: Scalars['Boolean']['output'];
  defaultMaterialMargin: Scalars['Float']['output'];
  defaultMaterialMarginRanges: Array<QuoteMaterialMarginRange>;
  defaultProjectDuration: Scalars['Int']['output'];
  defaultQuoteIsBinding: Scalars['Boolean']['output'];
  defaultQuoteMaterialPriceHandling: QuoteMaterialPriceHandling;
  defaultQuoteShowOfferConditions: Scalars['Boolean']['output'];
  defaultQuoteValidityDuration: Scalars['Int']['output'];
  defaultStartWorkDay?: Maybe<Scalars['String']['output']>;
  defaultTaskPhotoIsRequired: Scalars['Boolean']['output'];
  defaultTicketDuration: Scalars['Int']['output'];
  defaultVerticalSettings: Array<OrganisationVerticalSetting>;
  id: Scalars['ID']['output'];
};

export type OrganisationDefaultsUpdateInput = {
  defaultContractType?: InputMaybe<ContractType>;
  defaultEndWorkDay?: InputMaybe<Scalars['String']['input']>;
  defaultInvoicePaymentTermDuration?: InputMaybe<Scalars['Int']['input']>;
  defaultInvoiceShowLaborCostSeparately?: InputMaybe<Scalars['Boolean']['input']>;
  defaultMaterialMargin?: InputMaybe<Scalars['Float']['input']>;
  defaultMaterialMarginRanges?: InputMaybe<Array<OrganisationDefaultMaterialMarginRangeInput>>;
  defaultProjectDuration?: InputMaybe<Scalars['Int']['input']>;
  defaultQuoteIsBinding?: InputMaybe<Scalars['Boolean']['input']>;
  defaultQuoteMaterialPriceHandling?: InputMaybe<QuoteMaterialPriceHandling>;
  defaultQuoteShowOfferConditions?: InputMaybe<Scalars['Boolean']['input']>;
  defaultQuoteValidityDuration?: InputMaybe<Scalars['Int']['input']>;
  defaultStartWorkDay?: InputMaybe<Scalars['String']['input']>;
  defaultTaskPhotoIsRequired?: InputMaybe<Scalars['Boolean']['input']>;
  defaultTicketDuration?: InputMaybe<Scalars['Int']['input']>;
  defaultVerticalSettings?: InputMaybe<Array<VerticalSettingInput>>;
};

export type OrganisationDefaultsUpdateResult = {
  __typename: 'OrganisationDefaultsUpdateResult';
  organisationDefaults: OrganisationDefaults;
};

export type OrganisationDocumentSettingsUpdateInput = {
  invoiceTexts?: InputMaybe<OrganisationPdfTextsInput>;
  materialListTexts?: InputMaybe<OrganisationPdfTextsInput>;
  purchaseOrderTexts?: InputMaybe<OrganisationPdfTextsInput>;
  quoteTexts?: InputMaybe<OrganisationPdfTextsInput>;
  timeReportTexts?: InputMaybe<OrganisationPdfTextsInput>;
};

export type OrganisationDocumentSettingsUpdateResult = {
  __typename: 'OrganisationDocumentSettingsUpdateResult';
  documentSettings: DocumentSettings;
};

export type OrganisationEmailSettings = {
  __typename: 'OrganisationEmailSettings';
  fromAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  replyToAddress?: Maybe<Scalars['String']['output']>;
};

export type OrganisationEmailSettingsUpdateInput = {
  id: Scalars['ID']['input'];
  replyToAddress?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationEmailSettingsUpdateResult = {
  __typename: 'OrganisationEmailSettingsUpdateResult';
  organisationEmailSettings: OrganisationEmailSettings;
};

export type OrganisationEmailTemplate = {
  __typename: 'OrganisationEmailTemplate';
  attachments: Array<Attachment>;
  content: Scalars['String']['output'];
  context: EmailTemplateContext;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
};

export type OrganisationEmailTemplateValuesWithReplacementsResult = {
  __typename: 'OrganisationEmailTemplateValuesWithReplacementsResult';
  attachments: Array<Attachment>;
  content: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type OrganisationIntegration = {
  __typename: 'OrganisationIntegration';
  apiKey?: Maybe<Scalars['String']['output']>;
  foreignAccountId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integration: Integration;
  organisationId: Scalars['ID']['output'];
  settings: Scalars['JSON']['output'];
};

export type OrganisationIntegrationConnection = {
  __typename: 'OrganisationIntegrationConnection';
  edges: Array<OrganisationIntegrationEdge>;
  totalCount: Scalars['Int']['output'];
};

export type OrganisationIntegrationEdge = {
  __typename: 'OrganisationIntegrationEdge';
  node: OrganisationIntegration;
};

export type OrganisationIntegrationResult = {
  __typename: 'OrganisationIntegrationResult';
  organisationIntegration?: Maybe<OrganisationIntegration>;
};

export type OrganisationIntegrationsFilter = {
  integrationId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrganisationLocation = {
  __typename: 'OrganisationLocation';
  address: Address;
  id: Scalars['ID']['output'];
};

export type OrganisationMembersAvailabilityFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  excludeProjectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fromProjectId?: InputMaybe<Scalars['ID']['input']>;
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  withinRadiusKm?: InputMaybe<Scalars['Float']['input']>;
};

export type OrganisationMembershipInvitation = {
  __typename: 'OrganisationMembershipInvitation';
  acceptedByUser?: Maybe<User>;
  attachments: Array<AttachmentSnapshot>;
  author: User;
  contact: InvitationContact;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invitationToken: Scalars['String']['output'];
  invitationUrl: Scalars['String']['output'];
  messageBody: Scalars['String']['output'];
  messageSubject: Scalars['String']['output'];
  organisation: Organisation;
  organisationId: Scalars['ID']['output'];
  organisationRoles?: Maybe<Array<OrganisationRole>>;
  status: InvitationState;
  title: Scalars['String']['output'];
};

export type OrganisationMembershipsFilters = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrganisationPdfSettingsUpdateInput = {
  pdfFooterColumn1?: InputMaybe<Scalars['String']['input']>;
  pdfFooterColumn2?: InputMaybe<Scalars['String']['input']>;
  pdfFooterColumn3?: InputMaybe<Scalars['String']['input']>;
  pdfFooterVisibility?: InputMaybe<PdfVisibility>;
  pdfLogo?: InputMaybe<AttachmentInput>;
  pdfTextPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  pdfTextSecondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationPdfSettingsUpdateResult = {
  __typename: 'OrganisationPdfSettingsUpdateResult';
  pdfSettings: PdfSettings;
};

export type OrganisationPdfTexts = {
  __typename: 'OrganisationPdfTexts';
  closing?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  opening?: Maybe<Scalars['String']['output']>;
};

export type OrganisationPdfTextsInput = {
  closing?: InputMaybe<Scalars['String']['input']>;
  opening?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationPdfTextsType =
  | 'invoice'
  | 'material_list'
  | 'purchase_order'
  | 'quote'
  | 'time_report';

export type OrganisationProfile = {
  __typename: 'OrganisationProfile';
  _3d_roomShoppableTemplate: _3d_RoomShoppableTemplateResult;
  about?: Maybe<Scalars['JSON']['output']>;
  background?: Maybe<AttachmentSnapshot>;
  contactAddress?: Maybe<Address>;
  contactEmail?: Maybe<Scalars['EmailAddress']['output']>;
  contactPhones: Array<Phone>;
  contactWebsite?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  employeesNumber?: Maybe<Scalars['Int']['output']>;
  has3DShop: Scalars['Boolean']['output'];
  hasShop: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isMeister: Scalars['Boolean']['output'];
  logo?: Maybe<AttachmentSnapshot>;
  meister1Flows: Array<Meister1Flow>;
  openingHours?: Maybe<Scalars['JSON']['output']>;
  organisation: Organisation;
  organisationId: Scalars['ID']['output'];
  shoppableTemplate?: Maybe<ShoppableTemplate>;
  shoppableTemplates: ShoppableTemplateConnection;
  skillset: Array<Skill>;
  slug: Scalars['String']['output'];
  yearFounded?: Maybe<Scalars['Int']['output']>;
};


export type OrganisationProfile_3d_RoomShoppableTemplateArgs = {
  docId: Scalars['ID']['input'];
  roomRootItemId?: InputMaybe<Scalars['ID']['input']>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};


export type OrganisationProfileShoppableTemplateArgs = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};


export type OrganisationProfileShoppableTemplatesArgs = {
  filters?: InputMaybe<ShoppableTemplatesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OrganisationQuoteTemplatesSorting>>;
};

export type OrganisationProfileResult = {
  __typename: 'OrganisationProfileResult';
  profile?: Maybe<OrganisationProfile>;
  settings?: Maybe<OrganisationPublicSetting>;
};

export type OrganisationProjectPhase = {
  __typename: 'OrganisationProjectPhase';
  applicableForState: ProjectStateMachineStatus;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type OrganisationProjectsSortBy =
  | 'assignee'
  | 'buildingDistance'
  | 'buildingPlz'
  | 'buildingStreet'
  | 'categories'
  | 'createdAt'
  | 'createdBy'
  | 'deadline'
  | 'id'
  | 'minBudget'
  | 'number'
  | 'organisationEmail'
  | 'organisationName'
  | 'phase'
  | 'signedAt'
  | 'startDate'
  | 'state'
  | 'title';

export type OrganisationProjectsSorting = {
  column: OrganisationProjectsSortBy;
  direction: SortDirection;
};

export type OrganisationPublicSetting = {
  __typename: 'OrganisationPublicSetting';
  embeddableWizardIsLeadAddressRequired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type OrganisationQuoteTemplatesFilters = {
  addedToShop?: InputMaybe<Scalars['Boolean']['input']>;
  applicableFor?: InputMaybe<Array<TplApplicableFor>>;
  createdAfter?: InputMaybe<Scalars['Date']['input']>;
  createdBefore?: InputMaybe<Scalars['Date']['input']>;
  creatorOrganisationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  mineShareStatus?: InputMaybe<TemplateShareStatus>;
  priceMax?: InputMaybe<Scalars['Float']['input']>;
  priceMin?: InputMaybe<Scalars['Float']['input']>;
  rootItemTypes?: InputMaybe<Array<ItemType>>;
  sources?: InputMaybe<Array<TemplateSource>>;
  tagsAny?: InputMaybe<Array<Scalars['String']['input']>>;
  templateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrganisationQuoteTemplatesSortBy =
  | 'createdAt'
  | 'discountedPrice'
  | 'title'
  | 'totalPrice';

export type OrganisationQuoteTemplatesSorting = {
  column: OrganisationQuoteTemplatesSortBy;
  direction: SortDirection;
};

export type OrganisationRole = {
  __typename: 'OrganisationRole';
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type OrganisationSetting = {
  __typename: 'OrganisationSetting';
  billOfMaterialsExportSettings: OrganisationSettingBillOfMaterialsExportSettings;
  brandingAdOwnDashboardLeftTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdOwnDashboardRightTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdOwnDashboardSideTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdReferralDashboardLeftTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdReferralDashboardRightTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdReferralDashboardSideTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdSponsoringDashboardLeftTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdSponsoringDashboardRightTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingAdSponsoringDashboardSideTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoOwnSidebarBottom?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoOwnSidebarTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoOwnTopbarLeft?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoOwnTopbarRight?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoReferralSidebarBottom?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoReferralSidebarTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoReferralTopbarLeft?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoReferralTopbarRight?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoSponsoringSidebarBottom?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoSponsoringSidebarTop?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoSponsoringTopbarLeft?: Maybe<OrganisationSettingBrandingSlot>;
  brandingLogoSponsoringTopbarRight?: Maybe<OrganisationSettingBrandingSlot>;
  brandingThemeOwnSidebar?: Maybe<OrganisationSettingBrandingTheme>;
  brandingThemeOwnTopbar?: Maybe<OrganisationSettingBrandingTheme>;
  brandingThemeReferralSidebar?: Maybe<OrganisationSettingBrandingTheme>;
  brandingThemeReferralTopbar?: Maybe<OrganisationSettingBrandingTheme>;
  brandingThemeSponsoringSidebar?: Maybe<OrganisationSettingBrandingTheme>;
  brandingThemeSponsoringTopbar?: Maybe<OrganisationSettingBrandingTheme>;
  crmOrganisationNumberMinLength: Scalars['Int']['output'];
  crmOrganisationNumberPrefix: Scalars['String']['output'];
  crmUserNumberMinLength: Scalars['Int']['output'];
  crmUserNumberPrefix: Scalars['String']['output'];
  embeddableWizardIsLeadAddressRequired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceNumberMinLength: Scalars['Int']['output'];
  invoiceNumberPrefix: Scalars['String']['output'];
  projectCouldBeUnassigned: Scalars['Boolean']['output'];
  projectDefaultAttachments: Array<Attachment>;
  projectPhases: Array<OrganisationProjectPhase>;
  purchaseOrderNumberMinLength: Scalars['Int']['output'];
  purchaseOrderNumberPrefix: Scalars['String']['output'];
  quoteHeaderVisibility: QuoteHeaderVisibility;
  quoteIsSignatureVisible: Scalars['Boolean']['output'];
  quoteNumberMinLength: Scalars['Int']['output'];
  quoteNumberPrefix: Scalars['String']['output'];
  quoteProductsVisibility: QuoteProductsVisibility;
  quoteVatVisibility: VatVisibility;
  requirementNumberMinLength: Scalars['Int']['output'];
  requirementNumberPrefix: Scalars['String']['output'];
  timeReportNumberMinLength: Scalars['Int']['output'];
  timeReportNumberPrefix: Scalars['String']['output'];
};


export type OrganisationSettingProjectPhasesArgs = {
  filterIncludeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
};

export type OrganisationSettingBillOfMaterialsExportSettings = {
  __typename: 'OrganisationSettingBillOfMaterialsExportSettings';
  csvFields: Array<BillOfMaterialsField>;
  dataFormat: BillOfMaterialsDataFormat;
  pdfFields: Array<BillOfMaterialsField>;
  sections: Array<BillOfMaterialsSection>;
};

export type OrganisationSettingBillOfMaterialsExportSettingsInput = {
  csvFields: Array<BillOfMaterialsField>;
  dataFormat: BillOfMaterialsDataFormat;
  pdfFields: Array<BillOfMaterialsField>;
  sections: Array<BillOfMaterialsSection>;
};

export type OrganisationSettingBrandingSlot = {
  __typename: 'OrganisationSettingBrandingSlot';
  attachment?: Maybe<AttachmentSnapshot>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type OrganisationSettingBrandingSlotInput = {
  attachment?: InputMaybe<AttachmentInput>;
  enabled: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type OrganisationSettingBrandingTheme = {
  __typename: 'OrganisationSettingBrandingTheme';
  colorBackground: Scalars['String']['output'];
  colorText: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  type: BrandingThemeType;
};

export type OrganisationSettingBrandingThemeInput = {
  colorBackground: Scalars['String']['input'];
  colorText: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  type: BrandingThemeType;
};

export type OrganisationSettingsUpdateInput = {
  billOfMaterialsExportSettings?: InputMaybe<OrganisationSettingBillOfMaterialsExportSettingsInput>;
  brandingAdOwnDashboardLeftTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdOwnDashboardRightTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdOwnDashboardSideTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdReferralDashboardLeftTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdReferralDashboardRightTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdReferralDashboardSideTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdSponsoringDashboardLeftTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdSponsoringDashboardRightTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingAdSponsoringDashboardSideTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoOwnSidebarBottom?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoOwnSidebarTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoOwnTopbarLeft?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoOwnTopbarRight?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoReferralSidebarBottom?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoReferralSidebarTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoReferralTopbarLeft?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoReferralTopbarRight?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoSponsoringSidebarBottom?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoSponsoringSidebarTop?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoSponsoringTopbarLeft?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingLogoSponsoringTopbarRight?: InputMaybe<OrganisationSettingBrandingSlotInput>;
  brandingThemeOwnSidebar?: InputMaybe<OrganisationSettingBrandingThemeInput>;
  brandingThemeOwnTopbar?: InputMaybe<OrganisationSettingBrandingThemeInput>;
  brandingThemeReferralSidebar?: InputMaybe<OrganisationSettingBrandingThemeInput>;
  brandingThemeReferralTopbar?: InputMaybe<OrganisationSettingBrandingThemeInput>;
  brandingThemeSponsoringSidebar?: InputMaybe<OrganisationSettingBrandingThemeInput>;
  brandingThemeSponsoringTopbar?: InputMaybe<OrganisationSettingBrandingThemeInput>;
  crmOrganisationNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  crmOrganisationNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  crmUserNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  crmUserNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  embeddableWizardIsLeadAddressRequired?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  invoiceNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  projectCouldBeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  projectDefaultAttachments?: InputMaybe<Array<AttachmentInputWithId>>;
  purchaseOrderNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  quoteHeaderVisibility?: InputMaybe<QuoteHeaderVisibilityInput>;
  quoteIsSignatureVisible?: InputMaybe<Scalars['Boolean']['input']>;
  quoteNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  quoteNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  quoteProductsVisibility?: InputMaybe<QuoteProductsVisibilityInput>;
  quoteVatVisibility?: InputMaybe<VatVisibility>;
  requirementNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  requirementNumberPrefix?: InputMaybe<Scalars['String']['input']>;
  timeReportNumberMinLength?: InputMaybe<Scalars['Int']['input']>;
  timeReportNumberPrefix?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationSettingsUpdateResult = {
  __typename: 'OrganisationSettingsUpdateResult';
  organisationSettings: OrganisationSetting;
};

export type OrganisationTagsFilter = {
  group?: InputMaybe<TagGroup>;
};

export type OrganisationType =
  | 'CLIENT'
  | 'CRAFTSMAN'
  | 'MANUFACTURER'
  | 'SUPPLIER';

export type OrganisationUpdateInput = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankCode?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  billingAddress?: InputMaybe<AddressInput>;
  branchAddress?: InputMaybe<AddressInput>;
  brandColor?: InputMaybe<Scalars['String']['input']>;
  brandLogo?: InputMaybe<AttachmentInput>;
  brandTextColor?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['EmailAddress']['input']>;
  fullLegalName?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<AttachmentInput>;
  managingDirector?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['ID']['input']>;
  phones?: InputMaybe<Array<PhoneInput>>;
  pickupAddress?: InputMaybe<AddressInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  registrationOffice?: InputMaybe<Scalars['String']['input']>;
  representiveId?: InputMaybe<Scalars['ID']['input']>;
  skillset?: InputMaybe<Array<Skill>>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationUpdateResult = {
  __typename: 'OrganisationUpdateResult';
  organisation: ViewerOrganisation;
};

export type OrganisationVerticalSetting = {
  __typename: 'OrganisationVerticalSetting';
  averageWorkBuyingPrice: Scalars['Float']['output'];
  averageWorkSellingPrice: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  vertical: Vertical;
};

export type OutboundInvitationFilterType =
  | 'CONNECT'
  | 'ORG_MEMBER'
  | 'PROJECT_CLIENT';

export type OutgoingInvoice = {
  __typename: 'OutgoingInvoice';
  clientName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  issueDate?: Maybe<Scalars['Date']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  projectTitle: Scalars['String']['output'];
  status: InvoiceStatus;
  title: Scalars['String']['output'];
  totalGrossPrice: Scalars['Float']['output'];
  totalNetPrice: Scalars['Float']['output'];
  type: InvoiceType;
};

export type OutgoingInvoiceConnection = {
  __typename: 'OutgoingInvoiceConnection';
  edges: Array<OutgoingInvoiceEdge>;
  totalAmountOpen: Scalars['Float']['output'];
  totalAmountOverdue: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
};

export type OutgoingInvoiceEdge = {
  __typename: 'OutgoingInvoiceEdge';
  node: OutgoingInvoice;
};

export type OutgoingInvoicesFilters = {
  crmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InvoiceStatus>>;
};

export type OutgoingInvoicesSortBy =
  | 'createdAt'
  | 'number'
  | 'title';

export type OutgoingInvoicesSorting = {
  column: OutgoingInvoicesSortBy;
  direction: SortDirection;
};

export type OutgoingQuote = {
  __typename: 'OutgoingQuote';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agreedTotalPrice?: Maybe<Scalars['Float']['output']>;
  agreement: Agreement;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  docActorClientName: Scalars['String']['output'];
  docActorContracteeName: Scalars['String']['output'];
  docActorContractorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isBinding: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  needsAgreement: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  proposedTotalPrice: Scalars['Float']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  request?: Maybe<Request>;
  rootItemId: Scalars['ID']['output'];
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  xStatus?: Maybe<Scalars['String']['output']>;
};

export type OutgoingQuoteConnection = {
  __typename: 'OutgoingQuoteConnection';
  edges: Array<OutgoingQuoteEdge>;
  totalCount: Scalars['Int']['output'];
};

export type OutgoingQuoteEdge = {
  __typename: 'OutgoingQuoteEdge';
  node: OutgoingQuote;
};

export type OutgoingQuoteResult = {
  __typename: 'OutgoingQuoteResult';
  quote?: Maybe<Quote>;
};

export type OutgoingQuotesFilters = {
  agreement?: InputMaybe<Agreement>;
  docActors?: InputMaybe<Array<DocActorFilterInput>>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  isChangePending?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  isSapQuote?: InputMaybe<Scalars['Boolean']['input']>;
  model?: InputMaybe<FilterModelInput>;
  projectExcludeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  projectIncludeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  xStatus?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OutgoingQuotesSortBy =
  | 'acceptedAt'
  | 'agreedTotalPrice'
  | 'createdAt'
  | 'createdBy'
  | 'docActorClientName'
  | 'docActorContracteeName'
  | 'number'
  | 'projectNumber'
  | 'proposedTotalPrice'
  | 'publishedAt'
  | 'title';

export type OutgoingQuotesSorting = {
  column: OutgoingQuotesSortBy;
  direction: SortDirection;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PasteItemInput = {
  destDocId: Scalars['ID']['input'];
  destItemId: Scalars['ID']['input'];
  destProjectId?: InputMaybe<Scalars['ID']['input']>;
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceDocId: Scalars['ID']['input'];
  sourceItemId: Scalars['ID']['input'];
  sourceProjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type PasteItemResult = {
  __typename: 'PasteItemResult';
  doc: Document;
  items: Array<Item>;
  ok: Scalars['Boolean']['output'];
  parentItem: Item;
  parentPathItems: Array<Item>;
};

export type PayInvoiceInput = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: PayInvoiceInputValues;
};

export type PayInvoiceInputValues = {
  paidAt: Scalars['Date']['input'];
};

export type PayInvoiceResult = {
  __typename: 'PayInvoiceResult';
  invoice: Invoice;
};

export type PdfLetterhead = {
  __typename: 'PdfLetterhead';
  attachment: PdfLetterheadAttachment;
  settings: PdfLetterheadSettings;
};

export type PdfLetterheadAttachment = {
  __typename: 'PdfLetterheadAttachment';
  url: Scalars['String']['output'];
};

export type PdfLetterheadSettings = {
  __typename: 'PdfLetterheadSettings';
  marginBottom: Scalars['Int']['output'];
  marginTopFirstPage: Scalars['Int']['output'];
  marginTopSubsequentPage: Scalars['Int']['output'];
  textPrimaryColor: Scalars['String']['output'];
  textSecondaryColor: Scalars['String']['output'];
};

export type PdfSettings = {
  __typename: 'PdfSettings';
  id: Scalars['ID']['output'];
  pdfFooterColumn1: Scalars['String']['output'];
  pdfFooterColumn2: Scalars['String']['output'];
  pdfFooterColumn3: Scalars['String']['output'];
  pdfFooterVisibility: PdfVisibility;
  pdfLetterhead?: Maybe<PdfLetterhead>;
  pdfLogo?: Maybe<AttachmentSnapshot>;
  pdfTextPrimaryColor: Scalars['String']['output'];
  pdfTextSecondaryColor: Scalars['String']['output'];
};

export type PdfVisibility =
  | 'all_pages'
  | 'first_page'
  | 'no';

export type PendingDocShare = {
  __typename: 'PendingDocShare';
  docOwningSystemOrganisationId: Scalars['ID']['output'];
  docOwningSystemOrganisationTitle: Scalars['String']['output'];
  docTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recipientPermissions: Array<PermissionName>;
  recipientSystemOrganisationId: Scalars['ID']['output'];
  recipientSystemOrganisationTitle: Scalars['String']['output'];
  sharingSystemOrganisationId: Scalars['ID']['output'];
  sharingSystemOrganisationTitle: Scalars['String']['output'];
};

export type Permission = {
  __typename: 'Permission';
  id: Scalars['ID']['output'];
  internalName: PermissionName;
  label: PermissionName;
};

export type PermissionName =
  | 'ADD_MEMBER_TO_ORG'
  | 'ADD_PROJECT_MEMBER'
  | 'APPLY_TEMPLATE'
  | 'CREATE_PROJECT'
  | 'CREATE_TASK'
  | 'DECIDE_QUOTES'
  | 'EXECUTE_TASK'
  | 'MANAGE_CRM'
  | 'MANAGE_GLOBAL_PIM'
  | 'MANAGE_GLOBAL_SUPPLIER'
  | 'MANAGE_GLOBAL_TEMPLATES'
  | 'MANAGE_INVOICES'
  | 'MANAGE_INVOICES_SETTINGS'
  | 'MANAGE_MY_BUILDINGS'
  | 'MANAGE_ORG'
  | 'MANAGE_PROJECT'
  | 'MANAGE_QUOTES'
  | 'MANAGE_QUOTES_ACTORS'
  | 'MANAGE_QUOTES_SETTINGS'
  | 'MANAGE_REQUIREMENTS'
  | 'MANAGE_REQUIREMENTS_ACTORS'
  | 'MANAGE_REQUIREMENTS_SETTINGS'
  | 'MANAGE_TASK'
  | 'MANAGE_TEMPLATE'
  | 'MANAGE_TEMPLATE_SETTINGS'
  | 'READ_CRM'
  | 'READ_INVOICES'
  | 'READ_PROJECT'
  | 'READ_PROJECT_MEMBERS'
  | 'READ_QUOTES'
  | 'READ_REQUIREMENTS'
  | 'READ_TEMPLATE'
  | 'SEE_QUOTES_CALCULATION'
  | 'SEE_QUOTES_HIDDEN_ELEMENTS'
  | 'SEE_QUOTES_TASKS'
  | 'SEE_REQUIREMENTS_CALCULATION'
  | 'SEE_REQUIREMENTS_HIDDEN_ELEMENTS'
  | 'SEE_REQUIREMENTS_TASKS'
  | 'SHARE_INVOICES'
  | 'SHARE_QUOTES'
  | 'SHARE_REQUIREMENTS'
  | 'SHARE_TEMPLATE'
  | 'TAKE_QUOTES_DECISION'
  | 'TAKE_REQUIREMENTS_DECISION';

export type PermissionPolicy =
  | 'ADD_MEMBER_TO_ORG'
  | 'ADD_PROJECT_MEMBER'
  | 'APPLY_TEMPLATE'
  | 'CREATE_PROJECT'
  | 'CREATE_TASK'
  | 'DECIDE_QUOTES'
  | 'EXECUTE_TASK'
  | 'IFBUILDING_MANAGE_ORG'
  | 'IFQUOTE_MANAGE_QUOTES'
  | 'IFQUOTE_READ_QUOTES'
  | 'IFQUOTE_TAKE_QUOTES_DECISION'
  | 'IFREQUIREMENT_MANAGE_REQUIREMENTS'
  | 'IFREQUIREMENT_READ_REQUIREMENTS'
  | 'IFREQUIREMENT_TAKE_REQUIREMENTS_DECISION'
  | 'IFTEMPLATE_MANAGE_TEMPLATE'
  | 'MANAGE_CRM'
  | 'MANAGE_GLOBAL_PIM'
  | 'MANAGE_GLOBAL_SUPPLIER'
  | 'MANAGE_GLOBAL_TEMPLATES'
  | 'MANAGE_INVOICES'
  | 'MANAGE_INVOICES_SETTINGS'
  | 'MANAGE_MY_BUILDINGS'
  | 'MANAGE_ORG'
  | 'MANAGE_PROJECT'
  | 'MANAGE_QUOTES'
  | 'MANAGE_QUOTES_ACTORS'
  | 'MANAGE_QUOTES_SETTINGS'
  | 'MANAGE_REQUIREMENTS'
  | 'MANAGE_REQUIREMENTS_ACTORS'
  | 'MANAGE_REQUIREMENTS_SETTINGS'
  | 'MANAGE_TASK'
  | 'MANAGE_TEMPLATE'
  | 'MANAGE_TEMPLATE_SETTINGS'
  | 'READ_CRM'
  | 'READ_INVOICES'
  | 'READ_PROJECT'
  | 'READ_PROJECT_MEMBERS'
  | 'READ_QUOTES'
  | 'READ_REQUIREMENTS'
  | 'READ_TEMPLATE'
  | 'SEE_QUOTES_CALCULATION'
  | 'SEE_QUOTES_HIDDEN_ELEMENTS'
  | 'SEE_QUOTES_TASKS'
  | 'SEE_REQUIREMENTS_CALCULATION'
  | 'SEE_REQUIREMENTS_HIDDEN_ELEMENTS'
  | 'SEE_REQUIREMENTS_TASKS'
  | 'SHARE_INVOICES'
  | 'SHARE_QUOTES'
  | 'SHARE_REQUIREMENTS'
  | 'SHARE_TEMPLATE'
  | 'TAKE_QUOTES_DECISION'
  | 'TAKE_REQUIREMENTS_DECISION';

export type PermissionScope =
  | 'building'
  | 'doc'
  | 'invoice'
  | 'organisation'
  | 'project'
  | 'quote'
  | 'requirement'
  | 'template';

export type Person = WithAvatar & WithContactInfo & WithPersonInfo & {
  __typename: 'Person';
  avatar: AttachmentSnapshot;
  company: Company;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMeister: Scalars['Boolean']['output'];
  locale: Scalars['String']['output'];
  phones: Array<Phone>;
  skillset: Array<Skill>;
  title: Salutation;
  website?: Maybe<Scalars['String']['output']>;
};

export type Phone = {
  __typename: 'Phone';
  id: Scalars['ID']['output'];
  main: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  type: PhoneType;
};

export type PhoneInput = {
  main: Scalars['Boolean']['input'];
  number: Scalars['String']['input'];
  type: PhoneType;
};

export type PhoneType =
  | 'HOME'
  | 'HOTLINE'
  | 'MOBILE'
  | 'PRIVATE'
  | 'WORK';

export type PhotoApproveTaskItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  photo: AttachmentInput;
  projectId: Scalars['ID']['input'];
};

export type PhotoApproveTaskItemResult = {
  __typename: 'PhotoApproveTaskItemResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
};

export type PhotoDisapproveTaskItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type PhotoDisapproveTaskItemResult = {
  __typename: 'PhotoDisapproveTaskItemResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
};

export type PimAddSupplierDiscountGroupImportationConfigurationFieldMappingInput = {
  _multiplier: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type PimAddSupplierDiscountGroupImportationCsvConfigurationsInput = {
  decimal: Scalars['String']['input'];
  delimiter: Scalars['String']['input'];
  fieldMapping: PimAddSupplierDiscountGroupImportationConfigurationFieldMappingInput;
};

export type PimAddSupplierDiscountGroupImportationInput = {
  csvConfigurations: PimAddSupplierDiscountGroupImportationCsvConfigurationsInput;
  filename: Scalars['String']['input'];
  supplierDiscountGroupVariantId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PimAddSupplierDiscountGroupImportationResult = {
  __typename: 'PimAddSupplierDiscountGroupImportationResult';
  supplierDiscountGroupImportation: PimSupplierDiscountGroupImportation;
};

export type PimAddSupplierProductViewedInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimAddSupplierProductViewedResult = {
  __typename: 'PimAddSupplierProductViewedResult';
  ok: Scalars['Boolean']['output'];
};

export type PimAssignManufacturerInput = {
  manufacturerId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type PimAssignManufacturerProductInput = {
  articleNumber: Scalars['String']['input'];
  manufacturerProductId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimAssignSupplierProductsProductTypeInput = {
  productTypeId: Scalars['ID']['input'];
  supplierProductArticleNumbers: Array<PimSupplierProductArticleNumberInput>;
};

export type PimAssignSupplierProductsProductTypeResult = {
  __typename: 'PimAssignSupplierProductsProductTypeResult';
  ok: Scalars['Boolean']['output'];
};

export type PimAvailableFilterBrand = {
  __typename: 'PimAvailableFilterBrand';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type PimAvailableFilterBrandLine = {
  __typename: 'PimAvailableFilterBrandLine';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type PimAvailableFilterManufacturer = {
  __typename: 'PimAvailableFilterManufacturer';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  organisation: Company;
};

export type PimAvailableFilterProductType = {
  __typename: 'PimAvailableFilterProductType';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  productType: PimProductTypeRef;
};

export type PimAvailableFilterPropertyBool = {
  __typename: 'PimAvailableFilterPropertyBool';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  values: Array<PimAvailableFilterPropertyValueBool>;
};

export type PimAvailableFilterPropertyNumber = {
  __typename: 'PimAvailableFilterPropertyNumber';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  values: Array<PimAvailableFilterPropertyValueNumber>;
};

export type PimAvailableFilterPropertyText = {
  __typename: 'PimAvailableFilterPropertyText';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  values: Array<PimAvailableFilterPropertyValueText>;
};

export type PimAvailableFilterPropertyValueBool = {
  __typename: 'PimAvailableFilterPropertyValueBool';
  count: Scalars['Int']['output'];
  value: Scalars['Boolean']['output'];
};

export type PimAvailableFilterPropertyValueNumber = {
  __typename: 'PimAvailableFilterPropertyValueNumber';
  count: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type PimAvailableFilterPropertyValueText = {
  __typename: 'PimAvailableFilterPropertyValueText';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type PimAvailableFilterRangeNumber = {
  __typename: 'PimAvailableFilterRangeNumber';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type PimAvailableFilterSupplier = {
  __typename: 'PimAvailableFilterSupplier';
  count: Scalars['Int']['output'];
  crmCompany?: Maybe<CrmCompany>;
  id: Scalars['ID']['output'];
  organisation: Company;
};

export type PimAvailableFilterSupplierProductCategory = {
  __typename: 'PimAvailableFilterSupplierProductCategory';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  supplierId: Scalars['ID']['output'];
  supplierProductCategory: PimProductCategory;
};

export type PimBoolProperty = {
  __typename: 'PimBoolProperty';
  clientVisibility: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  valueBool?: Maybe<Scalars['Boolean']['output']>;
};

export type PimBrandLineRef = {
  __typename: 'PimBrandLineRef';
  brand: PimBrandRef;
  brandId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  mainBrandLine?: Maybe<PimMainBrandLineRef>;
  title: Scalars['String']['output'];
};

export type PimBrandRef = {
  __typename: 'PimBrandRef';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PimCreateProductInput = {
  articleNumber: Scalars['String']['input'];
  netPrice: Scalars['Float']['input'];
  supplierId: Scalars['ID']['input'];
  texts?: InputMaybe<PimTextsInput>;
};

export type PimCreateSupplierDiscountGroupVariantInput = {
  supplierId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type PimCreateSupplierDiscountGroupVariantResult = {
  __typename: 'PimCreateSupplierDiscountGroupVariantResult';
  supplierDiscountGroupVariant: PimSupplierDiscountGroupVariant;
};

export type PimCreateSupplierProductBrandingInput = {
  brandId: Scalars['ID']['input'];
  brandLineId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimCreateSupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  attachments: Array<AttachmentInput>;
  branding?: InputMaybe<PimCreateSupplierProductBrandingInput>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  manufacturerArticleNumber?: InputMaybe<Scalars['String']['input']>;
  pricing?: InputMaybe<PimProductPricingInput>;
  productTypeId?: InputMaybe<Scalars['ID']['input']>;
  properties: Array<PimProductPropertyInput>;
  supplierCatalogueId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
  supplierProductCategoryId?: InputMaybe<Scalars['ID']['input']>;
  texts: PimProductTextsInput;
  threedModelId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimCreateSupplierProductResult = {
  __typename: 'PimCreateSupplierProductResult';
  supplierProduct: PimSupplierProduct;
};

export type PimCreateThreedModelInput = {
  filename: Scalars['String']['input'];
};

export type PimCreateThreedModelResult = {
  __typename: 'PimCreateThreedModelResult';
  threedModel: PimThreedModel;
};

export type PimCurrentSuppliersResult = {
  __typename: 'PimCurrentSuppliersResult';
  suppliers: Array<Company>;
};

export type PimCustomSupplierProduct = Product & {
  __typename: 'PimCustomSupplierProduct';
  articleNumber: Scalars['String']['output'];
  attachments: Array<AttachmentSnapshot>;
  branding?: Maybe<PimProductBranding>;
  category: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturerArticleNumber?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  organisationCanEdit: Scalars['Boolean']['output'];
  organisationFavourite: Scalars['Boolean']['output'];
  organisationProperties: Array<PimProductProperty>;
  pricing?: Maybe<PimPricing>;
  productGroup?: Maybe<ProductGroup>;
  productType?: Maybe<PimProductTypeRef>;
  propertiesExtractedAt?: Maybe<Scalars['Date']['output']>;
  supplier: Company;
  supplierId: Scalars['ID']['output'];
  supplierProductProperties: Array<PimProductProperty>;
  texts?: Maybe<PimProductTexts>;
  threedModel?: Maybe<PimThreedModel>;
};

export type PimCustomSupplierProductSearchResult = ProductSearchResult & {
  __typename: 'PimCustomSupplierProductSearchResult';
  articleNumber: Scalars['String']['output'];
  attachments: Array<AttachmentSnapshot>;
  brand?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  organisationFavourite: Scalars['Boolean']['output'];
  pricing?: Maybe<PimPricing>;
  supplier: Company;
  supplierId: Scalars['ID']['output'];
  texts?: Maybe<PimProductTexts>;
};

export type PimDeleteCustomSupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimDeleteCustomSupplierProductResult = {
  __typename: 'PimDeleteCustomSupplierProductResult';
  product: PimCustomSupplierProduct;
};

export type PimDeleteSupplierDiscountGroupVariantInput = {
  supplierDiscountGroupVariantId: Scalars['ID']['input'];
};

export type PimDeleteSupplierDiscountGroupVariantResult = {
  __typename: 'PimDeleteSupplierDiscountGroupVariantResult';
  ok: Scalars['Boolean']['output'];
};

export type PimDeleteSupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimDeleteSupplierProductResult = {
  __typename: 'PimDeleteSupplierProductResult';
  supplierProduct: PimSupplierProduct;
};

export type PimDiscountGroup = {
  __typename: 'PimDiscountGroup';
  description: Scalars['String']['output'];
  discountType: PimDiscountGroupDiscountType;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type PimDiscountGroupDiscountType =
  | 'DISCOUNT'
  | 'FACTOR'
  | 'SURCHARGE';

export type PimExtractProductPropertiesInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimGetAvailableSupplierProductCategoryRootsResult = {
  __typename: 'PimGetAvailableSupplierProductCategoryRootsResult';
  result: Array<PimSupplierProductCategoryRoots>;
};

export type PimGetProductResult = {
  __typename: 'PimGetProductResult';
  product?: Maybe<Product>;
};

export type PimGetSupplierCatalogueResult = {
  __typename: 'PimGetSupplierCatalogueResult';
  supplierCatalogue: PimSupplierCatalogue;
};

export type PimGetSupplierCataloguesResult = {
  __typename: 'PimGetSupplierCataloguesResult';
  supplierCatalogues: Array<PimSupplierCatalogue>;
};

export type PimGetSupplierDiscountGroupProductsResult = {
  __typename: 'PimGetSupplierDiscountGroupProductsResult';
  products: Array<Product>;
  totalCount: Scalars['Int']['output'];
};

export type PimGetSupplierDiscountGroupsFilters = {
  supplierDiscountGroupVariantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PimGetSupplierDiscountGroupsResult = {
  __typename: 'PimGetSupplierDiscountGroupsResult';
  discountGroups: Array<PimDiscountGroup>;
  totalCount: Scalars['Int']['output'];
};

export type PimGetSupplierProductResult = {
  __typename: 'PimGetSupplierProductResult';
  supplierProduct?: Maybe<PimSupplierProduct>;
};

export type PimGetSupplierProductSetInfoResult = {
  __typename: 'PimGetSupplierProductSetInfoResult';
  supplierProductSetInfo: PimSupplierProductSetInfo;
};

export type PimGetThreedModelResult = {
  __typename: 'PimGetThreedModelResult';
  threedModel?: Maybe<PimThreedModel>;
};

export type PimImportation = {
  __typename: 'PimImportation';
  completionPercentage: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  importCompletionPercentage: Scalars['Float']['output'];
  isProcessing: Scalars['Boolean']['output'];
  searchIndexCompletionPercentage: Scalars['Float']['output'];
  status: PimImportationStatus;
  totalProcessedProducts?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export type PimImportationStatus =
  | 'deleted'
  | 'deleting'
  | 'failed'
  | 'has_duplicate'
  | 'indexed'
  | 'indexing'
  | 'to_delete'
  | 'to_index';

export type PimImportationsFilters = {
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimImportationsResult = {
  __typename: 'PimImportationsResult';
  importations: Array<PimImportation>;
};

export type PimMainBrandLineRef = {
  __typename: 'PimMainBrandLineRef';
  brand: PimBrandRef;
  brandId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PimManufacturerProduct = {
  __typename: 'PimManufacturerProduct';
  articleNumber: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturer?: Maybe<Company>;
  productType?: Maybe<PimProductTypeRef>;
  properties: Array<PimProductProperty>;
  texts: PimManufacturerProductTexts;
};

export type PimManufacturerProductImportationType =
  | 'dqr_xml';

export type PimManufacturerProductTexts = {
  __typename: 'PimManufacturerProductTexts';
  brand?: Maybe<Scalars['String']['output']>;
  descriptionText?: Maybe<Scalars['String']['output']>;
  dimensionText?: Maybe<Scalars['String']['output']>;
  extendedDescriptionText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  languageKey?: Maybe<LanguageKey>;
  marketingText?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  titleText: Scalars['String']['output'];
};

export type PimModifySupplierDiscountGroupArgsInput = {
  value: Scalars['Float']['input'];
};

export type PimModifySupplierDiscountGroupInput = {
  args: PimModifySupplierDiscountGroupArgsInput;
  discountGroupId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimModifySupplierDiscountGroupResult = {
  __typename: 'PimModifySupplierDiscountGroupResult';
  discountGroup: PimDiscountGroup;
};

export type PimModifySupplierProductBrandingInput = {
  brandId: Scalars['ID']['input'];
  brandLineId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimModifySupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  product: PimModifySupplierProductProductInput;
  supplierId: Scalars['ID']['input'];
};

export type PimModifySupplierProductProductInput = {
  attachments: Array<AttachmentInput>;
  branding?: InputMaybe<PimModifySupplierProductBrandingInput>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  manufacturerArticleNumber?: InputMaybe<Scalars['String']['input']>;
  pricing?: InputMaybe<PimProductPricingInput>;
  productTypeId?: InputMaybe<Scalars['ID']['input']>;
  properties: Array<PimProductPropertyInput>;
  supplierCatalogueId: Scalars['ID']['input'];
  supplierProductCategoryId?: InputMaybe<Scalars['ID']['input']>;
  texts: PimProductTextsInput;
  threedModelId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimModifySupplierProductResult = {
  __typename: 'PimModifySupplierProductResult';
  supplierProduct: PimSupplierProduct;
};

export type PimNumberProperty = {
  __typename: 'PimNumberProperty';
  clientVisibility: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  valueNumber?: Maybe<Scalars['Float']['output']>;
};

export type PimPricing = {
  __typename: 'PimPricing';
  currency?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  discountGroup?: Maybe<PimDiscountGroup>;
  discountGroupKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  listPrice?: Maybe<Scalars['Float']['output']>;
  netPrice?: Maybe<Scalars['Float']['output']>;
  priceIndicator?: Maybe<Scalars['Float']['output']>;
  priceUnit?: Maybe<Scalars['String']['output']>;
  priceUnitAmount?: Maybe<Scalars['Float']['output']>;
  purchasePrice?: Maybe<Scalars['Float']['output']>;
};

export type PimProductAlternativePropertyValue = PimProductAlternativePropertyValueBool | PimProductAlternativePropertyValueNumber | PimProductAlternativePropertyValueRange | PimProductAlternativePropertyValueText;

export type PimProductAlternativePropertyValueBool = {
  __typename: 'PimProductAlternativePropertyValueBool';
  valueBool: Scalars['Boolean']['output'];
};

export type PimProductAlternativePropertyValueNumber = {
  __typename: 'PimProductAlternativePropertyValueNumber';
  unit?: Maybe<Scalars['String']['output']>;
  valueNumber: Scalars['Float']['output'];
};

export type PimProductAlternativePropertyValueRange = {
  __typename: 'PimProductAlternativePropertyValueRange';
  unit?: Maybe<Scalars['String']['output']>;
  valueRange: PimPropertyValueRange;
};

export type PimProductAlternativePropertyValueText = {
  __typename: 'PimProductAlternativePropertyValueText';
  valueText: Scalars['String']['output'];
};

export type PimProductBranding = {
  __typename: 'PimProductBranding';
  brand: PimBrandRef;
  brandLine?: Maybe<PimBrandLineRef>;
};

export type PimProductCategory = {
  __typename: 'PimProductCategory';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  supplierId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PimProductCategoryTree = {
  __typename: 'PimProductCategoryTree';
  children: Array<PimProductCategory>;
  parents: Array<PimProductCategory>;
  productCategory?: Maybe<PimProductCategory>;
};

export type PimProductCategoryTreeWithSupplier = {
  __typename: 'PimProductCategoryTreeWithSupplier';
  supplier?: Maybe<Company>;
  tree: PimProductCategoryTree;
};

export type PimProductMetaInfo = {
  __typename: 'PimProductMetaInfo';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdByOrganisation?: Maybe<Company>;
  createdByUser?: Maybe<Person>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  importation?: Maybe<ProductMetaInfoImportation>;
};

export type PimProductPricingInput = {
  currency: Scalars['String']['input'];
  discountGroupKey?: InputMaybe<Scalars['String']['input']>;
  listPrice: Scalars['Float']['input'];
  netPrice?: InputMaybe<Scalars['Float']['input']>;
  priceUnit: Scalars['String']['input'];
  priceUnitAmount: Scalars['Float']['input'];
};

export type PimProductProperty = PimProductPropertyBool | PimProductPropertyBoolTyped | PimProductPropertyNumber | PimProductPropertyNumberTyped | PimProductPropertyRange | PimProductPropertyRangeTyped | PimProductPropertyText | PimProductPropertyTextTyped;

export type PimProductPropertyBool = {
  __typename: 'PimProductPropertyBool';
  pimProperty: PimBoolProperty;
  prop2: Props2Bool;
};

export type PimProductPropertyBoolInput = {
  clientVisibility: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  valueBool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PimProductPropertyBoolTyped = {
  __typename: 'PimProductPropertyBoolTyped';
  pimProperty: PimTypedBoolProperty;
  prop2: Props2Bool;
};

export type PimProductPropertyBoolTypedInput = {
  clientVisibility: Scalars['Boolean']['input'];
  productTypeKey: Scalars['String']['input'];
  propertyTypeKey: Scalars['String']['input'];
  typeSystem: PimProductTypeSystemSystemType;
  valueBool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PimProductPropertyInput = {
  bool?: InputMaybe<PimProductPropertyBoolInput>;
  boolTyped?: InputMaybe<PimProductPropertyBoolTypedInput>;
  number?: InputMaybe<PimProductPropertyNumberInput>;
  numberTyped?: InputMaybe<PimProductPropertyNumberTypedInput>;
  range?: InputMaybe<PimProductPropertyRangeInput>;
  rangeTyped?: InputMaybe<PimProductPropertyRangeTypedInput>;
  text?: InputMaybe<PimProductPropertyTextInput>;
  textTyped?: InputMaybe<PimProductPropertyTextTypedInput>;
};

export type PimProductPropertyNumber = {
  __typename: 'PimProductPropertyNumber';
  pimProperty: PimNumberProperty;
  prop2: Props2Number;
};

export type PimProductPropertyNumberInput = {
  clientVisibility: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type PimProductPropertyNumberTyped = {
  __typename: 'PimProductPropertyNumberTyped';
  pimProperty: PimTypedNumberProperty;
  prop2: Props2Number;
};

export type PimProductPropertyNumberTypedInput = {
  clientVisibility: Scalars['Boolean']['input'];
  productTypeKey: Scalars['String']['input'];
  propertyTypeKey: Scalars['String']['input'];
  typeSystem: PimProductTypeSystemSystemType;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type PimProductPropertyRange = {
  __typename: 'PimProductPropertyRange';
  pimProperty: PimRangeProperty;
  prop2: Props2Number;
};

export type PimProductPropertyRangeInput = {
  clientVisibility: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  valueRange?: InputMaybe<PimPropertyValueRangeInput>;
};

export type PimProductPropertyRangeTyped = {
  __typename: 'PimProductPropertyRangeTyped';
  pimProperty: PimTypedRangeProperty;
  prop2: Props2Number;
};

export type PimProductPropertyRangeTypedInput = {
  clientVisibility: Scalars['Boolean']['input'];
  productTypeKey: Scalars['String']['input'];
  propertyTypeKey: Scalars['String']['input'];
  typeSystem: PimProductTypeSystemSystemType;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueRange?: InputMaybe<PimPropertyValueRangeInput>;
};

export type PimProductPropertyText = {
  __typename: 'PimProductPropertyText';
  pimProperty: PimTextProperty;
  prop2: Props2Text;
};

export type PimProductPropertyTextInput = {
  allowedValuesText: Array<Scalars['String']['input']>;
  clientVisibility: Scalars['Boolean']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  valueText?: InputMaybe<Scalars['String']['input']>;
};

export type PimProductPropertyTextTyped = {
  __typename: 'PimProductPropertyTextTyped';
  pimProperty: PimTypedTextProperty;
  prop2: Props2Text;
};

export type PimProductPropertyTextTypedInput = {
  clientVisibility: Scalars['Boolean']['input'];
  productTypeKey: Scalars['String']['input'];
  propertyTypeKey: Scalars['String']['input'];
  propertyTypeValueKey?: InputMaybe<Scalars['String']['input']>;
  typeSystem: PimProductTypeSystemSystemType;
};

export type PimProductTexts = {
  __typename: 'PimProductTexts';
  clientDescriptionText: Scalars['String']['output'];
  clientTitleText: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dimensionText?: Maybe<Scalars['String']['output']>;
  extendedDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  marketingText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PimProductTextsInput = {
  descriptionText?: InputMaybe<Scalars['String']['input']>;
  dimensionText?: InputMaybe<Scalars['String']['input']>;
  extendedDescriptionText?: InputMaybe<Scalars['String']['input']>;
  marketingText?: InputMaybe<Scalars['String']['input']>;
  titleText: Scalars['String']['input'];
};

export type PimProductThreedModelInput = {
  filename: Scalars['String']['input'];
};

export type PimProductType = {
  __typename: 'PimProductType';
  description?: Maybe<Scalars['String']['output']>;
  hasChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  labelSynonyms: Array<Scalars['String']['output']>;
  parentKey?: Maybe<Scalars['String']['output']>;
  propertyTypes: Array<PimProductTypePropertyType>;
  typeSystem: PimProductTypeSystemSystemType;
};

export type PimProductTypeChildrenResult = {
  __typename: 'PimProductTypeChildrenResult';
  productTypeChildren: Array<PimProductType>;
};

export type PimProductTypeParentResult = {
  __typename: 'PimProductTypeParentResult';
  productTypeParent?: Maybe<PimProductType>;
};

export type PimProductTypeParentsResult = {
  __typename: 'PimProductTypeParentsResult';
  productTypeParents: Array<PimProductType>;
};

export type PimProductTypePropertyType = {
  __typename: 'PimProductTypePropertyType';
  id: Scalars['ID']['output'];
  propertyType: PimPropertyType;
  propertyTypeValues: Array<PimPropertyTypeValue>;
};

export type PimProductTypePropertyTypeFavourites = {
  __typename: 'PimProductTypePropertyTypeFavourites';
  productType: PimProductType;
  propertyTypeFavourites: Array<PimPropertyTypeFavourite>;
};

export type PimProductTypeRef = {
  __typename: 'PimProductTypeRef';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  labelSynonyms: Array<Scalars['String']['output']>;
  parentKey?: Maybe<Scalars['String']['output']>;
  typeSystem: PimProductTypeSystemSystemType;
};

export type PimProductTypeSystemSystemType =
  | 'ETIM8';

export type PimProductTypeText = {
  __typename: 'PimProductTypeText';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  languageKey: LanguageKey;
  title: Scalars['String']['output'];
};

export type PimProductTypesResult = {
  __typename: 'PimProductTypesResult';
  productTypes: Array<PimProductType>;
};

export type PimPropertyType = {
  __typename: 'PimPropertyType';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  kind: PimPropertyTypeKind;
  label: Scalars['String']['output'];
  typeSystem: PimProductTypeSystemSystemType;
  unit?: Maybe<Scalars['String']['output']>;
};

export type PimPropertyTypeFavourite = {
  __typename: 'PimPropertyTypeFavourite';
  id: Scalars['ID']['output'];
};

export type PimPropertyTypeKind =
  | 'bool'
  | 'number'
  | 'range'
  | 'text';

export type PimPropertyTypeValue = {
  __typename: 'PimPropertyTypeValue';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type PimPropertyValueRange = {
  __typename: 'PimPropertyValueRange';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type PimPropertyValueRangeInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

export type PimPropertyValueText = {
  __typename: 'PimPropertyValueText';
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type PimRangeProperty = {
  __typename: 'PimRangeProperty';
  clientVisibility: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  valueRange?: Maybe<PimPropertyValueRange>;
};

export type PimReenableCustomSupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimReenableCustomSupplierProductResult = {
  __typename: 'PimReenableCustomSupplierProductResult';
  product: PimCustomSupplierProduct;
};

export type PimReenableSupplierProductInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimReenableSupplierProductResult = {
  __typename: 'PimReenableSupplierProductResult';
  supplierProduct: PimSupplierProduct;
};

export type PimRequestImportationUploadUrlInput = {
  filename: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimRootProductTypesResult = {
  __typename: 'PimRootProductTypesResult';
  productTypes: Array<PimProductType>;
};

export type PimRunPropertyExtractionInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimSearchBrandFilter = {
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PimSearchBrandLinesFilter = {
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  brandLineIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PimSearchBrandLinesResult = {
  __typename: 'PimSearchBrandLinesResult';
  brandLines: Array<PimBrandLineRef>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchBrandsResult = {
  __typename: 'PimSearchBrandsResult';
  brands: Array<PimBrandRef>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchGroupedProductsConnection = {
  __typename: 'PimSearchGroupedProductsConnection';
  edges: Array<PimSearchGroupedProductsEdge>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchGroupedProductsEdge = {
  __typename: 'PimSearchGroupedProductsEdge';
  node: PimSearchGroupedProductsNode;
};

export type PimSearchGroupedProductsGroupedProducts = {
  __typename: 'PimSearchGroupedProductsGroupedProducts';
  products: Array<Product>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchGroupedProductsNode = {
  __typename: 'PimSearchGroupedProductsNode';
  id: Scalars['ID']['output'];
  product: ProductSearchResult;
};

export type PimSearchManufacturerProductsAdvancedSearchParams = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PimSearchManufacturerProductsConnection = {
  __typename: 'PimSearchManufacturerProductsConnection';
  edges: Array<PimSearchManufacturerProductsEdge>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchManufacturerProductsEdge = {
  __typename: 'PimSearchManufacturerProductsEdge';
  node: PimManufacturerProduct;
};

export type PimSearchManufacturerProductsFilters = {
  excludeProductVariantGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  listPriceMax?: InputMaybe<Scalars['Float']['input']>;
  listPriceMin?: InputMaybe<Scalars['Float']['input']>;
  manufacturerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  netPriceMax?: InputMaybe<Scalars['Float']['input']>;
  netPriceMin?: InputMaybe<Scalars['Float']['input']>;
  productTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  productVariantGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PimSearchProductConnection = {
  __typename: 'PimSearchProductConnection';
  edges: Array<PimSearchProductsEdge>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchProductSuggestionsResult = {
  __typename: 'PimSearchProductSuggestionsResult';
  suggestions: Array<PimSuggestion>;
};

export type PimSearchProductTypesResult = {
  __typename: 'PimSearchProductTypesResult';
  productTypes: Array<PimProductType>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchProductsAdvancedSearchParams = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PimSearchProductsAvailableFiltersResult = {
  __typename: 'PimSearchProductsAvailableFiltersResult';
  brandLines: Array<PimAvailableFilterBrandLine>;
  brands: Array<PimAvailableFilterBrand>;
  listPrice?: Maybe<PimAvailableFilterRangeNumber>;
  manufacturerIds: Array<PimAvailableFilterManufacturer>;
  netPrice?: Maybe<PimAvailableFilterRangeNumber>;
  productTypeIds: Array<PimAvailableFilterProductType>;
  propertiesBool: Array<PimAvailableFilterPropertyBool>;
  propertiesNumber: Array<PimAvailableFilterPropertyNumber>;
  propertiesText: Array<PimAvailableFilterPropertyText>;
  supplierIds: Array<PimAvailableFilterSupplier>;
  supplierProductCategories: Array<PimAvailableFilterSupplierProductCategory>;
};

export type PimSearchProductsEdge = {
  __typename: 'PimSearchProductsEdge';
  node: ProductSearchResult;
};

export type PimSearchProductsFilters = {
  brandLines?: InputMaybe<Array<Scalars['String']['input']>>;
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeProductVariantGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  listPriceMax?: InputMaybe<Scalars['Float']['input']>;
  listPriceMin?: InputMaybe<Scalars['Float']['input']>;
  manufacturerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  netPriceMax?: InputMaybe<Scalars['Float']['input']>;
  netPriceMin?: InputMaybe<Scalars['Float']['input']>;
  organisationFavourite?: InputMaybe<Scalars['Boolean']['input']>;
  productTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  productVariantGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  propertiesBool?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  propertiesNumber?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  propertiesText?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  supplierFavourite?: InputMaybe<Scalars['Boolean']['input']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  supplierProductCategoryKey?: InputMaybe<Scalars['String']['input']>;
};

export type PimSearchSupplierCataloguesFilters = {
  organisationCanWrite: Scalars['Boolean']['input'];
  organisationIsSubscribed: Scalars['Boolean']['input'];
};

export type PimSearchSupplierCataloguesResult = {
  __typename: 'PimSearchSupplierCataloguesResult';
  supplierCatalogues: Array<PimSupplierCatalogue>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchSupplierDiscountGroupVariantsFilters = {
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PimSearchSupplierDiscountGroupVariantsResult = {
  __typename: 'PimSearchSupplierDiscountGroupVariantsResult';
  supplierDiscountGroupVariants: Array<PimSupplierDiscountGroupVariant>;
  totalCount: Scalars['Int']['output'];
};

export type PimSearchSupplierOrganisationDiscountGroupImportationsFilters = {
  supplierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PimSearchSupplierOrganisationDiscountGroupImportationsResult = {
  __typename: 'PimSearchSupplierOrganisationDiscountGroupImportationsResult';
  importations: Array<PimSupplierDiscountGroupImportation>;
  totalCount: Scalars['Int']['output'];
};

export type PimSetManufacturerProductProductTypeInput = {
  manufacturerProductId: Scalars['ID']['input'];
  productTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimSetManufacturerProductProductTypeResult = {
  __typename: 'PimSetManufacturerProductProductTypeResult';
  manufacturerProduct: PimManufacturerProduct;
};

export type PimSetProductTypePropertyTypeFavouritesInput = {
  productTypeId: Scalars['ID']['input'];
  propertyTypeIds: Array<Scalars['ID']['input']>;
};

export type PimSetProductTypePropertyTypeFavouritesResult = {
  __typename: 'PimSetProductTypePropertyTypeFavouritesResult';
  productTypePropertyTypeFavourites: PimProductTypePropertyTypeFavourites;
};

export type PimSetSupplierProductBrandingBrandingInput = {
  brandId: Scalars['ID']['input'];
  brandLineId?: InputMaybe<Scalars['ID']['input']>;
};

export type PimSetSupplierProductBrandingInput = {
  articleNumber: Scalars['String']['input'];
  branding?: InputMaybe<PimSetSupplierProductBrandingBrandingInput>;
  supplierId: Scalars['ID']['input'];
};

export type PimSetSupplierProductBrandingResult = {
  __typename: 'PimSetSupplierProductBrandingResult';
  product: PimCustomSupplierProduct;
};

export type PimSetSupplierProductProductTypeInput = {
  articleNumber: Scalars['String']['input'];
  productTypeId?: InputMaybe<Scalars['ID']['input']>;
  supplierId: Scalars['ID']['input'];
};

export type PimSetSupplierProductProductTypeResult = {
  __typename: 'PimSetSupplierProductProductTypeResult';
  product: PimCustomSupplierProduct;
};

export type PimSetSupplierProductPropertySuggestionsInput = {
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
  properties: Array<PimProductPropertyInput>;
};

export type PimSetSupplierProductPropertySuggestionsResult = {
  __typename: 'PimSetSupplierProductPropertySuggestionsResult';
  product: PimCustomSupplierProduct;
};

export type PimSetSupplierProductPropertyValuesInput = {
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
  propertyValues: Array<PimSetSupplierProductPropertyValuesPropertyValueInput>;
};

export type PimSetSupplierProductPropertyValuesPropertyValueInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type PimSetSupplierProductPropertyValuesResult = {
  __typename: 'PimSetSupplierProductPropertyValuesResult';
  product: PimCustomSupplierProduct;
};

export type PimSetSupplierProductsFavouriteInput = {
  isFavourite: Scalars['Boolean']['input'];
  supplierProductArticleNumbers: Array<PimSupplierProductArticleNumberInput>;
};

export type PimSetSupplierProductsFavouriteProduct = {
  __typename: 'PimSetSupplierProductsFavouriteProduct';
  product: Product;
  productSearchResult: ProductSearchResult;
};

export type PimSetSupplierProductsFavouriteResult = {
  __typename: 'PimSetSupplierProductsFavouriteResult';
  products: Array<Maybe<PimSetSupplierProductsFavouriteProduct>>;
};

export type PimSpecificFieldQueryField = {
  field: PimSpecificFieldQueryFieldField;
  value: Scalars['String']['input'];
};

export type PimSpecificFieldQueryFieldField =
  | 'articleNumber'
  | 'descriptionText'
  | 'descriptionTextAndExtendedDescriptionText'
  | 'dimensionText'
  | 'extendedDescriptionText'
  | 'gtin'
  | 'matchCode'
  | 'titleText';

export type PimSubscribeToSupplierCatalogueInput = {
  supplierCatalogueId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimSubscribeToSupplierCatalogueResult = {
  __typename: 'PimSubscribeToSupplierCatalogueResult';
  supplierCatalogue: PimSupplierCatalogue;
};

export type PimSuggestion = {
  __typename: 'PimSuggestion';
  product: PimCustomSupplierProduct;
  searchString: Scalars['String']['output'];
};

export type PimSupplierCatalogue = {
  __typename: 'PimSupplierCatalogue';
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  supplier: Company;
  supplierCatalogueAccess: PimSupplierCatalogueAccess;
  supplierCatalogueSubscription?: Maybe<PimSupplierCatalogueSubscription>;
  supplierId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PimSupplierCatalogueAccess = {
  __typename: 'PimSupplierCatalogueAccess';
  organisationCanWrite: Scalars['Boolean']['output'];
};

export type PimSupplierCatalogueSubscription = {
  __typename: 'PimSupplierCatalogueSubscription';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  organisationId: Scalars['ID']['output'];
  supplierCatalogueId: Scalars['ID']['output'];
};

export type PimSupplierDiscountGroupImportation = {
  __typename: 'PimSupplierDiscountGroupImportation';
  id: Scalars['ID']['output'];
};

export type PimSupplierDiscountGroupVariant = {
  __typename: 'PimSupplierDiscountGroupVariant';
  id: Scalars['ID']['output'];
  organisationId: Scalars['ID']['output'];
  supplier: Company;
  title: Scalars['String']['output'];
};

export type PimSupplierProduct = Product & {
  __typename: 'PimSupplierProduct';
  articleNumber: Scalars['String']['output'];
  attachments: Array<AttachmentSnapshot>;
  branding?: Maybe<PimProductBranding>;
  category: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturerArticleNumber?: Maybe<Scalars['String']['output']>;
  model: Scalars['String']['output'];
  organisationCanEdit: Scalars['Boolean']['output'];
  organisationFavourite: Scalars['Boolean']['output'];
  organisationProperties: Array<PimProductProperty>;
  pricing?: Maybe<PimPricing>;
  productType?: Maybe<PimProductTypeRef>;
  propertiesExtractedAt?: Maybe<Scalars['Date']['output']>;
  supplier: Company;
  supplierCatalogue: PimSupplierCatalogue;
  supplierFavourite: Scalars['Boolean']['output'];
  supplierId: Scalars['ID']['output'];
  supplierProductCategory?: Maybe<PimProductCategory>;
  supplierProductProperties: Array<PimProductProperty>;
  texts?: Maybe<PimProductTexts>;
  threedModel?: Maybe<PimThreedModel>;
};

export type PimSupplierProductAlternatives = {
  __typename: 'PimSupplierProductAlternatives';
  id: Scalars['ID']['output'];
  propertyAlternatives: Array<PimSupplierProductAlternativesPropertyAlternative>;
  propertyMatchingProductAlternatives: Array<PimSupplierProductAlternativesProduct>;
};

export type PimSupplierProductAlternativesAlternativeValues = {
  __typename: 'PimSupplierProductAlternativesAlternativeValues';
  products: Array<PimSupplierProductAlternativesAlternativeValuesProduct>;
  value?: Maybe<PimProductAlternativePropertyValue>;
};

export type PimSupplierProductAlternativesAlternativeValuesProduct = {
  __typename: 'PimSupplierProductAlternativesAlternativeValuesProduct';
  mismatchPropertyKeys: Array<Scalars['String']['output']>;
  product: PimSupplierProductAlternativesProduct;
};

export type PimSupplierProductAlternativesProduct = {
  __typename: 'PimSupplierProductAlternativesProduct';
  articleNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  supplierId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PimSupplierProductAlternativesPropertyAlternative = {
  __typename: 'PimSupplierProductAlternativesPropertyAlternative';
  alternativeValues: Array<PimSupplierProductAlternativesAlternativeValues>;
  referenceProperty: PimProductProperty;
  value?: Maybe<PimProductAlternativePropertyValue>;
};

export type PimSupplierProductAlternativesResult = {
  __typename: 'PimSupplierProductAlternativesResult';
  result?: Maybe<PimSupplierProductAlternatives>;
};

export type PimSupplierProductArticleNumberInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimSupplierProductCategoryImportationType =
  | 'csv_product_categories';

export type PimSupplierProductCategoryRoots = {
  __typename: 'PimSupplierProductCategoryRoots';
  supplier: Company;
  supplierProductCategoryRoots: Array<PimProductCategory>;
};

export type PimSupplierProductImportation = {
  __typename: 'PimSupplierProductImportation';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  supplier: Company;
  url: Scalars['String']['output'];
};

export type PimSupplierProductImportationType =
  | 'csv'
  | 'csv_properties'
  | 'richter_frenzel_xml';

export type PimSupplierProductOrganisationFavouritesResult = {
  __typename: 'PimSupplierProductOrganisationFavouritesResult';
  products: Array<ProductSearchResult>;
};

export type PimSupplierProductRecentViewsResult = {
  __typename: 'PimSupplierProductRecentViewsResult';
  products: Array<ProductSearchResult>;
};

export type PimSupplierProductSearchResult = ProductSearchResult & {
  __typename: 'PimSupplierProductSearchResult';
  articleNumber: Scalars['String']['output'];
  attachments: Array<AttachmentSnapshot>;
  brand?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
  organisationFavourite: Scalars['Boolean']['output'];
  pricing?: Maybe<PimPricing>;
  supplier: Company;
  supplierFavourite: Scalars['Boolean']['output'];
  supplierId: Scalars['ID']['output'];
  supplierProductCategory?: Maybe<PimProductCategory>;
  texts?: Maybe<PimProductTexts>;
};

export type PimSupplierProductSetInfo = {
  __typename: 'PimSupplierProductSetInfo';
  id: Scalars['ID']['output'];
  setArticleNumber: Scalars['String']['output'];
  setProducts: Array<PimSupplierProductSetProduct>;
  supplierId: Scalars['ID']['output'];
  supplierProductId: Scalars['ID']['output'];
};

export type PimSupplierProductSetProduct = {
  __typename: 'PimSupplierProductSetProduct';
  articleNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
  supplierId: Scalars['String']['output'];
  supplierProductId?: Maybe<Scalars['ID']['output']>;
  texts?: Maybe<PimProductTexts>;
};

export type PimTextProperty = {
  __typename: 'PimTextProperty';
  allowedValueTexts?: Maybe<Array<PimPropertyValueText>>;
  clientVisibility: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  valueText?: Maybe<PimPropertyValueText>;
};

export type PimTextsInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dimensionText?: InputMaybe<Scalars['String']['input']>;
  extendedDescription?: InputMaybe<Scalars['String']['input']>;
  marketingText?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PimThreedModel = {
  __typename: 'PimThreedModel';
  definitionFiles: Array<PimThreedModelDefinitionFiles>;
  id: Scalars['ID']['output'];
};

export type PimThreedModelDefinitionFiles = {
  __typename: 'PimThreedModelDefinitionFiles';
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PimTranslation = {
  __typename: 'PimTranslation';
  id: Scalars['ID']['output'];
  languageKey: LanguageKey;
  synonyms?: Maybe<Array<Scalars['String']['output']>>;
  value: Scalars['String']['output'];
};

export type PimTypedBoolProperty = {
  __typename: 'PimTypedBoolProperty';
  clientVisibility: Scalars['Boolean']['output'];
  productTypeKey: Scalars['String']['output'];
  propertyTypeKey: Scalars['String']['output'];
  typeSystem: PimProductTypeSystemSystemType;
  valueBool?: Maybe<Scalars['Boolean']['output']>;
};

export type PimTypedNumberProperty = {
  __typename: 'PimTypedNumberProperty';
  clientVisibility: Scalars['Boolean']['output'];
  productTypeKey: Scalars['String']['output'];
  propertyTypeKey: Scalars['String']['output'];
  typeSystem: PimProductTypeSystemSystemType;
  unit?: Maybe<Scalars['String']['output']>;
  valueNumber?: Maybe<Scalars['Float']['output']>;
};

export type PimTypedRangeProperty = {
  __typename: 'PimTypedRangeProperty';
  clientVisibility: Scalars['Boolean']['output'];
  productTypeKey: Scalars['String']['output'];
  propertyTypeKey: Scalars['String']['output'];
  typeSystem: PimProductTypeSystemSystemType;
  unit?: Maybe<Scalars['String']['output']>;
  valueRange?: Maybe<PimPropertyValueRange>;
};

export type PimTypedTextProperty = {
  __typename: 'PimTypedTextProperty';
  allowedPropertyTypeValues: Array<PimPropertyTypeValue>;
  clientVisibility: Scalars['Boolean']['output'];
  productTypeKey: Scalars['String']['output'];
  propertyTypeKey: Scalars['String']['output'];
  propertyTypeValue?: Maybe<PimPropertyTypeValue>;
  propertyTypeValueKey?: Maybe<Scalars['String']['output']>;
  typeSystem: PimProductTypeSystemSystemType;
};

export type PimUnassignManufacturerInput = {
  productId: Scalars['ID']['input'];
};

export type PimUnassignManufacturerProductInput = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimUnsubscribeFromSupplierCatalogueInput = {
  supplierCatalogueId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type PimUnsubscribeFromSupplierCatalogueResult = {
  __typename: 'PimUnsubscribeFromSupplierCatalogueResult';
  supplierCatalogue: PimSupplierCatalogue;
};

export type PimUpdateProduct = {
  __typename: 'PimUpdateProduct';
  product: PimCustomSupplierProduct;
};

export type PimUpdateProductInput = {
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
  values: PimUpdateProductValuesInput;
};

export type PimUpdateProductTypesFromEtimResult = {
  __typename: 'PimUpdateProductTypesFromEtimResult';
  ok: Scalars['Boolean']['output'];
};

export type PimUpdateProductValuesInput = {
  attachments?: InputMaybe<Array<AttachmentInput>>;
  brand?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  manufacturerArticleNumber?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Array<PimProductPropertyInput>>;
  texts?: InputMaybe<PimTextsInput>;
};

export type PlanSession = WithContents & {
  __typename: 'PlanSession';
  contents: Array<Content>;
  distanceToAddress?: Maybe<Scalars['Float']['output']>;
  from: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isTentative: Scalars['Boolean']['output'];
  project: Project;
  roles: Array<ProjectRole>;
  till: Scalars['DateTime']['output'];
  who: User;
};


export type PlanSessionDistanceToAddressArgs = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
};

export type PlanSessionConnection = {
  __typename: 'PlanSessionConnection';
  edges: Array<PlanSessionEdge>;
  totalCount: Scalars['Int']['output'];
};

export type PlanSessionEdge = {
  __typename: 'PlanSessionEdge';
  node: PlanSession;
};

export type PlanSessionsFilters = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  whoIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Product = {
  articleNumber: Scalars['String']['output'];
  attachments: Array<AttachmentSnapshot>;
  branding?: Maybe<PimProductBranding>;
  category: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
  texts?: Maybe<PimProductTexts>;
  threedModel?: Maybe<PimThreedModel>;
};

export type ProductGroup = {
  __typename: 'ProductGroup';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  mainProductGroup?: Maybe<MainProductGroup>;
};

export type ProductMetaInfoImportation = PimImportation | PimSupplierProductImportation;

export type ProductProps2Entry = {
  bool?: InputMaybe<ProductProps2EntryBool>;
  number?: InputMaybe<ProductProps2EntryNumber>;
  numberArray?: InputMaybe<ProductProps2EntryNumberArray>;
  text?: InputMaybe<ProductProps2EntryText>;
  textArray?: InputMaybe<ProductProps2EntryTextArray>;
};

export type ProductProps2EntryBool = {
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueBool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductProps2EntryNumber = {
  allowedValuesNumber: Array<Props2AllowedValuesNumberInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  range?: InputMaybe<Props2NumberRangeInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductProps2EntryNumberArray = {
  allowedValuesNumber: Array<Props2AllowedValuesNumberInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  range?: InputMaybe<Props2NumberRangeInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumberArray?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type ProductProps2EntryText = {
  allowedValuesText: Array<Props2AllowedValuesTextInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueText?: InputMaybe<Scalars['String']['input']>;
};

export type ProductProps2EntryTextArray = {
  allowedValuesText: Array<Props2AllowedValuesTextInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueTextArray?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductSearchFilterExpressionFilterKey =
  | 'brand'
  | 'brandLine'
  | 'favourites'
  | 'listPrice'
  | 'netPrice'
  | 'properties';

export type ProductSearchFilterExpressionFilterKind =
  | 'bool'
  | 'number'
  | 'text';

export type ProductSearchResult = {
  articleNumber: Scalars['String']['output'];
  attachments: Array<AttachmentSnapshot>;
  brand?: Maybe<Scalars['String']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  texts?: Maybe<PimProductTexts>;
};

export type ProductsVisibilityImages =
  | 'all'
  | 'no'
  | 'one';

export type Project = WithAttachments & WithContents & WithCustomFields & WithProjectInfo & {
  __typename: 'Project';
  acceptedDocSharings: Array<SharedDocument>;
  address?: Maybe<Address>;
  assigned: Scalars['Boolean']['output'];
  assignee?: Maybe<ProjectMembership>;
  assignees: Array<Maybe<User>>;
  attachments: Array<Attachment>;
  author: User;
  billOfMaterialsItems: Array<BillOfMaterialsItem>;
  budget?: Maybe<Money>;
  building?: Maybe<Building>;
  buildingInfo?: Maybe<BuildingInfo>;
  buildingItem?: Maybe<Item>;
  canInviteContractee: Scalars['Boolean']['output'];
  categories: Array<Skill>;
  channels: Array<Channel>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  contents: Array<Content>;
  contracteeInvitations: Array<ProjectContracteeInvitation>;
  contractors: Array<CrmCompany>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  crmLinks: Array<ProjectCrmLink>;
  crmOrganisation?: Maybe<CrmCompany>;
  crmOrganisationLinks: Array<ProjectLinkCrmOrganisation>;
  crmUserLinks: Array<ProjectLinkCrmUser>;
  customFields: Array<CustomFieldField>;
  deadline?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  earliestPlanSessionDate?: Maybe<Scalars['DateTime']['output']>;
  earliestStart?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  incomingQuoteRequests: Array<Request>;
  internalStakeholders: Array<ProjectMembership>;
  invitingContractor?: Maybe<CrmCompany>;
  latestPlanSessionDate?: Maybe<Scalars['DateTime']['output']>;
  leadId?: Maybe<Scalars['Int']['output']>;
  marketplace: Scalars['Boolean']['output'];
  marketplaceFullAddress: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  order?: Maybe<Order>;
  orders: Array<Order>;
  outgoingQuoteRequest?: Maybe<Request>;
  outgoingQuoteRequests: Array<Request>;
  overdue: Scalars['Boolean']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  phase: ProjectPhase;
  planSessions: Array<PlanSession>;
  projectChangedAt: Scalars['DateTime']['output'];
  quotingStatus: QuotingStatus;
  roles: Array<ProjectRole>;
  source: ProjectSource;
  state: ProjectStateMachineStatus;
  status: OpportunityStatus;
  subOriginProject?: Maybe<ProjectInfo>;
  subcontracts: Array<Maybe<Subcontract>>;
  tasks: Array<TaskDocument>;
  ticket: Scalars['Boolean']['output'];
  timeReport?: Maybe<TimeReport>;
  timeReports: Array<TimeReport>;
  title: Scalars['String']['output'];
  type: ProjectType;
  urgent: Scalars['Boolean']['output'];
  viewerPermissions: Array<PermissionName>;
  viewerPermissionsDetails: ProjectViewerPermissionsDetail;
  workSessions: Array<WorkSession>;
};


export type ProjectAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectBillOfMaterialsItemsArgs = {
  aggregateProducts: Scalars['Boolean']['input'];
  filterSearchTerm?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  itemStatus?: InputMaybe<Array<BillOfMaterialsItemStatus>>;
  itemType?: InputMaybe<Array<BillOfMaterialsItemType>>;
  productArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productSupplierId?: InputMaybe<Scalars['ID']['input']>;
  quoteId?: InputMaybe<Array<Scalars['ID']['input']>>;
  quoteItemId?: InputMaybe<Scalars['ID']['input']>;
  quoteItemStatus?: InputMaybe<BillOfMaterialsQuoteItemStatusFilter>;
};


export type ProjectChannelsArgs = {
  filters?: InputMaybe<ChannelsFilters>;
};


export type ProjectContracteeInvitationsArgs = {
  filterState?: InputMaybe<Array<InvitationState>>;
};


export type ProjectOrderArgs = {
  id: Scalars['ID']['input'];
};


export type ProjectOutgoingQuoteRequestArgs = {
  id: Scalars['ID']['input'];
};


export type ProjectTasksArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectTimeReportArgs = {
  id: Scalars['ID']['input'];
};


export type ProjectWorkSessionsArgs = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type ProjectAssignBuildingResult = {
  __typename: 'ProjectAssignBuildingResult';
  contracteeCrm?: Maybe<CrmCompany>;
  project: Project;
};

export type ProjectConnection = {
  __typename: 'ProjectConnection';
  edges: Array<ProjectEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProjectContracteeInvitation = {
  __typename: 'ProjectContracteeInvitation';
  acceptedByOrganisation?: Maybe<Organisation>;
  acceptedByUser?: Maybe<User>;
  attachments: Array<AttachmentSnapshot>;
  author: Person;
  contact: InvitationContact;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  invitationToken: Scalars['String']['output'];
  invitationUrl: Scalars['String']['output'];
  messageBody: Scalars['String']['output'];
  messageSubject: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['ID']['output'];
  status: InvitationState;
  title: Scalars['String']['output'];
};

export type ProjectCreateInput = {
  budgetAmount?: InputMaybe<Scalars['Float']['input']>;
  buildingItemId?: InputMaybe<Scalars['ID']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  crmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  deadline?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  desiredProjectInitialStatus: DesiredProjectInitialStatus;
  earliestStart?: InputMaybe<Scalars['String']['input']>;
  pageName: ProjectSourcePageName;
  ticket?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  type: ProjectType;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectCreateResult = {
  __typename: 'ProjectCreateResult';
  project: Project;
};

export type ProjectCrmLink = ProjectLinkCrmOrganisation | ProjectLinkCrmUser;

export type ProjectDocumentOrigin =
  | 'FOREIGN'
  | 'OWN';

export type ProjectDocumentsStatus = {
  __typename: 'ProjectDocumentsStatus';
  areAllOutgoingQuoteItemsDone: Scalars['Boolean']['output'];
  isAnyOutgoingQuoteItemDone: Scalars['Boolean']['output'];
};

export type ProjectEdge = {
  __typename: 'ProjectEdge';
  cursor?: Maybe<Scalars['ID']['output']>;
  node: Project;
};

export type ProjectFilterKind =
  | 'DEFAULT'
  | 'TICKET';

export type ProjectIncomingInvoiceConnection = {
  __typename: 'ProjectIncomingInvoiceConnection';
  edges: Array<IncomingInvoiceEdge>;
  totalAmountOpen: Scalars['Float']['output'];
  totalAmountOverdue: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProjectIncomingQuoteConnection = {
  __typename: 'ProjectIncomingQuoteConnection';
  edges: Array<IncomingQuoteEdge>;
  totalCount: Scalars['Int']['output'];
};

export type ProjectIncomingQuoteRequestResult = {
  __typename: 'ProjectIncomingQuoteRequestResult';
  request?: Maybe<Request>;
};

export type ProjectInfo = {
  __typename: 'ProjectInfo';
  id: Scalars['ID']['output'];
  state: ProjectStateMachineStatus;
  title: Scalars['String']['output'];
};

export type ProjectInfoByTokenContact = {
  __typename: 'ProjectInfoByTokenContact';
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  title: Salutation;
};

export type ProjectInfoByTokenRequirement = {
  __typename: 'ProjectInfoByTokenRequirement';
  id: Scalars['ID']['output'];
};

export type ProjectInfoByTokenResult = {
  __typename: 'ProjectInfoByTokenResult';
  contractee: ProjectInfoByTokenContact;
  project: ProjectInfo;
  requirement?: Maybe<ProjectInfoByTokenRequirement>;
  styleSheetUrl?: Maybe<Scalars['String']['output']>;
};

export type ProjectLinkCrmOrganisation = {
  __typename: 'ProjectLinkCrmOrganisation';
  crmOrganisation: CrmCompany;
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  linkAs: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type ProjectLinkCrmUser = {
  __typename: 'ProjectLinkCrmUser';
  crmUser: CrmPerson;
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  linkAs: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type ProjectMembership = {
  __typename: 'ProjectMembership';
  id: Scalars['ID']['output'];
  permissionNames: Array<PermissionName>;
  roles: Array<ProjectRole>;
  user: User;
};

export type ProjectMode =
  | 'MULTI_PLAYER'
  | 'SINGLE_PLAYER';

export type ProjectModifyInput = {
  budgetAmount?: InputMaybe<Scalars['Float']['input']>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  deadline?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  earliestStart?: InputMaybe<Scalars['String']['input']>;
  marketplace?: InputMaybe<Scalars['Boolean']['input']>;
  marketplaceFullAddress?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ProjectType>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectModifyResult = {
  __typename: 'ProjectModifyResult';
  project: Project;
};

export type ProjectOutgoingInvoiceConnection = {
  __typename: 'ProjectOutgoingInvoiceConnection';
  edges: Array<OutgoingInvoiceEdge>;
  totalAmountOpen: Scalars['Float']['output'];
  totalAmountOverdue: Scalars['Float']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProjectOutgoingQuoteConnection = {
  __typename: 'ProjectOutgoingQuoteConnection';
  edges: Array<OutgoingQuoteEdge>;
  totalCount: Scalars['Int']['output'];
};

export type ProjectOutgoingQuoteRequestResult = {
  __typename: 'ProjectOutgoingQuoteRequestResult';
  request?: Maybe<Request>;
};

export type ProjectOutgoingQuotesAllowedInvoiceTypesResult = {
  __typename: 'ProjectOutgoingQuotesAllowedInvoiceTypesResult';
  types: Array<QuoteAllowedInvoiceTypes>;
};

export type ProjectPhase = {
  __typename: 'ProjectPhase';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProjectRef = {
  __typename: 'ProjectRef';
  id: Scalars['ID']['output'];
  state: ProjectStateMachineStatus;
  title: Scalars['String']['output'];
};

export type ProjectRequirementsConnection = {
  __typename: 'ProjectRequirementsConnection';
  edges: Array<RequirementEdge>;
  totalCount: Scalars['Int']['output'];
};

export type ProjectResult = {
  __typename: 'ProjectResult';
  project?: Maybe<Project>;
};

export type ProjectRole = {
  __typename: 'ProjectRole';
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ProjectSource = {
  __typename: 'ProjectSource';
  createdByCrmOrganisationId?: Maybe<Scalars['ID']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  crmOrganisationLogo?: Maybe<AttachmentSnapshot>;
  crmOrganisationTitle?: Maybe<Scalars['String']['output']>;
  flowTemplateId?: Maybe<Scalars['String']['output']>;
  flowTemplateTitle?: Maybe<Scalars['String']['output']>;
  flowTemplateVersionCounter?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  importFileName?: Maybe<Scalars['String']['output']>;
  manualPageName?: Maybe<ProjectSourcePageName>;
  quoteProductsDistributionId?: Maybe<Scalars['ID']['output']>;
  requestId?: Maybe<Scalars['ID']['output']>;
  requestTitle?: Maybe<Scalars['String']['output']>;
  source: ProjectSourceType;
  userAvatar?: Maybe<AttachmentSnapshot>;
  userFamilyname?: Maybe<Scalars['String']['output']>;
  userFirstname?: Maybe<Scalars['String']['output']>;
};

export type ProjectSourcePageName =
  | 'Building'
  | 'CreateProjectModal'
  | 'CreateTicketModal'
  | 'CrmCompany'
  | 'Dashboard'
  | 'Opportunities'
  | 'PlanningProjects'
  | 'PlanningTickets'
  | 'Projects'
  | 'Shop'
  | 'Tickets'
  | 'Todos';

export type ProjectSourceType =
  | 'ContracteeInvitation'
  | 'Flow3D'
  | 'FlowM1'
  | 'FlowWizard'
  | 'Import'
  | 'Legacy'
  | 'Manual'
  | 'QuoteProductsDistribution'
  | 'RequestAutomated'
  | 'RequestManual';

export type ProjectStateMachineStatus =
  | 'cancelled'
  | 'completed'
  | 'contracted'
  | 'contractee_declined'
  | 'contractor_declined'
  | 'legacy_closed'
  | 'opportunity';

export type ProjectType =
  | 'DIY'
  | 'NEW'
  | 'OTHER'
  | 'RENEWAL'
  | 'REPAIR';

export type ProjectViewerPermissionsDetail = {
  __typename: 'ProjectViewerPermissionsDetail';
  computedViewerPermissions: Array<PermissionName>;
  permissionsByOrgRoles: Array<PermissionName>;
  permissionsByProjectRoles: Array<PermissionName>;
};

export type ProjectsFilters = {
  assigned?: InputMaybe<Scalars['Boolean']['input']>;
  assigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  buildingZip?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Skill>>;
  crmLinkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  crmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  customFields?: InputMaybe<Array<CustomFieldFilter>>;
  excludeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeStakeholderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  kind?: InputMaybe<ProjectFilterKind>;
  minBudget?: InputMaybe<Scalars['Int']['input']>;
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  phaseId?: InputMaybe<Scalars['ID']['input']>;
  phaseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  relevantToUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceCrmOrganisationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sources?: InputMaybe<Array<ProjectSourceType>>;
  type?: InputMaybe<Array<ProjectType>>;
  urgent?: InputMaybe<Scalars['Boolean']['input']>;
  withinRadiusKm?: InputMaybe<Scalars['Int']['input']>;
  won?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyMapping = {
  __typename: 'PropertyMapping';
  from: PropertyMappingFrom;
  to: PropertyMappingTo;
};

export type PropertyMappingFrom = {
  __typename: 'PropertyMappingFrom';
  expr: Scalars['String']['output'];
};

export type PropertyMappingTo = {
  __typename: 'PropertyMappingTo';
  key: Scalars['String']['output'];
};

export type PropertySelectionSetter = {
  __typename: 'PropertySelectionSetter';
  from: PropertySelectionSetterFrom;
  to: PropertySelectionSetterTo;
};

export type PropertySelectionSetterFrom = {
  __typename: 'PropertySelectionSetterFrom';
  expr: Scalars['String']['output'];
};

export type PropertySelectionSetterTo = {
  __typename: 'PropertySelectionSetterTo';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type ProposedCalculation = {
  __typename: 'ProposedCalculation';
  itemCalculation?: Maybe<ItemCalculation>;
  itemId: Scalars['ID']['output'];
};

export type Props2 = Props2Bool | Props2BoolComputed | Props2Number | Props2NumberArray | Props2NumberArrayComputed | Props2NumberComputed | Props2Text | Props2TextArray | Props2TextArrayComputed | Props2TextComputed;

export type Props2AllowedValuesNumber = {
  __typename: 'Props2AllowedValuesNumber';
  allowedNumber: Scalars['Float']['output'];
  media?: Maybe<AttachmentSnapshot>;
};

export type Props2AllowedValuesNumberInput = {
  allowedNumber: Scalars['Float']['input'];
  media?: InputMaybe<AttachmentInput>;
};

export type Props2AllowedValuesText = {
  __typename: 'Props2AllowedValuesText';
  allowedText: Scalars['String']['output'];
  media?: Maybe<AttachmentSnapshot>;
};

export type Props2AllowedValuesTextInput = {
  allowedText: Scalars['String']['input'];
  media?: InputMaybe<AttachmentInput>;
};

export type Props2AskWhat =
  | 'decision'
  | 'properties';

export type Props2AskWhen =
  | 'onEmbeddableWizard'
  | 'onQuoteCreate'
  | 'onQuoteRefinement';

export type Props2AskWhom =
  | 'contractee'
  | 'contractor';

export type Props2Bool = {
  __typename: 'Props2Bool';
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['output'];
  essential: Scalars['Boolean']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  valueBool?: Maybe<Scalars['Boolean']['output']>;
};

export type Props2BoolComputed = {
  __typename: 'Props2BoolComputed';
  clientVisibility: Scalars['Boolean']['output'];
  expr: Scalars['String']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  valueBoolComputed?: Maybe<Scalars['Boolean']['output']>;
};

export type Props2Kind =
  | 'Props2Bool'
  | 'Props2BoolComputed'
  | 'Props2Number'
  | 'Props2NumberArray'
  | 'Props2NumberArrayComputed'
  | 'Props2NumberComputed'
  | 'Props2Text'
  | 'Props2TextArray'
  | 'Props2TextArrayComputed'
  | 'Props2TextComputed';

export type Props2NonComputed = Props2Bool | Props2Number | Props2NumberArray | Props2Text | Props2TextArray;

export type Props2Number = {
  __typename: 'Props2Number';
  allowedValuesNumber: Array<Props2AllowedValuesNumber>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['output'];
  essential: Scalars['Boolean']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  range?: Maybe<Props2NumberRange>;
  unit?: Maybe<Scalars['String']['output']>;
  valueNumber?: Maybe<Scalars['Float']['output']>;
};

export type Props2NumberArray = {
  __typename: 'Props2NumberArray';
  allowedValuesNumber: Array<Props2AllowedValuesNumber>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['output'];
  essential: Scalars['Boolean']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  range?: Maybe<Props2NumberRange>;
  unit?: Maybe<Scalars['String']['output']>;
  valueNumberArray?: Maybe<Array<Scalars['Float']['output']>>;
};

export type Props2NumberArrayComputed = {
  __typename: 'Props2NumberArrayComputed';
  clientVisibility: Scalars['Boolean']['output'];
  expr: Scalars['String']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  valueNumberArrayComputed?: Maybe<Array<Scalars['Float']['output']>>;
};

export type Props2NumberComputed = {
  __typename: 'Props2NumberComputed';
  clientVisibility: Scalars['Boolean']['output'];
  expr: Scalars['String']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  valueNumberComputed?: Maybe<Scalars['Float']['output']>;
};

export type Props2NumberRange = {
  __typename: 'Props2NumberRange';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type Props2NumberRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type Props2NumberUnit =
  | 'cm'
  | 'gr'
  | 'kg'
  | 'l'
  | 'm'
  | 'm2'
  | 'm3'
  | 'mm'
  | 'piece'
  | 't';

export type Props2Text = {
  __typename: 'Props2Text';
  allowedValuesText: Array<Props2AllowedValuesText>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['output'];
  essential: Scalars['Boolean']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  valueText?: Maybe<Scalars['String']['output']>;
};

export type Props2TextArray = {
  __typename: 'Props2TextArray';
  allowedValuesText: Array<Props2AllowedValuesText>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['output'];
  essential: Scalars['Boolean']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  valueTextArray?: Maybe<Array<Scalars['String']['output']>>;
};

export type Props2TextArrayComputed = {
  __typename: 'Props2TextArrayComputed';
  clientVisibility: Scalars['Boolean']['output'];
  expr: Scalars['String']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  valueTextArrayComputed?: Maybe<Array<Scalars['String']['output']>>;
};

export type Props2TextComputed = {
  __typename: 'Props2TextComputed';
  clientVisibility: Scalars['Boolean']['output'];
  expr: Scalars['String']['output'];
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  missingValue: Scalars['Boolean']['output'];
  valueTextComputed?: Maybe<Scalars['String']['output']>;
};

export type Protected__AbsenceConnection = AbsenceConnection | MissingCapabilities;

export type Protected__BillOfMaterialsGeneratePdfResult = BillOfMaterialsGeneratePdfResult | MissingPermissions;

export type Protected__Branding = Branding | MissingCapabilities;

export type Protected__CrmCompanyConnection = CrmCompanyConnection | MissingPermissions;

export type Protected__CrmCompanyResult = CrmCompanyResult | MissingPermissions;

export type Protected__CrmEmailMessageConnection = CrmEmailMessageConnection | MissingPermissions;

export type Protected__CrmMailMessage = CrmMailMessage | MissingPermissions;

export type Protected__CrmPersonConnection = CrmPersonConnection | MissingPermissions;

export type Protected__CrmPersonResult = CrmPersonResult | MissingPermissions;

export type Protected__DatanormOnlineEntriesResult = DatanormOnlineEntriesResult | MissingPermissions;

export type Protected__DocumentSettings = DocumentSettings | MissingCapabilities | MissingPermissions;

export type Protected__IncomingInvoiceConnection = IncomingInvoiceConnection | MissingPermissions;

export type Protected__IncomingQuoteConnection = IncomingQuoteConnection | MissingPermissions;

export type Protected__IncomingQuoteResult = IncomingQuoteResult | MissingPermissions;

export type Protected__IntegrationConnection = IntegrationConnection | MissingCapabilities;

export type Protected__Invoice = Invoice | MissingCapabilities | MissingPermissions;

export type Protected__InvoiceCalculation = InvoiceCalculation | MissingPermissions;

export type Protected__Item = Item | MissingPermissions;

export type Protected__ItemsResult = ItemsResult | MissingPermissions;

export type Protected__MarketplaceOpportunityConnection = MarketplaceOpportunityConnection | MissingCapabilities;

export type Protected__MarketplaceOpportunityResult = MarketplaceOpportunityResult | MissingCapabilities | MissingPermissions;

export type Protected__OpportunityQuoteRequestResult = MissingPermissions | OpportunityQuoteRequestResult;

export type Protected__OrderConnection = MissingCapabilities | OrderConnection;

export type Protected__OrderGeneratePdfResult = MissingPermissions | OrderGeneratePdfResult;

export type Protected__OrganisationCounter = MissingCapabilities | MissingPermissions | OrganisationCounter;

export type Protected__OrganisationEmailSettings = MissingCapabilities | MissingPermissions | OrganisationEmailSettings;

export type Protected__OrganisationIntegrationConnection = MissingCapabilities | MissingPermissions | OrganisationIntegrationConnection;

export type Protected__OrganisationIntegrationResult = MissingCapabilities | OrganisationIntegrationResult;

export type Protected__OutgoingInvoiceConnection = MissingCapabilities | MissingPermissions | OutgoingInvoiceConnection;

export type Protected__OutgoingQuoteConnection = MissingCapabilities | MissingPermissions | OutgoingQuoteConnection;

export type Protected__OutgoingQuoteResult = MissingCapabilities | MissingPermissions | OutgoingQuoteResult;

export type Protected__PdfSettings = MissingCapabilities | MissingPermissions | PdfSettings;

export type Protected__PimCurrentSuppliersResult = MissingCapabilities | PimCurrentSuppliersResult;

export type Protected__PimCustomSupplierProduct = MissingCapabilities | PimCustomSupplierProduct;

export type Protected__PimGetAvailableSupplierProductCategoryRootsResult = MissingCapabilities | PimGetAvailableSupplierProductCategoryRootsResult;

export type Protected__PimGetProductResult = MissingCapabilities | PimGetProductResult;

export type Protected__PimGetSupplierCatalogueResult = MissingCapabilities | PimGetSupplierCatalogueResult;

export type Protected__PimGetSupplierCataloguesResult = MissingCapabilities | PimGetSupplierCataloguesResult;

export type Protected__PimGetSupplierDiscountGroupProductsResult = MissingCapabilities | PimGetSupplierDiscountGroupProductsResult;

export type Protected__PimGetSupplierDiscountGroupsResult = MissingCapabilities | PimGetSupplierDiscountGroupsResult;

export type Protected__PimGetSupplierProductResult = MissingCapabilities | PimGetSupplierProductResult;

export type Protected__PimGetThreedModelResult = MissingCapabilities | PimGetThreedModelResult;

export type Protected__PimImportationsResult = MissingCapabilities | PimImportationsResult;

export type Protected__PimProductCategoryTreeWithSupplier = MissingCapabilities | PimProductCategoryTreeWithSupplier;

export type Protected__PimProductMetaInfo = MissingCapabilities | PimProductMetaInfo;

export type Protected__PimProductType = MissingCapabilities | PimProductType;

export type Protected__PimProductTypeChildrenResult = MissingCapabilities | PimProductTypeChildrenResult;

export type Protected__PimProductTypeParentResult = MissingCapabilities | PimProductTypeParentResult;

export type Protected__PimProductTypeParentsResult = MissingCapabilities | PimProductTypeParentsResult;

export type Protected__PimProductTypePropertyTypeFavourites = MissingCapabilities | PimProductTypePropertyTypeFavourites;

export type Protected__PimProductTypesResult = MissingCapabilities | PimProductTypesResult;

export type Protected__PimRootProductTypesResult = MissingCapabilities | PimRootProductTypesResult;

export type Protected__PimSearchBrandLinesResult = MissingCapabilities | PimSearchBrandLinesResult;

export type Protected__PimSearchBrandsResult = MissingCapabilities | PimSearchBrandsResult;

export type Protected__PimSearchGroupedProductsConnection = MissingCapabilities | PimSearchGroupedProductsConnection;

export type Protected__PimSearchManufacturerProductsConnection = MissingCapabilities | PimSearchManufacturerProductsConnection;

export type Protected__PimSearchProductConnection = MissingPermissions | PimSearchProductConnection;

export type Protected__PimSearchProductSuggestionsResult = MissingCapabilities | PimSearchProductSuggestionsResult;

export type Protected__PimSearchProductTypesResult = MissingCapabilities | PimSearchProductTypesResult;

export type Protected__PimSearchProductsAvailableFiltersResult = MissingCapabilities | PimSearchProductsAvailableFiltersResult;

export type Protected__PimSearchSupplierCataloguesResult = MissingCapabilities | PimSearchSupplierCataloguesResult;

export type Protected__PimSearchSupplierDiscountGroupVariantsResult = MissingCapabilities | PimSearchSupplierDiscountGroupVariantsResult;

export type Protected__PimSearchSupplierOrganisationDiscountGroupImportationsResult = MissingCapabilities | PimSearchSupplierOrganisationDiscountGroupImportationsResult;

export type Protected__PimSupplierProductAlternativesResult = MissingCapabilities | PimSupplierProductAlternativesResult;

export type Protected__PimSupplierProductOrganisationFavouritesResult = MissingCapabilities | PimSupplierProductOrganisationFavouritesResult;

export type Protected__PimSupplierProductRecentViewsResult = MissingCapabilities | PimSupplierProductRecentViewsResult;

export type Protected__PlanSessionConnection = MissingCapabilities | PlanSessionConnection;

export type Protected__ProjectIncomingInvoiceConnection = MissingPermissions | ProjectIncomingInvoiceConnection;

export type Protected__ProjectIncomingQuoteConnection = MissingPermissions | ProjectIncomingQuoteConnection;

export type Protected__ProjectIncomingQuoteRequestResult = MissingPermissions | ProjectIncomingQuoteRequestResult;

export type Protected__ProjectOutgoingInvoiceConnection = MissingCapabilities | MissingPermissions | ProjectOutgoingInvoiceConnection;

export type Protected__ProjectOutgoingQuoteConnection = MissingCapabilities | MissingPermissions | ProjectOutgoingQuoteConnection;

export type Protected__ProjectOutgoingQuoteRequestResult = MissingPermissions | ProjectOutgoingQuoteRequestResult;

export type Protected__ProjectOutgoingQuotesAllowedInvoiceTypesResult = MissingCapabilities | MissingPermissions | ProjectOutgoingQuotesAllowedInvoiceTypesResult;

export type Protected__ProjectRequirementsConnection = MissingPermissions | ProjectRequirementsConnection;

export type Protected__ProjectResult = MissingPermissions | ProjectResult;

export type Protected__QuoteActualCalculation = MissingPermissions | QuoteActualCalculation;

export type Protected__QuoteAgreedCalculation = MissingPermissions | QuoteAgreedCalculation;

export type Protected__QuoteGenerateContractingPdfResult = MissingPermissions | QuoteGenerateContractingPdfResult;

export type Protected__QuoteGeneratePdfResult = MissingPermissions | QuoteGeneratePdfResult;

export type Protected__QuoteItemSapS4HanaDataResult = MissingCapabilities | MissingPermissions | QuoteItemSapS4HanaDataResult;

export type Protected__QuoteResult = MissingPermissions | QuoteResult;

export type Protected__QuoteTemplateConnection = MissingCapabilities | MissingPermissions | QuoteTemplateConnection;

export type Protected__QuoteTemplateLatestAvailableVersionResult = MissingCapabilities | MissingPermissions | QuoteTemplateLatestAvailableVersionResult;

export type Protected__QuoteTemplateLatestResult = MissingCapabilities | MissingPermissions | QuoteTemplateLatestResult;

export type Protected__QuoteTemplateVersionResult = MissingCapabilities | MissingPermissions | QuoteTemplateVersionResult;

export type Protected__RequirementGeneratePdfResult = MissingPermissions | RequirementGeneratePdfResult;

export type Protected__RequirementResult = MissingPermissions | RequirementResult;

export type Protected__RequirementsConnection = MissingPermissions | RequirementsConnection;

export type Protected__SapS4HanaSalesQuotationsResult = MissingPermissions | SapS4HanaSalesQuotationsResult;

export type Protected__SearchTemplateTypesResultConnection = MissingCapabilities | MissingPermissions | SearchTemplateTypesResultConnection;

export type Protected__SearchTemplatesAvailableFiltersResult = MissingCapabilities | MissingPermissions | SearchTemplatesAvailableFiltersResult;

export type Protected__TemplateLinkingItemsToTemplateResult = MissingCapabilities | MissingPermissions | TemplateLinkingItemsToTemplateResult;

export type Protected__TemplateType = MissingCapabilities | MissingPermissions | TemplateType;

export type ProvideRequestFeedbackInput = {
  feedback: RequestRecipientFeedback;
  requestId: Scalars['ID']['input'];
};

export type ProvideRequestFeedbackResult = {
  __typename: 'ProvideRequestFeedbackResult';
  request: Request;
  requestRecipient?: Maybe<RequestRecipient>;
};

export type PublishQuoteInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type PublishQuoteResult = {
  __typename: 'PublishQuoteResult';
  quote: Quote;
};

export type PublishRequestInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type PublishRequestResult = {
  __typename: 'PublishRequestResult';
  request: Request;
};

export type PublishRequestToMarketplaceInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type PublishRequestToMarketplaceResult = {
  __typename: 'PublishRequestToMarketplaceResult';
  request: Request;
};

export type PublishTemplateVersionInput = {
  docId: Scalars['ID']['input'];
};

export type PublishTemplateVersionResult = PublishTemplateVersionResultSuccess | PublishTemplateVersionResultValidationError;

export type PublishTemplateVersionResultSuccess = {
  __typename: 'PublishTemplateVersionResultSuccess';
  quoteTemplate: QuoteTemplate;
};

export type PublishTemplateVersionResultValidationError = {
  __typename: 'PublishTemplateVersionResultValidationError';
  results: Array<CompileDocIsolatedExpressionResultDiagnostic>;
};

export type PublishedVersion = {
  __typename: 'PublishedVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  versionNumber: Scalars['Int']['output'];
};

export type PurchaseOrderTexts = {
  __typename: 'PurchaseOrderTexts';
  closing: Scalars['String']['output'];
  id: Scalars['String']['output'];
  opening: Scalars['String']['output'];
};

export type PurchaseOrderTextsInput = {
  closing?: InputMaybe<Scalars['String']['input']>;
  opening?: InputMaybe<Scalars['String']['input']>;
};

export type QaApproveTaskItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type QaApproveTaskItemResult = {
  __typename: 'QaApproveTaskItemResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
};

export type QaDisapproveTaskItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type QaDisapproveTaskItemResult = {
  __typename: 'QaDisapproveTaskItemResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
};

export type QuantityUnit =
  | 'cm'
  | 'cm2'
  | 'cm3'
  | 'doz'
  | 'gr'
  | 'hl'
  | 'hr'
  | 'kg'
  | 'km'
  | 'l'
  | 'lump_sum'
  | 'm'
  | 'm2'
  | 'm3'
  | 'mm'
  | 'pair'
  | 'piece'
  | 'set'
  | 't';

export type Query = {
  __typename: 'Query';
  _3d_room?: Maybe<_3d_RoomResult>;
  _noop?: Maybe<Scalars['Boolean']['output']>;
  absences: Protected__AbsenceConnection;
  aiDescription: AiDescriptionResult;
  aiRephrase: AiRephraseResult;
  appInfo?: Maybe<AppInfo>;
  availableUsers: Array<AvailableUser>;
  billOfMaterialsGeneratePdf: Protected__BillOfMaterialsGeneratePdfResult;
  branding: Protected__Branding;
  building: Building;
  buildings: BuildingConnection;
  cacheInfo: Scalars['JSON']['output'];
  cart?: Maybe<Cart>;
  carts: CartConnection;
  channel?: Maybe<Channel>;
  compileDocIsolatedExpression: CompileDocIsolatedExpressionResult;
  crmCompanies: Protected__CrmCompanyConnection;
  crmCompany: Protected__CrmCompanyResult;
  crmCompanyChannels: Array<Channel>;
  crmEmailMessage: Protected__CrmMailMessage;
  crmEmailMessages: Protected__CrmEmailMessageConnection;
  crmLinkingInvitation?: Maybe<CrmLinkingInvitation>;
  crmPerson: Protected__CrmPersonResult;
  crmPersons: Protected__CrmPersonConnection;
  customFieldConfig: Array<CustomFieldConfig>;
  datanormOnlineAvailability?: Maybe<DatanormOnlineAvailability>;
  datanormOnlineEntries: Protected__DatanormOnlineEntriesResult;
  docProposedCalculation: DocProposedCalculation;
  getFromCache: Scalars['JSON']['output'];
  getFromCacheExist: Scalars['JSON']['output'];
  globalOrganisation?: Maybe<Organisation>;
  idsConnectAvailability?: Maybe<IdsConnectAvailability>;
  incomingInvoice: Protected__Invoice;
  incomingInvoices: Protected__IncomingInvoiceConnection;
  incomingQuote: Protected__IncomingQuoteResult;
  incomingQuotes: Protected__IncomingQuoteConnection;
  integrations: Protected__IntegrationConnection;
  invoiceCalculation: Protected__InvoiceCalculation;
  itekCompanyOrganisationCredentials: Array<ItekCompanyCredentials>;
  item: Protected__Item;
  itemNextAndPreviousDecisionItem: ItemNextAndPreviousDecisionItemResult;
  itemNextPossibleDecisionItem: ItemNextPossibleDecisionItemResult;
  itemOrSubItemsPropsUsedInOtherItems: Scalars['Boolean']['output'];
  itemPossibleItemTypes: ItemPossibleItemTypesResult;
  items: Protected__ItemsResult;
  m1ApiLeadResponse?: Maybe<Scalars['JSON']['output']>;
  marketplaceOpportunities: Protected__MarketplaceOpportunityConnection;
  marketplaceOpportunity: Protected__MarketplaceOpportunityResult;
  meister1Flows: Array<Meister1Flow>;
  meister1PollForQuoteDocOfLeadId?: Maybe<Array<Scalars['ID']['output']>>;
  meister1PollForRequirementDocOfLeadId?: Maybe<Array<Scalars['ID']['output']>>;
  meister1PollForWebhookProjectContracteeInvitation?: Maybe<WebhookProjectContracteeInvitation>;
  membershipInvitations: Array<OrganisationMembershipInvitation>;
  openMasterdataAvailability?: Maybe<OpenMasterdataAvailability>;
  opportunityQuoteRequest: Protected__OpportunityQuoteRequestResult;
  orderGeneratePdf: Protected__OrderGeneratePdfResult;
  orders: Protected__OrderConnection;
  organisationChannels: Array<Channel>;
  organisationContractingPdfs: OrganisationContractingPdfsResult;
  organisationCounters: Protected__OrganisationCounter;
  organisationDefaults: OrganisationDefaults;
  organisationDocumentSettings: Protected__DocumentSettings;
  organisationEmailSettings: Protected__OrganisationEmailSettings;
  organisationEmailTemplateValuesWithReplacements: OrganisationEmailTemplateValuesWithReplacementsResult;
  organisationEmailTemplates: Array<OrganisationEmailTemplate>;
  organisationIntegration: Protected__OrganisationIntegrationResult;
  organisationIntegrations: Protected__OrganisationIntegrationConnection;
  organisationMembersAvailability: Array<User>;
  organisationMembershipInvitation?: Maybe<OrganisationMembershipInvitation>;
  organisationMemberships: Array<User>;
  organisationPdfSettings: Protected__PdfSettings;
  organisationProfile: OrganisationProfileResult;
  organisationProfileById: OrganisationProfileResult;
  organisationSettings: OrganisationSetting;
  outgoingInvoice: Protected__Invoice;
  outgoingInvoices: Protected__OutgoingInvoiceConnection;
  outgoingQuote: Protected__OutgoingQuoteResult;
  outgoingQuotes: Protected__OutgoingQuoteConnection;
  pendingDocShares: Array<PendingDocShare>;
  pimCurrentSuppliers: Protected__PimCurrentSuppliersResult;
  pimGetAvailableSupplierProductCategoryRoots: Protected__PimGetAvailableSupplierProductCategoryRootsResult;
  pimGetProduct: Protected__PimGetProductResult;
  pimGetProductById: Protected__PimCustomSupplierProduct;
  pimGetProductMetaInfo: Protected__PimProductMetaInfo;
  pimGetSupplierCatalogue: Protected__PimGetSupplierCatalogueResult;
  pimGetSupplierCatalogues: Protected__PimGetSupplierCataloguesResult;
  pimGetSupplierDiscountGroupProducts: Protected__PimGetSupplierDiscountGroupProductsResult;
  pimGetSupplierDiscountGroups: Protected__PimGetSupplierDiscountGroupsResult;
  pimGetSupplierProduct: Protected__PimGetSupplierProductResult;
  pimGetSupplierProductCategoryTree: Protected__PimProductCategoryTreeWithSupplier;
  pimGetSupplierProductSetInfo: PimGetSupplierProductSetInfoResult;
  pimGetThreedModel: Protected__PimGetThreedModelResult;
  pimImportations: Protected__PimImportationsResult;
  pimProductType: Protected__PimProductType;
  pimProductTypeChildren: Protected__PimProductTypeChildrenResult;
  pimProductTypeParent: Protected__PimProductTypeParentResult;
  pimProductTypeParents: Protected__PimProductTypeParentsResult;
  pimProductTypePropertyTypeFavourites: Protected__PimProductTypePropertyTypeFavourites;
  pimProductTypes: Protected__PimProductTypesResult;
  pimRootProductTypes: Protected__PimRootProductTypesResult;
  pimSearchBrandLines: Protected__PimSearchBrandLinesResult;
  pimSearchBrands: Protected__PimSearchBrandsResult;
  pimSearchGroupedProducts: Protected__PimSearchGroupedProductsConnection;
  pimSearchManufacturerProducts: Protected__PimSearchManufacturerProductsConnection;
  pimSearchProductSuggestions: Protected__PimSearchProductSuggestionsResult;
  pimSearchProductTypes: Protected__PimSearchProductTypesResult;
  pimSearchProducts: Protected__PimSearchProductConnection;
  pimSearchProductsAvailableFilters: Protected__PimSearchProductsAvailableFiltersResult;
  pimSearchProductsForDecision: Protected__PimSearchProductConnection;
  pimSearchSupplierCatalogues: Protected__PimSearchSupplierCataloguesResult;
  pimSearchSupplierDiscountGroupVariants: Protected__PimSearchSupplierDiscountGroupVariantsResult;
  pimSearchSupplierOrganisationDiscountGroupImportations: Protected__PimSearchSupplierOrganisationDiscountGroupImportationsResult;
  pimSupplierProductAlternatives: Protected__PimSupplierProductAlternativesResult;
  pimSupplierProductOrganisationFavourites: Protected__PimSupplierProductOrganisationFavouritesResult;
  pimSupplierProductRecentViews: Protected__PimSupplierProductRecentViewsResult;
  planSessions: Protected__PlanSessionConnection;
  project: Protected__ProjectResult;
  projectContracteeInvitation?: Maybe<ProjectContracteeInvitation>;
  projectDocumentsStatus?: Maybe<ProjectDocumentsStatus>;
  projectIncomingInvoices: Protected__ProjectIncomingInvoiceConnection;
  projectIncomingQuoteRequest: Protected__ProjectIncomingQuoteRequestResult;
  projectIncomingQuotes: Protected__ProjectIncomingQuoteConnection;
  projectInfoByToken: ProjectInfoByTokenResult;
  projectOutgoingInvoices: Protected__ProjectOutgoingInvoiceConnection;
  projectOutgoingQuoteRequest: Protected__ProjectOutgoingQuoteRequestResult;
  projectOutgoingQuotes: Protected__ProjectOutgoingQuoteConnection;
  projectOutgoingQuotesAllowedInvoiceTypes: Protected__ProjectOutgoingQuotesAllowedInvoiceTypesResult;
  projectRequirements: Protected__ProjectRequirementsConnection;
  projectRoles: Array<ProjectRole>;
  projects: ProjectConnection;
  quote: Protected__QuoteResult;
  quoteActualCalculation: Protected__QuoteActualCalculation;
  quoteAgreedCalculation: Protected__QuoteAgreedCalculation;
  quoteExternalIdentifier?: Maybe<QuoteExternalIdentifier>;
  quoteGenerateContractingPdf: Protected__QuoteGenerateContractingPdfResult;
  quoteGeneratePdf: Protected__QuoteGeneratePdfResult;
  quoteInvoiceCalculation: QuoteInvoiceCalculation;
  quoteItemSapS4HanaData: Protected__QuoteItemSapS4HanaDataResult;
  quoteProductsDistribution: QuoteProductsDistributionResult;
  quoteSapS4HanaData?: Maybe<QuoteSapS4HanaData>;
  quoteTemplateLatest: Protected__QuoteTemplateLatestResult;
  quoteTemplateLatestAvailableVersion: Protected__QuoteTemplateLatestAvailableVersionResult;
  quoteTemplateVersion: Protected__QuoteTemplateVersionResult;
  quoteTemplates: Protected__QuoteTemplateConnection;
  rawTemplateDataRows: Scalars['JSON']['output'];
  referralInfo?: Maybe<ReferralInfo>;
  requirement: Protected__RequirementResult;
  requirementGeneratePdf: Protected__RequirementGeneratePdfResult;
  requirements: Protected__RequirementsConnection;
  roles: Array<OrganisationRole>;
  sapS4HanaSalesQuotation: SapS4HanaSalesQuotationResult;
  sapS4HanaSalesQuotations: Protected__SapS4HanaSalesQuotationsResult;
  searchCraftsmen: CraftsmanSearchResultConnection;
  searchManufacturers: ManufacturerSearchResultConnection;
  searchQuoteProductsDistributions: SearchQuoteProductsDistributionsResult;
  searchSuppliers: SupplierSearchResultConnection;
  searchTemplateTypes: Protected__SearchTemplateTypesResultConnection;
  searchTemplates: Protected__QuoteTemplateConnection;
  searchTemplatesAvailableFilters: Protected__SearchTemplatesAvailableFiltersResult;
  templateLinkingItemsToTemplate: Protected__TemplateLinkingItemsToTemplateResult;
  templateType: Protected__TemplateType;
  testAwaitPerf?: Maybe<Scalars['Int']['output']>;
  todoItem?: Maybe<TodoItem>;
  todoItems: TodoItemsConnection;
  user?: Maybe<User>;
  userCalendarToken?: Maybe<Scalars['String']['output']>;
  validateEmailAddress?: Maybe<Scalars['Boolean']['output']>;
  viewer?: Maybe<Viewer>;
  viewerPreference?: Maybe<UserPreference>;
  wizardItem: WizardItemResult;
};


export type Query_3d_RoomArgs = {
  docId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  roomRootItemId?: InputMaybe<Scalars['ID']['input']>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};


export type QueryAbsencesArgs = {
  filters?: InputMaybe<AbsencesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAiDescriptionArgs = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  locale: Locale;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAiRephraseArgs = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  locale: Locale;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryBillOfMaterialsGeneratePdfArgs = {
  aggregateProducts: Scalars['Boolean']['input'];
  fields?: InputMaybe<Array<BillOfMaterialsField>>;
  filterSearchTerm?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  itemStatus?: InputMaybe<Array<BillOfMaterialsItemStatus>>;
  itemType?: InputMaybe<Array<BillOfMaterialsItemType>>;
  productArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productSupplierId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
  quoteId?: InputMaybe<Array<Scalars['ID']['input']>>;
  quoteItemId?: InputMaybe<Scalars['ID']['input']>;
  quoteItemStatus?: InputMaybe<BillOfMaterialsQuoteItemStatusFilter>;
  sections?: InputMaybe<Array<BillOfMaterialsSection>>;
};


export type QueryBuildingArgs = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
};


export type QueryBuildingsArgs = {
  filters?: InputMaybe<BuildingsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCartArgs = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
};


export type QueryCartsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChannelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompileDocIsolatedExpressionArgs = {
  input: CompileDocIsolatedExpressionInput;
};


export type QueryCrmCompaniesArgs = {
  filters?: InputMaybe<CrmCompaniesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OrganisationCrmOrganisationsSorting>>;
};


export type QueryCrmCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCrmCompanyChannelsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCrmEmailMessageArgs = {
  messageId: Scalars['ID']['input'];
};


export type QueryCrmEmailMessagesArgs = {
  filters?: InputMaybe<CrmEmailMessagesFilters>;
};


export type QueryCrmLinkingInvitationArgs = {
  refToken: Scalars['String']['input'];
};


export type QueryCrmPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCrmPersonsArgs = {
  filters?: InputMaybe<CrmPersonsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OrganisationCrmUsersSorting>>;
};


export type QueryCustomFieldConfigArgs = {
  filterObjectType?: InputMaybe<CustomFieldObjectType>;
};


export type QueryDatanormOnlineAvailabilityArgs = {
  msOrganisationId: Scalars['ID']['input'];
};


export type QueryDatanormOnlineEntriesArgs = {
  authentication: ItekAuthenticateInput;
};


export type QueryDocProposedCalculationArgs = {
  contractType?: InputMaybe<ContractType>;
  decisionMode?: InputMaybe<ItemCalculationDecisionMode>;
  docId: Scalars['ID']['input'];
  itemOverrides?: InputMaybe<Array<ItemCalculationItemOverrideInput>>;
  minStateLevel?: InputMaybe<ItemBinding>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetFromCacheArgs = {
  cacheType: CacheType;
  docId: Scalars['ID']['input'];
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetFromCacheExistArgs = {
  cacheType: CacheType;
  docId: Scalars['ID']['input'];
  versionNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGlobalOrganisationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIdsConnectAvailabilityArgs = {
  msOrganisationId: Scalars['ID']['input'];
};


export type QueryIncomingInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryIncomingInvoicesArgs = {
  filters?: InputMaybe<IncomingInvoicesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<IncomingInvoicesSorting>>;
};


export type QueryIncomingQuoteArgs = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryIncomingQuotesArgs = {
  filters?: InputMaybe<IncomingQuotesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<IncomingQuotesSorting>>;
};


export type QueryIntegrationsArgs = {
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInvoiceCalculationArgs = {
  docId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  subtotalItemFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryItekCompanyOrganisationCredentialsArgs = {
  msOrganisationId: Scalars['ID']['input'];
};


export type QueryItemArgs = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryItemNextAndPreviousDecisionItemArgs = {
  applyItemActions?: InputMaybe<Array<ApplyItemActionInput>>;
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  decisionContext?: InputMaybe<AskWhen>;
  docId: Scalars['ID']['input'];
  embeddedMode: Scalars['Boolean']['input'];
  itemId: Scalars['ID']['input'];
  itemUuidSeed: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  viewerDecisionRole?: InputMaybe<AskWhom>;
};


export type QueryItemNextPossibleDecisionItemArgs = {
  applyItemActions?: InputMaybe<Array<ApplyItemActionInput>>;
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  decisionContext?: InputMaybe<AskWhen>;
  docId: Scalars['ID']['input'];
  embeddedMode: Scalars['Boolean']['input'];
  itemId?: InputMaybe<Scalars['ID']['input']>;
  itemPathSortable?: InputMaybe<Scalars['String']['input']>;
  itemUuidSeed: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  viewerDecisionRole?: InputMaybe<AskWhom>;
};


export type QueryItemOrSubItemsPropsUsedInOtherItemsArgs = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  skipSubtree?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryItemPossibleItemTypesArgs = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryItemsArgs = {
  docId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryM1ApiLeadResponseArgs = {
  leadId: Scalars['Int']['input'];
};


export type QueryMarketplaceOpportunitiesArgs = {
  filters?: InputMaybe<MarketplaceOpportunitiesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<MarketplaceOpportunitiesSorting>>;
};


export type QueryMarketplaceOpportunityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMeister1PollForQuoteDocOfLeadIdArgs = {
  input: Meister1PollForDocOfLeadIdInput;
};


export type QueryMeister1PollForRequirementDocOfLeadIdArgs = {
  input: Meister1PollForDocOfLeadIdInput;
};


export type QueryMeister1PollForWebhookProjectContracteeInvitationArgs = {
  desiredInvitationId: Scalars['String']['input'];
};


export type QueryMembershipInvitationsArgs = {
  filterState?: InputMaybe<Array<InvitationState>>;
};


export type QueryOpenMasterdataAvailabilityArgs = {
  msOrganisationId: Scalars['ID']['input'];
};


export type QueryOpportunityQuoteRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderGeneratePdfArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  filterProjectId?: InputMaybe<Scalars['ID']['input']>;
  filterStatus?: InputMaybe<OrderStatus>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sorting: Array<OrdersSorting>;
};


export type QueryOrganisationContractingPdfsArgs = {
  filters?: InputMaybe<OrganisationContractingPdfsFilters>;
};


export type QueryOrganisationEmailTemplateValuesWithReplacementsArgs = {
  context?: InputMaybe<SendCrmEmailContextInput>;
  crmCompanyId: Scalars['ID']['input'];
  crmPersonId?: InputMaybe<Scalars['ID']['input']>;
  templateId: Scalars['ID']['input'];
};


export type QueryOrganisationEmailTemplatesArgs = {
  forContext?: InputMaybe<EmailTemplateContext>;
};


export type QueryOrganisationIntegrationArgs = {
  integrationId: IntegrationId;
};


export type QueryOrganisationIntegrationsArgs = {
  filter?: InputMaybe<OrganisationIntegrationsFilter>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganisationMembersAvailabilityArgs = {
  filters?: InputMaybe<OrganisationMembersAvailabilityFilters>;
};


export type QueryOrganisationMembershipInvitationArgs = {
  refToken: Scalars['String']['input'];
};


export type QueryOrganisationMembershipsArgs = {
  filters?: InputMaybe<OrganisationMembershipsFilters>;
};


export type QueryOrganisationProfileArgs = {
  slug: Scalars['String']['input'];
};


export type QueryOrganisationProfileByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOutgoingInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryOutgoingInvoicesArgs = {
  filters?: InputMaybe<OutgoingInvoicesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OutgoingInvoicesSorting>>;
};


export type QueryOutgoingQuoteArgs = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryOutgoingQuotesArgs = {
  filters?: InputMaybe<OutgoingQuotesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OutgoingQuotesSorting>>;
};


export type QueryPimCurrentSuppliersArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryPimGetAvailableSupplierProductCategoryRootsArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryPimGetProductArgs = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPimGetProductByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPimGetProductMetaInfoArgs = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};


export type QueryPimGetSupplierCatalogueArgs = {
  supplierCatalogueId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};


export type QueryPimGetSupplierCataloguesArgs = {
  supplierId: Scalars['ID']['input'];
};


export type QueryPimGetSupplierDiscountGroupProductsArgs = {
  discountGroupId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  supplierId: Scalars['ID']['input'];
};


export type QueryPimGetSupplierDiscountGroupsArgs = {
  filters?: InputMaybe<PimGetSupplierDiscountGroupsFilters>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryPimGetSupplierProductArgs = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPimGetSupplierProductCategoryTreeArgs = {
  supplierId: Scalars['ID']['input'];
  supplierProductCategoryId?: InputMaybe<Scalars['ID']['input']>;
  supplierProductCategoryKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPimGetSupplierProductSetInfoArgs = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};


export type QueryPimGetThreedModelArgs = {
  threedModelId: Scalars['ID']['input'];
};


export type QueryPimImportationsArgs = {
  filters?: InputMaybe<PimImportationsFilters>;
};


export type QueryPimProductTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPimProductTypeChildrenArgs = {
  productTypeId: Scalars['ID']['input'];
};


export type QueryPimProductTypeParentArgs = {
  productTypeId: Scalars['ID']['input'];
};


export type QueryPimProductTypeParentsArgs = {
  productTypeId: Scalars['ID']['input'];
};


export type QueryPimProductTypePropertyTypeFavouritesArgs = {
  productTypeId: Scalars['ID']['input'];
};


export type QueryPimProductTypesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryPimSearchBrandLinesArgs = {
  filter?: InputMaybe<PimSearchBrandLinesFilter>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPimSearchBrandsArgs = {
  filter?: InputMaybe<PimSearchBrandFilter>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPimSearchGroupedProductsArgs = {
  filters?: InputMaybe<PimSearchProductsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  specificFieldQueryFields?: InputMaybe<Array<PimSpecificFieldQueryField>>;
};


export type QueryPimSearchManufacturerProductsArgs = {
  advancedSearchParams?: InputMaybe<PimSearchManufacturerProductsAdvancedSearchParams>;
  filters?: InputMaybe<PimSearchManufacturerProductsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPimSearchProductSuggestionsArgs = {
  q: Scalars['String']['input'];
};


export type QueryPimSearchProductTypesArgs = {
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  productTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPimSearchProductsArgs = {
  filters?: InputMaybe<PimSearchProductsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  specificFieldQueryFields?: InputMaybe<Array<PimSpecificFieldQueryField>>;
};


export type QueryPimSearchProductsAvailableFiltersArgs = {
  filters?: InputMaybe<PimSearchProductsFilters>;
  search?: InputMaybe<Scalars['String']['input']>;
  specificFieldQueryFields?: InputMaybe<Array<PimSpecificFieldQueryField>>;
};


export type QueryPimSearchProductsForDecisionArgs = {
  docId: Scalars['ID']['input'];
  filters?: InputMaybe<PimSearchProductsFilters>;
  itemId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  specificFieldQueryFields?: InputMaybe<Array<PimSpecificFieldQueryField>>;
};


export type QueryPimSearchSupplierCataloguesArgs = {
  filters?: InputMaybe<PimSearchSupplierCataloguesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  supplierCatalogueId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPimSearchSupplierDiscountGroupVariantsArgs = {
  filters?: InputMaybe<PimSearchSupplierDiscountGroupVariantsFilters>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryPimSearchSupplierOrganisationDiscountGroupImportationsArgs = {
  filters?: InputMaybe<PimSearchSupplierOrganisationDiscountGroupImportationsFilters>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryPimSupplierProductAlternativesArgs = {
  articleNumber: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};


export type QueryPimSupplierProductOrganisationFavouritesArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryPimSupplierProductRecentViewsArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryPlanSessionsArgs = {
  filters?: InputMaybe<PlanSessionsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectContracteeInvitationArgs = {
  refToken: Scalars['String']['input'];
};


export type QueryProjectDocumentsStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectIncomingInvoicesArgs = {
  filters?: InputMaybe<IncomingInvoicesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<IncomingInvoicesSorting>>;
};


export type QueryProjectIncomingQuoteRequestArgs = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};


export type QueryProjectIncomingQuotesArgs = {
  filters?: InputMaybe<IncomingQuotesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<IncomingQuotesSorting>>;
};


export type QueryProjectInfoByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryProjectOutgoingInvoicesArgs = {
  filters?: InputMaybe<OutgoingInvoicesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OutgoingInvoicesSorting>>;
};


export type QueryProjectOutgoingQuoteRequestArgs = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};


export type QueryProjectOutgoingQuotesArgs = {
  filters?: InputMaybe<OutgoingQuotesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OutgoingQuotesSorting>>;
};


export type QueryProjectOutgoingQuotesAllowedInvoiceTypesArgs = {
  filters?: InputMaybe<OutgoingQuotesFilters>;
  projectId: Scalars['ID']['input'];
};


export type QueryProjectRequirementsArgs = {
  filters?: InputMaybe<RequirementsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<RequirementsSorting>>;
};


export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<ProjectsFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OrganisationProjectsSorting>>;
};


export type QueryQuoteArgs = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};


export type QueryQuoteActualCalculationArgs = {
  decisionMode?: InputMaybe<ItemCalculationDecisionMode>;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryQuoteAgreedCalculationArgs = {
  decisionMode?: InputMaybe<ItemCalculationDecisionMode>;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryQuoteExternalIdentifierArgs = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryQuoteGenerateContractingPdfArgs = {
  calculationType: QuotePdfCalculationType;
  contractingPdfId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
};


export type QueryQuoteGeneratePdfArgs = {
  calculationType: QuotePdfCalculationType;
  projectId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
};


export type QueryQuoteInvoiceCalculationArgs = {
  docId: Scalars['ID']['input'];
  itemOverrides?: InputMaybe<Array<ItemCalculationItemOverrideInput>>;
  projectId: Scalars['ID']['input'];
  subItemFilter?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryQuoteItemSapS4HanaDataArgs = {
  docId: Scalars['ID']['input'];
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};


export type QueryQuoteProductsDistributionArgs = {
  quoteProductsDistributionId: Scalars['ID']['input'];
};


export type QueryQuoteSapS4HanaDataArgs = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QueryQuoteTemplateLatestArgs = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};


export type QueryQuoteTemplateLatestAvailableVersionArgs = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};


export type QueryQuoteTemplateVersionArgs = {
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
  versionNumber: Scalars['Int']['input'];
};


export type QueryQuoteTemplatesArgs = {
  filters?: InputMaybe<OrganisationQuoteTemplatesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OrganisationQuoteTemplatesSorting>>;
};


export type QueryRawTemplateDataRowsArgs = {
  id: Scalars['ID']['input'];
  versionNumber: Scalars['Int']['input'];
};


export type QueryReferralInfoArgs = {
  refToken: Scalars['String']['input'];
};


export type QueryRequirementArgs = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};


export type QueryRequirementGeneratePdfArgs = {
  projectId: Scalars['ID']['input'];
  requirementId: Scalars['ID']['input'];
};


export type QueryRequirementsArgs = {
  filters?: InputMaybe<RequirementsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<RequirementsSorting>>;
};


export type QuerySapS4HanaSalesQuotationArgs = {
  salesQuotationId: Scalars['ID']['input'];
};


export type QuerySapS4HanaSalesQuotationsArgs = {
  filter?: InputMaybe<SapS4HanaSalesQuotationsFilterInput>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchCraftsmenArgs = {
  filters?: InputMaybe<SearchCraftsmenFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchManufacturersArgs = {
  filters?: InputMaybe<SearchManufacturersFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<QuerySearchManufacturersSorting>>;
};


export type QuerySearchQuoteProductsDistributionsArgs = {
  filters?: InputMaybe<SearchQuoteProductsDistributionsFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchSuppliersArgs = {
  filters?: InputMaybe<SearchSuppliersFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<QuerySearchSuppliersSorting>>;
};


export type QuerySearchTemplateTypesArgs = {
  filter?: InputMaybe<SearchTemplateTypesFilter>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchTemplatesArgs = {
  filters?: InputMaybe<SearchTemplatesFiltersInput>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<SearchTemplatesSortingInput>>;
};


export type QuerySearchTemplatesAvailableFiltersArgs = {
  filters?: InputMaybe<SearchTemplatesFiltersInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTemplateLinkingItemsToTemplateArgs = {
  docId: Scalars['ID']['input'];
};


export type QueryTemplateTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTestAwaitPerfArgs = {
  waitInMs: Scalars['Int']['input'];
};


export type QueryTodoItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTodoItemsArgs = {
  filterAssigneeId?: InputMaybe<Scalars['ID']['input']>;
  filterIsDone?: InputMaybe<Scalars['Boolean']['input']>;
  filterLinkedCrmOrganisationId?: InputMaybe<Scalars['ID']['input']>;
  filterLinkedCrmUserId?: InputMaybe<Scalars['ID']['input']>;
  filterLinkedProjectId?: InputMaybe<Scalars['ID']['input']>;
  filterTimeAfter?: InputMaybe<Scalars['DateTime']['input']>;
  filterTimeBefore?: InputMaybe<Scalars['DateTime']['input']>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sorting: Array<TodoItemsSorting>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserCalendarTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryValidateEmailAddressArgs = {
  emailAddress: Scalars['EmailAddress']['input'];
};


export type QueryViewerPreferenceArgs = {
  key: Scalars['String']['input'];
};


export type QueryWizardItemArgs = {
  applyItemActions?: InputMaybe<Array<ApplyItemActionInput>>;
  contractorId?: InputMaybe<Scalars['ID']['input']>;
  docId: Scalars['ID']['input'];
  embeddedMode: Scalars['Boolean']['input'];
  itemId: Scalars['ID']['input'];
  itemUuidSeed: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySearchManufacturersSortBy =
  | 'name';

export type QuerySearchManufacturersSorting = {
  column: QuerySearchManufacturersSortBy;
  direction: SortDirection;
};

export type QuerySearchSuppliersSortBy =
  | 'name';

export type QuerySearchSuppliersSorting = {
  column: QuerySearchSuppliersSortBy;
  direction: SortDirection;
};

export type Quote = With3DRoomSnapshot & WithAgreementInfo & WithAttachments & WithAuthor & WithBindingInfo & WithCalculation & WithChangeOrderInfo & WithContents & WithCustomFields & WithDocumentInfo & WithPublishingInfo & WithQuoteDetails & WithQuoteTexts & {
  __typename: 'Quote';
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  actors: Array<DocActor>;
  agreedCalculation?: Maybe<ItemCalculation>;
  agreement: Agreement;
  agreementSignature?: Maybe<SignatureExtended>;
  attachments: Array<Attachment>;
  author: User;
  changeDecliningReason: Scalars['String']['output'];
  client?: Maybe<DocActor>;
  containsError: Scalars['Boolean']['output'];
  contents: Array<Content>;
  contractType: ContractType;
  contractee: DocActor;
  contractor: DocActor;
  createdAt: Scalars['DateTime']['output'];
  customFields: Array<CustomFieldField>;
  debugAllProps: Array<DebugAllProp>;
  decliningReason: Scalars['String']['output'];
  defaultMaterialMargin: Scalars['Float']['output'];
  defaultVerticalSettings: Array<QuoteVerticalSetting>;
  description: Scalars['String']['output'];
  discountPercentage: Scalars['Float']['output'];
  discountReason: Scalars['String']['output'];
  docShares: Array<DocShare>;
  duration?: Maybe<Scalars['Int']['output']>;
  earliestDate?: Maybe<Scalars['Date']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  externalIdentifier?: Maybe<Scalars['String']['output']>;
  has3dRoomSnapshot: Scalars['Boolean']['output'];
  headerVisibility: QuoteHeaderVisibility;
  id: Scalars['ID']['output'];
  images: Array<Attachment>;
  incomingRequest?: Maybe<Request>;
  incomingRequestRecipientStatus?: Maybe<RequestRecipientStatus>;
  interpolatedQuoteTexts?: Maybe<QuoteTexts>;
  isBinding: Scalars['Boolean']['output'];
  isDiscountPercentageVisible: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  isSignatureVisible: Scalars['Boolean']['output'];
  items: Array<Item>;
  lastUpdatedAt: Scalars['DateTime']['output'];
  m1?: Maybe<M1>;
  mainContract?: Maybe<Subcontract>;
  materialFactor: Scalars['Float']['output'];
  materialMarginRanges?: Maybe<Array<QuoteMaterialMarginRange>>;
  materialPriceHandling: QuoteMaterialPriceHandling;
  needsAgreement: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  originalProjectNumber: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  pendingDocShares: Array<PendingDocShare>;
  productsVisibility: QuoteProductsVisibility;
  projectId: Scalars['ID']['output'];
  proposedCalculation?: Maybe<ItemCalculation>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  quoteForRequirement?: Maybe<Requirement>;
  quoteTexts?: Maybe<QuoteTexts>;
  requestedDeliveryDate?: Maybe<Scalars['Date']['output']>;
  rootItem: Item;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  showOfferConditions: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  validityDuration: Scalars['Int']['output'];
  vatInfo: VatInfo;
  vatRate: Scalars['Float']['output'];
  vatVisibility: VatVisibility;
  viewerPermissions: Array<PermissionName>;
  workFactor: Scalars['Float']['output'];
  xId?: Maybe<Scalars['String']['output']>;
  xStatus?: Maybe<Scalars['String']['output']>;
};


export type QuoteAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuoteImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuoteItemsArgs = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuoteActualCalculation = {
  __typename: 'QuoteActualCalculation';
  documentCalculation: ItemCalculation;
  itemCalculations: Array<ActualCalculation>;
};

export type QuoteAgreedCalculation = {
  __typename: 'QuoteAgreedCalculation';
  documentCalculation: ItemCalculation;
  itemCalculations: Array<AgreedCalculation>;
};

export type QuoteAllowedInvoiceTypes = {
  __typename: 'QuoteAllowedInvoiceTypes';
  invoiceAllowedTypes: Array<InvoiceType>;
  quoteId: Scalars['ID']['output'];
};

export type QuoteBulkUpdateProductsInput = {
  docId: Scalars['ID']['input'];
  excludeItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type QuoteBulkUpdateProductsResult = {
  __typename: 'QuoteBulkUpdateProductsResult';
  /** Changed items */
  changedItems: Array<Item>;
  /** Changed document */
  doc: Quote;
};

export type QuoteExternalIdentifier = {
  __typename: 'QuoteExternalIdentifier';
  externalIdentifier: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  quoteId: Scalars['ID']['output'];
};

export type QuoteGenerateContractingPdfResult = {
  __typename: 'QuoteGenerateContractingPdfResult';
  file?: Maybe<AttachmentSnapshot>;
};

export type QuoteGeneratePdfResult = {
  __typename: 'QuoteGeneratePdfResult';
  file?: Maybe<AttachmentSnapshot>;
};

export type QuoteHeaderVisibility = {
  __typename: 'QuoteHeaderVisibility';
  isClientNumberVisible: Scalars['Boolean']['output'];
  isContractorAddressVisible: Scalars['Boolean']['output'];
  isContractorContactPersonVisible: Scalars['Boolean']['output'];
  isContractorEmailVisible: Scalars['Boolean']['output'];
  isContractorNameVisible: Scalars['Boolean']['output'];
  isContractorPhoneVisible: Scalars['Boolean']['output'];
  isDocumentNumberVisible: Scalars['Boolean']['output'];
  isEndClientVisible: Scalars['Boolean']['output'];
  isExternalIdentifierVisible: Scalars['Boolean']['output'];
  isProjectNumberVisible: Scalars['Boolean']['output'];
  isPublishDateVisible: Scalars['Boolean']['output'];
};

export type QuoteHeaderVisibilityInput = {
  isClientNumberVisible: Scalars['Boolean']['input'];
  isContractorAddressVisible: Scalars['Boolean']['input'];
  isContractorContactPersonVisible: Scalars['Boolean']['input'];
  isContractorEmailVisible: Scalars['Boolean']['input'];
  isContractorNameVisible: Scalars['Boolean']['input'];
  isContractorPhoneVisible: Scalars['Boolean']['input'];
  isDocumentNumberVisible: Scalars['Boolean']['input'];
  isEndClientVisible: Scalars['Boolean']['input'];
  isExternalIdentifierVisible: Scalars['Boolean']['input'];
  isProjectNumberVisible: Scalars['Boolean']['input'];
  isPublishDateVisible: Scalars['Boolean']['input'];
};

export type QuoteInvoiceCalculation = {
  __typename: 'QuoteInvoiceCalculation';
  documentCalculation: ItemCalculation;
  itemCalculations: Array<ItemInvoiceCalculation>;
};

export type QuoteItemLinkCrmOrganisation = {
  __typename: 'QuoteItemLinkCrmOrganisation';
  crmOrganisation: CrmCompany;
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  item: Item;
  linkAs: Scalars['String']['output'];
};

export type QuoteItemLinkCrmUser = {
  __typename: 'QuoteItemLinkCrmUser';
  crmUser: CrmPerson;
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  item: Item;
  linkAs: Scalars['String']['output'];
};

export type QuoteItemSapS4HanaDataResult = {
  __typename: 'QuoteItemSapS4HanaDataResult';
  items: Array<ItemSapS4HanaData>;
};

export type QuoteMaterialMarginRange = {
  __typename: 'QuoteMaterialMarginRange';
  materialMargin: Scalars['Float']['output'];
  priceFrom: Scalars['Float']['output'];
  priceTo: Scalars['Float']['output'];
};

export type QuoteMaterialMarginRangeInput = {
  materialMargin: Scalars['Float']['input'];
  priceFrom: Scalars['Float']['input'];
  priceTo: Scalars['Float']['input'];
};

export type QuoteMaterialPriceHandling =
  | 'CALCULATED_MARGIN'
  | 'CALCULATED_SELL_PRICE';

export type QuoteModifyInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: QuoteModifyValuesInput;
};

export type QuoteModifyValuesInput = {
  contractType?: InputMaybe<ContractType>;
  defaultMaterialMargin?: InputMaybe<Scalars['Float']['input']>;
  defaultVerticalSettings?: InputMaybe<Array<VerticalSettingInput>>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  discountReason?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  earliestDate?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['String']['input']>;
  headerVisibility?: InputMaybe<QuoteHeaderVisibilityInput>;
  isBinding?: InputMaybe<Scalars['Boolean']['input']>;
  isDiscountPercentageVisible?: InputMaybe<Scalars['Boolean']['input']>;
  isSignatureVisible?: InputMaybe<Scalars['Boolean']['input']>;
  materialFactor?: InputMaybe<Scalars['Float']['input']>;
  materialMarginRanges?: InputMaybe<Array<QuoteMaterialMarginRangeInput>>;
  materialPriceHandling?: InputMaybe<QuoteMaterialPriceHandling>;
  productsVisibility?: InputMaybe<QuoteProductsVisibilityInput>;
  quoteForRequirementId?: InputMaybe<Scalars['ID']['input']>;
  quoteTexts?: InputMaybe<QuoteTextsInput>;
  requestedDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  showOfferConditions?: InputMaybe<Scalars['Boolean']['input']>;
  validityDuration?: InputMaybe<Scalars['Int']['input']>;
  vatInfo?: InputMaybe<VatInfoInput>;
  vatRate?: InputMaybe<Scalars['Float']['input']>;
  vatVisibility?: InputMaybe<VatVisibility>;
  workFactor?: InputMaybe<Scalars['Float']['input']>;
};

export type QuotePdfCalculationType =
  | 'agreed'
  | 'proposed';

export type QuoteProductsDistribution = {
  __typename: 'QuoteProductsDistribution';
  applications: Array<QuoteProductsDistributionApplication>;
  clientEmail?: Maybe<Scalars['EmailAddress']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  quoteProductsDistributionProducts: Array<QuoteProductsDistributionProductRef>;
};

export type QuoteProductsDistributionApplication = {
  __typename: 'QuoteProductsDistributionApplication';
  createdAt: Scalars['Date']['output'];
  docId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  project?: Maybe<Project>;
};

export type QuoteProductsDistributionMapping = {
  __typename: 'QuoteProductsDistributionMapping';
  itemCreated: Scalars['Boolean']['output'];
  itemId: Scalars['ID']['output'];
  productArticleNumber: Scalars['String']['output'];
  productSupplierId: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
};

export type QuoteProductsDistributionProduct = {
  __typename: 'QuoteProductsDistributionProduct';
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
  productArticleNumber: Scalars['String']['output'];
  productSupplierId: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  supplier: Company;
};

export type QuoteProductsDistributionProductRef = {
  __typename: 'QuoteProductsDistributionProductRef';
  id: Scalars['ID']['output'];
  productArticleNumber: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
};

export type QuoteProductsDistributionResult = {
  __typename: 'QuoteProductsDistributionResult';
  products: Array<QuoteProductsDistributionProduct>;
  quoteProductsDistribution: QuoteProductsDistribution;
};

export type QuoteProductsSyncAction = {
  __typename: 'QuoteProductsSyncAction';
  itemId: Scalars['ID']['output'];
  newItem?: Maybe<Scalars['JSON']['output']>;
  oldItem?: Maybe<Scalars['JSON']['output']>;
  type: QuoteProductsSyncActionType;
};

export type QuoteProductsSyncActionType =
  | 'CREATED'
  | 'DELETED'
  | 'UPDATED';

export type QuoteProductsVisibility = {
  __typename: 'QuoteProductsVisibility';
  showDescription: Scalars['Boolean']['output'];
  showImages: ProductsVisibilityImages;
  showTitle: Scalars['Boolean']['output'];
};

export type QuoteProductsVisibilityInput = {
  showDescription: Scalars['Boolean']['input'];
  showImages: ProductsVisibilityImages;
  showTitle: Scalars['Boolean']['input'];
};

export type QuoteResult = {
  __typename: 'QuoteResult';
  quote?: Maybe<Quote>;
};

export type QuoteSapS4HanaBusinessPartner = {
  __typename: 'QuoteSapS4HanaBusinessPartner';
  crmCompany: Company;
  id: Scalars['ID']['output'];
  partnerFunction: Scalars['String']['output'];
  partnerType: SapS4HanaBusinessPartnerPartnerType;
};

export type QuoteSapS4HanaData = {
  __typename: 'QuoteSapS4HanaData';
  businessPartners: Array<QuoteSapS4HanaBusinessPartner>;
  createdAt: Scalars['DateTime']['output'];
  distributionChannel?: Maybe<Scalars['String']['output']>;
  headerTexts: Array<QuoteSapS4HanaHeaderText>;
  id: Scalars['ID']['output'];
  lastSyncedFromSapAt?: Maybe<Scalars['DateTime']['output']>;
  organisationDivision?: Maybe<Scalars['String']['output']>;
  salesOffice?: Maybe<Scalars['String']['output']>;
  salesOrganisation?: Maybe<Scalars['String']['output']>;
  salesQuotationType?: Maybe<Scalars['String']['output']>;
  sapS4HanaDataFieldMetadata: Array<EntityFieldMetadata>;
  updatedAt: Scalars['DateTime']['output'];
  xAvailablePartnerFunctions: Array<QuoteSapS4HanaDataAvailableValue>;
  xAvailableSalesQuotationItemSalesDocumentRejectionReasons: Array<QuoteSapS4HanaDataAvailableValue>;
  xAvailableSalesQuotationItemTexts: Array<QuoteSapS4HanaDataAvailableValue>;
  xAvailableSalesQuotationTexts: Array<QuoteSapS4HanaDataAvailableValue>;
  xBindingPeriodValidityStartDate?: Maybe<Scalars['DateTime']['output']>;
  xCreatedAt?: Maybe<Scalars['Date']['output']>;
  xCreatedByUser?: Maybe<Scalars['String']['output']>;
  xCustomerPaymentTerms?: Maybe<Scalars['String']['output']>;
  xId?: Maybe<Scalars['String']['output']>;
  xPurchaseOrderByCustomer?: Maybe<Scalars['String']['output']>;
  xReferenceDocumentId?: Maybe<Scalars['String']['output']>;
  xResubmissionDate?: Maybe<Scalars['DateTime']['output']>;
  xSalesQuotationDate?: Maybe<Scalars['DateTime']['output']>;
  xSalesQuotationItemSalesDocumentRejectionReasonDefault: Scalars['String']['output'];
  xSdDocumentReason?: Maybe<Scalars['String']['output']>;
  xStatus?: Maybe<Scalars['String']['output']>;
  xTransactionCurrency?: Maybe<Scalars['String']['output']>;
  xUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  xVersionIdentifier?: Maybe<Scalars['String']['output']>;
};

export type QuoteSapS4HanaDataAvailableValue = {
  __typename: 'QuoteSapS4HanaDataAvailableValue';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type QuoteSapS4HanaHeaderText = {
  __typename: 'QuoteSapS4HanaHeaderText';
  id: Scalars['ID']['output'];
  xLongTextId: Scalars['String']['output'];
  xLongTextValue: Scalars['String']['output'];
};

export type QuoteTemplate = With3DRoomSnapshot & WithAttachments & WithAuthor & WithBindingInfo & WithCalculation & WithContents & WithDocumentInfo & WithQuoteTexts & {
  __typename: 'QuoteTemplate';
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  accessType: TemplateAccessType;
  addedToShop: Scalars['Boolean']['output'];
  agreedCalculation?: Maybe<ItemCalculation>;
  attachments: Array<Attachment>;
  author: User;
  containsUnpublishedChanged: Scalars['Boolean']['output'];
  contents: Array<Content>;
  contractType: ContractType;
  createdAt: Scalars['DateTime']['output'];
  customTags: Array<Scalars['String']['output']>;
  debugAllProps: Array<DebugAllProp>;
  defaultMaterialMargin: Scalars['Float']['output'];
  defaultVerticalSettings: Array<QuoteVerticalSetting>;
  description: Scalars['String']['output'];
  discountPercentage: Scalars['Float']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  has3dRoomSnapshot: Scalars['Boolean']['output'];
  hasAnyPublishedVersion: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images: Array<Attachment>;
  implementsTemplateType?: Maybe<TemplateTypeAtRevision>;
  isBinding: Scalars['Boolean']['output'];
  isLikedByMe: Scalars['Boolean']['output'];
  items: Array<Item>;
  latestAvailableVersionNumber?: Maybe<Scalars['Int']['output']>;
  latestPublishedVersionNumber?: Maybe<Scalars['Int']['output']>;
  latestVersionPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  linkedTemplatesByThis: Array<LinkedTemplateRef>;
  linkingTemplatesToThis: Array<LinkingTemplateRef>;
  materialFactor: Scalars['Float']['output'];
  materialMarginRanges?: Maybe<Array<QuoteMaterialMarginRange>>;
  materialPriceHandling: QuoteMaterialPriceHandling;
  number: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  projectId?: Maybe<Scalars['ID']['output']>;
  proposedCalculation?: Maybe<ItemCalculation>;
  publicDescription: Scalars['String']['output'];
  publishedVersions: Array<PublishedVersion>;
  publishingCompany: Company;
  quoteTexts?: Maybe<QuoteTexts>;
  resolvedAsReadModelVersionNumber?: Maybe<Scalars['Int']['output']>;
  resolvedVersionPublishedAt?: Maybe<Scalars['Date']['output']>;
  rootItem: Item;
  sharedOnGallery: Scalars['Boolean']['output'];
  sharedPublicly: Scalars['Boolean']['output'];
  sharedWithOrganisations: Array<Company>;
  shopCatalogProductImage?: Maybe<Attachment>;
  shopCatalogProductImages: Array<Attachment>;
  subscribedToTemplate?: Maybe<TemplateSubscription>;
  title: Scalars['String']['output'];
  tplApplicableFor: Array<TplApplicableFor>;
  unpublishedChanges: Scalars['JSON']['output'];
  usedInOtherTemplates: Scalars['Boolean']['output'];
  vatInfo: VatInfo;
  vatRate: Scalars['Float']['output'];
  vatVisibility: VatVisibility;
  workFactor: Scalars['Float']['output'];
};


export type QuoteTemplateAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuoteTemplateImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuoteTemplateItemsArgs = {
  itemId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuoteTemplateShopCatalogProductImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuoteTemplateConnection = {
  __typename: 'QuoteTemplateConnection';
  edges: Array<QuoteTemplateEdge>;
  totalCount: Scalars['Int']['output'];
};

export type QuoteTemplateEdge = {
  __typename: 'QuoteTemplateEdge';
  node: QuoteTemplate;
};

export type QuoteTemplateLatestAvailableVersionResult = {
  __typename: 'QuoteTemplateLatestAvailableVersionResult';
  quoteTemplate?: Maybe<QuoteTemplate>;
};

export type QuoteTemplateLatestResult = {
  __typename: 'QuoteTemplateLatestResult';
  quoteTemplate?: Maybe<QuoteTemplate>;
};

export type QuoteTemplateMaterialMarginRangeInput = {
  materialMargin: Scalars['Float']['input'];
  priceFrom: Scalars['Float']['input'];
  priceTo: Scalars['Float']['input'];
};

export type QuoteTemplateVersionResult = {
  __typename: 'QuoteTemplateVersionResult';
  quoteTemplate?: Maybe<QuoteTemplate>;
};

export type QuoteTexts = {
  __typename: 'QuoteTexts';
  closing: Scalars['String']['output'];
  id: Scalars['String']['output'];
  opening: Scalars['String']['output'];
};

export type QuoteTextsInput = {
  closing?: InputMaybe<Scalars['String']['input']>;
  opening?: InputMaybe<Scalars['String']['input']>;
};

export type QuoteVerticalSetting = {
  __typename: 'QuoteVerticalSetting';
  averageWorkBuyingPrice: Scalars['Float']['output'];
  averageWorkSellingPrice: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  vertical: Vertical;
};

export type QuotingStatus =
  | 'binding'
  | 'non_binding'
  | 'none';

export type RearrangeOrganisationProjectPhaseChange =
  | 'SWAP_WITH_NEXT'
  | 'SWAP_WITH_PREVIOUS';

export type RearrangeOrganisationProjectPhaseInput = {
  change: RearrangeOrganisationProjectPhaseChange;
  phaseId: Scalars['ID']['input'];
};

export type RearrangeOrganisationProjectPhaseResult = {
  __typename: 'RearrangeOrganisationProjectPhaseResult';
  organisationSettings: OrganisationSetting;
};

export type RecalculateQuoteInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type RecalculateQuoteResult = {
  __typename: 'RecalculateQuoteResult';
  quote: Quote;
};

export type RecalculateQuoteTemplateInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type RecalculateQuoteTemplateResult = {
  __typename: 'RecalculateQuoteTemplateResult';
  quoteTemplate: QuoteTemplate;
};

export type ReceivedLinkingInvitation = WithLogo & {
  __typename: 'ReceivedLinkingInvitation';
  id: Scalars['ID']['output'];
  logo: AttachmentSnapshot;
  publicProfileSlug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type RecommendedTemplate = {
  __typename: 'RecommendedTemplate';
  templateId: Scalars['ID']['output'];
  templateVersionNumber: Scalars['Int']['output'];
};

export type RecommendedTemplateConfig = {
  __typename: 'RecommendedTemplateConfig';
  bindProps: Array<RecommendedTemplateConfigBindProp>;
  templateId: Scalars['ID']['output'];
  templateVersionNumber: Scalars['Int']['output'];
};

export type RecommendedTemplateConfigBindProp = {
  __typename: 'RecommendedTemplateConfigBindProp';
  destKey: Scalars['String']['output'];
  sourceKey: Scalars['String']['output'];
};

export type RecommendedTemplateType = {
  __typename: 'RecommendedTemplateType';
  templateTypeId: Scalars['ID']['output'];
};

export type RecommendedTemplateTypeConfig = {
  __typename: 'RecommendedTemplateTypeConfig';
  templateSearchFilterDefinitions: RecommendedTemplateTypeTemplateSearchFilterDefinitions;
  templateSearchFilterExpressions: RecommendedTemplateTypeTemplateSearchFilterExpressions;
  templateSearchSortingDefinitions: Array<RecommendedTemplateTypeTemplateSearchSortingDefinition>;
  templateType: TemplateTypeAtRevision;
  templateTypeId: Scalars['ID']['output'];
};

export type RecommendedTemplateTypeTemplateSearchFilterDefinitions = {
  __typename: 'RecommendedTemplateTypeTemplateSearchFilterDefinitions';
  id: Scalars['ID']['output'];
  propertyFilters: Array<ItemTemplateSearchFilterPropertyFilter>;
  templateTypeIdFilter?: Maybe<EntitySearchIdFilter>;
};

export type RecommendedTemplateTypeTemplateSearchFilterExpressions = {
  __typename: 'RecommendedTemplateTypeTemplateSearchFilterExpressions';
  id: Scalars['ID']['output'];
  propertyFilters: Array<ItemTemplateSearchFilterPropertyFilterComputed>;
};

export type RecommendedTemplateTypeTemplateSearchSortingDefinition = EntitySearchSortingPropertyValueSorting;

export type RecommendifyLinkedItemInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};

export type RecommendifyLinkedItemResult = {
  __typename: 'RecommendifyLinkedItemResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type ReferralInfo = WithLogo & {
  __typename: 'ReferralInfo';
  id: Scalars['ID']['output'];
  logo: AttachmentSnapshot;
  title: Scalars['String']['output'];
};

export type RejectAllQuotesInRequestInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type RejectAllQuotesInRequestResult = {
  __typename: 'RejectAllQuotesInRequestResult';
  request: Request;
};

export type RelatedQuoteItem = {
  __typename: 'RelatedQuoteItem';
  quote: Quote;
  quoteItem: Item;
};

export type RemoveAbsenceInput = {
  id: Scalars['ID']['input'];
};

export type RemoveAbsenceResult = {
  __typename: 'RemoveAbsenceResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveInvoiceExtraItemInput = {
  docId: Scalars['ID']['input'];
  invoiceExtraItemId: Scalars['ID']['input'];
  invoiceId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type RemoveInvoiceExtraItemResult = {
  __typename: 'RemoveInvoiceExtraItemResult';
  invoice: Invoice;
  invoiceExtraItem: InvoiceExtraItem;
};

export type RemoveItemAttachmentsResult = {
  __typename: 'RemoveItemAttachmentsResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveItemLinkCrmOrganisationInput = {
  crmOrganisationId: Scalars['ID']['input'];
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  linkAs: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type RemoveItemLinkCrmUserInput = {
  crmUserId: Scalars['ID']['input'];
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  linkAs: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type RemoveItemProductInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type RemoveItemProductResult = {
  __typename: 'RemoveItemProductResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type RemoveItemRecommendedTemplateInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
};

export type RemoveItemRecommendedTemplateResult = {
  __typename: 'RemoveItemRecommendedTemplateResult';
  doc: Document;
  item: Item;
};

export type RemoveItemRecommendedTemplateTypeInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  templateTypeId: Scalars['ID']['input'];
};

export type RemoveItemRecommendedTemplateTypeResult = {
  __typename: 'RemoveItemRecommendedTemplateTypeResult';
  doc: Document;
  item: Item;
};

export type RemoveOrderItemsInput = {
  itemIds: Array<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
};

export type RemoveOrderItemsResult = {
  __typename: 'RemoveOrderItemsResult';
  order: Order;
};

export type RemoveOrganisationCapabilityInput = {
  capability: Capabilities;
  organisationId: Scalars['ID']['input'];
};

export type RemoveOrganisationCapabilityResult = {
  __typename: 'RemoveOrganisationCapabilityResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveOrganisationContractingPdfInput = {
  contractingPdfId: Scalars['ID']['input'];
};

export type RemoveOrganisationContractingPdfResult = {
  __typename: 'RemoveOrganisationContractingPdfResult';
  result: Scalars['Boolean']['output'];
};

export type RemoveOrganisationEmailTemplateResult = {
  __typename: 'RemoveOrganisationEmailTemplateResult';
  templateId: Scalars['ID']['output'];
};

export type RemoveOrganisationIntegrationInput = {
  integrationId: IntegrationId;
};

export type RemoveOrganisationIntegrationResult = {
  __typename: 'RemoveOrganisationIntegrationResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveOrganisationProjectPhaseInput = {
  phaseId: Scalars['ID']['input'];
};

export type RemoveOrganisationProjectPhaseResult = {
  __typename: 'RemoveOrganisationProjectPhaseResult';
  organisationSettings: OrganisationSetting;
};

export type RemoveProductAttachmentsResult = {
  __typename: 'RemoveProductAttachmentsResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveProjectAttachmentsResult = {
  __typename: 'RemoveProjectAttachmentsResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveProjectLinkCrmOrganisationInput = {
  crmOrganisationId: Scalars['ID']['input'];
  linkAs: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type RemoveProjectLinkCrmUserInput = {
  crmUserId: Scalars['ID']['input'];
  linkAs: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type RemoveProjectMembershipInput = {
  membershipId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type RemoveProjectMembershipResult = {
  __typename: 'RemoveProjectMembershipResult';
  project: Project;
};

export type RemoveQuoteDocActorInput = {
  docActorId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type RemoveQuoteDocActorResult = {
  __typename: 'RemoveQuoteDocActorResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveQuoteTemplateFromShopInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type RemoveQuoteTemplateFromShopResult = {
  __typename: 'RemoveQuoteTemplateFromShopResult';
  quoteTemplate: QuoteTemplate;
};

export type RemoveRequestAttachmentsResult = {
  __typename: 'RemoveRequestAttachmentsResult';
  ok: Scalars['Boolean']['output'];
};

export type RemoveRequestRecipientInput = {
  projectId: Scalars['ID']['input'];
  recipientSystemOrganisationId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type RemoveRequestRecipientResult = {
  __typename: 'RemoveRequestRecipientResult';
  removedRecipientId: Scalars['ID']['output'];
  request: Request;
};

export type RemoveRequirementDocActorInput = {
  docActorId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type RemoveRequirementDocActorResult = {
  __typename: 'RemoveRequirementDocActorResult';
  ok: Scalars['Boolean']['output'];
};

export type RenameOrganisationProjectPhaseInput = {
  name: Scalars['String']['input'];
  phaseId: Scalars['ID']['input'];
};

export type RenameOrganisationProjectPhaseResult = {
  __typename: 'RenameOrganisationProjectPhaseResult';
  projectPhase: OrganisationProjectPhase;
};

export type RenameProps2KeyInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  newKey: Scalars['String']['input'];
  oldKey: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type RenameProps2KeyResult = {
  __typename: 'RenameProps2KeyResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type Request = WithAttachments & WithProjectInfo & {
  __typename: 'Request';
  address?: Maybe<Address>;
  attachments: Array<Attachment>;
  budget?: Maybe<Money>;
  categories: Array<Skill>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  deadline?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  earliestStart?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  instructions: Scalars['String']['output'];
  marketplace: Scalars['Boolean']['output'];
  marketplaceFullAddress: Scalars['Boolean']['output'];
  mode: ProjectMode;
  number: Scalars['String']['output'];
  overdue: Scalars['Boolean']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['Date']['output']>;
  quote?: Maybe<Quote>;
  quotes: Array<Quote>;
  recipients: Array<RequestRecipient>;
  requestType: RequestType;
  requestorDoc: Requirement;
  requestorDocId: Scalars['ID']['output'];
  requestorOrganisationId: Scalars['ID']['output'];
  requestorProjectId: Scalars['ID']['output'];
  status: RequestStatus;
  ticket: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: ProjectType;
  urgent: Scalars['Boolean']['output'];
  viewerNumActiveQuotes: Scalars['Int']['output'];
  viewerNumActiveRecipients: Scalars['Int']['output'];
  viewerRecipientNumPublishedQuotes: Scalars['Int']['output'];
  viewerRecipientNumQuotes: Scalars['Int']['output'];
  viewerRecipientProject?: Maybe<Project>;
  viewerRecipientStatus: RequestRecipientStatus;
  wonBySystemOrganisationId?: Maybe<Scalars['ID']['output']>;
};


export type RequestAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RequestQuoteArgs = {
  id: Scalars['ID']['input'];
};

export type RequestCrmEmailAttachmentUploadUrlInput = {
  context?: InputMaybe<SendCrmEmailContextInput>;
  crmCompanyId: Scalars['ID']['input'];
  crmPersonId?: InputMaybe<Scalars['ID']['input']>;
  filename: Scalars['String']['input'];
};

export type RequestGaebImportUploadUrlInput = {
  filename: Scalars['String']['input'];
};

export type RequestImportFromJsonUrlInput = {
  filename: Scalars['String']['input'];
};

export type RequestMarketplaceStatus = {
  __typename: 'RequestMarketplaceStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  marketplaceStatus: MarketplaceStatus;
  requestId: Scalars['ID']['output'];
  systemOrganisationId: Scalars['ID']['output'];
};

export type RequestOrganisationLetterheadUploadUrlResult = {
  __typename: 'RequestOrganisationLetterheadUploadUrlResult';
  accessUrl: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type RequestPimCreateThreedModelUploadUrlInput = {
  filename: Scalars['String']['input'];
};

export type RequestProjectAttachmentUploadUrlResult = {
  __typename: 'RequestProjectAttachmentUploadUrlResult';
  url: Scalars['String']['output'];
};

export type RequestRecipient = {
  __typename: 'RequestRecipient';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  recipientContact: Organisation;
  recipientProfileSlug?: Maybe<Scalars['String']['output']>;
  recipientProjectId?: Maybe<Scalars['ID']['output']>;
  recipientQuotes: Array<Quote>;
  recipientStatus: RequestRecipientStatus;
  recipientSystemOrganisationId: Scalars['ID']['output'];
  requestId: Scalars['ID']['output'];
  requestRecipientSuggestionId?: Maybe<Scalars['ID']['output']>;
  source: RequestRecipientSource;
};

export type RequestRecipientFeedback =
  | 'MAYBE'
  | 'NO'
  | 'YES';

export type RequestRecipientSource =
  | 'DIRECT'
  | 'MARKETPLACE';

export type RequestRecipientStatus =
  | 'INITIAL'
  | 'MAYBE'
  | 'NO'
  | 'YES';

export type RequestRecipientSuggestion = {
  __typename: 'RequestRecipientSuggestion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  requestParticipientId: Scalars['ID']['output'];
  suggestedContact?: Maybe<Scalars['JSON']['output']>;
  suggestedSystemOrganisationId?: Maybe<Scalars['ID']['output']>;
};

export type RequestStatus =
  | 'CLOSED'
  | 'CLOSED_FOR_BIDDING'
  | 'OPEN'
  | 'PUBLISHED'
  | 'WITHDRAWN';

export type RequestSupplierOrganisationInput = {
  branchAddress?: InputMaybe<AddressInput>;
  messageBody: Scalars['String']['input'];
  messageSubject: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type RequestSupplierOrganisationResult = {
  __typename: 'RequestSupplierOrganisationResult';
  ok: Scalars['Boolean']['output'];
};

export type RequestType =
  | 'FORWARD'
  | 'RFQ';

export type RequestUploadUrlResult = {
  __typename: 'RequestUploadUrlResult';
  uploadUrl: Scalars['String']['output'];
};

export type RequestXiopdImportUploadUrlInput = {
  filename: Scalars['String']['input'];
};

export type Requirement = With3DRoomSnapshot & WithAttachments & WithAuthor & WithCalculation & WithContents & WithCustomFields & WithDocumentInfo & WithPublishingInfo & {
  __typename: 'Requirement';
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  actors: Array<DocActor>;
  agreedCalculation?: Maybe<ItemCalculation>;
  attachments: Array<Attachment>;
  author: User;
  client?: Maybe<DocActor>;
  containsError: Scalars['Boolean']['output'];
  contents: Array<Content>;
  contractType: ContractType;
  contractee: DocActor;
  contractor?: Maybe<DocActor>;
  createdAt: Scalars['DateTime']['output'];
  customFields: Array<CustomFieldField>;
  description: Scalars['String']['output'];
  discountPercentage: Scalars['Float']['output'];
  docShares: Array<DocShare>;
  has3dRoomSnapshot: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images: Array<Attachment>;
  isPublished: Scalars['Boolean']['output'];
  item?: Maybe<Item>;
  items: Array<Item>;
  lastUpdatedAt: Scalars['DateTime']['output'];
  m1?: Maybe<M1>;
  mainContract?: Maybe<Subcontract>;
  materialFactor: Scalars['Float']['output'];
  number: Scalars['String']['output'];
  originalProjectNumber: Scalars['String']['output'];
  outgoingRequests: Array<Request>;
  owningSystemOrganisationId: Scalars['ID']['output'];
  pendingDocShares: Array<PendingDocShare>;
  projectId: Scalars['ID']['output'];
  proposedCalculation?: Maybe<ItemCalculation>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  requirementRequestWonId?: Maybe<Scalars['ID']['output']>;
  rootItem: Item;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  vatInfo: VatInfo;
  vatRate: Scalars['Float']['output'];
  vatVisibility: VatVisibility;
  viewerPermissions: Array<PermissionName>;
  workFactor: Scalars['Float']['output'];
};


export type RequirementAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RequirementImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type RequirementItemArgs = {
  itemId: Scalars['ID']['input'];
};


export type RequirementOutgoingRequestsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RequirementEdge = {
  __typename: 'RequirementEdge';
  node: RequirementNode;
};

export type RequirementGeneratePdfResult = {
  __typename: 'RequirementGeneratePdfResult';
  file?: Maybe<AttachmentSnapshot>;
};

export type RequirementNode = {
  __typename: 'RequirementNode';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  projectNumber: Scalars['String']['output'];
  publishedAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type RequirementResult = {
  __typename: 'RequirementResult';
  requirement?: Maybe<Requirement>;
};

export type RequirementsConnection = {
  __typename: 'RequirementsConnection';
  edges: Array<RequirementEdge>;
  totalCount: Scalars['Int']['output'];
};

export type RequirementsFilters = {
  docActors?: InputMaybe<Array<DocActorFilterInput>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  origin?: InputMaybe<ProjectDocumentOrigin>;
  projectExcludeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  projectIncludeState?: InputMaybe<Array<ProjectStateMachineStatus>>;
  withoutWonRequest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RequirementsSortBy =
  | 'createdAt'
  | 'number'
  | 'projectNumber'
  | 'publishedAt'
  | 'title';

export type RequirementsSorting = {
  column: RequirementsSortBy;
  direction: SortDirection;
};

export type ResetEliminateDecisionOptionInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type ResetEliminateDecisionOptionResult = {
  __typename: 'ResetEliminateDecisionOptionResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
  parentItem: Item;
};

export type ResetEliminateItemInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type ResetEliminateItemResult = {
  __typename: 'ResetEliminateItemResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
  parentItem: Item;
};

export type ResetQuoteDocActorInput = {
  crmCompanyId: Scalars['ID']['input'];
  docActorId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ResetQuoteDocActorResult = {
  __typename: 'ResetQuoteDocActorResult';
  docActor: DocActor;
};

export type ResetRequirementDocActorInput = {
  crmCompanyId: Scalars['ID']['input'];
  docActorId: Scalars['ID']['input'];
  docActorType: DocActorType;
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type ResetRequirementDocActorResult = {
  __typename: 'ResetRequirementDocActorResult';
  docActor: DocActor;
};

export type ResolveTemplateInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  resolveDepth?: InputMaybe<Scalars['Int']['input']>;
};

export type ResolveTemplateResult = {
  __typename: 'ResolveTemplateResult';
  doc: Document;
  item: Item;
};

export type RestoreTemplateVersionInput = {
  docId: Scalars['ID']['input'];
  versionNumber: Scalars['Int']['input'];
};

export type RestoreTemplateVersionResult = {
  __typename: 'RestoreTemplateVersionResult';
  quoteTemplate: QuoteTemplate;
};

export type RunNonProjectDocumentAttributeExpressionsInput = {
  docId: Scalars['ID']['input'];
};

export type RunProjectDocumentAttributeExpressionsInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type Salutation =
  | 'mr'
  | 'ms';

export type SapCreateBusinessPartnerInput = {
  address: AddressInput;
  email: Scalars['EmailAddress']['input'];
  familyname: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  salutation: Salutation;
};

export type SapCreateBusinessPartnerResult = {
  __typename: 'SapCreateBusinessPartnerResult';
  fioriLaunchPadUrl: Scalars['String']['output'];
};

export type SapS4HanaBusinessPartnerPartnerType =
  | 'CONTACT_PERSON'
  | 'CUSTOMER'
  | 'PERSONNEL'
  | 'SUPPLIER';

export type SapS4HanaCancelItemInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type SapS4HanaCancelItemResult = {
  __typename: 'SapS4HanaCancelItemResult';
  doc: Quote;
  item: Item;
  itemSapS4HanaData: ItemSapS4HanaData;
};

export type SapS4HanaCancelQuoteInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type SapS4HanaCancelQuoteResult = {
  __typename: 'SapS4HanaCancelQuoteResult';
  quote: Quote;
  quoteItemsSapS4HanaData: QuoteItemSapS4HanaDataResult;
  quoteSapS4HanaData?: Maybe<QuoteSapS4HanaData>;
};

export type SapS4HanaGenerateQuotePdfInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type SapS4HanaGenerateQuotePdfResult = {
  __typename: 'SapS4HanaGenerateQuotePdfResult';
  file?: Maybe<AttachmentSnapshot>;
};

export type SapS4HanaModifyHanaItemDataInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  values: SapS4HanaModifyHanaItemDataValuesInput;
};

export type SapS4HanaModifyHanaItemDataResult = {
  __typename: 'SapS4HanaModifyHanaItemDataResult';
  item: Item;
  itemSapS4HanaData: ItemSapS4HanaData;
};

export type SapS4HanaModifyHanaItemDataText = {
  xLongTextId: Scalars['String']['input'];
  xLongTextValue: Scalars['String']['input'];
};

export type SapS4HanaModifyHanaItemDataValuesInput = {
  itemTexts?: InputMaybe<Array<SapS4HanaModifyHanaItemDataText>>;
};

export type SapS4HanaSalesQuotation = {
  __typename: 'SapS4HanaSalesQuotation';
  distributionChannel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<SapS4HanaSalesQuotationItem>;
  organisationDivision?: Maybe<Scalars['String']['output']>;
  salesDistrict?: Maybe<Scalars['String']['output']>;
  salesGroup?: Maybe<Scalars['String']['output']>;
  salesOffice?: Maybe<Scalars['String']['output']>;
  salesOrganisation?: Maybe<Scalars['String']['output']>;
  salesQuotationId: Scalars['ID']['output'];
};

export type SapS4HanaSalesQuotationItem = {
  __typename: 'SapS4HanaSalesQuotationItem';
  alternativeToItem?: Maybe<Scalars['String']['output']>;
  higherLevelItem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  material?: Maybe<Scalars['String']['output']>;
  netAmount?: Maybe<Scalars['Float']['output']>;
  position: Scalars['String']['output'];
  requestedQuantity?: Maybe<Scalars['Float']['output']>;
  salesDocumentRjcnReason?: Maybe<Scalars['String']['output']>;
  salesQuotationItemCategory?: Maybe<Scalars['String']['output']>;
  salesQuotationItemId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type SapS4HanaSalesQuotationResult = {
  __typename: 'SapS4HanaSalesQuotationResult';
  salesQuotation: SapS4HanaSalesQuotation;
};

export type SapS4HanaSalesQuotationsEdge = {
  __typename: 'SapS4HanaSalesQuotationsEdge';
  createdByUser?: Maybe<Scalars['String']['output']>;
  salesQuotationId: Scalars['String']['output'];
};

export type SapS4HanaSalesQuotationsFilterInput = {
  createdAtAfter?: InputMaybe<Scalars['Date']['input']>;
  createdAtBefore?: InputMaybe<Scalars['Date']['input']>;
  salesQuotationId?: InputMaybe<Scalars['String']['input']>;
};

export type SapS4HanaSalesQuotationsResult = {
  __typename: 'SapS4HanaSalesQuotationsResult';
  edges: Array<SapS4HanaSalesQuotationsEdge>;
  totalCount: Scalars['Int']['output'];
};

export type SapS4HanaUncancelItemInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type SapS4HanaUncancelItemResult = {
  __typename: 'SapS4HanaUncancelItemResult';
  doc: Quote;
  item: Item;
  itemSapS4HanaData: ItemSapS4HanaData;
};

export type SapS4HanaUpdateQuoteFromRemoteInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type SapS4HanaUpdateQuoteFromRemoteResult = {
  __typename: 'SapS4HanaUpdateQuoteFromRemoteResult';
  quote: Quote;
  quoteItemsSapS4HanaData: QuoteItemSapS4HanaDataResult;
  quoteSapS4HanaData?: Maybe<QuoteSapS4HanaData>;
};

export type SearchCraftsmenFilters = {
  categories?: InputMaybe<Array<Skill>>;
  myContacts?: InputMaybe<Scalars['Boolean']['input']>;
  omitOrganisationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyMeisterCraftsmen?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchFilterBoolFilterDefinitionInput = {
  operatorBool: EntitySearchBoolFilterOperator;
  valueBool: Scalars['Boolean']['input'];
};

export type SearchFilterBoolFilterExpressionInput = {
  expr: Scalars['String']['input'];
  operatorBool: EntitySearchBoolFilterOperator;
};

export type SearchFilterIdFilterDefinitionInput = {
  operatorId: EntitySearchIdFilterOperator;
  valueId: Scalars['ID']['input'];
};

export type SearchFilterIdInFilterDefinitionInput = {
  operatorIdIn: EntitySearchIdInFilterOperator;
  valueIdIn: Array<Scalars['ID']['input']>;
};

export type SearchFilterNumberBetweenFilterDefinitionInput = {
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
  valueNumberBetween: ValueNumberBetweenInput;
};

export type SearchFilterNumberBetweenFilterExpressionInput = {
  expr: Scalars['String']['input'];
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
};

export type SearchFilterNumberFilterDefinitionInput = {
  operatorNumber: EntitySearchNumberFilterOperator;
  valueNumber: Scalars['Float']['input'];
};

export type SearchFilterNumberFilterExpressionInput = {
  expr: Scalars['String']['input'];
  operatorNumber: EntitySearchNumberFilterOperator;
};

export type SearchFilterPropertyBoolFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorBool: EntitySearchBoolFilterOperator;
  valueBool: Scalars['Boolean']['input'];
};

export type SearchFilterPropertyBoolFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorBool: EntitySearchBoolFilterOperator;
};

export type SearchFilterPropertyBoolInFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorBoolIn: EntitySearchBoolInFilterOperator;
  valueBoolIn: Array<Scalars['Boolean']['input']>;
};

export type SearchFilterPropertyBoolInFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorBoolIn: EntitySearchBoolInFilterOperator;
};

export type SearchFilterPropertyNumberArrayOfFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorNumberArrayOf: EntitySearchArrayOfFilterOperator;
  valueNumberArrayOf: Array<Scalars['Float']['input']>;
};

export type SearchFilterPropertyNumberArrayOfFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorNumberArrayOf: EntitySearchArrayOfFilterOperator;
};

export type SearchFilterPropertyNumberBetweenFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
  valueNumberBetween: ValueNumberBetweenInput;
};

export type SearchFilterPropertyNumberBetweenFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorNumberBetween: EntitySearchNumberBetweenFilterOperator;
};

export type SearchFilterPropertyNumberFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorNumber: EntitySearchNumberFilterOperator;
  valueNumber: Scalars['Float']['input'];
};

export type SearchFilterPropertyNumberFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorNumber: EntitySearchNumberFilterOperator;
};

export type SearchFilterPropertyNumberInFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorNumberIn: EntitySearchNumberInFilterOperator;
  valueNumberIn: Array<Scalars['Float']['input']>;
};

export type SearchFilterPropertyNumberInFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorNumberIn: EntitySearchNumberInFilterOperator;
};

export type SearchFilterPropertyTextArrayOfFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorTextArrayOf: EntitySearchArrayOfFilterOperator;
  valueTextArrayOf: Array<Scalars['String']['input']>;
};

export type SearchFilterPropertyTextArrayOfFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorTextArrayOf: EntitySearchArrayOfFilterOperator;
};

export type SearchFilterPropertyTextFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorText: EntitySearchTextFilterOperator;
  valueText: Scalars['String']['input'];
};

export type SearchFilterPropertyTextFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorText: EntitySearchTextFilterOperator;
};

export type SearchFilterPropertyTextInFilterDefinitionInput = {
  key: Scalars['String']['input'];
  operatorTextIn: EntitySearchTextInFilterOperator;
  valueTextIn: Array<Scalars['String']['input']>;
};

export type SearchFilterPropertyTextInFilterExpressionInput = {
  expr: Scalars['String']['input'];
  key: Scalars['String']['input'];
  operatorTextIn: EntitySearchTextInFilterOperator;
};

export type SearchFilterTextFilterDefinitionInput = {
  operatorText: EntitySearchTextFilterOperator;
  valueText: Scalars['String']['input'];
};

export type SearchFilterTextFilterExpressionInput = {
  expr: Scalars['String']['input'];
  operatorText: EntitySearchTextFilterOperator;
};

export type SearchFilterTextInFilterDefinitionInput = {
  operatorTextIn: EntitySearchTextInFilterOperator;
  valueTextIn: Array<Scalars['String']['input']>;
};

export type SearchFilterTextInFilterExpressionInput = {
  expr: Scalars['String']['input'];
  operatorTextIn: EntitySearchTextInFilterOperator;
};

export type SearchManufacturersFilters = {
  excludeIfAlreadyCrm: Scalars['Boolean']['input'];
  organisationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  withinRadiusKm?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchQuoteProductsDistributionsFilters = {
  applied?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchQuoteProductsDistributionsResult = {
  __typename: 'SearchQuoteProductsDistributionsResult';
  quoteProductsDistributions: Array<QuoteProductsDistribution>;
  totalCount: Scalars['Int']['output'];
};

export type SearchQuoteTemplateConnection = {
  __typename: 'SearchQuoteTemplateConnection';
  edges: Array<SearchTemplatesEdge>;
  totalCount: Scalars['Int']['output'];
};

export type SearchSuppliersFilters = {
  excludeIfAlreadyCrm?: InputMaybe<Scalars['Boolean']['input']>;
  organisationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  withinRadiusKm?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchTemplateTypesFilter = {
  excludeTemplateTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchTemplateTypesResultConnection = {
  __typename: 'SearchTemplateTypesResultConnection';
  edges: Array<SearchTemplateTypesResultEdge>;
  totalCount: Scalars['Int']['output'];
};

export type SearchTemplateTypesResultEdge = {
  __typename: 'SearchTemplateTypesResultEdge';
  node: TemplateType;
};

export type SearchTemplatesAvailableFilterPropertyBool = {
  __typename: 'SearchTemplatesAvailableFilterPropertyBool';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<SearchTemplatesAvailableFilterPropertyValueBool>;
};

export type SearchTemplatesAvailableFilterPropertyNumber = {
  __typename: 'SearchTemplatesAvailableFilterPropertyNumber';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<SearchTemplatesAvailableFilterPropertyNumberValue>;
};

export type SearchTemplatesAvailableFilterPropertyNumberArray = {
  __typename: 'SearchTemplatesAvailableFilterPropertyNumberArray';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<SearchTemplatesAvailableFilterPropertyNumberArrayValue>;
};

export type SearchTemplatesAvailableFilterPropertyNumberArrayValue = {
  __typename: 'SearchTemplatesAvailableFilterPropertyNumberArrayValue';
  count: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type SearchTemplatesAvailableFilterPropertyNumberValue = {
  __typename: 'SearchTemplatesAvailableFilterPropertyNumberValue';
  count: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type SearchTemplatesAvailableFilterPropertyText = {
  __typename: 'SearchTemplatesAvailableFilterPropertyText';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<SearchTemplatesAvailableFilterPropertyTextValue>;
};

export type SearchTemplatesAvailableFilterPropertyTextArray = {
  __typename: 'SearchTemplatesAvailableFilterPropertyTextArray';
  isOrganisationFavourite: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  values: Array<SearchTemplatesAvailableFilterPropertyTextArrayValue>;
};

export type SearchTemplatesAvailableFilterPropertyTextArrayValue = {
  __typename: 'SearchTemplatesAvailableFilterPropertyTextArrayValue';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SearchTemplatesAvailableFilterPropertyTextValue = {
  __typename: 'SearchTemplatesAvailableFilterPropertyTextValue';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SearchTemplatesAvailableFilterPropertyValueBool = {
  __typename: 'SearchTemplatesAvailableFilterPropertyValueBool';
  count: Scalars['Int']['output'];
  value: Scalars['Boolean']['output'];
};

export type SearchTemplatesAvailableFiltersResult = {
  __typename: 'SearchTemplatesAvailableFiltersResult';
  applicableFor: Array<TemplateSearchAvailableFilterApplicableFor>;
  creatorOrganisationIds: Array<TemplateSearchAvailableFilterCreatorOrganisationId>;
  propertiesBool: Array<SearchTemplatesAvailableFilterPropertyBool>;
  propertiesNumber: Array<SearchTemplatesAvailableFilterPropertyNumber>;
  propertiesNumberArray: Array<SearchTemplatesAvailableFilterPropertyNumberArray>;
  propertiesText: Array<SearchTemplatesAvailableFilterPropertyText>;
  propertiesTextArray: Array<SearchTemplatesAvailableFilterPropertyTextArray>;
  rootItemTypes: Array<TemplateSearchAvailableFilterRootItemType>;
  tags: Array<TemplateSearchAvailableFilterTag>;
  templateTypeIds: Array<TemplateSearchAvailableFilterTemplateType>;
};

export type SearchTemplatesEdge = {
  __typename: 'SearchTemplatesEdge';
  node: QuoteTemplate;
};

export type SearchTemplatesFieldSortingInput = {
  direction: SortDirection;
  field: SearchTemplatesSortBy;
};

export type SearchTemplatesFiltersInput = {
  addedToShop?: InputMaybe<Scalars['Boolean']['input']>;
  applicableFor?: InputMaybe<Array<TplApplicableFor>>;
  createdAfter?: InputMaybe<Scalars['Date']['input']>;
  createdBefore?: InputMaybe<Scalars['Date']['input']>;
  creatorOrganisationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  excludeTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceMax?: InputMaybe<Scalars['Float']['input']>;
  priceMin?: InputMaybe<Scalars['Float']['input']>;
  propertiesBool?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  propertiesNumber?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  propertiesNumberArray?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  propertiesText?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  propertiesTextArray?: InputMaybe<Array<EntitySearchFilterPropertyFilterInput>>;
  publishingStatus?: InputMaybe<Array<TemplatePublishingStatus>>;
  rootItemTypes?: InputMaybe<Array<ItemType>>;
  sources?: InputMaybe<Array<TemplateSource>>;
  tagsAny?: InputMaybe<Array<Scalars['String']['input']>>;
  templateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  templateTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SearchTemplatesPropertySortingInput = {
  direction: SortDirection;
  key: Scalars['String']['input'];
  kind: SearchTemplatesPropertySortingKind;
};

export type SearchTemplatesPropertySortingKind =
  | 'number';

export type SearchTemplatesSortBy =
  | 'createdAt'
  | 'priceNetTotal'
  | 'priceTotal'
  | 'title';

export type SearchTemplatesSortingInput = {
  fieldSorting?: InputMaybe<SearchTemplatesFieldSortingInput>;
  propertySorting?: InputMaybe<SearchTemplatesPropertySortingInput>;
};

export type SendChannelMessageResult = {
  __typename: 'SendChannelMessageResult';
  channel: Channel;
  message: Message;
};

export type SendCrmEmailContextInput = {
  id: Scalars['ID']['input'];
  type: SendCrmEmailContextType;
};

export type SendCrmEmailContextType =
  | 'PROJECT'
  | 'QUOTE'
  | 'REQUIREMENT';

export type SendCrmEmailInput = {
  adHocAttachments: Array<SendCrmEmailInputAdHocAttachment>;
  contentHtml: Scalars['String']['input'];
  context?: InputMaybe<SendCrmEmailContextInput>;
  crmCompanyId: Scalars['ID']['input'];
  crmPersonId?: InputMaybe<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  urlAttachments: Array<UrlAttachment>;
};

export type SendCrmEmailInputAdHocAttachment = {
  uploadUrl: Scalars['String']['input'];
};

export type SendCrmLinkingInvitationInput = {
  crmOrganisationId: Scalars['ID']['input'];
  crmPersonId?: InputMaybe<Scalars['ID']['input']>;
  messageBody: Scalars['String']['input'];
  messageSubject: Scalars['String']['input'];
};

export type SendCrmLinkingInvitationResult = {
  __typename: 'SendCrmLinkingInvitationResult';
  crmOrganisation: CrmCompany;
};

export type SendProjectStateEventInput = {
  event: SendProjectStateEventInputEvent;
  projectId: Scalars['ID']['input'];
};

export type SendProjectStateEventInputEvent = {
  CANCEL?: InputMaybe<SendProjectStateEventInputEventCancel>;
  COMPLETE?: InputMaybe<SendProjectStateEventInputEventComplete>;
  CONTRACTEE_DECLINE?: InputMaybe<SendProjectStateEventInputEventContracteeDecline>;
  CONTRACTOR_DECLINE?: InputMaybe<SendProjectStateEventInputEventContractorDecline>;
  WIN?: InputMaybe<SendProjectStateEventInputEventWin>;
};

export type SendProjectStateEventInputEventCancel = {
  dummy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendProjectStateEventInputEventComplete = {
  dummy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendProjectStateEventInputEventContracteeDecline = {
  dummy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendProjectStateEventInputEventContractorDecline = {
  dummy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SendProjectStateEventInputEventWin = {
  dummy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetContentsInput = {
  contents: Array<ContentInput>;
  id: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetContentsResult = {
  __typename: 'SetContentsResult';
  contents: Array<Content>;
  id: Scalars['ID']['output'];
};

export type SetCustomFieldConfigInput = {
  allowedValues?: InputMaybe<Scalars['String']['input']>;
  dataType: CustomFieldDataType;
  group?: InputMaybe<Scalars['String']['input']>;
  isUnique?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  objectType: CustomFieldObjectType;
  showAsColumnInList?: InputMaybe<Scalars['Boolean']['input']>;
  showInCreateForm?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetCustomFieldsInput = {
  objectId: Scalars['ID']['input'];
  objectType: CustomFieldObjectType;
  values: Array<SetCustomFieldsInputValue>;
};

export type SetCustomFieldsInputValue = {
  key: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
};

export type SetCustomFieldsResult = {
  __typename: 'SetCustomFieldsResult';
  customFields: Array<CustomFieldField>;
  id: Scalars['ID']['output'];
};

export type SetItemAttachmentClientVisibilityInput = {
  attachmentId: Scalars['ID']['input'];
  clientVisibility: Scalars['Boolean']['input'];
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemAttachmentClientVisibilityResult = {
  __typename: 'SetItemAttachmentClientVisibilityResult';
  doc: Document;
  item: Item;
};

export type SetItemBindingInput = {
  binding: ItemBinding;
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemBindingResult = {
  __typename: 'SetItemBindingResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  /** Optionally changed another items */
  otherChangedItems: Array<Item>;
};

export type SetItemChildrenVisibilityInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  values: SetItemChildrenVisibilityValuesInput;
};

export type SetItemChildrenVisibilityResult = {
  __typename: 'SetItemChildrenVisibilityResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  /** Optionally changed another items */
  otherChangedItems: Array<Item>;
};

export type SetItemChildrenVisibilityValuesInput = {
  childrenVisibility: ItemChildrenVisibility;
};

export type SetItemManufacturerProductInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  manufacturerProductId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemManufacturerProductResult = {
  __typename: 'SetItemManufacturerProductResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
};

export type SetItemPlacementInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  placementId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemPlacementResult = {
  __typename: 'SetItemPlacementResult';
  item: Item;
};

export type SetItemProductInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemProductResult = {
  __typename: 'SetItemProductResult';
  doc: Document;
  item: Item;
  otherChangedItems: Array<Item>;
};

export type SetItemProductTypeInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  productTypeId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemProductTypeResult = {
  __typename: 'SetItemProductTypeResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
};

export type SetItemProps2Mapping = {
  from: SetItemProps2MappingFrom;
  to: SetItemProps2MappingTo;
};

export type SetItemProps2MappingFrom = {
  expr: Scalars['String']['input'];
};

export type SetItemProps2MappingTo = {
  key: Scalars['String']['input'];
};

export type SetItemProps2MappingsInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  mappings: Array<SetItemProps2Mapping>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetItemProps2MappingsResult = {
  __typename: 'SetItemProps2MappingsResult';
  doc: Document;
  item: Item;
};

export type SetItemProps2SelectionSetter = {
  from: SetItemProps2SelectionSetterFrom;
  to: SetItemProps2SelectionSetterTo;
};

export type SetItemProps2SelectionSetterFrom = {
  expr: Scalars['String']['input'];
};

export type SetItemProps2SelectionSetterTo = {
  id: Scalars['ID']['input'];
  key: Scalars['String']['input'];
};

export type SetItemProps2SelectionSettersInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  propertySelectionSetters: Array<SetItemProps2SelectionSetter>;
};

export type SetItemProps2SelectionSettersResult = {
  __typename: 'SetItemProps2SelectionSettersResult';
  doc: Document;
  item: Item;
};

export type SetItemRecommendedTemplateRankInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  rank: Scalars['Int']['input'];
  templateId: Scalars['ID']['input'];
};

export type SetItemRecommendedTemplateRankResult = {
  __typename: 'SetItemRecommendedTemplateRankResult';
  doc: Document;
  item: Item;
};

export type SetItemRecommendedTemplateTypeRankInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  rank: Scalars['Int']['input'];
  templateTypeId: Scalars['ID']['input'];
};

export type SetItemRecommendedTemplateTypeRankResult = {
  __typename: 'SetItemRecommendedTemplateTypeRankResult';
  doc: Document;
  item: Item;
};

export type SetItemTemplatePlaceholderTemplateInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  templateQuoteId: Scalars['ID']['input'];
  templateQuoteIdAtVersionNumber: Scalars['Int']['input'];
};

export type SetItemTemplatePlaceholderTemplateResult = {
  __typename: 'SetItemTemplatePlaceholderTemplateResult';
  doc: Document;
  item: Item;
};

export type SetOrderArrivedInput = {
  arrivalDate?: InputMaybe<Scalars['Date']['input']>;
  orderId: Scalars['ID']['input'];
};

export type SetOrderArrivedResult = {
  __typename: 'SetOrderArrivedResult';
  order: Order;
};

export type SetOrderItemArrivedInput = {
  itemId: Scalars['ID']['input'];
  numArrived: Scalars['Int']['input'];
  orderId: Scalars['ID']['input'];
};

export type SetOrderItemArrivedResult = {
  __typename: 'SetOrderItemArrivedResult';
  item: OrderItem;
  order: Order;
};

export type SetOrderItemDamagedDeliveryInput = {
  itemId: Scalars['ID']['input'];
  numDamagedDelivery: Scalars['Int']['input'];
  orderId: Scalars['ID']['input'];
};

export type SetOrderItemDamagedDeliveryResult = {
  __typename: 'SetOrderItemDamagedDeliveryResult';
  item: OrderItem;
  order: Order;
};

export type SetOrderItemDamagedOnsiteInput = {
  itemId: Scalars['ID']['input'];
  numDamagedOnsite: Scalars['Int']['input'];
  orderId: Scalars['ID']['input'];
};

export type SetOrderItemDamagedOnsiteResult = {
  __typename: 'SetOrderItemDamagedOnsiteResult';
  item: OrderItem;
  order: Order;
};

export type SetOrderPaidInput = {
  orderId: Scalars['ID']['input'];
  paidDate?: InputMaybe<Scalars['Date']['input']>;
};

export type SetOrderPaidResult = {
  __typename: 'SetOrderPaidResult';
  order: Order;
};

export type SetOrganisationMemberDefaultProjectRolesInput = {
  defaultProjectRoleIds: Array<Scalars['ID']['input']>;
  memberId: Scalars['ID']['input'];
};

export type SetOrganisationMemberDefaultProjectRolesResult = {
  __typename: 'SetOrganisationMemberDefaultProjectRolesResult';
  member: User;
  organisation: Organisation;
};

export type SetOrganisationMemberOrganisationRolesInput = {
  memberId: Scalars['ID']['input'];
  roleIds: Array<Scalars['ID']['input']>;
};

export type SetOrganisationMemberOrganisationRolesResult = {
  __typename: 'SetOrganisationMemberOrganisationRolesResult';
  member: User;
  organisation: Organisation;
};

export type SetOrganisationPdfLetterheadInput = {
  marginBottom: Scalars['Int']['input'];
  marginTopFirstPage: Scalars['Int']['input'];
  marginTopSubsequentPage: Scalars['Int']['input'];
  pdfLetterheadUrl: Scalars['String']['input'];
  textPrimaryColor: Scalars['String']['input'];
  textSecondaryColor: Scalars['String']['input'];
};

export type SetOrganisationPdfLetterheadPayload = {
  __typename: 'SetOrganisationPdfLetterheadPayload';
  pdfSettings: PdfSettings;
};

export type SetPlaceholderForTemplateTypeInput = {
  itemId: Scalars['ID']['input'];
  templateId: Scalars['ID']['input'];
  templateTypeId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetPlaceholderForTemplateTypeResult = {
  __typename: 'SetPlaceholderForTemplateTypeResult';
  ok: Scalars['Boolean']['output'];
};

export type SetProjectAssigneeResult = {
  __typename: 'SetProjectAssigneeResult';
  project: Project;
  tentativeProject?: Maybe<Project>;
};

export type SetQuoteContracteeAddressInput = {
  address: AddressInput;
  docActorId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
};

export type SetQuoteContracteeAddressResult = {
  __typename: 'SetQuoteContracteeAddressResult';
  docActor: DocActor;
};

export type SetQuoteExternalIdentifierInput = {
  docId: Scalars['ID']['input'];
  externalIdentifier?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
};

export type SetQuoteExternalIdentifierResult = {
  __typename: 'SetQuoteExternalIdentifierResult';
  quoteExternalIdentifier?: Maybe<QuoteExternalIdentifier>;
};

export type SetTaskItemDoneInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type SetTaskItemDoneResult = {
  __typename: 'SetTaskItemDoneResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
  otherChangedItems: Array<Item>;
};

export type SetTaskItemNotDoneInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type SetTaskItemNotDoneResult = {
  __typename: 'SetTaskItemNotDoneResult';
  /** Changed document */
  doc: TaskDocument;
  /** Changed item */
  item: Item;
  otherChangedItems: Array<Item>;
};

export type SetTreeHierarchyInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Item id which is being moved */
  itemId: Scalars['ID']['input'];
  /** New parent id */
  parentId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  /** New item rank under a parent */
  rank: Scalars['Int']['input'];
};

export type SetTreeHierarchyResult = {
  __typename: 'SetTreeHierarchyResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  newParentItem: Item;
  oldParentItem: Item;
  /** Optionally changed another items */
  otherChangedItems: Array<Item>;
};

export type SetUnnestedTemplateItemDecisionContingentItemPreselectionInput = {
  decisionContingentItemPreselection?: InputMaybe<Scalars['Boolean']['input']>;
  templateDocId: Scalars['ID']['input'];
  unnestedItemId: Scalars['ID']['input'];
};

export type SetUnnestedTemplateItemDecisionContingentItemPreselectionResult = {
  __typename: 'SetUnnestedTemplateItemDecisionContingentItemPreselectionResult';
  templateDoc: QuoteTemplate;
};


export type SetUnnestedTemplateItemDecisionContingentItemPreselectionResultTemplateDocArgs = {
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};

export type SetUnnestedTemplateItemDecisionSubitemsPreselectionInput = {
  decisionSubitemsPreselection: Array<Scalars['ID']['input']>;
  templateDocId: Scalars['ID']['input'];
  unnestedItemId: Scalars['ID']['input'];
};

export type SetUnnestedTemplateItemDecisionSubitemsPreselectionResult = {
  __typename: 'SetUnnestedTemplateItemDecisionSubitemsPreselectionResult';
  templateDoc: QuoteTemplate;
};


export type SetUnnestedTemplateItemDecisionSubitemsPreselectionResultTemplateDocArgs = {
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};

export type SetUserPreferenceInput = {
  key: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
};

export type SetUserPreferenceResult = {
  __typename: 'SetUserPreferenceResult';
  ok: Scalars['Boolean']['output'];
};

export type ShareDocInput = {
  docId: Scalars['ID']['input'];
  permissions: Array<PermissionName>;
  projectId: Scalars['ID']['input'];
  recipientSystemOrganisationId: Scalars['ID']['input'];
};

export type ShareDocResult = {
  __typename: 'ShareDocResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ShareQuoteTemplatePubliclyInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type ShareQuoteTemplatePubliclyResult = {
  __typename: 'ShareQuoteTemplatePubliclyResult';
  quoteTemplate: QuoteTemplate;
};

export type ShareQuoteTemplateWithOrganisationsInput = {
  quoteTemplateId: Scalars['ID']['input'];
  sharedWithSystemOrganisationIds: Array<Scalars['ID']['input']>;
};

export type ShareQuoteTemplateWithOrganisationsResult = {
  __typename: 'ShareQuoteTemplateWithOrganisationsResult';
  quoteTemplate: QuoteTemplate;
};

export type SharedDocument = {
  __typename: 'SharedDocument';
  docType: DocType;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ShoppableTemplate = WithAttachments & WithBindingInfo & WithCalculation & WithContents & WithDocumentInfo & {
  __typename: 'ShoppableTemplate';
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  agreedCalculation?: Maybe<ItemCalculation>;
  attachments: Array<Attachment>;
  contents: Array<Content>;
  contractType: ContractType;
  createdAt: Scalars['DateTime']['output'];
  customTags: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  discountPercentage: Scalars['Float']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  has3dRoomSnapshot: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images: Array<Attachment>;
  isBinding: Scalars['Boolean']['output'];
  isLikedByMe: Scalars['Boolean']['output'];
  items: Array<Item>;
  lastUpdatedAt: Scalars['DateTime']['output'];
  materialFactor: Scalars['Float']['output'];
  number: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  proposedCalculation?: Maybe<ItemCalculation>;
  publicDescription: Scalars['String']['output'];
  publishingCompany: Company;
  rootItem: Item;
  shopCatalogProductImage?: Maybe<Attachment>;
  shopCatalogProductImages: Array<Attachment>;
  title: Scalars['String']['output'];
  vatInfo: VatInfo;
  vatRate: Scalars['Float']['output'];
  vatVisibility: VatVisibility;
  workFactor: Scalars['Float']['output'];
};


export type ShoppableTemplateAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ShoppableTemplateImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ShoppableTemplateItemsArgs = {
  itemId?: InputMaybe<Scalars['ID']['input']>;
};


export type ShoppableTemplateShopCatalogProductImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ShoppableTemplateConnection = {
  __typename: 'ShoppableTemplateConnection';
  edges: Array<ShoppableTemplateEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ShoppableTemplateEdge = {
  __typename: 'ShoppableTemplateEdge';
  cursor: Scalars['ID']['output'];
  node: ShoppableTemplate;
};

export type ShoppableTemplatesFilters = {
  createdAfter?: InputMaybe<Scalars['Date']['input']>;
  createdBefore?: InputMaybe<Scalars['Date']['input']>;
  has3dShape?: InputMaybe<Scalars['Boolean']['input']>;
  likedByMe?: InputMaybe<Scalars['Boolean']['input']>;
  likedByMyOrganisation?: InputMaybe<Scalars['Boolean']['input']>;
  likedByMyOrganisationExcludeMe?: InputMaybe<Scalars['Boolean']['input']>;
  priceMax?: InputMaybe<Scalars['Float']['input']>;
  priceMin?: InputMaybe<Scalars['Float']['input']>;
  tagsAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SignatureExtended = {
  __typename: 'SignatureExtended';
  attachment: AttachmentSnapshot;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  organisationId: Scalars['ID']['output'];
  place: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type SignatureExtendedInput = {
  date: Scalars['Date']['input'];
  place: Scalars['String']['input'];
  signature: AttachmentInput;
};

export type SimulateSapS4HanaSalesQuoteFromQuoteInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type SimulateSapS4HanaSalesQuoteFromQuoteResult = {
  __typename: 'SimulateSapS4HanaSalesQuoteFromQuoteResult';
  quote: Quote;
};

export type Skill =
  | 'AERATION'
  | 'BALCONY'
  | 'BATHROOM'
  | 'CARPENTER'
  | 'CLIMATE_AND_AIRCONDITIONING'
  | 'CONCRETE_WORK'
  | 'DOORS'
  | 'ELECTRICIAN'
  | 'ENGRAVING'
  | 'FLOORS'
  | 'HEATING'
  | 'KITCHEN'
  | 'LANDSCAPE'
  | 'OTHER'
  | 'PAINTING'
  | 'PLUMBING'
  | 'ROOF'
  | 'SOLAR'
  | 'WARM_WATER'
  | 'WATER_TREATMENT'
  | 'WINDOWS';

export type SortDirection =
  | 'asc'
  | 'desc';

export type StartProjectWorkSessionInput = {
  isBreak: Scalars['Boolean']['input'];
  projectId: Scalars['ID']['input'];
};

export type StartProjectWorkSessionResult = {
  __typename: 'StartProjectWorkSessionResult';
  project: Project;
  workSession: WorkSession;
};

export type Subcontract = {
  __typename: 'Subcontract';
  id: Scalars['ID']['output'];
  originItem: Item;
  originProject: ProjectInfo;
  originQuote: Quote;
  subProject: ProjectInfo;
  subRequirement: Requirement;
};

export type SubcontractInput = {
  docId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  rootItemDescription?: InputMaybe<Scalars['String']['input']>;
  rootItemTitle?: InputMaybe<Scalars['String']['input']>;
  subcontractProjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubcontractResult = {
  __typename: 'SubcontractResult';
  item: Item;
  subcontract: Subcontract;
};

export type SubmitOrderResult = {
  __typename: 'SubmitOrderResult';
  order: Order;
};

export type SubscribeToQuoteTemplateInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type SubscribeToQuoteTemplateResult = {
  __typename: 'SubscribeToQuoteTemplateResult';
  quoteTemplate: QuoteTemplate;
};

export type SupplierSearchResult = {
  __typename: 'SupplierSearchResult';
  crmCompany?: Maybe<CrmCompany>;
  distance?: Maybe<Scalars['Float']['output']>;
  organisation: Company;
};

export type SupplierSearchResultConnection = {
  __typename: 'SupplierSearchResultConnection';
  edges: Array<SupplierSearchResultEdge>;
  totalCount: Scalars['Int']['output'];
};

export type SupplierSearchResultEdge = {
  __typename: 'SupplierSearchResultEdge';
  node: SupplierSearchResult;
};

export type SyncProductsInQuoteFromGaebP94Input = {
  dataUrl: Scalars['String']['input'];
  defaultSupplierOrganisationId: Scalars['ID']['input'];
  docId: Scalars['ID']['input'];
  opts?: InputMaybe<SyncProductsInQuoteOptsInput>;
  projectId: Scalars['ID']['input'];
};

export type SyncProductsInQuoteFromGaebP94Result = {
  __typename: 'SyncProductsInQuoteFromGaebP94Result';
  quoteProductsSyncActions: Array<QuoteProductsSyncAction>;
  rootItemProps2: Array<Scalars['JSON']['output']>;
};

export type SyncProductsInQuoteInput = {
  docId: Scalars['ID']['input'];
  opts?: InputMaybe<SyncProductsInQuoteOptsInput>;
  products: Array<SyncProductsInQuoteProductInput>;
  projectId: Scalars['ID']['input'];
};

export type SyncProductsInQuoteOptsInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  rootSubTreeItemId?: InputMaybe<Scalars['ID']['input']>;
};

export type SyncProductsInQuoteProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gtin?: InputMaybe<Scalars['String']['input']>;
  listPrice?: InputMaybe<Scalars['Float']['input']>;
  netPrice?: InputMaybe<Scalars['Float']['input']>;
  productArticleNumber: Scalars['String']['input'];
  productSupplierId: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type SyncProductsInQuoteResult = {
  __typename: 'SyncProductsInQuoteResult';
  quoteProductsSyncActions: Array<QuoteProductsSyncAction>;
  rootItemProps2: Array<Scalars['JSON']['output']>;
};

export type SystemLabels = {
  __typename: 'SystemLabels';
  column: Scalars['String']['output'];
  disabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type TagGroup =
  | 'CRM_COMPANY'
  | 'CRM_PERSON'
  | 'TEMPLATE';

export type TaskDocument = WithAgreementInfo & WithAttachments & WithAuthor & WithBindingInfo & WithChangeOrderInfo & WithCustomFields & WithDocumentInfo & WithPublishingInfo & {
  __typename: 'TaskDocument';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  actors: Array<DocActor>;
  agreement: Agreement;
  agreementSignature?: Maybe<SignatureExtended>;
  attachments: Array<Attachment>;
  author: User;
  changeDecliningReason: Scalars['String']['output'];
  client?: Maybe<DocActor>;
  contractee: DocActor;
  contractor: DocActor;
  createdAt: Scalars['DateTime']['output'];
  customFields: Array<CustomFieldField>;
  decliningReason: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: Array<Attachment>;
  isBinding: Scalars['Boolean']['output'];
  isPublished: Scalars['Boolean']['output'];
  isSignatureVisible: Scalars['Boolean']['output'];
  items: Array<Item>;
  lastUpdatedAt: Scalars['DateTime']['output'];
  needsAgreement: Scalars['Boolean']['output'];
  number: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  viewerPermissions: Array<PermissionName>;
  workSessions: Array<WorkSession>;
};


export type TaskDocumentAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TaskDocumentImagesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type TaskDocumentItemsArgs = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
};


export type TaskDocumentWorkSessionsArgs = {
  assigneeId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type TemplateAccessType =
  | 'explicit'
  | 'implicit';

export type TemplateBulkUpdateProductsInput = {
  docId: Scalars['ID']['input'];
  excludeItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TemplateBulkUpdateProductsResult = {
  __typename: 'TemplateBulkUpdateProductsResult';
  /** Changed items */
  changedItems: Array<Item>;
  /** Changed document */
  doc: QuoteTemplate;
};

export type TemplateLinkingItemsToTemplateResult = {
  __typename: 'TemplateLinkingItemsToTemplateResult';
  templates: Array<TemplateLinkingItemsToTemplateResultTemplate>;
};

export type TemplateLinkingItemsToTemplateResultTemplate = {
  __typename: 'TemplateLinkingItemsToTemplateResultTemplate';
  doc: QuoteTemplate;
  linkingItems: Array<Item>;
};

export type TemplatePublishingStatus =
  | 'DRAFT'
  | 'PRIVATE'
  | 'PROTECTED'
  | 'PUBLIC';

export type TemplateSearchAvailableFilterApplicableFor = {
  __typename: 'TemplateSearchAvailableFilterApplicableFor';
  count: Scalars['Int']['output'];
  value: TplApplicableFor;
};

export type TemplateSearchAvailableFilterCreatorOrganisationId = {
  __typename: 'TemplateSearchAvailableFilterCreatorOrganisationId';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  organisation: Company;
};

export type TemplateSearchAvailableFilterRootItemType = {
  __typename: 'TemplateSearchAvailableFilterRootItemType';
  count: Scalars['Int']['output'];
  value: ItemType;
};

export type TemplateSearchAvailableFilterTag = {
  __typename: 'TemplateSearchAvailableFilterTag';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type TemplateSearchAvailableFilterTemplateType = {
  __typename: 'TemplateSearchAvailableFilterTemplateType';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  templateType: TemplateType;
};

export type TemplateSearchFilterDefinitionsInput = {
  propertyFilterDefinitions: Array<TemplateSearchFilterPropertyFilterDefinitionInput>;
};

export type TemplateSearchFilterExpressionsInput = {
  propertyFilterExpressions: Array<TemplateSearchFilterPropertyFilterExpressionInput>;
};

export type TemplateSearchFilterPropertyFilterDefinitionInput = {
  boolFilter?: InputMaybe<SearchFilterPropertyBoolFilterDefinitionInput>;
  boolInFilter?: InputMaybe<SearchFilterPropertyBoolInFilterDefinitionInput>;
  numberArrayOfFilter?: InputMaybe<SearchFilterPropertyNumberArrayOfFilterDefinitionInput>;
  numberBetweenFilter?: InputMaybe<SearchFilterPropertyNumberBetweenFilterDefinitionInput>;
  numberFilter?: InputMaybe<SearchFilterPropertyNumberFilterDefinitionInput>;
  numberInFilter?: InputMaybe<SearchFilterPropertyNumberInFilterDefinitionInput>;
  textArrayOfFilter?: InputMaybe<SearchFilterPropertyTextArrayOfFilterDefinitionInput>;
  textFilter?: InputMaybe<SearchFilterPropertyTextFilterDefinitionInput>;
  textInFilter?: InputMaybe<SearchFilterPropertyTextInFilterDefinitionInput>;
};

export type TemplateSearchFilterPropertyFilterExpressionInput = {
  boolFilter?: InputMaybe<SearchFilterPropertyBoolFilterExpressionInput>;
  boolInFilter?: InputMaybe<SearchFilterPropertyBoolInFilterExpressionInput>;
  numberArrayOfFilter?: InputMaybe<SearchFilterPropertyNumberArrayOfFilterExpressionInput>;
  numberBetweenFilter?: InputMaybe<SearchFilterPropertyNumberBetweenFilterExpressionInput>;
  numberFilter?: InputMaybe<SearchFilterPropertyNumberFilterExpressionInput>;
  numberInFilter?: InputMaybe<SearchFilterPropertyNumberInFilterExpressionInput>;
  textArrayOfFilter?: InputMaybe<SearchFilterPropertyTextArrayOfFilterExpressionInput>;
  textFilter?: InputMaybe<SearchFilterPropertyTextFilterExpressionInput>;
  textInFilter?: InputMaybe<SearchFilterPropertyTextInFilterExpressionInput>;
};

export type TemplateSearchSortingDefinitionInput = {
  propertySorting?: InputMaybe<TemplateSearchSortingPropertySortingDefinitionInput>;
};

export type TemplateSearchSortingPropertySortingDefinitionInput = {
  direction: SortDirection;
  key: Scalars['String']['input'];
  kind: TemplateSearchSortingPropertySortingDefinitionKind;
};

export type TemplateSearchSortingPropertySortingDefinitionKind =
  | 'number';

export type TemplateShareStatus =
  | 'PRIVATE'
  | 'PROTECTED'
  | 'PUBLIC';

export type TemplateSource =
  | 'available'
  | 'mine_published'
  | 'mine_unpublished'
  | 'subscribable'
  | 'subscribed';

export type TemplateSubscription = {
  __typename: 'TemplateSubscription';
  subscribedVersionNumber: Scalars['Int']['output'];
  updateAvailable: Scalars['Boolean']['output'];
};

export type TemplateType = {
  __typename: 'TemplateType';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  props2: Array<Props2NonComputed>;
  revision: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type TemplateTypeAtRevision = {
  __typename: 'TemplateTypeAtRevision';
  atRevision: Scalars['Int']['output'];
  templateType: TemplateType;
};

export type TemplateTypeImplementor = {
  __typename: 'TemplateTypeImplementor';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type TemplateTypeProps2Entry = {
  bool?: InputMaybe<TemplateTypeProps2EntryBool>;
  number?: InputMaybe<TemplateTypeProps2EntryNumber>;
  numberArray?: InputMaybe<TemplateTypeProps2EntryNumberArray>;
  text?: InputMaybe<TemplateTypeProps2EntryText>;
  textArray?: InputMaybe<TemplateTypeProps2EntryTextArray>;
};

export type TemplateTypeProps2EntryBool = {
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueBool?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TemplateTypeProps2EntryNumber = {
  allowedValuesNumber: Array<Props2AllowedValuesNumberInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  range?: InputMaybe<Props2NumberRangeInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
};

export type TemplateTypeProps2EntryNumberArray = {
  allowedValuesNumber: Array<Props2AllowedValuesNumberInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  range?: InputMaybe<Props2NumberRangeInput>;
  unit?: InputMaybe<Scalars['String']['input']>;
  valueNumberArray?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type TemplateTypeProps2EntryText = {
  allowedValuesText: Array<Props2AllowedValuesTextInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueText?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateTypeProps2EntryTextArray = {
  allowedValuesText: Array<Props2AllowedValuesTextInput>;
  askWhen: Array<Props2AskWhen>;
  askWhom: Array<Props2AskWhom>;
  clientVisibility: Scalars['Boolean']['input'];
  essential: Scalars['Boolean']['input'];
  group: Scalars['String']['input'];
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  valueTextArray?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TemplateVariantItemConfigurationInput = {
  decisionContingentItemPreselection?: InputMaybe<Scalars['Boolean']['input']>;
  decisionSubitemsPreselection?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
};

export type TemplateVersion =
  | 'latestPublished'
  | 'latestUnpublished';

export type TimeReport = {
  __typename: 'TimeReport';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  contracteeSignature?: Maybe<SignatureExtended>;
  contractorSignature?: Maybe<SignatureExtended>;
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<AttachmentSnapshot>;
  fromDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  items: Array<TimeReportItem>;
  number: Scalars['String']['output'];
  organisation: Organisation;
  project: Project;
  tasksDescription?: Maybe<Scalars['JSON']['output']>;
  tillDate: Scalars['Date']['output'];
  timeReportTexts?: Maybe<TimeReportTexts>;
};

export type TimeReportItem = {
  __typename: 'TimeReportItem';
  assignee: User;
  date: Scalars['Date']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  timeReport?: Maybe<TimeReport>;
};

export type TimeReportSettings = {
  __typename: 'TimeReportSettings';
  timeReportTexts: OrganisationPdfTexts;
};

export type TimeReportTexts = {
  __typename: 'TimeReportTexts';
  closing: Scalars['String']['output'];
  id: Scalars['String']['output'];
  opening: Scalars['String']['output'];
};

export type TimeReportTextsInput = {
  closing?: InputMaybe<Scalars['String']['input']>;
  opening?: InputMaybe<Scalars['String']['input']>;
};

export type TodoItem = {
  __typename: 'TodoItem';
  assignee?: Maybe<User>;
  attachments: Array<AttachmentSnapshot>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDone: Scalars['Boolean']['output'];
  linkedCrmOrganisation?: Maybe<CrmCompany>;
  linkedCrmUser?: Maybe<CrmPerson>;
  linkedProject?: Maybe<Project>;
  owningOrganisation: Organisation;
  time?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type TodoItemEdge = {
  __typename: 'TodoItemEdge';
  node: TodoItemNode;
};

export type TodoItemNode = {
  __typename: 'TodoItemNode';
  assignee?: Maybe<TodoItemUser>;
  attachments: Array<AttachmentSnapshot>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedById?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdById: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDone: Scalars['Boolean']['output'];
  linkedCrmOrganisation?: Maybe<TodoItemOrganisation>;
  linkedCrmUser?: Maybe<TodoItemUser>;
  linkedProject?: Maybe<TodoItemProject>;
  time?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type TodoItemOrganisation = {
  __typename: 'TodoItemOrganisation';
  crmOwningOrganisationId: Scalars['ID']['output'];
  id: Scalars['String']['output'];
  logo?: Maybe<AttachmentSnapshot>;
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type TodoItemProject = {
  __typename: 'TodoItemProject';
  deadline?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  state: ProjectStateMachineStatus;
  title: Scalars['String']['output'];
};

export type TodoItemUser = {
  __typename: 'TodoItemUser';
  avatar?: Maybe<AttachmentSnapshot>;
  familyname?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  fullname?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type TodoItemsConnection = {
  __typename: 'TodoItemsConnection';
  edges: Array<TodoItemEdge>;
  totalCount: Scalars['Int']['output'];
};

export type TodoItemsSortBy =
  | 'completedAt'
  | 'createdAt'
  | 'time';

export type TodoItemsSorting = {
  column: TodoItemsSortBy;
  direction: SortDirection;
};

export type ToggleDecisionOptionPreselectionInput = {
  decisionOptionId: Scalars['ID']['input'];
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type ToggleDecisionOptionPreselectionResult = {
  __typename: 'ToggleDecisionOptionPreselectionResult';
  /** Changed document */
  doc: Document;
  /** Changed item */
  item: Item;
  /** Optionally changed another items */
  otherChangedItems: Array<Item>;
};

export type ToggleOrganisationMembershipActiveInput = {
  active: Scalars['Boolean']['input'];
  memberId: Scalars['ID']['input'];
};

export type ToggleOrganisationMembershipActiveResult = {
  __typename: 'ToggleOrganisationMembershipActiveResult';
  member: User;
};

export type TplApplicableFor =
  | 'BUILDING'
  | 'QUOTE'
  | 'REQUIREMENT';

export type UnlikeQuoteTemplateInput = {
  docId: Scalars['ID']['input'];
};

export type UnlikeQuoteTemplateResult = {
  __typename: 'UnlikeQuoteTemplateResult';
  doc: QuoteTemplate;
};

export type UnlikeShopTemplateInput = {
  docId: Scalars['ID']['input'];
};

export type UnlikeShopTemplateResult = {
  __typename: 'UnlikeShopTemplateResult';
  doc: ShoppableTemplate;
};

export type UnpublishQuoteInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};

export type UnpublishQuoteResult = {
  __typename: 'UnpublishQuoteResult';
  quote: Quote;
};

export type UnpublishRequestToMarketplaceInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type UnpublishRequestToMarketplaceResult = {
  __typename: 'UnpublishRequestToMarketplaceResult';
  request: Request;
};

export type UnshareDocInput = {
  docId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  recipientSystemOrganisationId: Scalars['ID']['input'];
};

export type UnshareDocResult = {
  __typename: 'UnshareDocResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UnsubscribeFromQuoteTemplateInput = {
  quoteTemplateId: Scalars['ID']['input'];
};

export type UnsubscribeFromQuoteTemplateResult = {
  __typename: 'UnsubscribeFromQuoteTemplateResult';
  quoteTemplate: QuoteTemplate;
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionInput = {
  docId: Scalars['ID']['input'];
  excludeItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResult = {
  __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResult';
  templates: Array<UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResult>;
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateError = {
  __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateError';
  errorMessage: Scalars['String']['output'];
  template: QuoteTemplate;
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItem = {
  __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItem';
  item: Item;
  result: UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResult;
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResult = UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultSuccess | UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultValidationError;

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultSuccess = {
  __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultSuccess';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultValidationError = {
  __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItemResultValidationError';
  results: Array<CompileDocIsolatedExpressionResultDiagnostic>;
};

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResult = UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateError | UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResultSuccess;

export type UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResultSuccess = {
  __typename: 'UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateResultSuccess';
  linkingItems: Array<UpdateAllLinkingItemsToTemplateToLatestAvailableVersionResultTemplateLinkingItem>;
  publishedNewVersion: Scalars['Boolean']['output'];
  template: QuoteTemplate;
};

export type UpdateItekDataResult = {
  __typename: 'UpdateItekDataResult';
  ok: Scalars['Boolean']['output'];
};

export type UpdateLinkedItemToLatestAvailablePublishedVersionInput = {
  docId: Scalars['ID']['input'];
  linkingItemId: Scalars['ID']['input'];
  newPropertyMappings?: InputMaybe<Array<SetItemProps2Mapping>>;
};

export type UpdateLinkedItemToLatestAvailablePublishedVersionResult = UpdateLinkedItemToLatestAvailablePublishedVersionResultSuccess | UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError;

export type UpdateLinkedItemToLatestAvailablePublishedVersionResultSuccess = {
  __typename: 'UpdateLinkedItemToLatestAvailablePublishedVersionResultSuccess';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError = {
  __typename: 'UpdateLinkedItemToLatestAvailablePublishedVersionResultValidationError';
  results: Array<CompileDocIsolatedExpressionResultDiagnostic>;
};

export type UpdateOrganisationIntegrationInput = {
  foreignAccountId?: InputMaybe<Scalars['String']['input']>;
  integrationId: IntegrationId;
  settings: Scalars['JSON']['input'];
};

export type UpdateOrganisationIntegrationResult = {
  __typename: 'UpdateOrganisationIntegrationResult';
  organisationIntegration: OrganisationIntegration;
};

export type UpdatePlanSessionsInput = {
  create: Array<CreatePlanSessionInput>;
  delete: Array<Scalars['ID']['input']>;
  modify: Array<ModifyPlanSessionInput>;
  projectId: Scalars['ID']['input'];
};

export type UpdatePlanSessionsResult = {
  __typename: 'UpdatePlanSessionsResult';
  ok: Scalars['Boolean']['output'];
  project: Project;
};

export type UpdateProductPricingsInQuoteInput = {
  docId: Scalars['ID']['input'];
  excludeItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  onlyItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  opts?: InputMaybe<UpdateProductPricingsInQuoteOptsInput>;
  projectId: Scalars['ID']['input'];
  supplierDiscountGroupVariantId?: InputMaybe<Scalars['ID']['input']>;
  supplierId: Scalars['ID']['input'];
};

export type UpdateProductPricingsInQuoteOptsInput = {
  materialMargin?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateProductPricingsInQuoteResult = {
  __typename: 'UpdateProductPricingsInQuoteResult';
  changedItems: Array<Item>;
  quote: Quote;
};

export type UpdateSapS4HanaSalesQuoteFromQuoteInput = {
  docId: Scalars['ID']['input'];
  expandedItemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  projectId: Scalars['ID']['input'];
};

export type UpdateSapS4HanaSalesQuoteFromQuoteResult = {
  __typename: 'UpdateSapS4HanaSalesQuoteFromQuoteResult';
  quote: Quote;
};

export type UpdateUserResult = {
  __typename: 'UpdateUserResult';
  user: Viewer;
};

export type UrlAttachment = {
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type User = WithAvatar & WithContactInfo & WithPersonInfo & {
  __typename: 'User';
  active: Scalars['Boolean']['output'];
  avatar: AttachmentSnapshot;
  defaultProjectRoles: Array<ProjectRole>;
  description: Scalars['String']['output'];
  distanceToProject?: Maybe<Scalars['Float']['output']>;
  email: Scalars['EmailAddress']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  homeAddress?: Maybe<Address>;
  id: Scalars['ID']['output'];
  isMeister: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  notices: Scalars['String']['output'];
  notifyEventsByEmail: Array<Scalars['String']['output']>;
  organisationId: Scalars['ID']['output'];
  organisationTitle: Scalars['ID']['output'];
  phones: Array<Phone>;
  roles: Array<OrganisationRole>;
  skillset: Array<Skill>;
  title: Salutation;
  type: UserType;
  user2UserChannelId?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};


export type UserDistanceToProjectArgs = {
  projectId: Scalars['ID']['input'];
};

export type UserModifyInput = {
  avatar?: InputMaybe<AttachmentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  familyname?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  isMeister?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  notificationChannels?: InputMaybe<Array<Scalars['String']['input']>>;
  notificationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  phones?: InputMaybe<Array<PhoneInput>>;
  picture?: InputMaybe<Scalars['String']['input']>;
  skillset?: InputMaybe<Array<Skill>>;
  title?: InputMaybe<Salutation>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UserPreference = {
  __typename: 'UserPreference';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
};

export type UserType =
  | 'ENDUSER'
  | 'PROUSER';

export type ValueNumberBetween = {
  __typename: 'ValueNumberBetween';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type ValueNumberBetweenInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

export type Vat = {
  __typename: 'Vat';
  rate: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type VatInfo = {
  __typename: 'VatInfo';
  countryCode: Scalars['String']['output'];
  rateType: VatRateType;
};

export type VatInfoInput = {
  countryCode: Scalars['String']['input'];
  rateType: VatRateType;
};

export type VatRateType =
  | 'custom'
  | 'reduced'
  | 'reduced1'
  | 'reduced2'
  | 'reduced3'
  | 'standard'
  | 'zero';

export type VatVisibility =
  | 'HIDE'
  | 'SHOW'
  | 'SHOW_SMALL';

export type Vertical =
  | 'alarm_systems'
  | 'carpentry_and_woodwork'
  | 'cast_stone'
  | 'chimney_sweeping'
  | 'concrete_and_reinforced_concrete'
  | 'drywall'
  | 'electrical_installation'
  | 'excavation_and_earthworks'
  | 'furnace_and_air_heating'
  | 'gardening'
  | 'general'
  | 'glass'
  | 'heat_and_cold_insulation'
  | 'heat_pump'
  | 'heating'
  | 'interior_decoration'
  | 'interior_doors'
  | 'masonry'
  | 'metal'
  | 'painting'
  | 'parquet'
  | 'plumbing'
  | 'road'
  | 'roof'
  | 'sanitary'
  | 'scaffolding'
  | 'screed'
  | 'shutters_and_sun_protection'
  | 'solar'
  | 'sound_insulation'
  | 'stone_masonry'
  | 'stucco'
  | 'swimming_pools'
  | 'tile'
  | 'well_construction'
  | 'windows_and_doors';

export type VerticalSettingInput = {
  averageWorkBuyingPrice: Scalars['Float']['input'];
  averageWorkSellingPrice: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  vertical: Vertical;
};

export type Viewer = WithAvatar & WithContactInfo & WithPersonInfo & {
  __typename: 'Viewer';
  avatar: AttachmentSnapshot;
  channels: Array<Channel>;
  defaultProjectRoles: Array<ProjectRole>;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  homeAddress?: Maybe<Address>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isMeister: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  location?: Maybe<Location>;
  locationUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  meister1Flows: Array<Meister1Flow>;
  organisation: ViewerOrganisation;
  organisationPermissions: Array<PermissionName>;
  phones: Array<Phone>;
  projectContracteeInvitations: Array<ProjectContracteeInvitation>;
  roles: Array<OrganisationRole>;
  skillset: Array<Skill>;
  title: Salutation;
  type: UserType;
  viewerBranding: ViewerBranding;
  viewerHasPushNotificationToken: Scalars['Boolean']['output'];
  viewerPersonalRefToken: Scalars['String']['output'];
  viewerRefToken: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};


export type ViewerChannelsArgs = {
  filters?: InputMaybe<ChannelsFilters>;
};


export type ViewerProjectContracteeInvitationsArgs = {
  filterState?: InputMaybe<Array<InvitationState>>;
};

export type ViewerBranding = {
  __typename: 'ViewerBranding';
  dashboardLeftTopAd?: Maybe<ViewerBrandingSlot>;
  dashboardRightTopAd?: Maybe<ViewerBrandingSlot>;
  dashboardSideTopAd?: Maybe<ViewerBrandingSlot>;
  id: Scalars['String']['output'];
  sidebarBottomLogo?: Maybe<ViewerBrandingSlot>;
  sidebarTheme?: Maybe<ViewerBrandingTheme>;
  sidebarTopLogo?: Maybe<ViewerBrandingSlot>;
  topbarLeftLogo?: Maybe<ViewerBrandingSlot>;
  topbarRightLogo?: Maybe<ViewerBrandingSlot>;
  topbarTheme?: Maybe<ViewerBrandingTheme>;
};

export type ViewerBrandingSlot = {
  __typename: 'ViewerBrandingSlot';
  attachment?: Maybe<AttachmentSnapshot>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ViewerBrandingTheme = {
  __typename: 'ViewerBrandingTheme';
  colorBackground: Scalars['String']['output'];
  colorText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: BrandingThemeType;
};

export type ViewerOrganisation = WithBranchAddress & WithCompanyInfo & WithContactInfo & WithLogo & {
  __typename: 'ViewerOrganisation';
  bankAccount: Scalars['String']['output'];
  bankCode: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  billingAddress?: Maybe<Address>;
  branchAddress?: Maybe<Address>;
  capabilities: Array<Capabilities>;
  channels: Array<Channel>;
  contactPerson?: Maybe<Person>;
  description: Scalars['String']['output'];
  email: Scalars['EmailAddress']['output'];
  enabledIntegrationIds: Array<IntegrationId>;
  fullLegalName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationM1MsysId: Scalars['String']['output'];
  isClientOrganisation: Scalars['Boolean']['output'];
  isCraftsmanOrganisation: Scalars['Boolean']['output'];
  isCrmOrganisation: Scalars['Boolean']['output'];
  isManufacturerOrganisation: Scalars['Boolean']['output'];
  isMeister: Scalars['Boolean']['output'];
  isSupplierOrganisation: Scalars['Boolean']['output'];
  logo: AttachmentSnapshot;
  managingDirector: Scalars['String']['output'];
  meister1Flows: Array<Meister1Flow>;
  membershipInvitations: Array<OrganisationMembershipInvitation>;
  memberships: Array<User>;
  organisationTags: Array<Scalars['String']['output']>;
  organisationType: OrganisationType;
  phones: Array<Phone>;
  pickupAddress?: Maybe<Address>;
  profile?: Maybe<OrganisationProfile>;
  projectRoles: Array<ProjectRole>;
  receivedLinkingInvitations: Array<ReceivedLinkingInvitation>;
  refToken: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
  registrationOffice: Scalars['String']['output'];
  representive?: Maybe<User>;
  roles: Array<OrganisationRole>;
  shoppableTemplate?: Maybe<ShoppableTemplate>;
  shoppableTemplates: ShoppableTemplateConnection;
  skillset: Array<Skill>;
  systemTags: Array<Scalars['String']['output']>;
  taxNumber: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  title: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
  website: Scalars['String']['output'];
};


export type ViewerOrganisationChannelsArgs = {
  filters?: InputMaybe<ChannelsFilters>;
};


export type ViewerOrganisationOrganisationTagsArgs = {
  filter?: InputMaybe<OrganisationTagsFilter>;
};


export type ViewerOrganisationShoppableTemplateArgs = {
  id: Scalars['ID']['input'];
  resolveLinks?: InputMaybe<DocumentResolveItemLinks>;
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};


export type ViewerOrganisationShoppableTemplatesArgs = {
  filters?: InputMaybe<ShoppableTemplatesFilters>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<OrganisationQuoteTemplatesSorting>>;
};

export type Visibility =
  | 'hide'
  | 'none'
  | 'show';

export type WebhookProjectContracteeInvitation = {
  __typename: 'WebhookProjectContracteeInvitation';
  contracteeInvitationUrl: Scalars['String']['output'];
};

export type With3DRoomSnapshot = {
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  has3dRoomSnapshot: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type WithAgreementInfo = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  agreement: Agreement;
  agreementSignature?: Maybe<SignatureExtended>;
  decliningReason: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSignatureVisible: Scalars['Boolean']['output'];
  needsAgreement: Scalars['Boolean']['output'];
};

export type WithAttachments = {
  attachments: Array<Attachment>;
  id: Scalars['ID']['output'];
};


export type WithAttachmentsAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WithAuthor = {
  author: User;
  id: Scalars['ID']['output'];
};

export type WithAvatar = {
  avatar: AttachmentSnapshot;
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type WithBillingAddress = {
  billingAddress?: Maybe<Address>;
  id: Scalars['ID']['output'];
};

export type WithBindingInfo = {
  id: Scalars['ID']['output'];
  isBinding: Scalars['Boolean']['output'];
};

export type WithBranchAddress = {
  branchAddress?: Maybe<Address>;
  id: Scalars['ID']['output'];
};

export type WithCalculation = {
  agreedCalculation?: Maybe<ItemCalculation>;
  contractType: ContractType;
  discountPercentage: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  materialFactor: Scalars['Float']['output'];
  proposedCalculation?: Maybe<ItemCalculation>;
  vatInfo: VatInfo;
  vatRate: Scalars['Float']['output'];
  vatVisibility: VatVisibility;
  workFactor: Scalars['Float']['output'];
};

export type WithChangeOrderInfo = {
  changeDecliningReason: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  needsAgreement: Scalars['Boolean']['output'];
};

export type WithCompanyInfo = {
  contactPerson?: Maybe<WithPersonInfo>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMeister: Scalars['Boolean']['output'];
  skillset: Array<Skill>;
  title: Scalars['String']['output'];
  website: Scalars['String']['output'];
};

export type WithContactInfo = {
  email: Scalars['EmailAddress']['output'];
  id: Scalars['ID']['output'];
  phones: Array<Phone>;
};

export type WithContents = {
  contents: Array<Content>;
  id: Scalars['ID']['output'];
};

export type WithCustomFields = {
  customFields: Array<CustomFieldField>;
  id: Scalars['ID']['output'];
};

export type WithDocumentInfo = {
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type WithItemAttachments = {
  attachments: Array<ItemAttachment>;
  id: Scalars['ID']['output'];
};


export type WithItemAttachmentsAttachmentsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WithLogo = {
  id: Scalars['ID']['output'];
  logo: AttachmentSnapshot;
  title: Scalars['String']['output'];
};

export type WithPersonInfo = {
  description: Scalars['String']['output'];
  familyname: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  fullname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isMeister: Scalars['Boolean']['output'];
  locale: Scalars['String']['output'];
  skillset: Array<Skill>;
  title: Salutation;
  website?: Maybe<Scalars['String']['output']>;
};

export type WithProjectInfo = {
  categories: Array<Skill>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  owningSystemOrganisationId: Scalars['ID']['output'];
  ticket: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: ProjectType;
};

export type WithPublishingInfo = {
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  lastUpdatedAt: Scalars['DateTime']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WithQuoteDetails = {
  contractType: ContractType;
  discountPercentage: Scalars['Float']['output'];
  discountReason: Scalars['String']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  earliestDate?: Maybe<Scalars['Date']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  headerVisibility: QuoteHeaderVisibility;
  id: Scalars['ID']['output'];
  isBinding: Scalars['Boolean']['output'];
  isDiscountPercentageVisible: Scalars['Boolean']['output'];
  materialFactor: Scalars['Float']['output'];
  productsVisibility: QuoteProductsVisibility;
  showOfferConditions: Scalars['Boolean']['output'];
  validityDuration: Scalars['Int']['output'];
  vatInfo: VatInfo;
  vatRate: Scalars['Float']['output'];
  vatVisibility: VatVisibility;
  workFactor: Scalars['Float']['output'];
};

export type WithQuoteTexts = {
  id: Scalars['ID']['output'];
  quoteTexts?: Maybe<QuoteTexts>;
};

export type WithdrawRequestInput = {
  projectId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
};

export type WithdrawRequestResult = {
  __typename: 'WithdrawRequestResult';
  request: Request;
};

export type WizardItemResult = {
  __typename: 'WizardItemResult';
  item: Item;
};

export type WorkSession = {
  __typename: 'WorkSession';
  assignee?: Maybe<User>;
  author: User;
  date?: Maybe<Scalars['Date']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  from?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isBreak: Scalars['Boolean']['output'];
  isCorrection: Scalars['Boolean']['output'];
  itemId?: Maybe<Scalars['ID']['output']>;
  projectId?: Maybe<Scalars['ID']['output']>;
  till?: Maybe<Scalars['DateTime']['output']>;
};

export type XiopdExportDestination =
  | 'MSYS'
  | 'UNKNOWN';

export type _3d_AddProductDataInput = {
  filename: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

export type _3d_AddProductDataResult = {
  __typename: '_3d_AddProductDataResult';
  product: PimCustomSupplierProduct;
};

export type _3d_AddSnapshotsInput = {
  docId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  snapshots: Array<AttachmentInput>;
};

export type _3d_AddSnapshotsResult = {
  __typename: '_3d_AddSnapshotsResult';
  item: Item;
};

export type _3d_CreateLeadInNewProjectInfoInput = {
  email: Scalars['EmailAddress']['input'];
  familyname: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  phones: Array<PhoneInput>;
  projectSiteAddress: AddressInput;
  title: Salutation;
};

export type _3d_CreateLeadInNewProjectInput = {
  leadInfo: _3d_CreateLeadInNewProjectInfoInput;
  leadInvitation: _3d_CreateLeadInNewProjectInvitationInput;
  organisationId: Scalars['ID']['input'];
  shape: _3d_ShapeInput;
  snapshots: Array<AttachmentInput>;
  templateQuoteId: Scalars['ID']['input'];
  templateVariantConfiguration?: InputMaybe<Array<TemplateVariantItemConfigurationInput>>;
};

export type _3d_CreateLeadInNewProjectInvitationInput = {
  attachments: Array<AttachmentInput>;
  messageBody: Scalars['String']['input'];
  messageSubject: Scalars['String']['input'];
};

export type _3d_CreateLeadInNewProjectResult = {
  __typename: '_3d_CreateLeadInNewProjectResult';
  refToken: Scalars['String']['output'];
};

export type _3d_File = {
  __typename: '_3d_File';
  s3Key: Scalars['String']['output'];
};

export type _3d_FileInput = {
  s3Key: Scalars['String']['input'];
};

export type _3d_Floorplan = {
  __typename: '_3d_Floorplan';
  carbonSheet?: Maybe<Scalars['JSON']['output']>;
  corners?: Maybe<Scalars['JSON']['output']>;
  floorTextures: Scalars['JSON']['output'];
  newFloorTextures: Scalars['JSON']['output'];
  newWallTextures: Scalars['JSON']['output'];
  rooms?: Maybe<Scalars['JSON']['output']>;
  units?: Maybe<Scalars['JSON']['output']>;
  version: Scalars['String']['output'];
  wallTextures: Scalars['JSON']['output'];
  walls?: Maybe<Array<Scalars['JSON']['output']>>;
};

export type _3d_FloorplanInput = {
  carbonSheet?: InputMaybe<Scalars['JSON']['input']>;
  corners?: InputMaybe<Scalars['JSON']['input']>;
  floorTextures: Scalars['JSON']['input'];
  newFloorTextures: Scalars['JSON']['input'];
  newWallTextures: Scalars['JSON']['input'];
  rooms?: InputMaybe<Scalars['JSON']['input']>;
  units?: InputMaybe<Scalars['JSON']['input']>;
  version: Scalars['String']['input'];
  wallTextures: Scalars['JSON']['input'];
  walls?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type _3d_Item = {
  __typename: '_3d_Item';
  _msItemInfo?: Maybe<_3d_MsItemInfo>;
  isSelected: Scalars['Boolean']['output'];
  item3dData?: Maybe<_3d_Item3dData>;
  itemCategory: Scalars['String']['output'];
  itemDefinitionFiles: Array<_3d_ItemDefinitionFile>;
  itemIconData?: Maybe<_3d_ItemIconData>;
  itemId?: Maybe<Scalars['ID']['output']>;
  itemProductData?: Maybe<_3d_ItemProductData>;
  itemQuantity?: Maybe<Scalars['Float']['output']>;
  itemRepeatData?: Maybe<_3d_ItemRepeatData>;
  itemTextureData?: Maybe<_3d_ItemTextureData>;
  itemType?: Maybe<Scalars['Int']['output']>;
  itemWallData?: Maybe<_3d_ItemWallData>;
  version: Scalars['String']['output'];
};

export type _3d_Item3dData = {
  __typename: '_3d_Item3dData';
  fixed: Scalars['Boolean']['output'];
  fixedPosition: Array<Scalars['Float']['output']>;
  isParametric: Scalars['Boolean']['output'];
  position: Array<Scalars['Float']['output']>;
  reflections: Scalars['Boolean']['output'];
  resizable: Scalars['Boolean']['output'];
  rotation: Array<Scalars['Float']['output']>;
  scale: Array<Scalars['Float']['output']>;
  size: Array<Scalars['Float']['output']>;
};

export type _3d_Item3dDataInput = {
  fixed: Scalars['Boolean']['input'];
  fixedPosition: Array<Scalars['Float']['input']>;
  isParametric: Scalars['Boolean']['input'];
  position: Array<Scalars['Float']['input']>;
  reflections: Scalars['Boolean']['input'];
  resizable: Scalars['Boolean']['input'];
  rotation: Array<Scalars['Float']['input']>;
  scale: Array<Scalars['Float']['input']>;
  size: Array<Scalars['Float']['input']>;
};

export type _3d_ItemDefinitionFile = {
  __typename: '_3d_ItemDefinitionFile';
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type _3d_ItemDefinitionFileInput = {
  key: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type _3d_ItemIconData = {
  __typename: '_3d_ItemIconData';
  iconHeight: Scalars['Float']['output'];
  iconOffsetX: Scalars['Float']['output'];
  iconOffsetY: Scalars['Float']['output'];
  iconWidth: Scalars['Float']['output'];
};

export type _3d_ItemIconDataInput = {
  iconHeight: Scalars['Float']['input'];
  iconOffsetX: Scalars['Float']['input'];
  iconOffsetY: Scalars['Float']['input'];
  iconWidth: Scalars['Float']['input'];
};

export type _3d_ItemInput = {
  _msItemInfo?: InputMaybe<_3d_MsItemInfoInput>;
  isSelected: Scalars['Boolean']['input'];
  item3dData?: InputMaybe<_3d_Item3dDataInput>;
  itemCategory: Scalars['String']['input'];
  itemDefinitionFiles: Array<_3d_ItemDefinitionFileInput>;
  itemIconData?: InputMaybe<_3d_ItemIconDataInput>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  itemProductData?: InputMaybe<_3d_ItemProductDataInput>;
  itemQuantity?: InputMaybe<Scalars['Int']['input']>;
  itemRepeatData?: InputMaybe<_3d_ItemRepeatDataInput>;
  itemTextureData?: InputMaybe<_3d_ItemTextureDataInput>;
  itemType?: InputMaybe<Scalars['Int']['input']>;
  itemWallData?: InputMaybe<_3d_ItemWallDataInput>;
  version: Scalars['String']['input'];
};

export type _3d_ItemProductData = {
  __typename: '_3d_ItemProductData';
  articleNumber?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  extendedDescription?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
};

export type _3d_ItemProductDataInput = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  extendedDescription?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type _3d_ItemRepeatData = {
  __typename: '_3d_ItemRepeatData';
  repeat: Scalars['Float']['output'];
  repeatX: Scalars['Float']['output'];
  repeatY: Scalars['Float']['output'];
};

export type _3d_ItemRepeatDataInput = {
  repeat: Scalars['Float']['input'];
  repeatX: Scalars['Float']['input'];
  repeatY: Scalars['Float']['input'];
};

export type _3d_ItemTextureData = {
  __typename: '_3d_ItemTextureData';
  color: Scalars['String']['output'];
  emissive: Scalars['String']['output'];
  reflective: Scalars['Float']['output'];
  shininess: Scalars['Float']['output'];
};

export type _3d_ItemTextureDataInput = {
  color: Scalars['String']['input'];
  emissive: Scalars['String']['input'];
  reflective: Scalars['Float']['input'];
  shininess: Scalars['Float']['input'];
};

export type _3d_ItemWallData = {
  __typename: '_3d_ItemWallData';
  wall: Scalars['String']['output'];
  wallSide: Scalars['String']['output'];
  wallSurfacePoint: Array<Scalars['Float']['output']>;
};

export type _3d_ItemWallDataInput = {
  wall: Scalars['String']['input'];
  wallSide: Scalars['String']['input'];
  wallSurfacePoint: Array<Scalars['Float']['input']>;
};

export type _3d_ModifyRoomShapeInput = {
  docId: Scalars['ID']['input'];
  itemId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  shape: _3d_ShapeInput;
};

export type _3d_MsItemInfo = {
  __typename: '_3d_MsItemInfo';
  itemDefinitionFiles: Array<_3d_ItemDefinitionFile>;
  itemId: Scalars['ID']['output'];
  productArticleNumber?: Maybe<Scalars['String']['output']>;
  productSupplierId?: Maybe<Scalars['String']['output']>;
};

export type _3d_MsItemInfoInput = {
  itemDefinitionFiles: Array<_3d_ItemDefinitionFileInput>;
  itemId: Scalars['ID']['input'];
  productArticleNumber?: InputMaybe<Scalars['String']['input']>;
  productSupplierId?: InputMaybe<Scalars['String']['input']>;
};

export type _3d_RemoveProductDataInput = {
  productId: Scalars['ID']['input'];
};

export type _3d_RemoveProductDataResult = {
  __typename: '_3d_RemoveProductDataResult';
  product: PimCustomSupplierProduct;
};

export type _3d_RequestAddProductDataUrlInput = {
  filename: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
};

export type _3d_RoomResult = {
  __typename: '_3d_RoomResult';
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  _3d_selectedRoomItems: Array<_3d_Item>;
};

export type _3d_RoomShoppableTemplateResult = {
  __typename: '_3d_RoomShoppableTemplateResult';
  _3d_roomSnapshot?: Maybe<_3d_Shape>;
  _3d_selectedRoomItems: Array<_3d_Item>;
};

export type _3d_Shape = {
  __typename: '_3d_Shape';
  floorplan: _3d_Floorplan;
  items: Array<_3d_Item>;
  roomCategory?: Maybe<Scalars['String']['output']>;
};

export type _3d_ShapeInput = {
  floorplan: _3d_FloorplanInput;
  items: Array<_3d_ItemInput>;
  roomCategory?: InputMaybe<Scalars['String']['input']>;
};

export type _3d_ModifyRoomShapeResult = {
  __typename: '_3d_modifyRoomShapeResult';
  _3d_roomSnapshot: _3d_Shape;
  doc: Document;
  item: Item;
};

export const namedOperations = {
  Query: {
    AvailableUsers: 'AvailableUsers',
    Me: 'Me',
    AbsenceSchedule: 'AbsenceSchedule',
    BillOfMaterialItemDetails: 'BillOfMaterialItemDetails',
    ItemManualProductBox: 'ItemManualProductBox',
    BillOfMaterialsItemProductBox: 'BillOfMaterialsItemProductBox',
    BillOfMaterialsQuotes: 'BillOfMaterialsQuotes',
    BuildingDetails: 'BuildingDetails',
    BuildingCreateModal: 'BuildingCreateModal',
    BuildingSelectModal: 'BuildingSelectModal',
    Channel: 'Channel',
    OrganisationChannel: 'OrganisationChannel',
    ProjectChannelSidebar: 'ProjectChannelSidebar',
    AddContactRelationshipModal: 'AddContactRelationshipModal',
    CraftsmenSearch: 'CraftsmenSearch',
    CrmCompanyOrPersonSelect: 'CrmCompanyOrPersonSelect',
    CrmCompanySelect: 'CrmCompanySelect',
    CrmCompanyOwnInformationDiscountGroups: 'CrmCompanyOwnInformationDiscountGroups',
    CrmCompanyOwnInformationDiscountGroupsVariant: 'CrmCompanyOwnInformationDiscountGroupsVariant',
    CrmCompanyOwnInformationDiscountGroupVariants: 'CrmCompanyOwnInformationDiscountGroupVariants',
    CrmCompanySupplierCataloguesBox: 'CrmCompanySupplierCataloguesBox',
    CrmCompanyEditBillingAddressModal: 'CrmCompanyEditBillingAddressModal',
    CrmCompanyEditBranchAddressModal: 'CrmCompanyEditBranchAddressModal',
    CrmCompanyEditContactTypeModal: 'CrmCompanyEditContactTypeModal',
    CrmCompanyInviteModal: 'CrmCompanyInviteModal',
    CrmPersonSelect: 'CrmPersonSelect',
    PendingInvitationsContractee: 'PendingInvitationsContractee',
    CrmCreateModal: 'CrmCreateModal',
    EmailTemplateValues: 'EmailTemplateValues',
    UseCrmSendEmail: 'UseCrmSendEmail',
    SelectCrmSuppliers: 'SelectCrmSuppliers',
    MassImportCrm_OrganisationSettings: 'MassImportCrm_OrganisationSettings',
    MassImportCrm_CrmCompanies: 'MassImportCrm_CrmCompanies',
    CustomFieldConfig: 'CustomFieldConfig',
    CreateItemAutoCompleteTemplates: 'CreateItemAutoCompleteTemplates',
    CreateItemAutoCompleteProducts: 'CreateItemAutoCompleteProducts',
    ProductBox_Product: 'ProductBox_Product',
    ProductBox_SupplierProductAlternatives: 'ProductBox_SupplierProductAlternatives',
    ProductSearchFiltersSection_ProductType: 'ProductSearchFiltersSection_ProductType',
    PropertyDisplayBox_Attachments: 'PropertyDisplayBox_Attachments',
    PendingChangesBox: 'PendingChangesBox',
    PropertiesDebugModal: 'PropertiesDebugModal',
    PropertiesDebugModal_UnansweredPropsFlow: 'PropertiesDebugModal_UnansweredPropsFlow',
    DebugAllPropQuote: 'DebugAllPropQuote',
    DebugAllPropTemplate: 'DebugAllPropTemplate',
    DecisionProcess_NextAndPreviousItems: 'DecisionProcess_NextAndPreviousItems',
    DecisionProcess_NextPossibleItem: 'DecisionProcess_NextPossibleItem',
    ItemOrSubItemsPropsUsedInOtherItems: 'ItemOrSubItemsPropsUsedInOtherItems',
    ItemTypeMenuItem_PossibleItemTypes: 'ItemTypeMenuItem_PossibleItemTypes',
    AddPlacementInProjectBuildingModal: 'AddPlacementInProjectBuildingModal',
    AiDescriptionModal_Item: 'AiDescriptionModal_Item',
    AiDescriptionModal_AI: 'AiDescriptionModal_AI',
    AiRephraseModal_Item: 'AiRephraseModal_Item',
    AiRephraseModal_AI: 'AiRephraseModal_AI',
    AttributeExpressionModal: 'AttributeExpressionModal',
    DecisionModal: 'DecisionModal',
    DecisionModalProductStage: 'DecisionModalProductStage',
    ExpressionModal: 'ExpressionModal',
    ItemProductSearchFilterExpressionModal: 'ItemProductSearchFilterExpressionModal',
    PropertyExpressionModal: 'PropertyExpressionModal',
    UseExpressionValidation_CompileDocIsolatedExpression: 'UseExpressionValidation_CompileDocIsolatedExpression',
    UseProposedCalculationsWithOverride: 'UseProposedCalculationsWithOverride',
    DocumentActorEditModal_CrmPerson: 'DocumentActorEditModal_CrmPerson',
    DocumentActorEditModal_OrganisationMembership: 'DocumentActorEditModal_OrganisationMembership',
    ValidateEmailAddress: 'ValidateEmailAddress',
    InvitationList: 'InvitationList',
    CreateInvoiceModal: 'CreateInvoiceModal',
    InvoiceSettingsModal: 'InvoiceSettingsModal',
    CalculateInvoiceWithSubtotalItemFilter: 'CalculateInvoiceWithSubtotalItemFilter',
    Meister1Integration: 'Meister1Integration',
    Meister1Flows: 'Meister1Flows',
    ManufacturerSelect__SearchManufacturers: 'ManufacturerSelect__SearchManufacturers',
    SelectManufacturers: 'SelectManufacturers',
    OpportunitiesKanban: 'OpportunitiesKanban',
    OpportunitiesKanbanList: 'OpportunitiesKanbanList',
    OpportunitiesKanbanWonList: 'OpportunitiesKanbanWonList',
    OpportunitiesList: 'OpportunitiesList',
    OrganisationUserSelect: 'OrganisationUserSelect',
    OrganisationProductImports: 'OrganisationProductImports',
    M1ApiLeadResponse: 'M1ApiLeadResponse',
    OrganisationIntegrationsBox_AvailableIntegrations: 'OrganisationIntegrationsBox_AvailableIntegrations',
    GenerateOrganisationPdfLetterheadModal: 'GenerateOrganisationPdfLetterheadModal',
    OrganisationPublicShopBox: 'OrganisationPublicShopBox',
    AddOrganisationPublicProfileModal: 'AddOrganisationPublicProfileModal',
    ModifyOrganisationTextsModal: 'ModifyOrganisationTextsModal',
    OrganisationTags: 'OrganisationTags',
    DatanormOnlineEntries: 'DatanormOnlineEntries',
    PimImportations: 'PimImportations',
    DatanormOnlineAvailability: 'DatanormOnlineAvailability',
    EditProjectPlanSessionsModal: 'EditProjectPlanSessionsModal',
    EditProjectPlanSessionsModal_Project: 'EditProjectPlanSessionsModal_Project',
    EditProjectPlanSessionsModal_Organisation: 'EditProjectPlanSessionsModal_Organisation',
    PlanningSessionsSchedule: 'PlanningSessionsSchedule',
    UseAvailabilityFilter_Members: 'UseAvailabilityFilter_Members',
    UseDistanceToProject: 'UseDistanceToProject',
    ProductCategoryTreeSelect: 'ProductCategoryTreeSelect',
    ProductSearchLandingPage_ProductCategoryRoots: 'ProductSearchLandingPage_ProductCategoryRoots',
    ProductSearchLandingPage_RecentlyViewedProducts: 'ProductSearchLandingPage_RecentlyViewedProducts',
    ProductSearchLandingPage_FavouriteProducts: 'ProductSearchLandingPage_FavouriteProducts',
    ProductSearchLandingPage_CurrentSuppliers: 'ProductSearchLandingPage_CurrentSuppliers',
    ProductTypeSelectSearchProductTypes: 'ProductTypeSelectSearchProductTypes',
    PimGetProductByArticleNumberIdentifier: 'PimGetProductByArticleNumberIdentifier',
    PimSearchSupplierCatalogues__OrganisationWriteAccess: 'PimSearchSupplierCatalogues__OrganisationWriteAccess',
    PimGetSupplierCatalogue__OrganisationWriteAccess: 'PimGetSupplierCatalogue__OrganisationWriteAccess',
    ProductEditHeaderBox__PimSearchBrands: 'ProductEditHeaderBox__PimSearchBrands',
    ProductEditHeaderBox__PimSearchBrandLines: 'ProductEditHeaderBox__PimSearchBrandLines',
    ProductEditSupplierBox__PimGetProduct: 'ProductEditSupplierBox__PimGetProduct',
    ProductOverviewSupplierProductPropertyFormBox: 'ProductOverviewSupplierProductPropertyFormBox',
    OpenMasterdataAvailability: 'OpenMasterdataAvailability',
    ProductSearchModal_ProductTypes: 'ProductSearchModal_ProductTypes',
    ProductSearchModal_Suppliers: 'ProductSearchModal_Suppliers',
    ProductSearchModal_Manufacturers: 'ProductSearchModal_Manufacturers',
    ProductFilterFields_AvailableFilters: 'ProductFilterFields_AvailableFilters',
    ProductBreadcrumbItems__ProductCategoryTree: 'ProductBreadcrumbItems__ProductCategoryTree',
    PickBrandLineModalSearchBrandLines: 'PickBrandLineModalSearchBrandLines',
    PickBrandModalSearchBrands: 'PickBrandModalSearchBrands',
    PickProductTypeModalSearchProductTypes: 'PickProductTypeModalSearchProductTypes',
    ProductSearchModal: 'ProductSearchModal',
    ProductSearchModalRecentViews: 'ProductSearchModalRecentViews',
    ProductTypePopularPropertyFiltersEditModal: 'ProductTypePopularPropertyFiltersEditModal',
    SupplierCatalogueProductCreateModal__PimSearchSupplierCatalogues: 'SupplierCatalogueProductCreateModal__PimSearchSupplierCatalogues',
    SupplierCatalogueProductCreateModal__GetSupplierProduct: 'SupplierCatalogueProductCreateModal__GetSupplierProduct',
    ProjectAssigneeSelect: 'ProjectAssigneeSelect',
    ProjectSelect: 'ProjectSelect',
    ProjectsKanban: 'ProjectsKanban',
    ProjectsKanbanList: 'ProjectsKanbanList',
    ProjectsList: 'ProjectsList',
    ProjectOverviewActionListBox: 'ProjectOverviewActionListBox',
    ProjectOverviewPurchaseOrders: 'ProjectOverviewPurchaseOrders',
    ProjectOverviewRequirements: 'ProjectOverviewRequirements',
    ProjectOverviewOutgoingInvoices: 'ProjectOverviewOutgoingInvoices',
    ProjectOverviewIncomingInvoices: 'ProjectOverviewIncomingInvoices',
    ProjectOverviewOutgoingQuotes: 'ProjectOverviewOutgoingQuotes',
    ProjectOverviewIncomingQuotes: 'ProjectOverviewIncomingQuotes',
    ProjectOverviewNextTasksBox: 'ProjectOverviewNextTasksBox',
    ProjectWorkSessions: 'ProjectWorkSessions',
    ProjectPhases: 'ProjectPhases',
    ProjectMemberCrmModal: 'ProjectMemberCrmModal',
    AddProjectMemberModal: 'AddProjectMemberModal',
    TicketCreateModal: 'TicketCreateModal',
    TicketCreateModalCrmOrganisationsBuilding: 'TicketCreateModalCrmOrganisationsBuilding',
    ModifyProjectMemberModal: 'ModifyProjectMemberModal',
    ProjectAssignBuildingModal: 'ProjectAssignBuildingModal',
    AllProjectContacts: 'AllProjectContacts',
    ProjectAddEntity: 'ProjectAddEntity',
    UseProjectChangeState: 'UseProjectChangeState',
    ProjectIsOpportunity: 'ProjectIsOpportunity',
    PurchaseOrderItemDetails: 'PurchaseOrderItemDetails',
    SelectSupplierAndDeliveryModal: 'SelectSupplierAndDeliveryModal',
    SupplierPickupAddresses: 'SupplierPickupAddresses',
    GenerateBillOfMaterialsPdf: 'GenerateBillOfMaterialsPdf',
    GeneratePurchaseOrderPdf: 'GeneratePurchaseOrderPdf',
    QuoteDecisionsInBulkProcess: 'QuoteDecisionsInBulkProcess',
    QuoteImportRequirementModal: 'QuoteImportRequirementModal',
    QuoteImportRequirementsModal: 'QuoteImportRequirementsModal',
    QuoteItemSapS4HanaData_QuotePreview: 'QuoteItemSapS4HanaData_QuotePreview',
    QuotePriceBudgetSummary: 'QuotePriceBudgetSummary',
    QuoteSapS4HanaData: 'QuoteSapS4HanaData',
    QuoteSetExternalIdentifierModal: 'QuoteSetExternalIdentifierModal',
    QuoteSettingsModal: 'QuoteSettingsModal',
    DownloadQuoteAsPdfButton: 'DownloadQuoteAsPdfButton',
    GenerateQuotePdf: 'GenerateQuotePdf',
    GenerateQuoteContractingPdf: 'GenerateQuoteContractingPdf',
    AcceptQuoteModal: 'AcceptQuoteModal',
    MarkQuoteAsAcceptedModal: 'MarkQuoteAsAcceptedModal',
    QuoteBulkUpdateProductPricingsModal__SupplierDiscountGroupVariants: 'QuoteBulkUpdateProductPricingsModal__SupplierDiscountGroupVariants',
    QuoteBulkUpdateProductPricingsModal__SearchSuppliers: 'QuoteBulkUpdateProductPricingsModal__SearchSuppliers',
    QuoteBulkUpdateProductPricingsModal__SearchSupplierDiscountGroupVariants: 'QuoteBulkUpdateProductPricingsModal__SearchSupplierDiscountGroupVariants',
    QuoteCreateModal: 'QuoteCreateModal',
    QuoteCreateModal_Requirement: 'QuoteCreateModal_Requirement',
    Meister1PollForQuoteDocOfLeadId: 'Meister1PollForQuoteDocOfLeadId',
    SapQuotes: 'SapQuotes',
    QuoteDocumentActors: 'QuoteDocumentActors',
    QuoteOfferConditionsModal: 'QuoteOfferConditionsModal',
    QuotePublishingInfoModal: 'QuotePublishingInfoModal',
    SendQuoteViaEmail: 'SendQuoteViaEmail',
    RequestQuoteForRequirement: 'RequestQuoteForRequirement',
    RequestGetQuoteModal: 'RequestGetQuoteModal',
    RequestGetRequirementModal: 'RequestGetRequirementModal',
    RequestOverviewOpportunityQuoteRequest: 'RequestOverviewOpportunityQuoteRequest',
    RequestOverviewProjectIncomingQuoteRequest: 'RequestOverviewProjectIncomingQuoteRequest',
    RequestOverviewProjectOutgoingQuoteRequest: 'RequestOverviewProjectOutgoingQuoteRequest',
    ConfirmDeletionModal: 'ConfirmDeletionModal',
    CreateRequirementModal: 'CreateRequirementModal',
    RequirementDecisionsInBulkProcess: 'RequirementDecisionsInBulkProcess',
    RequirementDocumentActors: 'RequirementDocumentActors',
    SendRequirementViaEmail: 'SendRequirementViaEmail',
    GenerateRequirementPdf: 'GenerateRequirementPdf',
    S4HanaQuoteCopyModal: 'S4HanaQuoteCopyModal',
    S4HanaQuoteDataBox: 'S4HanaQuoteDataBox',
    S4HanaQuoteItemDataBox: 'S4HanaQuoteItemDataBox',
    S4HanaQuoteData: 'S4HanaQuoteData',
    S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotations: 'S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotations',
    S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotation: 'S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotation',
    Schedule: 'Schedule',
    ShopTopbarItems: 'ShopTopbarItems',
    ShopSelectedCart: 'ShopSelectedCart',
    ShopCarts: 'ShopCarts',
    TemplatesQuoteHeaderBox: 'TemplatesQuoteHeaderBox',
    ShopProjectsByProjectId: 'ShopProjectsByProjectId',
    ShopProjectsByCrmCompanyId: 'ShopProjectsByCrmCompanyId',
    SelectShopQuotes: 'SelectShopQuotes',
    SelectShopRequirements: 'SelectShopRequirements',
    SupplierCatalogueSelect__PimSearchSupplierCatalogues: 'SupplierCatalogueSelect__PimSearchSupplierCatalogues',
    SupplierSelect__SearchSuppliers: 'SupplierSelect__SearchSuppliers',
    PhotoApprovalProcess: 'PhotoApprovalProcess',
    TaskManagementButtons: 'TaskManagementButtons',
    TasksTimesheetBox: 'TasksTimesheetBox',
    TasksTimesheetBox__WorkSessions: 'TasksTimesheetBox__WorkSessions',
    TasksTimesheetTasksForm: 'TasksTimesheetTasksForm',
    TemplateTypeOverviewModal: 'TemplateTypeOverviewModal',
    TemplateTypesSearchModal: 'TemplateTypesSearchModal',
    EditRecommendedTemplateModal: 'EditRecommendedTemplateModal',
    EditRecommendedTemplateModal__LatestAvailableVersion: 'EditRecommendedTemplateModal__LatestAvailableVersion',
    EditRecommendedTemplateTypeModal: 'EditRecommendedTemplateTypeModal',
    TemplateFilterFields_AvailableFilters: 'TemplateFilterFields_AvailableFilters',
    PropertyMappingExpressionModal: 'PropertyMappingExpressionModal',
    RestoreTemplateVersionModal: 'RestoreTemplateVersionModal',
    TemplateQuoteSelectField: 'TemplateQuoteSelectField',
    TemplatesQuoteOverviewModal: 'TemplatesQuoteOverviewModal',
    TemplatesQuoteSelectMultipleComponent: 'TemplatesQuoteSelectMultipleComponent',
    TemplatesQuoteSetsSearchModal: 'TemplatesQuoteSetsSearchModal',
    TemplatesQuoteSetsSearchModal_TemplateType: 'TemplatesQuoteSetsSearchModal_TemplateType',
    TemplatesQuoteTemplateEditSettingsModal: 'TemplatesQuoteTemplateEditSettingsModal',
    TemplatesQuoteTemplateVersionSettingsModal: 'TemplatesQuoteTemplateVersionSettingsModal',
    QuoteTemplatePublishButton: 'QuoteTemplatePublishButton',
    TemplateLinkUpdateModal: 'TemplateLinkUpdateModal',
    TemplateDebugModal: 'TemplateDebugModal',
    ThreeDRoom: 'ThreeDRoom',
    AddTimeReportModal: 'AddTimeReportModal',
    CreateTodoItemModal: 'CreateTodoItemModal',
    EditTodoItemModal: 'EditTodoItemModal',
    RelatedToBox_Project: 'RelatedToBox_Project',
    RelatedToBox_CrmCompany: 'RelatedToBox_CrmCompany',
    RelatedToBox_CrmPerson: 'RelatedToBox_CrmPerson',
    ConfirmDeactivationModal: 'ConfirmDeactivationModal',
    UserCalendarToken: 'UserCalendarToken',
    ViewerPreference: 'ViewerPreference',
    VanMarckeProfitabilityModal: 'VanMarckeProfitabilityModal',
    AddTaskWorkSessionsProcess: 'AddTaskWorkSessionsProcess',
    AddTaskWorkSessionModal: 'AddTaskWorkSessionModal',
    CorrectTaskWorkSessionModal: 'CorrectTaskWorkSessionModal',
    InstallerAgenda: 'InstallerAgenda',
    MyUserProfile: 'MyUserProfile',
    InstallerProjectOverview: 'InstallerProjectOverview',
    InstallerProjectRouter: 'InstallerProjectRouter',
    RefToken: 'RefToken',
    BuildingEdit: 'BuildingEdit',
    BuildingEditItem: 'BuildingEditItem',
    Buildings: 'Buildings',
    Calendar: 'Calendar',
    CrmAll: 'CrmAll',
    CrmCompanyProfile: 'CrmCompanyProfile',
    CrmPersonProfile: 'CrmPersonProfile',
    CrmUsersTable: 'CrmUsersTable',
    DashboardOpportunities: 'DashboardOpportunities',
    DashboardPersonalCalendar: 'DashboardPersonalCalendar',
    DashboardPersonalSchedule: 'DashboardPersonalSchedule',
    DashboardProjects: 'DashboardProjects',
    PendingDocSharesBox_PendingDocShares: 'PendingDocSharesBox_PendingDocShares',
    DocShareBox_quote_sharing: 'DocShareBox_quote_sharing',
    DocShareBox_crmOrganisationWithLinkedSystemOrganisationId: 'DocShareBox_crmOrganisationWithLinkedSystemOrganisationId',
    DocShareBox_requirement_sharing: 'DocShareBox_requirement_sharing',
    DocumentsOutgoingInvoices: 'DocumentsOutgoingInvoices',
    DocumentsIncomingInvoices: 'DocumentsIncomingInvoices',
    DocumentsPurchaseOrders: 'DocumentsPurchaseOrders',
    DocumentsIncomingQuotes: 'DocumentsIncomingQuotes',
    DocumentsOutgoingQuotes: 'DocumentsOutgoingQuotes',
    GlobalManufacturersTable: 'GlobalManufacturersTable',
    GlobalOrganisationProfile: 'GlobalOrganisationProfile',
    GlobalSuppliersTable: 'GlobalSuppliersTable',
    OrganisationBranding: 'OrganisationBranding',
    OrganisationProfile: 'OrganisationProfile',
    OrganisationSettingsDocuments: 'OrganisationSettingsDocuments',
    OrganisationSettingsIntegrations: 'OrganisationSettingsIntegrations',
    OrganisationSettingsPlanning: 'OrganisationSettingsPlanning',
    OrganisationSettingsPricesAndCalculations: 'OrganisationSettingsPricesAndCalculations',
    MyOrganisationUserProfile: 'MyOrganisationUserProfile',
    UserSettings: 'UserSettings',
    ArticleList: 'ArticleList',
    ArticleListCrmCompany: 'ArticleListCrmCompany',
    ArticleListsList: 'ArticleListsList',
    MarketplaceOpportunities: 'MarketplaceOpportunities',
    PlanningAbsences: 'PlanningAbsences',
    PlanningAbsencesMembers: 'PlanningAbsencesMembers',
    PlanningProjects: 'PlanningProjects',
    PlanningProjects_Organisation: 'PlanningProjects_Organisation',
    ProductOverview__SupplierProductAlternatives: 'ProductOverview__SupplierProductAlternatives',
    ProductOverview__ProductMetaInfo: 'ProductOverview__ProductMetaInfo',
    ProductOverview__SupplierProductSetInfo: 'ProductOverview__SupplierProductSetInfo',
    OrganisationProductsTable: 'OrganisationProductsTable',
    OrganisationProductsRecentViews: 'OrganisationProductsRecentViews',
    SupplierProductEdit__GetSupplierProductByArticleNumberIdentifier: 'SupplierProductEdit__GetSupplierProductByArticleNumberIdentifier',
    SupplierProductEdit__GetSupplierProduct: 'SupplierProductEdit__GetSupplierProduct',
    ProjectBillOfMaterials: 'ProjectBillOfMaterials',
    ProjectChatSidebar: 'ProjectChatSidebar',
    ProjectEmailPreview: 'ProjectEmailPreview',
    ProjectEmails: 'ProjectEmails',
    ProjectIncomingInvoice: 'ProjectIncomingInvoice',
    ProjectOutgoingInvoices: 'ProjectOutgoingInvoices',
    ProjectIncomingInvoices: 'ProjectIncomingInvoices',
    ProjectOutgoingInvoice: 'ProjectOutgoingInvoice',
    ProjectOutgoingInvoice_QuoteInvoiceCalculation: 'ProjectOutgoingInvoice_QuoteInvoiceCalculation',
    ProjectOverview: 'ProjectOverview',
    PurchaseOrderEdit: 'PurchaseOrderEdit',
    PurchaseOrderEditItem: 'PurchaseOrderEditItem',
    PurchaseOrder: 'PurchaseOrder',
    PurchaseOrderIdsConnectAvailability: 'PurchaseOrderIdsConnectAvailability',
    PurchaseOrders: 'PurchaseOrders',
    ProjectQuoteCalculation: 'ProjectQuoteCalculation',
    ProjectQuoteEdit: 'ProjectQuoteEdit',
    ProjectQuoteEditItem: 'ProjectQuoteEditItem',
    ProjectQuotePreview: 'ProjectQuotePreview',
    ProjectQuoteThreeD: 'ProjectQuoteThreeD',
    ProjectQuoteTroubleshoot: 'ProjectQuoteTroubleshoot',
    ProjectQuotes: 'ProjectQuotes',
    ProjectRequest: 'ProjectRequest',
    ProjectRequests: 'ProjectRequests',
    ProjectRequirementEdit: 'ProjectRequirementEdit',
    ProjectRequirementEditItem: 'ProjectRequirementEditItem',
    ProjectRequirementPreview: 'ProjectRequirementPreview',
    ProjectRequirementThreeD: 'ProjectRequirementThreeD',
    ProjectRequirements: 'ProjectRequirements',
    ProjectRoutes: 'ProjectRoutes',
    ProjectQuoteRoutes: 'ProjectQuoteRoutes',
    ProjectRequirementRoutes: 'ProjectRequirementRoutes',
    ProjectTasks: 'ProjectTasks',
    ProjectTasksItem: 'ProjectTasksItem',
    TimeReport: 'TimeReport',
    TimeReports: 'TimeReports',
    Shop: 'Shop',
    ShopPreview: 'ShopPreview',
    TasksList: 'TasksList',
    AssigneeFilterChips: 'AssigneeFilterChips',
    ContactFilterChips: 'ContactFilterChips',
    ClientFilterChips: 'ClientFilterChips',
    ProjectFilterChips: 'ProjectFilterChips',
    TasksListFilterOptions: 'TasksListFilterOptions',
    TemplateQuoteEdit: 'TemplateQuoteEdit',
    TemplateQuoteEditPreview: 'TemplateQuoteEditPreview',
    TemplateQuoteEditItem: 'TemplateQuoteEditItem',
    TemplateQuoteThreeDEdit: 'TemplateQuoteThreeDEdit',
    TemplateQuoteEditTroubleshoot: 'TemplateQuoteEditTroubleshoot',
    TemplateQuoteRoutes: 'TemplateQuoteRoutes',
    TemplateQuoteVersion: 'TemplateQuoteVersion',
    TemplateQuoteVersionPreview: 'TemplateQuoteVersionPreview',
    TemplateQuoteVersionItem: 'TemplateQuoteVersionItem',
    TemplateQuoteVersionRoutes: 'TemplateQuoteVersionRoutes',
    TemplateQuoteThreeDVersion: 'TemplateQuoteThreeDVersion',
    TemplateQuoteVersionTroubleshoot: 'TemplateQuoteVersionTroubleshoot',
    TemplateQuotes: 'TemplateQuotes',
    TemplateQuotesSearch: 'TemplateQuotesSearch',
    TemplateTypeOverview: 'TemplateTypeOverview',
    TemplateTypes: 'TemplateTypes',
    UnnestedTemplateQuoteEditItem: 'UnnestedTemplateQuoteEditItem',
    UnnestedTemplateQuoteVersionItem: 'UnnestedTemplateQuoteVersionItem',
    OrganisationPublicProfile: 'OrganisationPublicProfile',
    Meister1PollForWebhookProjectContracteeInvitation: 'Meister1PollForWebhookProjectContracteeInvitation',
    ThreeDStart_OrganisationProfile: 'ThreeDStart_OrganisationProfile',
    ThreeDStart_TemplatesList: 'ThreeDStart_TemplatesList',
    ThreeDProcess__OrganisationProfile: 'ThreeDProcess__OrganisationProfile',
    ThreeDProcess__ShoppableTemplateThreeD: 'ThreeDProcess__ShoppableTemplateThreeD',
    ThreeDRoomShoppableTemplate: 'ThreeDRoomShoppableTemplate',
    ProjectContracteeInputPage: 'ProjectContracteeInputPage',
    EmbeddableWizard: 'EmbeddableWizard'
  },
  Mutation: {
    CreateManufacturer: 'CreateManufacturer',
    CreateSupplier: 'CreateSupplier',
    CreateNewOrganisationAndUser: 'CreateNewOrganisationAndUser',
    ImplicitFlow_CreateNewOrganisationAndUser: 'ImplicitFlow_CreateNewOrganisationAndUser',
    ImplicitFlow_AcceptProjectContracteeInvitation: 'ImplicitFlow_AcceptProjectContracteeInvitation',
    AuthUpdateUser: 'AuthUpdateUser',
    CreateAbsence: 'CreateAbsence',
    ModifyAbsence: 'ModifyAbsence',
    AddItemAttachments: 'AddItemAttachments',
    ModifyItemAttachment: 'ModifyItemAttachment',
    RemoveItemAttachment: 'RemoveItemAttachment',
    AddProductAttachments: 'AddProductAttachments',
    ModifyProductAttachment: 'ModifyProductAttachment',
    RemoveProductAttachment: 'RemoveProductAttachment',
    AddProjectAttachments: 'AddProjectAttachments',
    ModifyProjectAttachment: 'ModifyProjectAttachment',
    RemoveProjectAttachment: 'RemoveProjectAttachment',
    AddRequestAttachments: 'AddRequestAttachments',
    ModifyRequestAttachment: 'ModifyRequestAttachment',
    RemoveRequestAttachment: 'RemoveRequestAttachment',
    CreateBuilding: 'CreateBuilding',
    ModifyBuilding: 'ModifyBuilding',
    DeleteBuilding: 'DeleteBuilding',
    BuildingAddCrmUserLink: 'BuildingAddCrmUserLink',
    BuildingAddCrmOrganisationLink: 'BuildingAddCrmOrganisationLink',
    BuildingRemoveCrmUserLink: 'BuildingRemoveCrmUserLink',
    BuildingRemoveCrmOrganisationLink: 'BuildingRemoveCrmOrganisationLink',
    BuildingCreate__CreateBuilding: 'BuildingCreate__CreateBuilding',
    SendChannelMessage: 'SendChannelMessage',
    ContentsSet: 'ContentsSet',
    CreateCrmOrganisation: 'CreateCrmOrganisation',
    ModifyCrmOrganisation: 'ModifyCrmOrganisation',
    ModifyCrmOrganisationAvatar: 'ModifyCrmOrganisationAvatar',
    CrmCompanyDetailsForm_ModifyCrmCompany: 'CrmCompanyDetailsForm_ModifyCrmCompany',
    ModifyCrmOrganisationContactPerson: 'ModifyCrmOrganisationContactPerson',
    ModifyCrmOrg_CrmCompanyOwnInformation: 'ModifyCrmOrg_CrmCompanyOwnInformation',
    PimModifySupplierDiscountGroup: 'PimModifySupplierDiscountGroup',
    ModifyCrmCompanyPaymentInformation: 'ModifyCrmCompanyPaymentInformation',
    ModifyCrmCompanyRegistrationDetails: 'ModifyCrmCompanyRegistrationDetails',
    CrmCompanySupplierCataloguesBox__SubscribeTo: 'CrmCompanySupplierCataloguesBox__SubscribeTo',
    CrmCompanySupplierCataloguesBox__UnsubscribeFrom: 'CrmCompanySupplierCataloguesBox__UnsubscribeFrom',
    CrmCompanyEditBillingAddressModal_ModifyCrmCompany: 'CrmCompanyEditBillingAddressModal_ModifyCrmCompany',
    CrmCompanyEditBranchAddressModal_ModifyCrmCompany: 'CrmCompanyEditBranchAddressModal_ModifyCrmCompany',
    CrmCompanyEditContactTypeModal_ModifyCrmCompany: 'CrmCompanyEditContactTypeModal_ModifyCrmCompany',
    CrmCompanyInviteModal__ModifyCrmCompany: 'CrmCompanyInviteModal__ModifyCrmCompany',
    SendCrmLinkingInvitation: 'SendCrmLinkingInvitation',
    RequestSupplierOrganisation: 'RequestSupplierOrganisation',
    CreateCrmUser: 'CreateCrmUser',
    ModifyCrmUser: 'ModifyCrmUser',
    CreateCrmPerson: 'CreateCrmPerson',
    cancelProjectContracteeInvitation: 'cancelProjectContracteeInvitation',
    CopySupplierOrganisationAsCrm__NewSupplier: 'CopySupplierOrganisationAsCrm__NewSupplier',
    SapCreateBusinessPartner: 'SapCreateBusinessPartner',
    RequestCrmEmailAttachmentUploadUrl: 'RequestCrmEmailAttachmentUploadUrl',
    CrmSendEmail: 'CrmSendEmail',
    ImportCrmOrganisations: 'ImportCrmOrganisations',
    SetCustomFieldConfig: 'SetCustomFieldConfig',
    SetCustomField: 'SetCustomField',
    ModifyItemEstimatedCalculation: 'ModifyItemEstimatedCalculation',
    ModifyItemActualCalculation: 'ModifyItemActualCalculation',
    BuildingPlacementBoxSet: 'BuildingPlacementBoxSet',
    DecisionManagementBox_ModifyWizardSettings: 'DecisionManagementBox_ModifyWizardSettings',
    HeaderBox_ModifyItemInfo: 'HeaderBox_ModifyItemInfo',
    SetItemAttachmentClientVisbility: 'SetItemAttachmentClientVisbility',
    QuoteModifyItem_MaterialCalculationBox: 'QuoteModifyItem_MaterialCalculationBox',
    ProductBox_SetItemProduct: 'ProductBox_SetItemProduct',
    ProductBox_RemoveItemProduct: 'ProductBox_RemoveItemProduct',
    ProductBox_SetItemManufacturerProduct: 'ProductBox_SetItemManufacturerProduct',
    ProductBox_SetItemProductType: 'ProductBox_SetItemProductType',
    ProductBox_ModifyItemProduct: 'ProductBox_ModifyItemProduct',
    ProductSelectQuestionControlForm_ModifyWizardSettings: 'ProductSelectQuestionControlForm_ModifyWizardSettings',
    PropertiesSection_DefineItemProps2: 'PropertiesSection_DefineItemProps2',
    PropertiesSection_EnterItemProps2Value: 'PropertiesSection_EnterItemProps2Value',
    CreateItemFromItemRecommendation: 'CreateItemFromItemRecommendation',
    AddRecommendedTemplate: 'AddRecommendedTemplate',
    AddRecommendedTemplateType: 'AddRecommendedTemplateType',
    ModifyRecommendedTemplate: 'ModifyRecommendedTemplate',
    ModifyRecommendedTemplateType: 'ModifyRecommendedTemplateType',
    RemoveRecommendedTemplate: 'RemoveRecommendedTemplate',
    RemoveRecommendedTemplateType: 'RemoveRecommendedTemplateType',
    SetRecommendedTemplateRank: 'SetRecommendedTemplateRank',
    SetRecommendedTemplateTypeRank: 'SetRecommendedTemplateTypeRank',
    QuoteModifyItemTaskInfo_TaskManagementBox: 'QuoteModifyItemTaskInfo_TaskManagementBox',
    SetPlaceholderForTemplateType: 'SetPlaceholderForTemplateType',
    DefineItemTemplateSearchFilterExpressions: 'DefineItemTemplateSearchFilterExpressions',
    QuoteModifyItem_TimeCalculationBox: 'QuoteModifyItem_TimeCalculationBox',
    SetItemChildrenVisibility: 'SetItemChildrenVisibility',
    SetItemTemplatePlaceholderTemplate: 'SetItemTemplatePlaceholderTemplate',
    PropertiesDebugModal_DefineItemProps2: 'PropertiesDebugModal_DefineItemProps2',
    PropertiesDebugModal_EnterItemProps2Value: 'PropertiesDebugModal_EnterItemProps2Value',
    PropertiesDebugModal_DefineItemProductSearchFilterExpression: 'PropertiesDebugModal_DefineItemProductSearchFilterExpression',
    PropertiesDebugModal_DefineItemAttributeExpression: 'PropertiesDebugModal_DefineItemAttributeExpression',
    HeaderBox_RunProjectDocumentAttributeExpressions: 'HeaderBox_RunProjectDocumentAttributeExpressions',
    HeaderBox_RunNonProjectDocumentAttributeExpressions: 'HeaderBox_RunNonProjectDocumentAttributeExpressions',
    ToggleDecisionOptionPreselection: 'ToggleDecisionOptionPreselection',
    ChangeContingency: 'ChangeContingency',
    FinalizeContingencyDecision: 'FinalizeContingencyDecision',
    EliminateItem: 'EliminateItem',
    ResetEliminateItem: 'ResetEliminateItem',
    ChangeDecision: 'ChangeDecision',
    FinalizeSubitemDecision: 'FinalizeSubitemDecision',
    EliminateDecisionOption: 'EliminateDecisionOption',
    ResetEliminateDecisionOption: 'ResetEliminateDecisionOption',
    UseDefaultDecisionActions_EnterItemProps2Value: 'UseDefaultDecisionActions_EnterItemProps2Value',
    UseDefaultDecisionActions_SelectProduct: 'UseDefaultDecisionActions_SelectProduct',
    UseDefaultDecisionActions_FinalizeProductSelect: 'UseDefaultDecisionActions_FinalizeProductSelect',
    DeleteDocumentItem: 'DeleteDocumentItem',
    ItemStateMenuItem__SetItemBinding: 'ItemStateMenuItem__SetItemBinding',
    ItemTypeMenuItem_ChangeItemType: 'ItemTypeMenuItem_ChangeItemType',
    ModifyItemVertical: 'ModifyItemVertical',
    QuoteItemCreateItems: 'QuoteItemCreateItems',
    CreateItemsFromTemplates: 'CreateItemsFromTemplates',
    AttributeExpressionModal_DefineItemAttributeExpression: 'AttributeExpressionModal_DefineItemAttributeExpression',
    DocumentBulkFinalise: 'DocumentBulkFinalise',
    PropertyExpressionModal_DefineItemProps2: 'PropertyExpressionModal_DefineItemProps2',
    RenameProps2Key: 'RenameProps2Key',
    ProductSearchFiltersSection_DefineProductSearchFilterDefinitions: 'ProductSearchFiltersSection_DefineProductSearchFilterDefinitions',
    ProductSearchFiltersSection_DefineProductSearchFilterExpressions: 'ProductSearchFiltersSection_DefineProductSearchFilterExpressions',
    TemplateSeachFiltersSection_DefineTemplateSearchFilterDefintions: 'TemplateSeachFiltersSection_DefineTemplateSearchFilterDefintions',
    TemplateSearchFiltersSection_DefineTemplateSearchFilterExpressions: 'TemplateSearchFiltersSection_DefineTemplateSearchFilterExpressions',
    TemplateSeachSortingsSection_DefineTemplateSearchSortingDefintions: 'TemplateSeachSortingsSection_DefineTemplateSearchSortingDefintions',
    DocExportAsExcel: 'DocExportAsExcel',
    DocExportAsM1ImpexJson: 'DocExportAsM1ImpexJson',
    ExportAsGaeb: 'ExportAsGaeb',
    RequestImportGaebUploadUrl: 'RequestImportGaebUploadUrl',
    CreateProjectFromGaebImport: 'CreateProjectFromGaebImport',
    CreateQuoteFromGaebImport: 'CreateQuoteFromGaebImport',
    ExportAsXiopd: 'ExportAsXiopd',
    RequestImportXiopdUploadUrl: 'RequestImportXiopdUploadUrl',
    CreateProjectFromXiopdImport: 'CreateProjectFromXiopdImport',
    CreateQuoteFromXiopdImport: 'CreateQuoteFromXiopdImport',
    InvitationListAcceptProjectContracteeInvitation: 'InvitationListAcceptProjectContracteeInvitation',
    InvitationListDeclineProjectContracteeInvitation: 'InvitationListDeclineProjectContracteeInvitation',
    InviteOrganisationMember: 'InviteOrganisationMember',
    InvoiceDetailsCreateInvoice: 'InvoiceDetailsCreateInvoice',
    DeleteDraftInvoice: 'DeleteDraftInvoice',
    ModifyInvoiceTexts_InvoiceDraftPreview: 'ModifyInvoiceTexts_InvoiceDraftPreview',
    ModifyInvoicePaymentItem: 'ModifyInvoicePaymentItem',
    InvoiceQuoteModifyItem: 'InvoiceQuoteModifyItem',
    ModifyInvoice: 'ModifyInvoice',
    OpenInvoice: 'OpenInvoice',
    PayInvoice: 'PayInvoice',
    CancelInvoice: 'CancelInvoice',
    AddInvoiceExtraItem: 'AddInvoiceExtraItem',
    ModifyInvoiceExtraItem: 'ModifyInvoiceExtraItem',
    RemoveInvoiceExtraItem: 'RemoveInvoiceExtraItem',
    RequestImportFromJsonUrl: 'RequestImportFromJsonUrl',
    ImportFromJson: 'ImportFromJson',
    ExportAsJson: 'ExportAsJson',
    ModifyOrganisation: 'ModifyOrganisation',
    ModifyOrganisationMembership: 'ModifyOrganisationMembership',
    ModifyOrganisationProfile__Attachments: 'ModifyOrganisationProfile__Attachments',
    ModifyOrganisationProfile__Services: 'ModifyOrganisationProfile__Services',
    ModifyOrganisationProfile__About: 'ModifyOrganisationProfile__About',
    ModifyOrganisationProfile__Contact: 'ModifyOrganisationProfile__Contact',
    OrganisationAccountingSettingsBox_ModifyOrganisationSettings: 'OrganisationAccountingSettingsBox_ModifyOrganisationSettings',
    OrganisationAccountingSettingsBox_ModifyOrganisationCounters: 'OrganisationAccountingSettingsBox_ModifyOrganisationCounters',
    OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettings: 'OrganisationBillOfMaterialsSettingsBox_ModifyOrganisationSettings',
    ModifyOrganisationAvatar: 'ModifyOrganisationAvatar',
    OrganisationDetailsForm_ModifyOrganisation: 'OrganisationDetailsForm_ModifyOrganisation',
    UpdateOrganisationEmailReplyToAddress: 'UpdateOrganisationEmailReplyToAddress',
    AddOrganisationEmailTemplate: 'AddOrganisationEmailTemplate',
    ModifyOrganisationEmailTemplate: 'ModifyOrganisationEmailTemplate',
    RemoveOrganisationEmailTemplate: 'RemoveOrganisationEmailTemplate',
    OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettings: 'OrganisationEmbeddableWizardSnippetBox_ModifyOrganisationSettings',
    OrganisationIntegrationsBoxAdd: 'OrganisationIntegrationsBoxAdd',
    OrganisationIntegrationsBoxRemove: 'OrganisationIntegrationsBoxRemove',
    OrganisationIntegrationsBoxUpdate: 'OrganisationIntegrationsBoxUpdate',
    OrganisationInvoiceSettingsForm_ModifyOrganisationDefaults: 'OrganisationInvoiceSettingsForm_ModifyOrganisationDefaults',
    OrganisationMembers_ModifyOrganisation: 'OrganisationMembers_ModifyOrganisation',
    CancelOrganisationMembershipInvitation: 'CancelOrganisationMembershipInvitation',
    ModifyOrganisationPaymentInformation: 'ModifyOrganisationPaymentInformation',
    RequestOrganisationPdfLetterheadUploadUrl: 'RequestOrganisationPdfLetterheadUploadUrl',
    GenerateOrganisationPdfLetterhead: 'GenerateOrganisationPdfLetterhead',
    GenerateExamplePdfDocument: 'GenerateExamplePdfDocument',
    SetOrganisationPdfLetterhead: 'SetOrganisationPdfLetterhead',
    OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaults: 'OrganisationPriceAndCalculationSettingsForm_ModifyOrganisationDefaults',
    OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettings: 'OrganisationProjectDefaultSettingsForm_ModifyOrganisationSettings',
    OrganisationProjectSettingsForm_ModifyOrganisationSettings: 'OrganisationProjectSettingsForm_ModifyOrganisationSettings',
    UpdateOrganisationQuotePdfSettings: 'UpdateOrganisationQuotePdfSettings',
    OrganisationQuoteSettingsForm_ModifyOrganisationDefaults: 'OrganisationQuoteSettingsForm_ModifyOrganisationDefaults',
    ModifyOrganisationRegistrationDetails: 'ModifyOrganisationRegistrationDetails',
    OrganisationScheduleSettingsBox_ModifyOrganisation: 'OrganisationScheduleSettingsBox_ModifyOrganisation',
    OrganisationScheduleSettingsBox_ModifyOrganisationDefaults: 'OrganisationScheduleSettingsBox_ModifyOrganisationDefaults',
    OrganisationTaskSettingsForm_ModifyOrganisationDefaults: 'OrganisationTaskSettingsForm_ModifyOrganisationDefaults',
    AddOrganisationProfile: 'AddOrganisationProfile',
    OrganisationTextsModal_ModifyOrganisation: 'OrganisationTextsModal_ModifyOrganisation',
    AddOrganisationContractingPdfs: 'AddOrganisationContractingPdfs',
    ModifyOrganisationContractingPdfs: 'ModifyOrganisationContractingPdfs',
    RemoveOrganisationContractingPdfs: 'RemoveOrganisationContractingPdfs',
    ExtractOrganisationContractingPdfFormFields: 'ExtractOrganisationContractingPdfFormFields',
    GetCsvHeader: 'GetCsvHeader',
    ImportDatanormOnlineEntry: 'ImportDatanormOnlineEntry',
    AddPimImport: 'AddPimImport',
    PimRequestImportationUploadUrl: 'PimRequestImportationUploadUrl',
    DeletePimImport: 'DeletePimImport',
    DeletePimSupplierImportations: 'DeletePimSupplierImportations',
    CancelPimImport: 'CancelPimImport',
    PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeader: 'PimSupplierDiscountGroupsImportationColumnMappingModal_GetCsvHeader',
    AddPimSupplierDiscountGroupsImportationButton_CreatePimSupplierDiscountGroupsImportation: 'AddPimSupplierDiscountGroupsImportationButton_CreatePimSupplierDiscountGroupsImportation',
    EditProjectPlanSessionsModal_Update: 'EditProjectPlanSessionsModal_Update',
    PimUpdateProduct: 'PimUpdateProduct',
    _3d_addProductData: '_3d_addProductData',
    PimSetSupplierProductProductType: 'PimSetSupplierProductProductType',
    PimSetManufacturerProductProductType: 'PimSetManufacturerProductProductType',
    RequestPimCreateThreedModelUploadUrl: 'RequestPimCreateThreedModelUploadUrl',
    _3d_requestAddProductDataUrl: '_3d_requestAddProductDataUrl',
    ProductEditSupplierBox__PimCreateSupplierProduct: 'ProductEditSupplierBox__PimCreateSupplierProduct',
    PimSetSupplierProductBranding: 'PimSetSupplierProductBranding',
    PimSetSupplierProductPropertySuggestions: 'PimSetSupplierProductPropertySuggestions',
    _3d_removeProductData: '_3d_removeProductData',
    pimCreateThreedModel: 'pimCreateThreedModel',
    DeleteCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton: 'DeleteCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton',
    ReenableCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton: 'ReenableCustomSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton',
    DeleteSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton: 'DeleteSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton',
    ReenableSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton: 'ReenableSupplierProduct__OrganisationSupplierProductDeleteOrReenableMenuButton',
    OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavourite: 'OrganisationSupplierProductFavouriteButton__PimSetSupplierProductsFavourite',
    SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariant: 'SupplierDiscountGroupVariantDeleteMenuButton_DeleteSupplierDiscountGroupVariant',
    OpenMasterdataUpdateSupplierProduct: 'OpenMasterdataUpdateSupplierProduct',
    PimAddSupplierProductViewed: 'PimAddSupplierProductViewed',
    PimRunPropertyExtraction: 'PimRunPropertyExtraction',
    SetProductTypePopularPropertyFilters: 'SetProductTypePopularPropertyFilters',
    SupplierCatalogueProductCreateModal__PimCreateSupplierProduct: 'SupplierCatalogueProductCreateModal__PimCreateSupplierProduct',
    SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariant: 'SupplierDiscountGroupVariantCreateModal_CreateSupplierDiscountGroupVariant',
    OpenMasterdataAuthenticate: 'OpenMasterdataAuthenticate',
    SetProjectAssignee: 'SetProjectAssignee',
    RenameOrganisationProjectPhase: 'RenameOrganisationProjectPhase',
    RearrangeOrganisationProjectPhase: 'RearrangeOrganisationProjectPhase',
    RemoveOrganisationProjectPhase: 'RemoveOrganisationProjectPhase',
    AddOrganisationProjectPhase: 'AddOrganisationProjectPhase',
    CreateProject: 'CreateProject',
    ModifyProject: 'ModifyProject',
    ProjectAddCrmUserLink: 'ProjectAddCrmUserLink',
    ProjectAddCrmOrganisationLink: 'ProjectAddCrmOrganisationLink',
    ProjectRemoveCrmUserLink: 'ProjectRemoveCrmUserLink',
    ProjectRemoveCrmOrganisationLink: 'ProjectRemoveCrmOrganisationLink',
    StartProjectWorkSession: 'StartProjectWorkSession',
    FinishProjectWorkSession: 'FinishProjectWorkSession',
    AddProjectContracteeInvitation: 'AddProjectContracteeInvitation',
    addProjectMemberFromOrganisationMembers: 'addProjectMemberFromOrganisationMembers',
    CreateTicketModal_CreateDocumentItem: 'CreateTicketModal_CreateDocumentItem',
    modifyProjectMembership: 'modifyProjectMembership',
    RemoveProjectMembership: 'RemoveProjectMembership',
    ProjectAssignBuilding: 'ProjectAssignBuilding',
    ChangeProjectPhase: 'ChangeProjectPhase',
    SendProjectStateEvent: 'SendProjectStateEvent',
    CreateIdsConnectOrder: 'CreateIdsConnectOrder',
    CreatePurchaseOrder: 'CreatePurchaseOrder',
    ChangeOrderItemQuantity: 'ChangeOrderItemQuantity',
    ImportRequirement: 'ImportRequirement',
    ModifyQuoteTexts_QuotePreview: 'ModifyQuoteTexts_QuotePreview',
    ModifyQuoteDiscount: 'ModifyQuoteDiscount',
    SetQuoteSapS4HanaStatus: 'SetQuoteSapS4HanaStatus',
    SetQuoteExternalIdentifier: 'SetQuoteExternalIdentifier',
    UpdateQuoteSettings: 'UpdateQuoteSettings',
    UnpublishQuote: 'UnpublishQuote',
    AcceptQuote: 'AcceptQuote',
    DeclineChanges: 'DeclineChanges',
    AcceptChanges: 'AcceptChanges',
    DeleteQuote: 'DeleteQuote',
    DeclineQuote: 'DeclineQuote',
    ModifyQuote: 'ModifyQuote',
    MakeQuoteBinding: 'MakeQuoteBinding',
    DiscardPendingChanges: 'DiscardPendingChanges',
    DuplicateQuote: 'DuplicateQuote',
    PublishQuote: 'PublishQuote',
    PublishQuoteButton_SetContractorAddress: 'PublishQuoteButton_SetContractorAddress',
    QuoteBulkUpdateProductsButton__QuoteBulkUpdateProducts: 'QuoteBulkUpdateProductsButton__QuoteBulkUpdateProducts',
    AcceptQuoteModal_SetQuoteContracteeAddress: 'AcceptQuoteModal_SetQuoteContracteeAddress',
    MarkQuoteAsAcceptedModal_SetDocActorAddress: 'MarkQuoteAsAcceptedModal_SetDocActorAddress',
    QuoteBulkUpdateProductPricingsModal__UpdateProductPricingsInQuote: 'QuoteBulkUpdateProductPricingsModal__UpdateProductPricingsInQuote',
    CreateQuote: 'CreateQuote',
    AddQuoteDocumentActor: 'AddQuoteDocumentActor',
    ModifyQuoteDocumentActor: 'ModifyQuoteDocumentActor',
    ResetQuoteDocActor: 'ResetQuoteDocActor',
    ModifyQuoteOfferConditions: 'ModifyQuoteOfferConditions',
    ModifyPublishingInfo: 'ModifyPublishingInfo',
    PublishRequestToMarketplace: 'PublishRequestToMarketplace',
    UnpublishRequestToMarketplace: 'UnpublishRequestToMarketplace',
    PublishRequest: 'PublishRequest',
    WithdrawRequest: 'WithdrawRequest',
    CloseRequest: 'CloseRequest',
    CloseForBiddingRequest: 'CloseForBiddingRequest',
    AddRequestBidders: 'AddRequestBidders',
    rejectAllQuotesInRequest: 'rejectAllQuotesInRequest',
    ProvideRequestFeedback: 'ProvideRequestFeedback',
    CreateProjectForRequest: 'CreateProjectForRequest',
    RemoveRequestRecipient: 'RemoveRequestRecipient',
    CreateQuoteRequest: 'CreateQuoteRequest',
    OutsourceItem: 'OutsourceItem',
    ModifyRequestAdditionalInfo: 'ModifyRequestAdditionalInfo',
    ModifyRequestProjectInfo: 'ModifyRequestProjectInfo',
    CreateRequirement: 'CreateRequirement',
    AddRequirementDocumentActor: 'AddRequirementDocumentActor',
    ModifyRequirementDocumentActor: 'ModifyRequirementDocumentActor',
    ResetRequirementDocumentActor: 'ResetRequirementDocumentActor',
    DeleteRequirement: 'DeleteRequirement',
    CopyQuoteSapS4HanaSalesQuote: 'CopyQuoteSapS4HanaSalesQuote',
    ModifySapS4HanaSalesQuoteData: 'ModifySapS4HanaSalesQuoteData',
    SapS4HanaCancelItem: 'SapS4HanaCancelItem',
    SapS4HanaUncancelItem: 'SapS4HanaUncancelItem',
    SapS4HanaModifyItemData: 'SapS4HanaModifyItemData',
    S4HanaSimulateQuotation: 'S4HanaSimulateQuotation',
    S4HanaCreateQuote: 'S4HanaCreateQuote',
    S4HanaUpdateQuote: 'S4HanaUpdateQuote',
    S4HanaCancelQuote: 'S4HanaCancelQuote',
    SapS4HanaUpdateQuoteFromRemote: 'SapS4HanaUpdateQuoteFromRemote',
    SapS4HanaDownloadQuoteAsPdf: 'SapS4HanaDownloadQuoteAsPdf',
    S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuote: 'S4HanaSalesQuotationImportModal_CreateQuoteFromSapS4HanaSalesQuote',
    ScheduleModifyProject: 'ScheduleModifyProject',
    ScheduleModifyPlanSession: 'ScheduleModifyPlanSession',
    ScheduleCreatePlanSession: 'ScheduleCreatePlanSession',
    ScheduleDeletePlanSession: 'ScheduleDeletePlanSession',
    ShopRemoveFromCart: 'ShopRemoveFromCart',
    ShopAddToCart: 'ShopAddToCart',
    ShopLikeTemplate: 'ShopLikeTemplate',
    ShopUnlikeTemplate: 'ShopUnlikeTemplate',
    ApproveTaskItem: 'ApproveTaskItem',
    DisapproveTaskItem: 'DisapproveTaskItem',
    PhotoApproveTaskItem: 'PhotoApproveTaskItem',
    PhotoDisapproveTaskItem: 'PhotoDisapproveTaskItem',
    QaApproveTaskItem: 'QaApproveTaskItem',
    QaDisapproveTaskItem: 'QaDisapproveTaskItem',
    SetTaskItemDone: 'SetTaskItemDone',
    SetTaskItemNotDone: 'SetTaskItemNotDone',
    ModifyProjectWorkSession: 'ModifyProjectWorkSession',
    ModifyMyProjectWorkSession: 'ModifyMyProjectWorkSession',
    CorrectWorkSession_TasksForm: 'CorrectWorkSession_TasksForm',
    TemplateBulkUpdateProductsButton__TemplateBulkUpdateProducts: 'TemplateBulkUpdateProductsButton__TemplateBulkUpdateProducts',
    SetItemProps2Mappings: 'SetItemProps2Mappings',
    RestoreTemplateVersion: 'RestoreTemplateVersion',
    ResolveTemplate: 'ResolveTemplate',
    DeleteQuoteTemplate: 'DeleteQuoteTemplate',
    ModifyQuoteTemplate__isBinding: 'ModifyQuoteTemplate__isBinding',
    DuplicateQuoteTemplate: 'DuplicateQuoteTemplate',
    SetUnnestedTemplateDecisionPreselection: 'SetUnnestedTemplateDecisionPreselection',
    SetUnnestedTemplateItemDecisionContingentItemPreselection: 'SetUnnestedTemplateItemDecisionContingentItemPreselection',
    ModifyQuoteTemplateDiscount: 'ModifyQuoteTemplateDiscount',
    ModifyQuoteTemplate: 'ModifyQuoteTemplate',
    ModifyQuoteTemplatePublishingInfo: 'ModifyQuoteTemplatePublishingInfo',
    ModifyTplApplicableFor: 'ModifyTplApplicableFor',
    implementsTemplateType: 'implementsTemplateType',
    CreateQuoteTemplate: 'CreateQuoteTemplate',
    CreateQuoteTemplateButton_SetItemProduct: 'CreateQuoteTemplateButton_SetItemProduct',
    AddQuoteTemplateToShop: 'AddQuoteTemplateToShop',
    RemoveQuoteTemplateFromShop: 'RemoveQuoteTemplateFromShop',
    QuoteTemplateCreateFromItemButton_CreateTemplateFromItem: 'QuoteTemplateCreateFromItemButton_CreateTemplateFromItem',
    DiscardUnpublishedChanges: 'DiscardUnpublishedChanges',
    PublishQuoteTemplate: 'PublishQuoteTemplate',
    ShareQuoteTemplateWithOrganisations: 'ShareQuoteTemplateWithOrganisations',
    ShareQuoteTemplatePublicly: 'ShareQuoteTemplatePublicly',
    SubscribeToQuoteTemplate: 'SubscribeToQuoteTemplate',
    UnsubscribeFromQuoteTemplate: 'UnsubscribeFromQuoteTemplate',
    UpdateAllLinksToLatestPublishedVersion: 'UpdateAllLinksToLatestPublishedVersion',
    QuoteTemplateUpdateToLatestAvailablePublishedVersion: 'QuoteTemplateUpdateToLatestAvailablePublishedVersion',
    LikeQuoteTemplate: 'LikeQuoteTemplate',
    UnlikeQuoteTemplate: 'UnlikeQuoteTemplate',
    LikeShopTemplate: 'LikeShopTemplate',
    UnlikeShopTemplate: 'UnlikeShopTemplate',
    ThreeDModifyRoomShape: 'ThreeDModifyRoomShape',
    ThreeDAddSnapshots: 'ThreeDAddSnapshots',
    CreateTimeReport: 'CreateTimeReport',
    CreateTodoItem: 'CreateTodoItem',
    ModifyTodoItem: 'ModifyTodoItem',
    UserAccountChangeEmailBox: 'UserAccountChangeEmailBox',
    UserAccountChangePasswordBox: 'UserAccountChangePasswordBox',
    RequestAccountDeletion: 'RequestAccountDeletion',
    SetOrganisationMemberOrganisationRoles: 'SetOrganisationMemberOrganisationRoles',
    SetOrganisationMemberDefaultProjectRoles: 'SetOrganisationMemberDefaultProjectRoles',
    ModifyUserOrganisationMembership: 'ModifyUserOrganisationMembership',
    RedeemLinkingInvitationToken: 'RedeemLinkingInvitationToken',
    EnableUserCalendar: 'EnableUserCalendar',
    DisableUserCalendar: 'DisableUserCalendar',
    UpdateUserNotificationSettings: 'UpdateUserNotificationSettings',
    SetUserPreference: 'SetUserPreference',
    CreateWorkSessions: 'CreateWorkSessions',
    CorrectWorkSession: 'CorrectWorkSession',
    AddPushNotificationToken: 'AddPushNotificationToken',
    ModifyMyUserAvatar: 'ModifyMyUserAvatar',
    AcceptCrmLinkingInvitation: 'AcceptCrmLinkingInvitation',
    AcceptOrganisationMembershipInvitation: 'AcceptOrganisationMembershipInvitation',
    AcceptProjectContracteeInvitation: 'AcceptProjectContracteeInvitation',
    BuildingEdit_CreateItems: 'BuildingEdit_CreateItems',
    BuildingEdit_CreateItemsFromTemplates: 'BuildingEdit_CreateItemsFromTemplates',
    BuildingEdit_PasteItemFromClipboard: 'BuildingEdit_PasteItemFromClipboard',
    ModifyCrmUserAvatar: 'ModifyCrmUserAvatar',
    PendingDocSharesBox_AcceptDocShare: 'PendingDocSharesBox_AcceptDocShare',
    DocShareBox_shareDoc: 'DocShareBox_shareDoc',
    DocShareBox_cancelPendingShareDoc: 'DocShareBox_cancelPendingShareDoc',
    DocShareBox_unshareDoc: 'DocShareBox_unshareDoc',
    OrganisationBrandingTheme_ModifyOrganisationSettings: 'OrganisationBrandingTheme_ModifyOrganisationSettings',
    OrganisationBrandingLogo_ModifyOrganisationSettings: 'OrganisationBrandingLogo_ModifyOrganisationSettings',
    OrganisationBrandingAd_ModifyOrganisationSettings: 'OrganisationBrandingAd_ModifyOrganisationSettings',
    toggleOrganisationMembershipActive: 'toggleOrganisationMembershipActive',
    ModifyUserAvatar: 'ModifyUserAvatar',
    ApplyArticleList: 'ApplyArticleList',
    ArticleList_ModifyCrmCompanyEmail: 'ArticleList_ModifyCrmCompanyEmail',
    ArticleLists_CreateQuoteProductsDistribution: 'ArticleLists_CreateQuoteProductsDistribution',
    RemoveAbsence: 'RemoveAbsence',
    SupplierCatalogueProductEdit__PimModifySupplierProduct: 'SupplierCatalogueProductEdit__PimModifySupplierProduct',
    AddPurchaseOrderItems: 'AddPurchaseOrderItems',
    ModifyPurchaseOrderItem: 'ModifyPurchaseOrderItem',
    RemovePurchaseOrderItems: 'RemovePurchaseOrderItems',
    ModifyPurchaseOrder: 'ModifyPurchaseOrder',
    DeletePurchaseOrder: 'DeletePurchaseOrder',
    ModifyOrder: 'ModifyOrder',
    SubmitOrder: 'SubmitOrder',
    ProjectQuoteCalculation_CreateItems: 'ProjectQuoteCalculation_CreateItems',
    ProjectQuoteCalculation_CreateItemsFromTemplates: 'ProjectQuoteCalculation_CreateItemsFromTemplates',
    ProjectQuoteCalculation_PasteItemFromClipboard: 'ProjectQuoteCalculation_PasteItemFromClipboard',
    ProjectQuoteEdit_CreateItems: 'ProjectQuoteEdit_CreateItems',
    ProjectQuoteEdit_CreateItemsFromTemplates: 'ProjectQuoteEdit_CreateItemsFromTemplates',
    ProjectQuoteEdit_CreateItemsFromTemplateTypes: 'ProjectQuoteEdit_CreateItemsFromTemplateTypes',
    ProjectQuoteEdit_PasteItemFromClipboard: 'ProjectQuoteEdit_PasteItemFromClipboard',
    ProjectQuoteThreeD_CreateItems: 'ProjectQuoteThreeD_CreateItems',
    ProjectQuoteThreeD_CreateItemsFromTemplates: 'ProjectQuoteThreeD_CreateItemsFromTemplates',
    ProjectQuoteThreeD_PasteItemFromClipboard: 'ProjectQuoteThreeD_PasteItemFromClipboard',
    ProjectRequirementEdit_CreateItems: 'ProjectRequirementEdit_CreateItems',
    ProjectRequirementEdit_CreateItemsFromTemplates: 'ProjectRequirementEdit_CreateItemsFromTemplates',
    ProjectRequirementEdit_PasteItemFromClipboard: 'ProjectRequirementEdit_PasteItemFromClipboard',
    ProjectRequirementThreeD_CreateItems: 'ProjectRequirementThreeD_CreateItems',
    ProjectRequirementThreeD_CreateItemsFromTemplates: 'ProjectRequirementThreeD_CreateItemsFromTemplates',
    ProjectRequirementThreeD_PasteItemFromClipboard: 'ProjectRequirementThreeD_PasteItemFromClipboard',
    UseCreateTasksItems_CreateItems: 'UseCreateTasksItems_CreateItems',
    UseCreateTasksItems_CreateItemsDefect: 'UseCreateTasksItems_CreateItemsDefect',
    UseCreateTasksItems_CreateItemsUnpaid: 'UseCreateTasksItems_CreateItemsUnpaid',
    ModifyTimeReportTexts: 'ModifyTimeReportTexts',
    SignTimeReport: 'SignTimeReport',
    ApproveTimeReport: 'ApproveTimeReport',
    ToggleTodoItemDone: 'ToggleTodoItemDone',
    ModifyQuoteTemplateTexts_TemplatesQuotePreview: 'ModifyQuoteTemplateTexts_TemplatesQuotePreview',
    TemplateQuoteEdit_CreateItems: 'TemplateQuoteEdit_CreateItems',
    TemplateQuoteEdit_CreateTemplate: 'TemplateQuoteEdit_CreateTemplate',
    TemplateQuoteEdit_SetItemProduct: 'TemplateQuoteEdit_SetItemProduct',
    TemplateQuoteEdit_PasteItemFromClipboard: 'TemplateQuoteEdit_PasteItemFromClipboard',
    threeD_CreateLeadInNewProject: 'threeD_CreateLeadInNewProject',
    setTreeHierarchy: 'setTreeHierarchy',
    RequestProjectAttachmentUploadUrl: 'RequestProjectAttachmentUploadUrl',
    AddProjectContracteeInput: 'AddProjectContracteeInput',
    EmbeddableWizard_createEmbedabbleWizardLead: 'EmbeddableWizard_createEmbedabbleWizardLead'
  },
  Fragment: {
    CurrentOrganisationCard_ViewerOrganisation: 'CurrentOrganisationCard_ViewerOrganisation',
    ViewerShape: 'ViewerShape',
    AuthOrganisation: 'AuthOrganisation',
    AuthBrandingTheme: 'AuthBrandingTheme',
    AuthBrandingSlot: 'AuthBrandingSlot',
    AddressDetails__Address: 'AddressDetails__Address',
    WithBillingAddress: 'WithBillingAddress',
    WithBranchAddress: 'WithBranchAddress',
    Attachment: 'Attachment',
    ItemAttachment: 'ItemAttachment',
    AttachmentSnapshot: 'AttachmentSnapshot',
    Attachments: 'Attachments',
    WithItemAttachments: 'WithItemAttachments',
    BillOfMaterialsItem: 'BillOfMaterialsItem',
    BillOfMaterialsItemAlert: 'BillOfMaterialsItemAlert',
    BillOfMaterialItemDetails: 'BillOfMaterialItemDetails',
    ItemManualProductBox_ItemProduct: 'ItemManualProductBox_ItemProduct',
    OrderDetails: 'OrderDetails',
    RelatedQuoteItems: 'RelatedQuoteItems',
    BuildingSubHeader_Building: 'BuildingSubHeader_Building',
    BuildingRow__Building: 'BuildingRow__Building',
    BuildingDetails__Building: 'BuildingDetails__Building',
    BuildingItemLinks: 'BuildingItemLinks',
    BuildingItem_Building: 'BuildingItem_Building',
    BuildingItem_Item: 'BuildingItem_Item',
    BuildingItem_ChildItem: 'BuildingItem_ChildItem',
    BuildingItem_ChildItemComponent: 'BuildingItem_ChildItemComponent',
    BuildingSelectModal_Building: 'BuildingSelectModal_Building',
    BuildingSelectModal_Item: 'BuildingSelectModal_Item',
    BuildingTreeItem: 'BuildingTreeItem',
    ChannelMessages__Channel: 'ChannelMessages__Channel',
    ChannelMessages__Message: 'ChannelMessages__Message',
    ChannelParticipant: 'ChannelParticipant',
    ChannelListItem: 'ChannelListItem',
    ProjectCrmLinksLabel: 'ProjectCrmLinksLabel',
    ContentsContent: 'ContentsContent',
    Contents: 'Contents',
    CraftsmenSearchOrganisation: 'CraftsmenSearchOrganisation',
    CreateCrmOrganisation_CrmCompany: 'CreateCrmOrganisation_CrmCompany',
    OrganisationRouter__CrmCompany: 'OrganisationRouter__CrmCompany',
    CrmCompanyOrPersonSelect_CrmPerson: 'CrmCompanyOrPersonSelect_CrmPerson',
    CrmCompanySelect_CrmCompaniesRecord: 'CrmCompanySelect_CrmCompaniesRecord',
    CrmCompanyBuildingsBox__Building: 'CrmCompanyBuildingsBox__Building',
    CrmCompanyCommentsBox_Channel: 'CrmCompanyCommentsBox_Channel',
    CrmCompanyCustomFieldsBox_CrmCompany: 'CrmCompanyCustomFieldsBox_CrmCompany',
    CrmCompanyDetailsBox_CrmComapny: 'CrmCompanyDetailsBox_CrmComapny',
    CrmCompanyDetailsData_CrmCompany: 'CrmCompanyDetailsData_CrmCompany',
    CrmCompanyDetailsForm_CrmCompany: 'CrmCompanyDetailsForm_CrmCompany',
    CrmCompanyMembersBox_CrmCompany: 'CrmCompanyMembersBox_CrmCompany',
    CrmCompanyMemberRow__CrmPerson: 'CrmCompanyMemberRow__CrmPerson',
    CrmCompanyOwnInformation_CrmCompany: 'CrmCompanyOwnInformation_CrmCompany',
    DiscountGroup: 'DiscountGroup',
    CrmCompanyPaymentInformationBox_CrmCompany: 'CrmCompanyPaymentInformationBox_CrmCompany',
    CrmCompanyPaymentInformationData_CrmCompany: 'CrmCompanyPaymentInformationData_CrmCompany',
    CrmCompanyPaymentInformationForm_CrmComapny: 'CrmCompanyPaymentInformationForm_CrmComapny',
    CrmCompanyRegistrationDetailsBox_CrmCompany: 'CrmCompanyRegistrationDetailsBox_CrmCompany',
    CrmCompanyRegistrationDetailsData_CrmCompany: 'CrmCompanyRegistrationDetailsData_CrmCompany',
    CrmCompanyRegistrationDetailsForm_CrmCompany: 'CrmCompanyRegistrationDetailsForm_CrmCompany',
    CrmCompanySupplierCataloguesBox__SupplierCatalogue: 'CrmCompanySupplierCataloguesBox__SupplierCatalogue',
    CrmCompanyListItem: 'CrmCompanyListItem',
    crmCompanyToEmailRecipientOption_CrmCompany: 'crmCompanyToEmailRecipientOption_CrmCompany',
    CrmPersonDetails: 'CrmPersonDetails',
    CrmPersonInformationForm_CrmPerson: 'CrmPersonInformationForm_CrmPerson',
    CrmPersonSelect_CrmPerson: 'CrmPersonSelect_CrmPerson',
    CrmPersonCustomFieldsBox_CrmPerson: 'CrmPersonCustomFieldsBox_CrmPerson',
    CrmPersonListItem: 'CrmPersonListItem',
    crmPersonToEmailRecipientOptionl_CrmPerson: 'crmPersonToEmailRecipientOptionl_CrmPerson',
    BaseDataTableAddress: 'BaseDataTableAddress',
    SearchGlobalSuppliersRow: 'SearchGlobalSuppliersRow',
    SearchGlobalManufacturersRow: 'SearchGlobalManufacturersRow',
    CrmEmailListItem_CrmMailMessage: 'CrmEmailListItem_CrmMailMessage',
    CrmEmailMessagesBox_CrmCompany: 'CrmEmailMessagesBox_CrmCompany',
    CrmEmailMessagesBox_CrmPerson: 'CrmEmailMessagesBox_CrmPerson',
    CrmEmailMessagesBox_CrmMailMessage: 'CrmEmailMessagesBox_CrmMailMessage',
    EmailMessagePreviewModal_CrmMailMessage: 'EmailMessagePreviewModal_CrmMailMessage',
    CrmSendEmailForm_AvailableTemplate: 'CrmSendEmailForm_AvailableTemplate',
    MassImportCrm_OrganisationSettings: 'MassImportCrm_OrganisationSettings',
    MassImportCrm_CrmCompany: 'MassImportCrm_CrmCompany',
    CustomFields: 'CustomFields',
    CustomFieldConfig: 'CustomFieldConfig',
    DecisionOptionGalleryItem_Item: 'DecisionOptionGalleryItem_Item',
    DecisionOptionListItem_Item: 'DecisionOptionListItem_Item',
    DecisionOptionListItem_ParentItem: 'DecisionOptionListItem_ParentItem',
    WithDocumentItemCalculation: 'WithDocumentItemCalculation',
    WithDocumentItemInfo: 'WithDocumentItemInfo',
    WithDocumentItemTreeInfo: 'WithDocumentItemTreeInfo',
    WithDocumentItemChangeOrderInfo: 'WithDocumentItemChangeOrderInfo',
    WithDocumentItemDecisionInfo: 'WithDocumentItemDecisionInfo',
    WithDocumentItemVisibilityInfo: 'WithDocumentItemVisibilityInfo',
    WithItemBindingInfo: 'WithItemBindingInfo',
    WithItemProperties: 'WithItemProperties',
    WithProductInfo: 'WithProductInfo',
    WithProductFiltersInfo: 'WithProductFiltersInfo',
    WithDocumentItemTaskInfo: 'WithDocumentItemTaskInfo',
    WithDocumentItemAgreementInfo: 'WithDocumentItemAgreementInfo',
    WithDocumentItemInvoiceInfo: 'WithDocumentItemInvoiceInfo',
    WithDocumentItemAuthorOrganisation: 'WithDocumentItemAuthorOrganisation',
    Vat: 'Vat',
    ItemCalculation: 'ItemCalculation',
    TreeItem__DocumentItem: 'TreeItem__DocumentItem',
    DocumentItem_Calculations: 'DocumentItem_Calculations',
    ItemProductFields: 'ItemProductFields',
    FieldsAffectedByProperties: 'FieldsAffectedByProperties',
    FieldsAffectedByAttributeExpressions: 'FieldsAffectedByAttributeExpressions',
    TreeItemTemplatePlaceholder: 'TreeItemTemplatePlaceholder',
    BuildingPlacementBox_Item: 'BuildingPlacementBox_Item',
    BuildingPlacementBox_Project: 'BuildingPlacementBox_Project',
    AssignBuildingButton_Project: 'AssignBuildingButton_Project',
    DecisionManagementBox_Item: 'DecisionManagementBox_Item',
    DecisionManagementBox_ChildItem: 'DecisionManagementBox_ChildItem',
    EstimatedCalculation__Item: 'EstimatedCalculation__Item',
    EstimatedCalculationtBox_AllDocItem: 'EstimatedCalculationtBox_AllDocItem',
    HeaderBox_Item: 'HeaderBox_Item',
    ItemFilesBox_Item: 'ItemFilesBox_Item',
    MaterialCalculationBox_Item: 'MaterialCalculationBox_Item',
    MaterialCalculationBox_Project: 'MaterialCalculationBox_Project',
    MaterialCalculationEstimatedRow_Item: 'MaterialCalculationEstimatedRow_Item',
    MaterialCalculationActualRow_Item: 'MaterialCalculationActualRow_Item',
    ProductBox_ItemProduct: 'ProductBox_ItemProduct',
    ProductBox_Item: 'ProductBox_Item',
    ProductBox_Project: 'ProductBox_Project',
    ProductSearchFiltersSection_Item: 'ProductSearchFiltersSection_Item',
    ProductSelectQuestionControlForm_Item: 'ProductSelectQuestionControlForm_Item',
    PropertiesSection_Item: 'PropertiesSection_Item',
    PropertyDisplayBox_Item: 'PropertyDisplayBox_Item',
    QuantityBox_Item: 'QuantityBox_Item',
    RecommendationsBox_Item: 'RecommendationsBox_Item',
    RecommendationsConfigBox_Item: 'RecommendationsConfigBox_Item',
    RequiredOnCompletionBox_Item: 'RequiredOnCompletionBox_Item',
    RequiredOnCompletionBox_Project: 'RequiredOnCompletionBox_Project',
    RulesSection_CurrentDocumentItem: 'RulesSection_CurrentDocumentItem',
    SubelementsBox_Item: 'SubelementsBox_Item',
    SubelementsBox_ChildItem: 'SubelementsBox_ChildItem',
    TaskManagementBox_Item: 'TaskManagementBox_Item',
    TaskManagementBox_Project: 'TaskManagementBox_Project',
    TemplateTypePlaceholderBox_Item: 'TemplateTypePlaceholderBox_Item',
    TemplateSearchFilterDefinitions: 'TemplateSearchFilterDefinitions',
    TemplateSearchSortingDefinition: 'TemplateSearchSortingDefinition',
    TemplateSearchSortingDefault: 'TemplateSearchSortingDefault',
    TemplateTypePlaceholderBox_QuoteTemplate: 'TemplateTypePlaceholderBox_QuoteTemplate',
    TemplateTypePlaceholderBox_Quote: 'TemplateTypePlaceholderBox_Quote',
    TimeCalculationBox_Item: 'TimeCalculationBox_Item',
    TimeCalculationBox_AllDocItem: 'TimeCalculationBox_AllDocItem',
    TimeCalculationBox_Project: 'TimeCalculationBox_Project',
    TimeCalculationEstimatedRow_Item: 'TimeCalculationEstimatedRow_Item',
    TimeCalculationActualRow_Item: 'TimeCalculationActualRow_Item',
    TimeCalculationActualRow_AllDocItem: 'TimeCalculationActualRow_AllDocItem',
    TimeCalculationWorkSessions_Item: 'TimeCalculationWorkSessions_Item',
    TimeCalculationWorkSessions_AllDocItem: 'TimeCalculationWorkSessions_AllDocItem',
    ChildVisibilityIconButton_Item: 'ChildVisibilityIconButton_Item',
    PropertiesDebugModal_DocumentItem: 'PropertiesDebugModal_DocumentItem',
    DecisionContingencyCheckbox_Item: 'DecisionContingencyCheckbox_Item',
    DecisionContingencySwitch_Item: 'DecisionContingencySwitch_Item',
    DecisionOptionButton_DocumentItem: 'DecisionOptionButton_DocumentItem',
    ShouldAskQuestions_Item: 'ShouldAskQuestions_Item',
    ShouldAskForContingencyOrDecision_Item: 'ShouldAskForContingencyOrDecision_Item',
    ShouldAskForContingency_Item: 'ShouldAskForContingency_Item',
    ShouldAskForDecision_Item: 'ShouldAskForDecision_Item',
    ShouldAskForProperties_Item: 'ShouldAskForProperties_Item',
    ShouldAskForProductSelection_Item: 'ShouldAskForProductSelection_Item',
    HasProductFilters_Item: 'HasProductFilters_Item',
    IsItemAdmittedToDecisionWizard_Item: 'IsItemAdmittedToDecisionWizard_Item',
    CanItemBeFinalized_Item: 'CanItemBeFinalized_Item',
    GetDefaultDecisionQuestion_Item: 'GetDefaultDecisionQuestion_Item',
    IsItemGreyedOut_Item: 'IsItemGreyedOut_Item',
    DocumentItems_Document: 'DocumentItems_Document',
    DocumentStatus_Document: 'DocumentStatus_Document',
    ProposedCalculations_Item: 'ProposedCalculations_Item',
    DecisionMutations_Item: 'DecisionMutations_Item',
    DocItemContextMenuItems_Item: 'DocItemContextMenuItems_Item',
    DecisionModal_DecisionItem: 'DecisionModal_DecisionItem',
    DecisionModal_OptionItem: 'DecisionModal_OptionItem',
    DecisionModal_GetState_Item: 'DecisionModal_GetState_Item',
    DecisionModalPropertiesStage_Item: 'DecisionModalPropertiesStage_Item',
    DecisionModalDecisionStage_Item: 'DecisionModalDecisionStage_Item',
    DecisionModalDecisionStage_OptionItem: 'DecisionModalDecisionStage_OptionItem',
    DecisionModalProductStage_Item: 'DecisionModalProductStage_Item',
    DecisionModalProductStage_ProductSearchResult: 'DecisionModalProductStage_ProductSearchResult',
    DecisionOptionModal_DecisionItem: 'DecisionOptionModal_DecisionItem',
    DecisionOptionModal_OptionItem: 'DecisionOptionModal_OptionItem',
    DecisionOptionDescription_Item: 'DecisionOptionDescription_Item',
    DecisionRestoreButton_Item: 'DecisionRestoreButton_Item',
    DecisionOptionButtons_Item: 'DecisionOptionButtons_Item',
    DecisionOptionButtons_ParentItem: 'DecisionOptionButtons_ParentItem',
    DecisionsInBulkProcess_Item: 'DecisionsInBulkProcess_Item',
    BulkFinalizeResult_Item: 'BulkFinalizeResult_Item',
    CalculationRow_DecisionsInBulkItem_Item: 'CalculationRow_DecisionsInBulkItem_Item',
    IsItemVisible_Item: 'IsItemVisible_Item',
    CanItemBeDecidedUpon_Item: 'CanItemBeDecidedUpon_Item',
    AreItemsAllowedToBulkDecision_Item: 'AreItemsAllowedToBulkDecision_Item',
    IsItemPreselected_Item: 'IsItemPreselected_Item',
    ExpressionModal_DocumentItem: 'ExpressionModal_DocumentItem',
    EssentialProps2Fields: 'EssentialProps2Fields',
    ItemAttributeExpressionFields: 'ItemAttributeExpressionFields',
    ItemAttributeExpressions: 'ItemAttributeExpressions',
    ItemProps2: 'ItemProps2',
    Prop2RelatedItemFields: 'Prop2RelatedItemFields',
    Props2All: 'Props2All',
    Props2MappingAll: 'Props2MappingAll',
    Props2NonComputedAll: 'Props2NonComputedAll',
    PreviewItem__DocumentItem: 'PreviewItem__DocumentItem',
    InvitationRow__ProjectContracteeInvitation: 'InvitationRow__ProjectContracteeInvitation',
    InvitationRow__OrganisationMembershipInvitation: 'InvitationRow__OrganisationMembershipInvitation',
    InvitationRow__ReceivedLinkingInvitation: 'InvitationRow__ReceivedLinkingInvitation',
    InvoiceCalculation: 'InvoiceCalculation',
    InvoiceAction_Invoice: 'InvoiceAction_Invoice',
    InvoiceAction_Project: 'InvoiceAction_Project',
    InvoiceDraftPreview_Project: 'InvoiceDraftPreview_Project',
    InvoiceDraftPreview_Quote: 'InvoiceDraftPreview_Quote',
    InvoiceDraftPreview_Invoice: 'InvoiceDraftPreview_Invoice',
    InvoiceExtraItemDetailsModal_Invoice: 'InvoiceExtraItemDetailsModal_Invoice',
    InvoiceExtraItemDetailsModal_Quote: 'InvoiceExtraItemDetailsModal_Quote',
    InvoiceExtraItemDetailsModal_InvoiceExtraItem: 'InvoiceExtraItemDetailsModal_InvoiceExtraItem',
    InvoiceExtraTreeItem_Invoice: 'InvoiceExtraTreeItem_Invoice',
    InvoiceExtraTreeItem_InvoiceExtraItem: 'InvoiceExtraTreeItem_InvoiceExtraItem',
    InvoiceItemCalculationBox_Item: 'InvoiceItemCalculationBox_Item',
    InvoiceItemCalculationBox_Quote: 'InvoiceItemCalculationBox_Quote',
    InvoiceItemDetailsModal_Item: 'InvoiceItemDetailsModal_Item',
    InvoiceItemDetailsModal_Quote: 'InvoiceItemDetailsModal_Quote',
    InvoiceItemDetailsModal_Project: 'InvoiceItemDetailsModal_Project',
    InvoiceListItem_OutgoingInvoice: 'InvoiceListItem_OutgoingInvoice',
    InvoiceListItem_IncomingInvoice: 'InvoiceListItem_IncomingInvoice',
    InvoicePreviewWithSelectTreeItemsForm_Invoice: 'InvoicePreviewWithSelectTreeItemsForm_Invoice',
    InvoicePreviewWithSelectTreeItemsForm_Quote: 'InvoicePreviewWithSelectTreeItemsForm_Quote',
    InvoicePreviewWithSelectTreeItemsForm_QuoteInvoiceCalculation: 'InvoicePreviewWithSelectTreeItemsForm_QuoteInvoiceCalculation',
    InvoicePreviewWithSelectTreeItemsForm_Project: 'InvoicePreviewWithSelectTreeItemsForm_Project',
    InvoiceSelectTreeItemsForm_Project: 'InvoiceSelectTreeItemsForm_Project',
    InvoiceSelectTreeItemsForm_Invoice: 'InvoiceSelectTreeItemsForm_Invoice',
    InvoiceSelectTreeItemsForm_InvoiceExtraItem: 'InvoiceSelectTreeItemsForm_InvoiceExtraItem',
    InvoiceSelectTreeItemsForm_Quote: 'InvoiceSelectTreeItemsForm_Quote',
    InvoiceSubHeader_Invoice: 'InvoiceSubHeader_Invoice',
    InvoiceSubHeader_Project: 'InvoiceSubHeader_Project',
    InvoiceDetails__Invoice: 'InvoiceDetails__Invoice',
    InvoiceItemDetails_InvoiceItem: 'InvoiceItemDetails_InvoiceItem',
    InvoiceExtraItemDetails_InvoiceExtraItem: 'InvoiceExtraItemDetails_InvoiceExtraItem',
    InvoicePaymentItemDetails_InvoicePaymentItem: 'InvoicePaymentItemDetails_InvoicePaymentItem',
    InvoiceQuote: 'InvoiceQuote',
    InvoiceSelectButton__Quote: 'InvoiceSelectButton__Quote',
    InvoiceSelectAgreedOrProposedList__QuoteItem: 'InvoiceSelectAgreedOrProposedList__QuoteItem',
    InvoiceSelectAgreedOrProposedList_Item: 'InvoiceSelectAgreedOrProposedList_Item',
    GetItemPriceData_InvoiceItem: 'GetItemPriceData_InvoiceItem',
    GetItemPriceData_InvoiceExtraItem: 'GetItemPriceData_InvoiceExtraItem',
    GetItemPriceData_InvoicePaymentItem: 'GetItemPriceData_InvoicePaymentItem',
    GetItemPriceData_QuoteItem: 'GetItemPriceData_QuoteItem',
    ShouldHidePrice_InvoiceItem: 'ShouldHidePrice_InvoiceItem',
    ShouldHidePrice_QuoteItem: 'ShouldHidePrice_QuoteItem',
    InvoiceReference: 'InvoiceReference',
    InvoiceHeader__Project: 'InvoiceHeader__Project',
    InvoiceHeader__Payee: 'InvoiceHeader__Payee',
    InvoiceHeader__Payer: 'InvoiceHeader__Payer',
    InvoiceHeader__Invoice: 'InvoiceHeader__Invoice',
    InvoiceItemDescriptionColumn_InvoiceItem: 'InvoiceItemDescriptionColumn_InvoiceItem',
    InvoiceItemDescriptionColumn_InvoiceExtraItem: 'InvoiceItemDescriptionColumn_InvoiceExtraItem',
    InvoiceItemDescriptionColumn_InvoicePaymentItem: 'InvoiceItemDescriptionColumn_InvoicePaymentItem',
    InvoiceItemDescriptionColumn_QuoteItem: 'InvoiceItemDescriptionColumn_QuoteItem',
    InvoiceItemDescriptionColumn_ItemSnapshot: 'InvoiceItemDescriptionColumn_ItemSnapshot',
    InvoiceItemMobileRow_InvoiceItem: 'InvoiceItemMobileRow_InvoiceItem',
    InvoiceItemMobileRow_InvoiceExtraItem: 'InvoiceItemMobileRow_InvoiceExtraItem',
    InvoiceItemMobileRow_InvoicePaymentItem: 'InvoiceItemMobileRow_InvoicePaymentItem',
    InvoicePriceTable_Invoice: 'InvoicePriceTable_Invoice',
    InvoicePriceTableItem__InvoiceItem: 'InvoicePriceTableItem__InvoiceItem',
    InvoicePriceTableItem__InvoiceExtraItem: 'InvoicePriceTableItem__InvoiceExtraItem',
    InvoicePriceTableItem__InvoicePaymentItem: 'InvoicePriceTableItem__InvoicePaymentItem',
    InvoiceSum__Invoice: 'InvoiceSum__Invoice',
    ManufacturerSelect__Manufacturer: 'ManufacturerSelect__Manufacturer',
    OpportunitiesKanban_OrganisationProjectPhase: 'OpportunitiesKanban_OrganisationProjectPhase',
    OpportunitiesPhaseColumnPhase_OrganisationProjectPhase: 'OpportunitiesPhaseColumnPhase_OrganisationProjectPhase',
    OpportunitiesPhaseColumnAvailablePhases_OrganisationProjectPhase: 'OpportunitiesPhaseColumnAvailablePhases_OrganisationProjectPhase',
    OpportunityCardItemMemoized_Project: 'OpportunityCardItemMemoized_Project',
    OpportunityCardItemMemoized_OrganisationProjectPhase: 'OpportunityCardItemMemoized_OrganisationProjectPhase',
    OpportunitiesList_Project: 'OpportunitiesList_Project',
    OpportunityListItem: 'OpportunityListItem',
    OrganisationAvatar: 'OrganisationAvatar',
    OrganisationMemberListItem__OrganisationMembership: 'OrganisationMemberListItem__OrganisationMembership',
    OrganisationMemberListItem__OrganisationInvitation: 'OrganisationMemberListItem__OrganisationInvitation',
    OrganisationRouter__Organisation: 'OrganisationRouter__Organisation',
    AccountingBox__OrganisationSettings: 'AccountingBox__OrganisationSettings',
    AccountingBox__OrganisationCounters: 'AccountingBox__OrganisationCounters',
    OrganisationSettingBillOfMaterialsExportSettings: 'OrganisationSettingBillOfMaterialsExportSettings',
    OrganisationBillOfMaterialsSettingsBox_Settings: 'OrganisationBillOfMaterialsSettingsBox_Settings',
    OrganisationDetailsBox_Organisation: 'OrganisationDetailsBox_Organisation',
    OrganisationDetailsBox_ViewerOrganisation: 'OrganisationDetailsBox_ViewerOrganisation',
    OrganisationEmailSettingsBox_Organisation: 'OrganisationEmailSettingsBox_Organisation',
    OrganisationEmailSettingsBox_OrganisationEmailSettings: 'OrganisationEmailSettingsBox_OrganisationEmailSettings',
    OrganisationEmailSettingsBox_OrganisationEmailTemplate: 'OrganisationEmailSettingsBox_OrganisationEmailTemplate',
    OrganisationEmailTemplates_OrganisationEmailTemplate: 'OrganisationEmailTemplates_OrganisationEmailTemplate',
    OrganisationEmbeddableWizardSnippetBox_Organisation: 'OrganisationEmbeddableWizardSnippetBox_Organisation',
    OrganisationEmbeddableWizardSnippetBox_Settings: 'OrganisationEmbeddableWizardSnippetBox_Settings',
    OrganisationIntegrationsBox_Integration: 'OrganisationIntegrationsBox_Integration',
    OrganisationIntegrationsBox_OrganisationIntegration: 'OrganisationIntegrationsBox_OrganisationIntegration',
    OrganisationInvoiceSettingsForm_Defaults: 'OrganisationInvoiceSettingsForm_Defaults',
    OrganisationMembersBox: 'OrganisationMembersBox',
    OrganisationMemberRow: 'OrganisationMemberRow',
    OrganisationMemberInvitationRow: 'OrganisationMemberInvitationRow',
    OrganisationPaymentInformationBox_Organisation: 'OrganisationPaymentInformationBox_Organisation',
    OrganisationPaymentInformationBox_ViewerOrganisation: 'OrganisationPaymentInformationBox_ViewerOrganisation',
    OrganisationPdfSettingsBox_PdfLetterhead: 'OrganisationPdfSettingsBox_PdfLetterhead',
    OrganisationPriceAndCalculationSettingsForm_Defaults: 'OrganisationPriceAndCalculationSettingsForm_Defaults',
    OrganisationProjectDefaultSettingsBox_Settings: 'OrganisationProjectDefaultSettingsBox_Settings',
    OrganisationProjectSettingsBox_Settings: 'OrganisationProjectSettingsBox_Settings',
    OrganisationPublicAboutBox_OrganisationProfile: 'OrganisationPublicAboutBox_OrganisationProfile',
    OrganisationPublicContactBox_OrganisationProfile: 'OrganisationPublicContactBox_OrganisationProfile',
    OrganisationPublicServicesBox_OrganisationProfile: 'OrganisationPublicServicesBox_OrganisationProfile',
    OrganisationQuotePdfSettingsBox_Settings: 'OrganisationQuotePdfSettingsBox_Settings',
    OrganisationQuoteHeaderVisibility: 'OrganisationQuoteHeaderVisibility',
    OrganisationQuoteProductsVisibility: 'OrganisationQuoteProductsVisibility',
    OrganisationQuoteSettingsForm_Defaults: 'OrganisationQuoteSettingsForm_Defaults',
    OrganisationRegistrationDetailsBox_Organisation: 'OrganisationRegistrationDetailsBox_Organisation',
    OrganisationRegistrationDetailsBox_ViewerOrganisation: 'OrganisationRegistrationDetailsBox_ViewerOrganisation',
    OrganisationScheduleSettingsBox_ViewerOrganisation: 'OrganisationScheduleSettingsBox_ViewerOrganisation',
    OrganisationScheduleSettingsBox_Defaults: 'OrganisationScheduleSettingsBox_Defaults',
    OrganisationTaskSettingsBox_Defaults: 'OrganisationTaskSettingsBox_Defaults',
    ModifyOrganisationProfileAboutButton_OrganisationProfile: 'ModifyOrganisationProfileAboutButton_OrganisationProfile',
    EditOrganisationProfileAboutModal_OrganisationProfile: 'EditOrganisationProfileAboutModal_OrganisationProfile',
    EditOrganisationProfileContactModal_OrganisationProfile: 'EditOrganisationProfileContactModal_OrganisationProfile',
    OrganisationEmailTemplateModal_OrganisationEmailTemplate: 'OrganisationEmailTemplateModal_OrganisationEmailTemplate',
    OrganisationContractingPdf: 'OrganisationContractingPdf',
    DatanormOnlineEntry: 'DatanormOnlineEntry',
    PimImportationRow: 'PimImportationRow',
    EditProjectPlanSessionsModal_Project: 'EditProjectPlanSessionsModal_Project',
    PlanningResources: 'PlanningResources',
    PimPricing: 'PimPricing',
    PimPropertyType: 'PimPropertyType',
    PimPropertyTypeValue: 'PimPropertyTypeValue',
    PimProductTypePropertyType: 'PimProductTypePropertyType',
    PimProductProperty: 'PimProductProperty',
    PimProductAlternativePropertyValue: 'PimProductAlternativePropertyValue',
    ProductOverview__Product: 'ProductOverview__Product',
    PimProductBranding: 'PimProductBranding',
    ProductOverview__PimProduct: 'ProductOverview__PimProduct',
    ProductOverview__PimSupplierProduct: 'ProductOverview__PimSupplierProduct',
    PimProductCategory: 'PimProductCategory',
    ProductSearchItem__ProductSearchResult: 'ProductSearchItem__ProductSearchResult',
    ProductSearchItem__PimProductSearchResult: 'ProductSearchItem__PimProductSearchResult',
    ProductSearchItem__PimSupplierProductSearchResult: 'ProductSearchItem__PimSupplierProductSearchResult',
    ManufacturerProductSearchItem__ManufacturerProduct: 'ManufacturerProductSearchItem__ManufacturerProduct',
    ProductTypeSelect_ProductType: 'ProductTypeSelect_ProductType',
    SupplierCatalogueAccess__OrganisationWriteAccess: 'SupplierCatalogueAccess__OrganisationWriteAccess',
    SupplierProductAlternativePicker__PimSupplierProductAlternativesAlternativeValuesProduct: 'SupplierProductAlternativePicker__PimSupplierProductAlternativesAlternativeValuesProduct',
    SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternative: 'SupplierProductAlternativePicker__PimSupplierProductAlternativesPropertyAlternative',
    SupplierProductAlternativePicker__PimSupplierProductAlternativesProduct: 'SupplierProductAlternativePicker__PimSupplierProductAlternativesProduct',
    ProductEditHeaderBox__PimBrand: 'ProductEditHeaderBox__PimBrand',
    ProductEditHeaderBox__PimBrandLine: 'ProductEditHeaderBox__PimBrandLine',
    ProductEditHeaderBox__PimProductType: 'ProductEditHeaderBox__PimProductType',
    ProductOverviewHeaderBox__PimProductMetaInfo: 'ProductOverviewHeaderBox__PimProductMetaInfo',
    ProductOverviewProductSetInfoBox_PimSupplierProductSetInfo: 'ProductOverviewProductSetInfoBox_PimSupplierProductSetInfo',
    GetProductFullDescription_Product: 'GetProductFullDescription_Product',
    GetProductFullDescription_ProductSearchResult: 'GetProductFullDescription_ProductSearchResult',
    PickBrandLineModalSearchBrandLines_BrandLine: 'PickBrandLineModalSearchBrandLines_BrandLine',
    PickBrandModalSearchBrands_Brand: 'PickBrandModalSearchBrands_Brand',
    PickProductTypeModalSearchProductTypes_ProductType: 'PickProductTypeModalSearchProductTypes_ProductType',
    ProductTypePopularPropertyFiltersEditModal_PimProductType: 'ProductTypePopularPropertyFiltersEditModal_PimProductType',
    ProductTypePopularPropertyFiltersEditModal_PimPropertyType: 'ProductTypePopularPropertyFiltersEditModal_PimPropertyType',
    SupplierCatalogueProductCreateModal__PimSupplierCatalogue: 'SupplierCatalogueProductCreateModal__PimSupplierCatalogue',
    SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariant: 'SupplierDiscountGroupVariantCreateModal_SupplierDiscountGroupVariant',
    ItemProductSearchFilterPropertyFilterComputed: 'ItemProductSearchFilterPropertyFilterComputed',
    ItemProductSearchFilterPriceFilterComputed: 'ItemProductSearchFilterPriceFilterComputed',
    ItemProductSearchFilterBrandFilterComputed: 'ItemProductSearchFilterBrandFilterComputed',
    ItemProductSearchFilterBrandLineFilterComputed: 'ItemProductSearchFilterBrandLineFilterComputed',
    ItemProductSearchFilterPropertyFilter: 'ItemProductSearchFilterPropertyFilter',
    ItemProductSearchFilterPriceFilter: 'ItemProductSearchFilterPriceFilter',
    ItemProductSearchFilterIdFilter: 'ItemProductSearchFilterIdFilter',
    ItemProductSearchIdFilter: 'ItemProductSearchIdFilter',
    ItemProductSearchFilterBrandFilter: 'ItemProductSearchFilterBrandFilter',
    ItemProductSearchFilterBrandLineFilter: 'ItemProductSearchFilterBrandLineFilter',
    ItemProductSearchFilterExpressions: 'ItemProductSearchFilterExpressions',
    ItemProductSearchFilterDefinitions: 'ItemProductSearchFilterDefinitions',
    ItemProductSearchFilterDefaults: 'ItemProductSearchFilterDefaults',
    ProjectMemberRow__ProjectMembership: 'ProjectMemberRow__ProjectMembership',
    ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhase: 'ProjectPhaseAndStateChangeMenuButton_OrganisationProjectPhase',
    ProjectPhaseChip_Project: 'ProjectPhaseChip_Project',
    ProjectPhaseChip_OrganisationSettings: 'ProjectPhaseChip_OrganisationSettings',
    ProjectPhasesConfigurator_OrganisationProjectPhase: 'ProjectPhasesConfigurator_OrganisationProjectPhase',
    WithProjectInfo: 'WithProjectInfo',
    ProjectOverview__Project: 'ProjectOverview__Project',
    ProjectPhaseColumnPhase_OrganisationProjectPhase: 'ProjectPhaseColumnPhase_OrganisationProjectPhase',
    ProjectPhaseColumnAvailablePhases_OrganisationProjectPhase: 'ProjectPhaseColumnAvailablePhases_OrganisationProjectPhase',
    ProjectCardItemMemoized_Project: 'ProjectCardItemMemoized_Project',
    ProjectCardItemMemoized_OrganisationProjectPhase: 'ProjectCardItemMemoized_OrganisationProjectPhase',
    ProjectsList_Project: 'ProjectsList_Project',
    ProjectOpportunityAlert: 'ProjectOpportunityAlert',
    ProjectOverdueAlert: 'ProjectOverdueAlert',
    ProjectTicketUnplannedAlert: 'ProjectTicketUnplannedAlert',
    ProjectOverviewActionListBox_Project: 'ProjectOverviewActionListBox_Project',
    ProjectOverviewBiddingInformationBox_Project: 'ProjectOverviewBiddingInformationBox_Project',
    ProjectOverviewBuildingBox_Project: 'ProjectOverviewBuildingBox_Project',
    ProjectCommentsBox_Project: 'ProjectCommentsBox_Project',
    ProjectContactLinks: 'ProjectContactLinks',
    QuoteItemContactLinks: 'QuoteItemContactLinks',
    ProjectOverviewCustomFieldsBox_Project: 'ProjectOverviewCustomFieldsBox_Project',
    ProjectOverviewDocumentsBox_Project: 'ProjectOverviewDocumentsBox_Project',
    ProjectOverview_OrderNode: 'ProjectOverview_OrderNode',
    ProjectOverview_OutgoingInvoice: 'ProjectOverview_OutgoingInvoice',
    ProjectOverview_IncomingInvoice: 'ProjectOverview_IncomingInvoice',
    ProjectOverview_OutgoingQuote: 'ProjectOverview_OutgoingQuote',
    ProjectOverview_IncomingQuote: 'ProjectOverview_IncomingQuote',
    ProjectOverviewFilesBox_Project: 'ProjectOverviewFilesBox_Project',
    ProjectOverviewHeaderBox_Project: 'ProjectOverviewHeaderBox_Project',
    ProjectOverviewForm_Project: 'ProjectOverviewForm_Project',
    ProjectOverviewData_Project: 'ProjectOverviewData_Project',
    ProjectOverviewSource_ProjectSource: 'ProjectOverviewSource_ProjectSource',
    ProjectOverviewNextTasksBox_Project: 'ProjectOverviewNextTasksBox_Project',
    ProjectOverviewOutgoingRequestsBox_Project: 'ProjectOverviewOutgoingRequestsBox_Project',
    ProjectOverviewParticipantsBox_Project: 'ProjectOverviewParticipantsBox_Project',
    RowMember_ProjectMembership: 'RowMember_ProjectMembership',
    RowContractee_Project: 'RowContractee_Project',
    RowContractee_CrmPerson: 'RowContractee_CrmPerson',
    RowContractee_ProjectContracteeInvitation: 'RowContractee_ProjectContracteeInvitation',
    ProjectOverviewPlanningBox_OrganisationDefaults: 'ProjectOverviewPlanningBox_OrganisationDefaults',
    ProjectOverviewPlanningBox_Project: 'ProjectOverviewPlanningBox_Project',
    ProjectOverviewWorkSessionsBox_Project: 'ProjectOverviewWorkSessionsBox_Project',
    WorkSession__Entity: 'WorkSession__Entity',
    ProjectAddEntityButton_Project: 'ProjectAddEntityButton_Project',
    ProjectListItem: 'ProjectListItem',
    ProjectSourceCreatedBy_ProjectSource: 'ProjectSourceCreatedBy_ProjectSource',
    ProjectSourceEntity_ProjectSource: 'ProjectSourceEntity_ProjectSource',
    AddProjectMemberModal_Project: 'AddProjectMemberModal_Project',
    QuoteTreeItem_TreeItem: 'QuoteTreeItem_TreeItem',
    QuoteTreeItem_TreeClientItem: 'QuoteTreeItem_TreeClientItem',
    QuoteTreeItem_TreeTableItem: 'QuoteTreeItem_TreeTableItem',
    QuoteTreeItem_Data123: 'QuoteTreeItem_Data123',
    RequirementTreeItem_TreeItem: 'RequirementTreeItem_TreeItem',
    RequirementTreeItem_Data123: 'RequirementTreeItem_Data123',
    AllProjectContacts_CrmCompany: 'AllProjectContacts_CrmCompany',
    AllProjectContacts_CrmPerson: 'AllProjectContacts_CrmPerson',
    InviteContractee_Project: 'InviteContractee_Project',
    PurchaseOrderListItem_OrderNode: 'PurchaseOrderListItem_OrderNode',
    PurchaseOrderListItem: 'PurchaseOrderListItem',
    PurchaseOrderStatusBadge_Order: 'PurchaseOrderStatusBadge_Order',
    PurchaseOrderStatusBadge_OrderNode: 'PurchaseOrderStatusBadge_OrderNode',
    PurchaseOrderTitle_Order: 'PurchaseOrderTitle_Order',
    PurchaseOrderTitle_OrderNode: 'PurchaseOrderTitle_OrderNode',
    RelatedOrderItem: 'RelatedOrderItem',
    PurchaseOrderHeader__Project: 'PurchaseOrderHeader__Project',
    PurchaseOrderHeader__Supplier: 'PurchaseOrderHeader__Supplier',
    PurchaseOrderHeader__Order: 'PurchaseOrderHeader__Order',
    QuoteAction_Quote: 'QuoteAction_Quote',
    QuoteAction_Project: 'QuoteAction_Project',
    QuoteItem_Project: 'QuoteItem_Project',
    QuoteItem_Quote: 'QuoteItem_Quote',
    QuoteItem_Item: 'QuoteItem_Item',
    QuoteItem_ChildItem: 'QuoteItem_ChildItem',
    QuoteItem_ChildItemComponent: 'QuoteItem_ChildItemComponent',
    QuoteItemActions_QuoteItem: 'QuoteItemActions_QuoteItem',
    QuoteItemActions_Quote: 'QuoteItemActions_Quote',
    QuoteItemActions_Project: 'QuoteItemActions_Project',
    QuoteItemMenuItems_Item: 'QuoteItemMenuItems_Item',
    QuoteListItem_IncomingQuote: 'QuoteListItem_IncomingQuote',
    QuoteListItem_OutgoingQuote: 'QuoteListItem_OutgoingQuote',
    QuoteMenu_Project: 'QuoteMenu_Project',
    QuoteMenu_Quote: 'QuoteMenu_Quote',
    QuotePreview_Quote: 'QuotePreview_Quote',
    QuotePreview_Item: 'QuotePreview_Item',
    QuotePreviewListItem: 'QuotePreviewListItem',
    QuotePriceSummaryBox_Project: 'QuotePriceSummaryBox_Project',
    QuoteStatus_Quote: 'QuoteStatus_Quote',
    QuoteStatusChip_Quote: 'QuoteStatusChip_Quote',
    QuoteSubHeader_Quote: 'QuoteSubHeader_Quote',
    QuoteSubHeader_Project: 'QuoteSubHeader_Project',
    QuoteHeaderVisibility: 'QuoteHeaderVisibility',
    QuoteProductsVisibility: 'QuoteProductsVisibility',
    Document_Calculations: 'Document_Calculations',
    DocumentRow__Document: 'DocumentRow__Document',
    DocumentWithItems__Document: 'DocumentWithItems__Document',
    QuoteRequestOrganisation__Organisation: 'QuoteRequestOrganisation__Organisation',
    DownloadQuoteAsPdfButton_Quote: 'DownloadQuoteAsPdfButton_Quote',
    PublishQuoteButton_Quote: 'PublishQuoteButton_Quote',
    PublishQuoteButton_Project: 'PublishQuoteButton_Project',
    ToggleQuoteBindingMenuItem_Quote: 'ToggleQuoteBindingMenuItem_Quote',
    InviteContracteeField_Project: 'InviteContracteeField_Project',
    AllowHaveChildren_Item: 'AllowHaveChildren_Item',
    PdfTemplateSelectModal_OrganisationContractingPdf: 'PdfTemplateSelectModal_OrganisationContractingPdf',
    PublishQuoteAndSendViaEmailModal_Quote: 'PublishQuoteAndSendViaEmailModal_Quote',
    PublishQuoteAndSendViaEmailModal_Project: 'PublishQuoteAndSendViaEmailModal_Project',
    QuoteBulkUpdateProductPricingsModal__CrmCompany: 'QuoteBulkUpdateProductPricingsModal__CrmCompany',
    QuoteBulkUpdateProductPricingsModal__SupplierDiscountGroupVariant: 'QuoteBulkUpdateProductPricingsModal__SupplierDiscountGroupVariant',
    QuoteDocumentActors__Actor: 'QuoteDocumentActors__Actor',
    SendQuoteViaEmailButton_Quote: 'SendQuoteViaEmailButton_Quote',
    SendQuoteViaEmailButton_Project: 'SendQuoteViaEmailButton_Project',
    SendQuoteViaEmailModal_Quote: 'SendQuoteViaEmailModal_Quote',
    SendQuoteViaEmailModal_Project: 'SendQuoteViaEmailModal_Project',
    QuotePreviewHeader__Quote: 'QuotePreviewHeader__Quote',
    QuotePreviewHeader__Project: 'QuotePreviewHeader__Project',
    QuotePreviewItemDescription_Item: 'QuotePreviewItemDescription_Item',
    IncomingRequestListItem: 'IncomingRequestListItem',
    OutgoingRequestListItem: 'OutgoingRequestListItem',
    RequestRequestorMenu: 'RequestRequestorMenu',
    RequestStatusBadge: 'RequestStatusBadge',
    RequestAttachmentsBox: 'RequestAttachmentsBox',
    RequestBiddersBox: 'RequestBiddersBox',
    RequestRecipient: 'RequestRecipient',
    RequestBidderActionMenu: 'RequestBidderActionMenu',
    RequestBiddingDetailsBox: 'RequestBiddingDetailsBox',
    RequestContactBox: 'RequestContactBox',
    RequestHeaderBox: 'RequestHeaderBox',
    RequestOverviewAttachmentsBox: 'RequestOverviewAttachmentsBox',
    RequestOverviewHeaderBox: 'RequestOverviewHeaderBox',
    RequestRequirementBox: 'RequestRequirementBox',
    RequestPublishButton: 'RequestPublishButton',
    RequestViewButton: 'RequestViewButton',
    RequestAdditionalInfoModal: 'RequestAdditionalInfoModal',
    RequestGetQuoteModal_Quote: 'RequestGetQuoteModal_Quote',
    RequestOverviewQuoteRequest: 'RequestOverviewQuoteRequest',
    RequestProjectInfoModal: 'RequestProjectInfoModal',
    M1Inputs: 'M1Inputs',
    RequirementAction_Requirement: 'RequirementAction_Requirement',
    RequirementAction_Project: 'RequirementAction_Project',
    RequirementDocumentActors__Actor: 'RequirementDocumentActors__Actor',
    RequirementItem_Item: 'RequirementItem_Item',
    RequirementItem_ChildItem: 'RequirementItem_ChildItem',
    RequirementItem_Requirement: 'RequirementItem_Requirement',
    RequirementItem_Project: 'RequirementItem_Project',
    RequirementItem_ChildItemComponent: 'RequirementItem_ChildItemComponent',
    RequirementListItem: 'RequirementListItem',
    RequirementMenu_Requirement: 'RequirementMenu_Requirement',
    RequirementMenu_Project: 'RequirementMenu_Project',
    RequirementPreview_Requirement: 'RequirementPreview_Requirement',
    RequirementPreview_Item: 'RequirementPreview_Item',
    RequirementSendViaEmailModal_Requirement: 'RequirementSendViaEmailModal_Requirement',
    RequirementSendViaEmailModal_Project: 'RequirementSendViaEmailModal_Project',
    RequirementSubHeader_Requirement: 'RequirementSubHeader_Requirement',
    RequirementSubHeader_Project: 'RequirementSubHeader_Project',
    RequirementHeader__Requirement: 'RequirementHeader__Requirement',
    RequirementPreviewItemDescription_Item: 'RequirementPreviewItemDescription_Item',
    S4HanaQuoteDataBox_QuoteSapS4HanaData: 'S4HanaQuoteDataBox_QuoteSapS4HanaData',
    S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartner: 'S4HanaQuoteDataBox_QuoteSapS4HanaBusinessPartner',
    ItemSapS4HanaPricingData: 'ItemSapS4HanaPricingData',
    ItemSapS4HanaData: 'ItemSapS4HanaData',
    S4HanaQuote_QuoteSapS4HanaData: 'S4HanaQuote_QuoteSapS4HanaData',
    S4HanaQuote_Quote: 'S4HanaQuote_Quote',
    S4HanaQuote_Item: 'S4HanaQuote_Item',
    S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntry: 'S4HanaSalesQuotationImportModal_SapS4HanaSalesQuotationSelectEntry',
    MapProject: 'MapProject',
    ScheduleWho: 'ScheduleWho',
    ScheduleProject: 'ScheduleProject',
    SchedulePlanSession: 'SchedulePlanSession',
    AgendaPlanSession: 'AgendaPlanSession',
    ShopCartQuote: 'ShopCartQuote',
    ShopCartRequirement: 'ShopCartRequirement',
    ShopCart: 'ShopCart',
    CartQuote: 'CartQuote',
    CartRequirement: 'CartRequirement',
    Cart: 'Cart',
    CartDocument: 'CartDocument',
    ShoppableTemplatesQuoteHeaderBox__ShoppableTemplate: 'ShoppableTemplatesQuoteHeaderBox__ShoppableTemplate',
    ShopItemsList_ShoppableTemplate: 'ShopItemsList_ShoppableTemplate',
    ShopProject: 'ShopProject',
    SupplierCatalogueSelect__PimSupplierCatalogue: 'SupplierCatalogueSelect__PimSupplierCatalogue',
    SearchSuppliers__Supplier: 'SearchSuppliers__Supplier',
    AddPhotoApprovalIconButton__TaskDocumentItem: 'AddPhotoApprovalIconButton__TaskDocumentItem',
    PhotoApprovalProcess: 'PhotoApprovalProcess',
    TaskItem_Project: 'TaskItem_Project',
    TaskItem_Document: 'TaskItem_Document',
    TaskItem_Item: 'TaskItem_Item',
    TaskItem_ChildItem: 'TaskItem_ChildItem',
    TaskItem_ChildItemComponent: 'TaskItem_ChildItemComponent',
    TaskManagementButtons_AllDocItem: 'TaskManagementButtons_AllDocItem',
    AssigneeOptions_Project: 'AssigneeOptions_Project',
    ToggleApprovalStatusButton__TaskDocumentItem: 'ToggleApprovalStatusButton__TaskDocumentItem',
    ToggleQaApprovalStatusButton__TaskDocumentItem: 'ToggleQaApprovalStatusButton__TaskDocumentItem',
    ToggleTaskCompletionIconButton__TaskDocumentItem: 'ToggleTaskCompletionIconButton__TaskDocumentItem',
    TasksTimesheetBox_Item: 'TasksTimesheetBox_Item',
    TasksTimesheetBreakForm_Item: 'TasksTimesheetBreakForm_Item',
    TasksTimesheetForm_Item: 'TasksTimesheetForm_Item',
    TasksTimesheetTasksForm_Item: 'TasksTimesheetTasksForm_Item',
    TaskShouldRender_Item: 'TaskShouldRender_Item',
    PhotoApprovalModal: 'PhotoApprovalModal',
    PhotoApprovalMultipleModal: 'PhotoApprovalMultipleModal',
    TaskTreeItem_TreeItem: 'TaskTreeItem_TreeItem',
    TaskTreeItem_TreeRootItem: 'TaskTreeItem_TreeRootItem',
    TasksTree_Document: 'TasksTree_Document',
    TaskTreeItem_TreeSimpleItem: 'TaskTreeItem_TreeSimpleItem',
    TaskTreeItem_TreeListItem: 'TaskTreeItem_TreeListItem',
    TaskTreeItem_EstimatedDuration: 'TaskTreeItem_EstimatedDuration',
    TaskTreeItem_Data123: 'TaskTreeItem_Data123',
    TaskTreeItem_ItemRightIcons: 'TaskTreeItem_ItemRightIcons',
    TaskTreeItem_ItemLeftButton: 'TaskTreeItem_ItemLeftButton',
    TaskTreeItem_ItemRightButton: 'TaskTreeItem_ItemRightButton',
    TemplateTypeOverviewHeaderBox_TemplateType: 'TemplateTypeOverviewHeaderBox_TemplateType',
    TemplateTypeOverviewPropertyBox_TemplateType: 'TemplateTypeOverviewPropertyBox_TemplateType',
    TemplateTypesSearchModal_TemplateType: 'TemplateTypesSearchModal_TemplateType',
    EditRecommendedTemplateModal_ItemRecommendation: 'EditRecommendedTemplateModal_ItemRecommendation',
    EditRecommendedTemplateModal_ItemRecommendationConfig: 'EditRecommendedTemplateModal_ItemRecommendationConfig',
    EditRecommendedTemplateModal_RecommendedTemplateConfig: 'EditRecommendedTemplateModal_RecommendedTemplateConfig',
    EditRecommendedTemplateTypeModal_ItemRecommendation: 'EditRecommendedTemplateTypeModal_ItemRecommendation',
    EditRecommendedTemplateTypeModal_ItemRecommendationConfig: 'EditRecommendedTemplateTypeModal_ItemRecommendationConfig',
    EditRecommendedTemplateTypeModal_RecommendedTemplateTypeConfig: 'EditRecommendedTemplateTypeModal_RecommendedTemplateTypeConfig',
    TemplateLinkIconButton_Item: 'TemplateLinkIconButton_Item',
    PropertyMappingSection_Properties: 'PropertyMappingSection_Properties',
    PropertyMappingSection_Mappings: 'PropertyMappingSection_Mappings',
    TemplateQuoteAddedToShopStatus_QuoteTemplate: 'TemplateQuoteAddedToShopStatus_QuoteTemplate',
    TemplateQuoteItem_Item: 'TemplateQuoteItem_Item',
    TemplateQuoteItem_ChildItem: 'TemplateQuoteItem_ChildItem',
    TemplateQuoteItem_Document: 'TemplateQuoteItem_Document',
    TemplateQuoteItem_ChildItemComponent: 'TemplateQuoteItem_ChildItemComponent',
    TemplateQuoteLinkedTemplateItemHeaderBox_Item: 'TemplateQuoteLinkedTemplateItemHeaderBox_Item',
    TemplateQuotePreviewItemDescription_Item: 'TemplateQuotePreviewItemDescription_Item',
    TemplateQuotePreviewItems_Item: 'TemplateQuotePreviewItems_Item',
    TemplateQuotePublishingStatus_QuoteTemplate: 'TemplateQuotePublishingStatus_QuoteTemplate',
    TemplatesQuoteStatus_Template: 'TemplatesQuoteStatus_Template',
    TemplateQuoteStatuses_QuoteTemplate: 'TemplateQuoteStatuses_QuoteTemplate',
    TemplateQuoteSubscriptionStatus_QuoteTemplate: 'TemplateQuoteSubscriptionStatus_QuoteTemplate',
    TemplateQuoteWarnings_QuoteTemplate: 'TemplateQuoteWarnings_QuoteTemplate',
    TemplatesQuoteSelectMultipleComponent_Template: 'TemplatesQuoteSelectMultipleComponent_Template',
    TemplatesQuoteSetsSearchModal_Template: 'TemplatesQuoteSetsSearchModal_Template',
    TemplateQuoteAction_QuoteTemplate: 'TemplateQuoteAction_QuoteTemplate',
    TemplateQuoteTemplateMenu_QuoteTemplate: 'TemplateQuoteTemplateMenu_QuoteTemplate',
    TemplatesQuoteTemplateSettingsModal_QuoteTemplate: 'TemplatesQuoteTemplateSettingsModal_QuoteTemplate',
    TemplatesQuoteTemplateSettingsModal_LinkingTemplates: 'TemplatesQuoteTemplateSettingsModal_LinkingTemplates',
    TemplatesQuoteTemplateSubHeader_QuoteTemplate: 'TemplatesQuoteTemplateSubHeader_QuoteTemplate',
    TemplatesQuoteTemplateSubHeader_QuoteTemplateItem: 'TemplatesQuoteTemplateSubHeader_QuoteTemplateItem',
    QuoteTemplateAddToShopButton_QuoteTemplate: 'QuoteTemplateAddToShopButton_QuoteTemplate',
    QuoteTemplateShareButton_QuoteTemplate: 'QuoteTemplateShareButton_QuoteTemplate',
    QuoteTemplateSubscribeButton_QuoteTemplate: 'QuoteTemplateSubscribeButton_QuoteTemplate',
    QuoteTemplateUnsubscribeButton_QuoteTemplate: 'QuoteTemplateUnsubscribeButton_QuoteTemplate',
    QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplate: 'QuoteTemplateUpdateSubscribedVersionButton_QuoteTemplate',
    TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic: 'TemplateLinkUpdateModal_CompileDocIsolatedExpressionResultDiagnostic',
    ToggleTemplatesQuoteBindingButton_QuoteTemplate: 'ToggleTemplatesQuoteBindingButton_QuoteTemplate',
    TemplateTreeItem_TreeItem: 'TemplateTreeItem_TreeItem',
    TemplateTreeItem_UnnestedTreeItem: 'TemplateTreeItem_UnnestedTreeItem',
    QuoteTemplateTreeItem_PublicFlowTreeItem: 'QuoteTemplateTreeItem_PublicFlowTreeItem',
    QuoteTemplateTreeItem_Data123: 'QuoteTemplateTreeItem_Data123',
    ItemTemplateSearchFilterPropertyFilterComputed: 'ItemTemplateSearchFilterPropertyFilterComputed',
    ItemTemplateSearchFilterPropertyFilter: 'ItemTemplateSearchFilterPropertyFilter',
    ThreeD__3d_Item: 'ThreeD__3d_Item',
    ThreeDShapeDetails: 'ThreeDShapeDetails',
    TimeReportHeader__Project: 'TimeReportHeader__Project',
    RelatedToBox_Project: 'RelatedToBox_Project',
    RelatedToBox_Company: 'RelatedToBox_Company',
    RelatedToBox_CrmPerson: 'RelatedToBox_CrmPerson',
    TodoListItem: 'TodoListItem',
    TodosBox_LinkedCrmOrganisation: 'TodosBox_LinkedCrmOrganisation',
    TodosBox_LinkedCrmUser: 'TodosBox_LinkedCrmUser',
    TodosBox_LinkedProject: 'TodosBox_LinkedProject',
    UserAvatar: 'UserAvatar',
    UserInformationBox_User: 'UserInformationBox_User',
    UserInformationBox_Viewer: 'UserInformationBox_Viewer',
    UserRolesBox_User: 'UserRolesBox_User',
    UserRolesBox_Viewer: 'UserRolesBox_Viewer',
    UserNotificationSettingsBox_User: 'UserNotificationSettingsBox_User',
    VanMarckeProfitability_Item: 'VanMarckeProfitability_Item',
    OrganisationVerticalSetting: 'OrganisationVerticalSetting',
    QuoteVerticalSetting: 'QuoteVerticalSetting',
    AddTaskWorkSessionsProcess_Item: 'AddTaskWorkSessionsProcess_Item',
    WorkSession__ItemEntity: 'WorkSession__ItemEntity',
    CorrectWorkSessionButton_AllDocItem: 'CorrectWorkSessionButton_AllDocItem',
    AddTaskWorkSessionModal_Item: 'AddTaskWorkSessionModal_Item',
    AddTaskWorkSessionsModal_Item: 'AddTaskWorkSessionsModal_Item',
    CorrectTaskWorkSessionModal_AllDocItem: 'CorrectTaskWorkSessionModal_AllDocItem',
    InvitationContact: 'InvitationContact',
    BuildingEdit_Item: 'BuildingEdit_Item',
    Building: 'Building',
    CrmCompanies__CrmCompany: 'CrmCompanies__CrmCompany',
    CrmUserRow: 'CrmUserRow',
    DashboardPersonalSchedule_PlanSession: 'DashboardPersonalSchedule_PlanSession',
    DocumentsOutgoingInvoices_Invoice: 'DocumentsOutgoingInvoices_Invoice',
    DocumentsIncomingInvoices_Invoice: 'DocumentsIncomingInvoices_Invoice',
    DocumentsPurchaseOrders_OrderNode: 'DocumentsPurchaseOrders_OrderNode',
    IncomingQuote: 'IncomingQuote',
    OutgoingQuote: 'OutgoingQuote',
    BrandingTheme_OrganisationSettings: 'BrandingTheme_OrganisationSettings',
    BrandingLogo_OrganisationSettings: 'BrandingLogo_OrganisationSettings',
    BrandingAd_OrganisationSettings: 'BrandingAd_OrganisationSettings',
    BrandingTheme: 'BrandingTheme',
    BrandingSlot: 'BrandingSlot',
    ArticleListsList_QuoteProductsDistribution: 'ArticleListsList_QuoteProductsDistribution',
    RequestRow: 'RequestRow',
    PlanningAbsence: 'PlanningAbsence',
    useSelectedDate_Project: 'useSelectedDate_Project',
    PimProductMetaInfo: 'PimProductMetaInfo',
    PimSearchGroupedProductsNode: 'PimSearchGroupedProductsNode',
    ProjectChatProject: 'ProjectChatProject',
    ProjectEmailsSidebar_CrmMailMessage: 'ProjectEmailsSidebar_CrmMailMessage',
    ProjectOutgoingInvoices_Invoice: 'ProjectOutgoingInvoices_Invoice',
    ProjectIncomingInvoices_Invoice: 'ProjectIncomingInvoices_Invoice',
    PurchaseOrderEdit_OrderItem: 'PurchaseOrderEdit_OrderItem',
    PurchaseOrder__Contractor: 'PurchaseOrder__Contractor',
    PurchaseOrder__Item: 'PurchaseOrder__Item',
    PurchaseOrders__Order: 'PurchaseOrders__Order',
    ProjectQuoteCalculation_Item: 'ProjectQuoteCalculation_Item',
    ProjectQuoteCalculation_M1: 'ProjectQuoteCalculation_M1',
    ProjectQuoteEdit_Item: 'ProjectQuoteEdit_Item',
    ProjectQuotePreviewHeader: 'ProjectQuotePreviewHeader',
    ProjectQuotePreviewHeader_Project: 'ProjectQuotePreviewHeader_Project',
    ProjectQuoteThreeD_Item: 'ProjectQuoteThreeD_Item',
    ProjectQuoteTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic: 'ProjectQuoteTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic',
    ProjectQuote_OutgoingQuote: 'ProjectQuote_OutgoingQuote',
    ProjectQuote_IncomingQuote: 'ProjectQuote_IncomingQuote',
    ProjectRequest__Request: 'ProjectRequest__Request',
    RequirementEdit_Item: 'RequirementEdit_Item',
    ProjectRequirementThreeD_Item: 'ProjectRequirementThreeD_Item',
    RequirementRow: 'RequirementRow',
    ProjectTasks_Document: 'ProjectTasks_Document',
    ProjectTasks_Item: 'ProjectTasks_Item',
    TimeReport__Contractor: 'TimeReport__Contractor',
    TimeReportItem__Entity: 'TimeReportItem__Entity',
    TimeReports__Report: 'TimeReports__Report',
    TemplatesQuotePreview_ShoppableTemplate: 'TemplatesQuotePreview_ShoppableTemplate',
    TodoItem: 'TodoItem',
    TasksListFilterOptionsAssignee: 'TasksListFilterOptionsAssignee',
    TasksListFilterOptionsCrmUser: 'TasksListFilterOptionsCrmUser',
    TasksListFilterOptionsProject: 'TasksListFilterOptionsProject',
    TemplateQuoteEdit_Item: 'TemplateQuoteEdit_Item',
    TemplateQuoteEditPreview_Item: 'TemplateQuoteEditPreview_Item',
    TemplateQuoteEditItem_Template: 'TemplateQuoteEditItem_Template',
    TemplateQuoteThreeDEdit_Item: 'TemplateQuoteThreeDEdit_Item',
    TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic: 'TemplateQuoteEditTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic',
    TemplateQuoteVersion_Template: 'TemplateQuoteVersion_Template',
    TemplateQuoteVersion_Item: 'TemplateQuoteVersion_Item',
    TemplateQuoteVersionPreview_Template: 'TemplateQuoteVersionPreview_Template',
    TemplateQuoteVersionItem_Template: 'TemplateQuoteVersionItem_Template',
    TemplateQuoteThreeDVersion_Item: 'TemplateQuoteThreeDVersion_Item',
    TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic: 'TemplateQuoteVersionTroubleshoot_CompileDocIsolatedExpressionResultDiagnostic',
    TemplateQuotes_Template: 'TemplateQuotes_Template',
    BulkActionsShare_QuoteTemplate: 'BulkActionsShare_QuoteTemplate',
    TemplateTypeOverviewSubHeader__TemplateType: 'TemplateTypeOverviewSubHeader__TemplateType',
    TemplateTypes_TemplateType: 'TemplateTypes_TemplateType',
    UnnestedTemplateQuoteEditItem_QuoteTemplateItem: 'UnnestedTemplateQuoteEditItem_QuoteTemplateItem',
    UnnestedTemplateQuoteVersionItem_QuoteTemplateItem: 'UnnestedTemplateQuoteVersionItem_QuoteTemplateItem',
    LogoImage_OrganisationProfile: 'LogoImage_OrganisationProfile',
    BackgroundImage_OrganisationProfile: 'BackgroundImage_OrganisationProfile',
    ThreeDStart__ShoppableTemplate: 'ThreeDStart__ShoppableTemplate',
    ThreeDProcess_Item: 'ThreeDProcess_Item',
    SetTreeHierarchy_DocumentItem: 'SetTreeHierarchy_DocumentItem',
    VirtualItem__DocumentItem: 'VirtualItem__DocumentItem',
    TreeItemRightIcons: 'TreeItemRightIcons'
  }
}