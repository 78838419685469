import { useApolloClient } from "@apollo/client";
import { assertNever } from "@msys/common";
import {
  CardContainer,
  CollapseSection,
  LabeledValue,
  LabeledValueWithButton,
  MenuButton,
  ModalOpenButton,
  Select,
  SelectMultiple,
} from "@msys/ui";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TemplateIcon from "@mui/icons-material/FileCopy";
import FunctionsIcon from "@mui/icons-material/Functions";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormik } from "formik";
import { differenceWith } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import {
  DefineItemTemplateSearchFilterDefinitionsInput,
  EntitySearchArrayOfFilterOperator,
  EntitySearchBoolFilterOperator,
  EntitySearchBoolInFilterOperator,
  EntitySearchNumberBetweenFilterOperator,
  EntitySearchNumberFilterOperator,
  EntitySearchNumberInFilterOperator,
  EntitySearchTextFilterOperator,
  EntitySearchTextInFilterOperator,
} from "../../../../clients/graphqlTypes";
import { ViewMode, ViewModeMenuItem } from "../../../commons/ViewModeMenuItem";
import {
  ArrayFilterOperatorOptions,
  BoolFilterOperatorOptions,
  NumberFilterOperatorOptions,
  TextFilterOperatorOptions,
} from "../../products/ProductSearchFilterOperatorOptions";
import { TemplateFilterFields_AvailableFiltersQueryVariables } from "../../templates/filters/TemplateFilterFields.generated";
import { TemplateTypeAtVersionWarningButton } from "../../templateTypes/TemplateTypeAtVersionWarningButton";
import { TemplateTypesSearchModal } from "../../templateTypes/TemplateTypesSearchModal";
import {
  TemplateFiltersFormValues,
  templateFiltersDefaultValue,
  templateFiltersValidationSchema,
} from "../../templates/filters/TemplateFilterFields";
import { TemplateTypeSearchFilterModal } from "../../templates/filters/TemplateTypeSearchFilterModal";
import {
  ItemTemplateSearchFilterPropertyFilterComputedFragment,
  ItemTemplateSearchFilterPropertyFilterFragment,
} from "../../templates/templateSearchFilters.generated";
import { ProductSearchFilterExpressionModal } from "../modals/ProductSearchFilterExpressionModal";
import { getEnhancedPropertyLabel } from "../properties";
import { adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2 } from "../templateSearchFilterExpressionUtils";
import {
  templateSearchFilterPropertyFilterExpression2Input,
  useTemplateSearchFiltersMutations,
} from "../useTemplateSearchFiltersMutations";
import {
  TemplateSearchFilterDefinitionsFragment,
  TemplateTypePlaceholderBox_ItemFragment,
  TemplateTypePlaceholderBox_QuoteFragment,
  TemplateTypePlaceholderBox_QuoteTemplateFragment,
  useSetPlaceholderForTemplateTypeMutation,
} from "./TemplateTypePlaceholderBox.generated";
import { useTemplateSearchSortingsMutations } from "../useTemplateSearchSortingsMutations";

interface Props {
  projectId: string | null;
  doc:
    | TemplateTypePlaceholderBox_QuoteTemplateFragment
    | TemplateTypePlaceholderBox_QuoteFragment;
  item: TemplateTypePlaceholderBox_ItemFragment;
  onUpdateDataRefetchQueries?: string[];
  isEditable?: boolean;
}

export const TemplateTypePlaceholderBox = ({
  projectId,
  doc,
  item,
  onUpdateDataRefetchQueries,
  isEditable = false,
}: Props) => {
  const { t } = useTranslate([
    "QuoteItem",
    "ItemPropertyField",
    "Global",
    "TemplateTypes",
  ]);

  const [viewMode, setViewMode] = React.useState<ViewMode>(null);

  const client = useApolloClient();

  const [setPlaceholderForTemplateType] =
    useSetPlaceholderForTemplateTypeMutation({ client });

  const { propertyFilters: propertySearchFilterDefinitions } =
    item.templateSearchFilterDefinitions;
  const { propertyFilters: propertySearchFiltersExpressions } =
    item.templateSearchFilterExpressions;

  const searchSortingDefinitions = item.templateSearchSortingDefinitions;

  const {
    defineItemTemplateSearchFilterDefinitions,
    handleDeleteTemplateSearchFilterPropertyDefinition,
    handleSetTemplateSearchFilterPropertyExpression,
    handleDeleteTemplateSearchFilterPropertyExpression,
    defineItemTemplateSearchFilterExpressions,
  } = useTemplateSearchFiltersMutations({
    projectId: projectId,
    docId: doc.id,
    itemId: item.id,
    propertySearchFiltersDefinitions: propertySearchFilterDefinitions,
    propertySearchFiltersExpressions,
    refetchQueries: onUpdateDataRefetchQueries,
  });

  const {
    handleDeleteTemplateSearchPropertySortingDefinition,
    handleSetTemplateSearchPropertySortingDefinition,
  } = useTemplateSearchSortingsMutations({
    projectId: projectId,
    docId: doc.id,
    itemId: item.id,
    searchSortingDefinitions: searchSortingDefinitions,
    refetchQueries: onUpdateDataRefetchQueries,
  });

  const unusedFilterProperties = differenceWith(
    item.placeholderForTemplateType?.templateType?.props2 ?? [],
    propertySearchFiltersExpressions,
    (p, f) => p.key === f.key
  );

  const unusedSortingProperties = differenceWith(
    item.placeholderForTemplateType?.templateType?.props2 ?? [],
    searchSortingDefinitions.filter(
      d => d.__typename === "EntitySearchSortingPropertyValueSorting"
    ),
    (p, f) => p.key === f.key
  ).flatMap(p => (p.__typename === "Props2Number" ? p : []));

  const atLeastOneSearchFilterExists = item.placeholderForTemplateType;

  if (
    !item.isRootItem &&
    (item.type === "section" || item.placeholderForTemplateType)
  ) {
    return (
      <CardContainer
        title={t("Template type placeholder", { ns: "QuoteItem" })}
        isExpandable
        ActionButton={
          !item.placeholderForTemplateType ? (
            <ModalOpenButton
              Modal={TemplateTypesSearchModal}
              modalProps={{
                handleTemplateTypeChoice: async templateType => {
                  setPlaceholderForTemplateType({
                    variables: {
                      input: {
                        itemId: item.id,
                        templateId: doc.id,
                        templateTypeId: templateType.id,
                      },
                    },
                  });
                },
              }}
            >
              <IconButton color="primary" size="small">
                <AddIcon />
              </IconButton>
            </ModalOpenButton>
          ) : (
            <>
              {item.placeholderForTemplateType.atRevision !==
              item.placeholderForTemplateType.templateType.revision ? (
                <TemplateTypeAtVersionWarningButton
                  templateTypeRevision={
                    item.placeholderForTemplateType.templateType.revision
                  }
                  atRevision={item.placeholderForTemplateType.atRevision}
                  onResolve={async () => {
                    if (item.placeholderForTemplateType) {
                      await setPlaceholderForTemplateType({
                        variables: {
                          input: {
                            itemId: item.id,
                            templateId: doc.id,
                            templateTypeId:
                              item.placeholderForTemplateType.templateType.id,
                          },
                        },
                      });

                      await defineItemTemplateSearchFilterExpressions({
                        variables: {
                          input: {
                            docId: doc.id,
                            projectId: projectId,
                            itemId: item.id,
                            templateSearchFilterExpressions: {
                              propertyFilterExpressions:
                                adjustTemplateSearchFilterPropertyFilterExpressionsToTemplateTypeProps2(
                                  {
                                    filters: propertySearchFiltersExpressions,
                                    templateTypeProps:
                                      item.placeholderForTemplateType
                                        .templateType.props2,
                                  }
                                ).map(
                                  templateSearchFilterPropertyFilterExpression2Input
                                ),
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              ) : undefined}
              {atLeastOneSearchFilterExists && (
                <MenuButton>
                  <ViewModeMenuItem
                    viewMode={viewMode}
                    onViewModeChange={setViewMode}
                    allowedModes={[null, "delete"]}
                  />
                </MenuButton>
              )}
            </>
          )
        }
      >
        {item.placeholderForTemplateType ? (
          <Stack>
            <ListItem
              disablePadding
              secondaryAction={
                viewMode === "delete" && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => {
                      setPlaceholderForTemplateType({
                        variables: {
                          input: {
                            itemId: item.id,
                            templateId: doc.id,
                            templateTypeId: null,
                          },
                        },
                      });
                      setViewMode(null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
            >
              <ListItemButton
                component={Link}
                to={`/templates/types/${item.placeholderForTemplateType.templateType.id}`}
                dense
              >
                <ListItemIcon
                  sx={theme => ({
                    minWidth: theme.layout.listItemMinWidth.sm,
                  })}
                >
                  <TemplateIcon />
                </ListItemIcon>
                <ListItemText>
                  {item.placeholderForTemplateType.templateType.title}
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <CollapseSection
              padding={1}
              title={t("Search filters", { ns: "TemplateTypes" })}
              ActionButtons={
                <ModalOpenButton
                  Modal={AddTemplateSearchFilterDefinitionsModal}
                  modalProps={{
                    handleComplete: async values => {
                      await defineItemTemplateSearchFilterDefinitions({
                        variables: {
                          input: {
                            projectId,
                            docId: doc.id,
                            itemId: item.id,
                            ...filterFormValuesToDefinitionInput(values),
                          },
                        },
                      });
                    },
                    templateTypeIdFilter:
                      item.placeholderForTemplateType.templateType.id,
                    templateSearchFilterDefinitions:
                      item.templateSearchFilterDefinitions,
                  }}
                >
                  <IconButton size={"small"} color={"primary"}>
                    <AddIcon />
                  </IconButton>
                </ModalOpenButton>
              }
            >
              <Grid
                container
                columns={2}
                rowSpacing={1}
                columnSpacing={2}
                padding={1}
              >
                {propertySearchFilterDefinitions.map(
                  propertySearchFilterDefinition => {
                    return (
                      <Grid
                        key={propertySearchFilterDefinition.key}
                        item
                        xs={1}
                      >
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <LabeledValue
                            label={propertySearchFilterDefinition.key}
                          >
                            {getValueWithOperator(
                              propertySearchFilterDefinition
                            )}
                          </LabeledValue>

                          {viewMode === "delete" && (
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={async () => {
                                await handleDeleteTemplateSearchFilterPropertyDefinition(
                                  propertySearchFilterDefinition.key
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </CollapseSection>
            <CollapseSection
              p={1}
              title={t("Expressions", { ns: "TemplateTypes" })}
              ActionButtons={
                <MenuButton
                  Icon={<AddIcon />}
                  buttonProps={{
                    disabled: unusedFilterProperties.length === 0,
                  }}
                >
                  {unusedFilterProperties.map(prop => {
                    switch (prop.__typename) {
                      case "Props2Bool": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<
                                | EntitySearchBoolFilterOperator
                                | EntitySearchBoolInFilterOperator
                              >
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "eq",
                              allowedSearchFilterOperators:
                                BoolFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  value.operator === "in"
                                    ? {
                                        boolInFilter: {
                                          key: prop.key,
                                          operatorBoolIn: value.operator,
                                          expr: value.expr,
                                        },
                                      }
                                    : value.operator === "eq"
                                      ? {
                                          boolFilter: {
                                            operatorBool: value.operator,
                                            expr: value.expr,
                                            key: prop.key,
                                          },
                                        }
                                      : assertNever(value.operator)
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      case "Props2Number": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<
                                | EntitySearchNumberFilterOperator
                                | EntitySearchNumberInFilterOperator
                                | EntitySearchNumberBetweenFilterOperator
                              >
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "eq",
                              allowedSearchFilterOperators:
                                NumberFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  value.operator === "in"
                                    ? {
                                        numberInFilter: {
                                          key: prop.key,
                                          operatorNumberIn: value.operator,
                                          expr: value.expr,
                                        },
                                      }
                                    : value.operator === "between"
                                      ? {
                                          numberBetweenFilter: {
                                            key: prop.key,
                                            operatorNumberBetween:
                                              value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : value.operator === "eq" ||
                                          value.operator === "lt" ||
                                          value.operator === "lte" ||
                                          value.operator === "gt" ||
                                          value.operator === "gte"
                                        ? {
                                            numberFilter: {
                                              key: prop.key,
                                              operatorNumber: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      case "Props2NumberArray": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "anyOf",
                              allowedSearchFilterOperators:
                                ArrayFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  {
                                    numberArrayOfFilter: {
                                      key: prop.key,
                                      operatorNumberArrayOf: value.operator,
                                      expr: value.expr,
                                    },
                                  }
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }

                      case "Props2Text": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<
                                | EntitySearchTextFilterOperator
                                | EntitySearchTextInFilterOperator
                              >
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "eq",
                              allowedSearchFilterOperators:
                                TextFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  value.operator === "in"
                                    ? {
                                        textInFilter: {
                                          key: prop.key,
                                          operatorTextIn: value.operator,
                                          expr: value.expr,
                                        },
                                      }
                                    : value.operator === "eq"
                                      ? {
                                          textFilter: {
                                            key: prop.key,
                                            operatorText: value.operator,
                                            expr: value.expr,
                                          },
                                        }
                                      : assertNever(value.operator)
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      case "Props2TextArray": {
                        return (
                          <ModalOpenButton
                            key={prop.key}
                            Modal={
                              ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                            }
                            modalProps={{
                              projectId: projectId,
                              docId: doc.id,
                              itemId: item.id,
                              filterKey: prop.key,
                              operator: "anyOf",
                              allowedSearchFilterOperators:
                                ArrayFilterOperatorOptions,
                              expression: "",
                              handleComplete: async (value, handleClose) => {
                                await handleSetTemplateSearchFilterPropertyExpression(
                                  prop.key,
                                  {
                                    textArrayOfFilter: {
                                      key: prop.key,
                                      operatorTextArrayOf: value.operator,
                                      expr: value.expr,
                                    },
                                  }
                                );
                                handleClose();
                              },
                            }}
                            disabled={!isEditable}
                          >
                            <MenuItem>
                              {getEnhancedPropertyLabel(prop, false)}
                            </MenuItem>
                          </ModalOpenButton>
                        );
                      }
                      default:
                        assertNever(prop);
                    }
                  })}
                </MenuButton>
              }
            >
              <Grid
                container
                columns={2}
                rowSpacing={1}
                columnSpacing={2}
                padding={1}
              >
                {propertySearchFiltersExpressions.map(
                  (propertySearchFilter, index) => {
                    switch (propertySearchFilter.__typename) {
                      case "EntitySearchPropertyFilterBoolFilterComputed":
                      case "EntitySearchPropertyFilterBoolInFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<
                                    | EntitySearchBoolFilterOperator
                                    | EntitySearchBoolInFilterOperator
                                  >
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.__typename ===
                                    "EntitySearchPropertyFilterBoolInFilterComputed"
                                      ? propertySearchFilter.operatorBoolIn
                                      : propertySearchFilter.operatorBool,
                                  allowedSearchFilterOperators:
                                    BoolFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "in"
                                        ? {
                                            boolInFilter: {
                                              key: propertySearchFilter.key,
                                              operatorBoolIn: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq"
                                          ? {
                                              boolFilter: {
                                                operatorBool: value.operator,
                                                expr: value.expr,
                                                key: propertySearchFilter.key,
                                              },
                                            }
                                          : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterNumberFilterComputed":
                      case "EntitySearchPropertyFilterNumberInFilterComputed":
                      case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<
                                    | EntitySearchNumberFilterOperator
                                    | EntitySearchNumberInFilterOperator
                                    | EntitySearchNumberBetweenFilterOperator
                                  >
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.__typename ===
                                    "EntitySearchPropertyFilterNumberFilterComputed"
                                      ? propertySearchFilter.operatorNumber
                                      : propertySearchFilter.__typename ===
                                          "EntitySearchPropertyFilterNumberInFilterComputed"
                                        ? propertySearchFilter.operatorNumberIn
                                        : propertySearchFilter.operatorNumberBetween,
                                  allowedSearchFilterOperators:
                                    NumberFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "in"
                                        ? {
                                            numberInFilter: {
                                              key: propertySearchFilter.key,
                                              operatorNumberIn: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "between"
                                          ? {
                                              numberBetweenFilter: {
                                                key: propertySearchFilter.key,
                                                operatorNumberBetween:
                                                  value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : value.operator === "eq" ||
                                              value.operator === "lt" ||
                                              value.operator === "lte" ||
                                              value.operator === "gt" ||
                                              value.operator === "gte"
                                            ? {
                                                numberFilter: {
                                                  key: propertySearchFilter.key,
                                                  operatorNumber:
                                                    value.operator,
                                                  expr: value.expr,
                                                },
                                              }
                                            : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterTextFilterComputed":
                      case "EntitySearchPropertyFilterTextInFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<
                                    | EntitySearchTextFilterOperator
                                    | EntitySearchTextInFilterOperator
                                  >
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.__typename ===
                                    "EntitySearchPropertyFilterTextFilterComputed"
                                      ? propertySearchFilter.operatorText
                                      : propertySearchFilter.operatorTextIn,
                                  allowedSearchFilterOperators:
                                    TextFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "in"
                                        ? {
                                            textInFilter: {
                                              key: propertySearchFilter.key,
                                              operatorTextIn: value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : value.operator === "eq"
                                          ? {
                                              textFilter: {
                                                key: propertySearchFilter.key,
                                                operatorText: value.operator,
                                                expr: value.expr,
                                              },
                                            }
                                          : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.operatorNumberArrayOf,
                                  allowedSearchFilterOperators:
                                    ArrayFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "allOf" ||
                                        value.operator === "anyOf"
                                        ? {
                                            numberArrayOfFilter: {
                                              key: propertySearchFilter.key,
                                              operatorNumberArrayOf:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
                        return (
                          <Grid key={propertySearchFilter.key} item xs={1}>
                            <Stack
                              direction={"row"}
                              spacing={0.5}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <ModalOpenButton
                                Modal={
                                  ProductSearchFilterExpressionModal<EntitySearchArrayOfFilterOperator>
                                }
                                modalProps={{
                                  projectId: projectId,
                                  docId: doc.id,
                                  itemId: item.id,
                                  filterKey: propertySearchFilter.key,
                                  operator:
                                    propertySearchFilter.operatorTextArrayOf,
                                  allowedSearchFilterOperators:
                                    ArrayFilterOperatorOptions,
                                  expression: propertySearchFilter.expr,
                                  handleComplete: async (
                                    value,
                                    handleClose
                                  ) => {
                                    await handleSetTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key,
                                      value.operator === "allOf" ||
                                        value.operator === "anyOf"
                                        ? {
                                            textArrayOfFilter: {
                                              key: propertySearchFilter.key,
                                              operatorTextArrayOf:
                                                value.operator,
                                              expr: value.expr,
                                            },
                                          }
                                        : assertNever(value.operator)
                                    );
                                    handleClose();
                                  },
                                }}
                                disabled={!isEditable}
                              >
                                <LabeledValueWithButton
                                  label={propertySearchFilter.key}
                                  labelIcon={FunctionsIcon}
                                >
                                  {getValueWithOperator(propertySearchFilter)}
                                </LabeledValueWithButton>
                              </ModalOpenButton>
                              {viewMode === "delete" && (
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={async () => {
                                    await handleDeleteTemplateSearchFilterPropertyExpression(
                                      propertySearchFilter.key
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Grid>
                        );
                      }
                      default:
                        assertNever(propertySearchFilter);
                    }
                  }
                )}
              </Grid>
            </CollapseSection>
            <CollapseSection
              p={1}
              title={t("Sorting", { ns: "TemplateTypes" })}
              ActionButtons={
                <MenuButton
                  Icon={<AddIcon />}
                  buttonProps={{
                    disabled: unusedSortingProperties.length === 0,
                  }}
                >
                  {unusedSortingProperties.map(prop => {
                    switch (prop.__typename) {
                      case "Props2Number": {
                        return (
                          <MenuItem
                            onClick={async () => {
                              await handleSetTemplateSearchPropertySortingDefinition(
                                prop.key,
                                "number",
                                "asc"
                              );
                            }}
                          >
                            {getEnhancedPropertyLabel(prop, false)}
                          </MenuItem>
                        );
                      }
                      default:
                        assertNever(prop.__typename);
                    }
                  })}
                </MenuButton>
              }
            >
              <Grid
                container
                columns={2}
                rowSpacing={1}
                columnSpacing={2}
                padding={1}
              >
                {searchSortingDefinitions.map((searchSorting, index) => {
                  switch (searchSorting.__typename) {
                    case "EntitySearchSortingPropertyValueSorting": {
                      return (
                        <Grid key={searchSorting.key} item xs={1}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Select
                              key={`${searchSorting.key}-input`}
                              label={searchSorting.key}
                              options={[
                                {
                                  label: "asc",
                                  value: "asc" as const,
                                },
                                {
                                  label: "desc",
                                  value: "desc" as const,
                                },
                              ]}
                              value={searchSorting.direction}
                              onChange={async value => {
                                await handleSetTemplateSearchPropertySortingDefinition(
                                  searchSorting.key,
                                  searchSorting.kind,
                                  value
                                );
                              }}
                            />
                            {viewMode === "delete" && (
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={async () => {
                                  await handleDeleteTemplateSearchPropertySortingDefinition(
                                    searchSorting.key
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Stack>
                        </Grid>
                      );
                    }
                    default:
                      assertNever(searchSorting.__typename);
                  }
                })}
              </Grid>
            </CollapseSection>
          </Stack>
        ) : (
          <Stack padding={2} alignItems="center" justifyContent={"center"}>
            <Typography
              color={theme => theme.palette.grey[600]}
              variant="body2"
            >
              {t("No template type defined", { ns: "QuoteItem" })}
            </Typography>
          </Stack>
        )}
      </CardContainer>
    );
  }

  return null;
};

export function getValueWithOperator(
  filter:
    | ItemTemplateSearchFilterPropertyFilterFragment
    | ItemTemplateSearchFilterPropertyFilterComputedFragment
    | undefined
    | null,
  unit?: string | null | undefined
) {
  const placeholder = "-";
  if (!filter) return placeholder;

  switch (filter.__typename) {
    case "EntitySearchPropertyFilterNumberFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumber}` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberBetweenFilter": {
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetween.min}-${filter.valueNumberBetween.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberFilterComputed": {
      if (
        filter.valueNumberComputed === null ||
        filter.valueNumberComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumber
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberInFilterComputed": {
      if (
        filter.valueNumberInComputed === null ||
        filter.valueNumberInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilter": {
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberArrayOf
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberArrayOf.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberArrayOfFilterComputed": {
      if (
        filter.valueNumberArrayOfComputed === null ||
        filter.valueNumberArrayOfComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberArrayOf
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberArrayOfComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterNumberBetweenFilterComputed": {
      if (
        filter.valueNumberBetweenComputed === null ||
        filter.valueNumberBetweenComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = NumberFilterOperatorOptions.find(
        opt => opt.value === filter.operatorNumberBetween
      )?.label;
      return (
        `${operatorLabel} ${filter.valueNumberBetweenComputed.min}-${filter.valueNumberBetweenComputed.max}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueText}"` + (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextFilterComputed": {
      if (
        filter.valueTextComputed === null ||
        filter.valueTextComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorText
      )?.label;
      return (
        `${operatorLabel} "${filter.valueTextComputed}"` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterTextInFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextIn.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextInFilterComputed": {
      if (
        filter.valueTextInComputed === null ||
        filter.valueTextInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextIn
      )?.label;
      return `${operatorLabel} ${filter.valueTextInComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextArrayOfFilter": {
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextArrayOf
      )?.label;
      return `${operatorLabel} ${filter.valueTextArrayOf.join(", ")}`;
    }
    case "EntitySearchPropertyFilterTextArrayOfFilterComputed": {
      if (
        filter.valueTextArrayOfComputed === null ||
        filter.valueTextArrayOfComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = ArrayFilterOperatorOptions.find(
        opt => opt.value === filter.operatorTextArrayOf
      )?.label;
      return `${operatorLabel} ${filter.valueTextArrayOfComputed.join(", ")}`;
    }
    case "EntitySearchPropertyFilterBoolFilter": {
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return `${operatorLabel} ${filter.valueBool}` + (unit ? ` ${unit}` : "");
    }
    case "EntitySearchPropertyFilterBoolInFilter": {
      const operatorLabel = BoolFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolIn.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolFilterComputed": {
      if (
        filter.valueBoolComputed === null ||
        filter.valueBoolComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBool
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolComputed}` +
        (unit ? ` ${unit}` : "")
      );
    }
    case "EntitySearchPropertyFilterBoolInFilterComputed": {
      if (
        filter.valueBoolInComputed === null ||
        filter.valueBoolInComputed === undefined
      ) {
        return placeholder;
      }
      const operatorLabel = TextFilterOperatorOptions.find(
        opt => opt.value === filter.operatorBoolIn
      )?.label;
      return (
        `${operatorLabel} ${filter.valueBoolInComputed.join(", ")}` +
        (unit ? ` ${unit}` : "")
      );
    }
    default:
      assertNever(filter);
  }
}

const AddTemplateSearchFilterDefinitionsModal = ({
  handleClose,
  handleComplete,
  templateTypeIdFilter,
  templateSearchFilterDefinitions,
}: {
  handleClose: () => void;
  handleComplete: (values: TemplateFiltersFormValues) => Promise<void>;
  templateTypeIdFilter: string;
  templateSearchFilterDefinitions: TemplateSearchFilterDefinitionsFragment;
}) => {
  const { t } = useTranslate(["TemplateTypes"]);

  const initialValues = React.useMemo((): TemplateFiltersFormValues => {
    return {
      ...templateFiltersDefaultValue,
      templateTypeIds: [templateTypeIdFilter],
      ...definitionsToFormValues(templateSearchFilterDefinitions),
    };
  }, [templateSearchFilterDefinitions, templateTypeIdFilter]);

  const formikProps = useFormik({
    initialValues,
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    enableReinitialize: false,
    onSubmit: handleComplete,
  });

  const templateSearchVariables = React.useMemo(
    (): TemplateFilterFields_AvailableFiltersQueryVariables => ({
      filters: { sources: ["available"] },
    }),
    []
  );

  return (
    <TemplateTypeSearchFilterModal
      title={t("Search filters", { ns: "TemplateTypes" })}
      handleClose={handleClose}
      formikProps={formikProps}
      templateSearchVariables={templateSearchVariables}
      canChangeTemplateType={false}
    />
  );
};

function definitionsToFormValues(
  definitions: TemplateSearchFilterDefinitionsFragment
) {
  return {
    propertiesBool: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename === "EntitySearchPropertyFilterBoolFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorBool,
                value: propertyFilter.valueBool,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterBoolInFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorBoolIn,
                  value: propertyFilter.valueBoolIn,
                }
              : []
        )
      : null,
    propertiesNumber: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename ===
          "EntitySearchPropertyFilterNumberBetweenFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorNumberBetween,
                value: propertyFilter.valueNumberBetween,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterNumberInFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorNumberIn,
                  value: propertyFilter.valueNumberIn,
                }
              : propertyFilter.__typename ===
                  "EntitySearchPropertyFilterNumberFilter"
                ? {
                    key: propertyFilter.key,
                    operator: propertyFilter.operatorNumber,
                    value: propertyFilter.valueNumber,
                  }
                : []
        )
      : null,
    propertiesText: definitions.propertyFilters
      ? definitions.propertyFilters.flatMap(propertyFilter =>
          propertyFilter.__typename === "EntitySearchPropertyFilterTextInFilter"
            ? {
                key: propertyFilter.key,
                operator: propertyFilter.operatorTextIn,
                value: propertyFilter.valueTextIn,
              }
            : propertyFilter.__typename ===
                "EntitySearchPropertyFilterTextFilter"
              ? {
                  key: propertyFilter.key,
                  operator: propertyFilter.operatorText,
                  value: propertyFilter.valueText,
                }
              : []
        )
      : null,
  };
}

function filterFormValuesToDefinitionInput(
  values: TemplateFiltersFormValues
): Omit<
  DefineItemTemplateSearchFilterDefinitionsInput,
  "projectId" | "docId" | "itemId"
> {
  return {
    templateSearchFilterDefinitions: {
      propertyFilterDefinitions: [
        ...(values.propertiesBool?.map(filter =>
          filter.operator === "in"
            ? {
                boolInFilter: {
                  key: filter.key,
                  operatorBoolIn: filter.operator,
                  valueBoolIn: filter.value,
                },
              }
            : filter.operator === "eq"
              ? {
                  boolFilter: {
                    key: filter.key,
                    operatorBool: filter.operator,
                    valueBool: filter.value,
                  },
                }
              : assertNever(filter)
        ) ?? []),
        ...(values.propertiesNumber?.map(filter =>
          filter.operator === "between"
            ? {
                numberBetweenFilter: {
                  key: filter.key,
                  operatorNumberBetween: filter.operator,
                  valueNumberBetween: filter.value,
                },
              }
            : filter.operator === "in"
              ? {
                  numberInFilter: {
                    key: filter.key,
                    operatorNumberIn: filter.operator,
                    valueNumberIn: filter.value,
                  },
                }
              : filter.operator === "eq" ||
                  filter.operator === "gt" ||
                  filter.operator === "gte" ||
                  filter.operator === "lt" ||
                  filter.operator === "lte"
                ? {
                    numberFilter: {
                      key: filter.key,
                      operatorNumber: filter.operator,
                      valueNumber: filter.value,
                    },
                  }
                : assertNever(filter.operator)
        ) ?? []),
        ...(values.propertiesText?.map(filter =>
          filter.operator === "in"
            ? {
                textInFilter: {
                  key: filter.key,
                  operatorTextIn: filter.operator,
                  valueTextIn: filter.value,
                },
              }
            : filter.operator === "eq"
              ? {
                  textFilter: {
                    key: filter.key,
                    operatorText: filter.operator,
                    valueText: filter.value,
                  },
                }
              : assertNever(filter)
        ) ?? []),
      ],
    },
  };
}
