/* eslint-disable */
import * as Types from '../../../clients/graphqlTypes';

import { gql } from '@apollo/client';
import { ProjectListItemFragmentDoc } from '../../features/projects/components/ProjectListItem.generated';
import { UseSelectedDate_ProjectFragmentDoc } from './useSelectedDate.generated';
import { MapProjectFragmentDoc } from '../../features/schedule/Fragments.generated';
import { PlanningResourcesFragmentDoc } from '../../features/planning/PlanningSchedule.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlanningProjectsQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit: Types.Scalars['Int']['input'];
  sorting?: Types.InputMaybe<Array<Types.OrganisationProjectsSorting>>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']['input']>;
  projectType?: Types.InputMaybe<Array<Types.ProjectType>>;
  projectCategories?: Types.InputMaybe<Array<Types.Skill>>;
  buildingZip?: Types.InputMaybe<Types.Scalars['String']['input']>;
  withinRadiusKm?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  minBudget?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterByCustomFields?: Types.InputMaybe<Array<Types.CustomFieldFilter>>;
  filterByCrmOrganisationId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterByCrmLinkIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySourceCrmOrganisationIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterBySourceUserIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
  filterIncludeState?: Types.InputMaybe<Array<Types.ProjectStateMachineStatus>>;
  filterKind?: Types.InputMaybe<Types.ProjectFilterKind>;
  filterAssigned?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterOverdue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterUrgent?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filterPhaseIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']>>;
}>;


export type PlanningProjectsQuery = { projects: { __typename: 'ProjectConnection', totalCount: number, edges: Array<{ __typename: 'ProjectEdge', node: { __typename: 'Project', id: string, viewerPermissions: Array<Types.PermissionName>, title: string, deadline?: any | null, overdue: boolean, state: Types.ProjectStateMachineStatus, leadId?: number | null, urgent: boolean, earliestStart?: any | null, earliestPlanSessionDate?: any | null, assigned: boolean, latestPlanSessionDate?: any | null, ticket: boolean, owningSystemOrganisationId: string, phase: { __typename: 'ProjectPhase', id: string, name: string }, budget?: { __typename: 'Money', currency: string, amount: number } | null, assignees: Array<{ __typename: 'User', id: string, firstname: string, familyname: string } | null>, assignee?: { __typename: 'ProjectMembership', id: string, user: { __typename: 'User', id: string, firstname: string, familyname: string, fullname: string, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } } } | null, buildingInfo?: { __typename: 'BuildingInfo', id: string, title: string, buildingAddress?: { __typename: 'Address', streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number, id: string } | null } | null, crmOrganisation?: { __typename: 'CrmCompany', id: string, title: string, contactPerson?: { __typename: 'CrmPerson', id: string, title: Types.Salutation, description: string, website?: string | null, isMeister: boolean, familyname: string, firstname: string, fullname: string, locale: string, skillset: Array<Types.Skill> } | null } | null } }> } };

export type PlanningProjects_OrganisationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PlanningProjects_OrganisationQuery = { organisationMemberships: Array<{ __typename: 'User', id: string, email: any, firstname: string, familyname: string, fullname: string, phones: Array<{ __typename: 'Phone', id: string, number: string, type: Types.PhoneType, main: boolean }>, roles: Array<{ __typename: 'OrganisationRole', id: string, label: string, internalName: string }>, homeAddress?: { __typename: 'Address', id: string, streetLines1: string, city: string, postalCode: string, countryCode: string, lat: number, lng: number } | null, avatar: { __typename: 'AttachmentSnapshot', id: string, title: string, mimeType: string, url: string, group: string } }>, roles: Array<{ __typename: 'OrganisationRole', id: string, label: string, internalName: string }> };


export const PlanningProjectsDocument = gql`
    query PlanningProjects($offset: Int, $limit: Int!, $sorting: [OrganisationProjectsSorting!], $searchTerm: String, $projectType: [ProjectType!], $projectCategories: [Skill!], $buildingZip: String, $withinRadiusKm: Int, $minBudget: Int, $filterByCustomFields: [CustomFieldFilter!], $filterByCrmOrganisationId: ID, $filterByCrmLinkIds: [ID!], $filterBySourceCrmOrganisationIds: [ID!], $filterBySourceUserIds: [ID!], $filterIncludeState: [ProjectStateMachineStatus!], $filterKind: ProjectFilterKind, $filterAssigned: Boolean, $filterOverdue: Boolean, $filterUrgent: Boolean, $filterPhaseIds: [ID!]) {
  projects(
    limit: $limit
    offset: $offset
    sorting: $sorting
    filters: {includeState: $filterIncludeState, kind: $filterKind, type: $projectType, categories: $projectCategories, buildingZip: $buildingZip, withinRadiusKm: $withinRadiusKm, minBudget: $minBudget, customFields: $filterByCustomFields, assigned: $filterAssigned, crmOrganisationId: $filterByCrmOrganisationId, crmLinkIds: $filterByCrmLinkIds, sourceCrmOrganisationIds: $filterBySourceCrmOrganisationIds, sourceUserIds: $filterBySourceUserIds, overdue: $filterOverdue, urgent: $filterUrgent, phaseIds: $filterPhaseIds}
    search: $searchTerm
  ) {
    ... on ProjectConnection {
      edges {
        node {
          id
          ...ProjectListItem
          ...useSelectedDate_Project
          ...MapProject
        }
      }
      totalCount
    }
  }
}
    ${ProjectListItemFragmentDoc}
${UseSelectedDate_ProjectFragmentDoc}
${MapProjectFragmentDoc}`;

/**
 * __usePlanningProjectsQuery__
 *
 * To run a query within a React component, call `usePlanningProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningProjectsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sorting: // value for 'sorting'
 *      searchTerm: // value for 'searchTerm'
 *      projectType: // value for 'projectType'
 *      projectCategories: // value for 'projectCategories'
 *      buildingZip: // value for 'buildingZip'
 *      withinRadiusKm: // value for 'withinRadiusKm'
 *      minBudget: // value for 'minBudget'
 *      filterByCustomFields: // value for 'filterByCustomFields'
 *      filterByCrmOrganisationId: // value for 'filterByCrmOrganisationId'
 *      filterByCrmLinkIds: // value for 'filterByCrmLinkIds'
 *      filterBySourceCrmOrganisationIds: // value for 'filterBySourceCrmOrganisationIds'
 *      filterBySourceUserIds: // value for 'filterBySourceUserIds'
 *      filterIncludeState: // value for 'filterIncludeState'
 *      filterKind: // value for 'filterKind'
 *      filterAssigned: // value for 'filterAssigned'
 *      filterOverdue: // value for 'filterOverdue'
 *      filterUrgent: // value for 'filterUrgent'
 *      filterPhaseIds: // value for 'filterPhaseIds'
 *   },
 * });
 */
export function usePlanningProjectsQuery(baseOptions: Apollo.QueryHookOptions<PlanningProjectsQuery, PlanningProjectsQueryVariables> & ({ variables: PlanningProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningProjectsQuery, PlanningProjectsQueryVariables>(PlanningProjectsDocument, options);
      }
export function usePlanningProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningProjectsQuery, PlanningProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningProjectsQuery, PlanningProjectsQueryVariables>(PlanningProjectsDocument, options);
        }
export function usePlanningProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlanningProjectsQuery, PlanningProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlanningProjectsQuery, PlanningProjectsQueryVariables>(PlanningProjectsDocument, options);
        }
export type PlanningProjectsQueryHookResult = ReturnType<typeof usePlanningProjectsQuery>;
export type PlanningProjectsLazyQueryHookResult = ReturnType<typeof usePlanningProjectsLazyQuery>;
export type PlanningProjectsSuspenseQueryHookResult = ReturnType<typeof usePlanningProjectsSuspenseQuery>;
export type PlanningProjectsQueryResult = Apollo.QueryResult<PlanningProjectsQuery, PlanningProjectsQueryVariables>;
export const PlanningProjects_OrganisationDocument = gql`
    query PlanningProjects_Organisation {
  organisationMemberships(filters: {active: true}) {
    id
    ...PlanningResources
  }
  roles {
    id
    label
    internalName
  }
}
    ${PlanningResourcesFragmentDoc}`;

/**
 * __usePlanningProjects_OrganisationQuery__
 *
 * To run a query within a React component, call `usePlanningProjects_OrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningProjects_OrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningProjects_OrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanningProjects_OrganisationQuery(baseOptions?: Apollo.QueryHookOptions<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>(PlanningProjects_OrganisationDocument, options);
      }
export function usePlanningProjects_OrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>(PlanningProjects_OrganisationDocument, options);
        }
export function usePlanningProjects_OrganisationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>(PlanningProjects_OrganisationDocument, options);
        }
export type PlanningProjects_OrganisationQueryHookResult = ReturnType<typeof usePlanningProjects_OrganisationQuery>;
export type PlanningProjects_OrganisationLazyQueryHookResult = ReturnType<typeof usePlanningProjects_OrganisationLazyQuery>;
export type PlanningProjects_OrganisationSuspenseQueryHookResult = ReturnType<typeof usePlanningProjects_OrganisationSuspenseQuery>;
export type PlanningProjects_OrganisationQueryResult = Apollo.QueryResult<PlanningProjects_OrganisationQuery, PlanningProjects_OrganisationQueryVariables>;