import { AutocompleteMultiple, CollapseSection } from "@msys/ui";
import { Typography, Stack } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { FormikProps, useFormik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import {
  OrganisationQuoteTemplatesFilters,
  OrganisationQuoteTemplatesSorting,
} from "../../../../clients/graphqlTypes";
import { FilterModal } from "../../../commons/filters/FilterModal";
import {
  FilterCreatedAfter,
  FilterCreatedBefore,
  FilterMaxPrice,
  FilterMinPrice,
  QuoteTemplatesSorting,
} from "../filters";
import {
  templateFiltersDefaultValue,
  TemplateFiltersFormValues,
  templateFiltersValidationSchema,
  useTemplateFilterFields,
} from "../filters/TemplateFilterFields";
import { TemplateFilterFields_AvailableFiltersQueryVariables } from "../filters/TemplateFilterFields.generated";

export const DEFAULT_SORTING: OrganisationQuoteTemplatesSorting[] = [
  {
    column: "createdAt",
    direction: "desc",
  },
];

export type Filters = Pick<
  OrganisationQuoteTemplatesFilters,
  "createdBefore" | "createdAfter" | "priceMin" | "priceMax" | "tagsAny"
>;

interface Props<F extends Filters> {
  filters: F;
  setFilters: Dispatch<SetStateAction<F>>;
  resetFilters: () => void;
  sorting: OrganisationQuoteTemplatesSorting[];
  setSorting: (newSorting: OrganisationQuoteTemplatesSorting[]) => void;
  handleClose: () => void;
  tags: string[];
  templateFiltersProps?: {
    templateSearchVariables: TemplateFilterFields_AvailableFiltersQueryVariables;
    formikProps: FormikProps<TemplateFiltersFormValues>;
  };
}

export const TemplateQuotesFilterModal = <F extends Filters>({
  filters,
  setFilters,
  resetFilters,
  sorting,
  setSorting,
  handleClose,
  tags,
  templateFiltersProps,
}: Props<F>) => {
  const { t } = useTranslate(["Templates", "Global"]);

  const [filtersState, setFiltersState] = React.useState(filters);
  const [sortingState, setSortingState] = React.useState(sorting);

  // we need an inner formik state to not immediately apply filters on the list
  const formikProps = useFormik<TemplateFiltersFormValues>({
    // @ts-ignore
    initialValues: {
      ...templateFiltersDefaultValue,
      ...templateFiltersProps?.formikProps.values,
    },
    validationSchema: templateFiltersValidationSchema,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      templateFiltersProps?.formikProps.setValues(newValues);
      await templateFiltersProps?.formikProps.submitForm();
    },
  });

  return (
    <FilterModal
      title={t("Filters", { ns: "Global" })}
      handleApply={async () => {
        setFilters(filtersState);
        setSorting(sortingState);
        await formikProps.submitForm();
      }}
      handleClose={handleClose}
      handleReset={async () => {
        resetFilters();
        await formikProps.setValues(templateFiltersDefaultValue);
        await formikProps.submitForm();
      }}
    >
      <Typography variant="h3">{t("Sort by", { ns: "Global" })}</Typography>
      <QuoteTemplatesSorting
        sorting={sortingState[0]}
        setSorting={setSortingState}
        defaultSorting={DEFAULT_SORTING}
      />
      <Typography variant="h3">{t("Filter by", { ns: "Global" })}</Typography>
      <Stack direction="row" spacing={1}>
        <FilterMinPrice
          value={filtersState.priceMin ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              priceMin: newValue,
            }))
          }
        />
        <FilterMaxPrice
          value={filtersState.priceMax ?? 0}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              priceMax: newValue,
            }))
          }
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <FilterCreatedAfter
          value={filtersState.createdAfter}
          setValue={newValue => {
            setFiltersState(filters => ({
              ...filters,
              createdAfter: newValue,
            }));
          }}
        />
        <FilterCreatedBefore
          value={filtersState.createdBefore}
          setValue={newValue =>
            setFiltersState(filters => ({
              ...filters,
              createdBefore: newValue,
            }))
          }
        />
      </Stack>
      <AutocompleteMultiple
        inputLabel={t("Tags", { ns: "Templates" })}
        options={tags}
        value={filtersState.tagsAny ?? []}
        onChange={value => {
          setFiltersState(state => ({ ...state, tagsAny: value }));
        }}
        filterOptions={(options, state) =>
          options.filter(option =>
            option
              .toLocaleLowerCase()
              .trim()
              .includes(state.inputValue.toLocaleLowerCase().trim())
          )
        }
      />
      {templateFiltersProps && (
        <TemplateFiltersSection
          {...templateFiltersProps}
          formikProps={formikProps}
        />
      )}
    </FilterModal>
  );
};

const TemplateFiltersSection = ({
  formikProps,
  templateSearchVariables,
}: {
  templateSearchVariables: TemplateFilterFields_AvailableFiltersQueryVariables;
  formikProps: FormikProps<TemplateFiltersFormValues>;
}) => {
  const { t } = useTranslate(["Global", "TemplatesSearch"]);

  const { nonPropertyFields, propertyFields, isLoading } =
    useTemplateFilterFields({
      formikProps,
      templateSearchVariables,
    });

  return (
    <Stack direction="column" spacing={1}>
      {nonPropertyFields.length > 0 && (
        <Stack direction="column" spacing={1}>
          {nonPropertyFields}
        </Stack>
      )}

      {propertyFields.length > 0 && (
        <CollapseSection
          title={t("Properties", { ns: "TemplatesSearch" })}
          isInitiallyExpanded={true}
          itemCount={propertyFields.length}
        >
          <Stack direction="column" spacing={1}>
            {propertyFields}
          </Stack>
        </CollapseSection>
      )}
    </Stack>
  );
};
