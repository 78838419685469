import { useApolloClient } from "@apollo/client";
import { getDataOrNull, getPictures } from "@msys/common";
import {
  DataGrid,
  DebouncedSearchInput,
  ErrorMessage,
  ListHeader,
  LoadingSpinner as LoadingIndicator,
  MediaCardItem,
  MediaListItem,
  Modal,
  Select,
  useFormatting,
  useLocalStorageAsState,
  useScreenWidth,
} from "@msys/ui";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useTranslate } from "@tolgee/react";
import { useFormik } from "formik";
import { isEqual, isUndefined, omit, uniqueId } from "lodash";
import React from "react";
import {
  SearchTemplatesFiltersInput,
  SearchTemplatesSortingInput,
} from "../../../../clients/graphqlTypes";
import { useUserData } from "../../../auth/useUserData";
import { ButtonCircleWrapper } from "../../../commons/button/ButtonCircleWrapper";
import { SwitchCollectionViewButton } from "../../../commons/button/SwitchCollectionViewButton";
import { FilterChipGroup } from "../../../commons/filters/FilterChip";
import { CollectionView } from "../../../commons/hooks/useCollectionView";
import { RESULTS_PER_PAGE_OPTIONS } from "../../../constants";
import {
  ProductSearchType,
  ProductSearchTypeOption,
  useProductSearchType,
} from "../../products/filters/useProductSearchTypes";
import { PropertiesFilterChips } from "../../properties/PropertiesFilterChips";
import { TemplateQuotePublishingStatus } from "./TemplateQuotePublishingStatus";
import { TemplatesQuoteOverviewModal } from "./TemplatesQuoteOverviewModal";
import {
  TemplatesQuoteSetsSearchModal_TemplateFragment,
  useTemplatesQuoteSetsSearchModalQuery,
  useTemplatesQuoteSetsSearchModal_TemplateTypeQuery,
} from "./TemplatesQuoteSetsSearchModal.generated";

const ALLOWED_VIEWS: CollectionView[] = ["gallery", "list", "table"];

interface Props {
  initialSearchValue?: string;
  initialFilters?: SearchTemplatesFiltersInput;
  initialSorting?: SearchTemplatesSortingInput[];
  handleTemplateChoice: (
    template: TemplatesQuoteSetsSearchModal_TemplateFragment
  ) => Promise<void> | void;
  handleClose: () => void;
  handleSearchValueChange?: (value: string) => void;
}

export const TemplatesQuoteSetsSearchModal = (props: Props) => {
  return (
    <Modal
      dialogProps={{
        maxWidth: "xl",
        PaperProps: { style: { height: "100%" } },
      }}
      handleClose={props.handleClose}
      title={"Placeholder search"} // TODO: translate
      alwaysVisible
    >
      <TemplatesQuoteSetsSearchModalComponent {...props} />
    </Modal>
  );
};

const FILTERS_DEFAULT_VALUE = {
  propertiesBool: null,
  propertiesNumber: null,
  propertiesText: null,
  propertiesNumberArray: null,
  propertiesTextArray: null,
  templateTypeIds: null,
};

export const TemplatesQuoteSetsSearchModalComponent = ({
  initialSearchValue = "",
  initialFilters,
  initialSorting,
  handleTemplateChoice,
  handleClose,
  handleSearchValueChange,
}: Props) => {
  const { t } = useTranslate(["TemplatesSearch", "Templates", "Global"]);
  const { getFormattedDate, getFormattedPrice } = useFormatting();
  const { isMinTablet } = useScreenWidth();

  const [selectedDoc, setSelectedDoc] =
    React.useState<TemplatesQuoteSetsSearchModal_TemplateFragment | null>(null);

  const { productSearchTypeOptions, productSearchType, setProductSearchType } =
    useProductSearchType();

  const [paginationModel, setPaginationModel] = React.useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 25 });

  const [searchTerm, setSearchTerm] =
    React.useState<string>(initialSearchValue);

  const initialFilterState = React.useMemo<SearchTemplatesFiltersInput>(
    () => ({
      ...FILTERS_DEFAULT_VALUE,
      ...initialFilters,
    }),
    [initialFilters]
  );

  const [filters, setFilters] =
    React.useState<SearchTemplatesFiltersInput>(initialFilterState);

  const searchVariables = {
    searchTerm: productSearchType === "fulltextSearch" ? searchTerm : undefined,
    specificFieldQueryFields:
      productSearchType !== "fulltextSearch"
        ? [{ field: productSearchType, value: searchTerm }]
        : undefined,
    filters,
  };

  const client = useApolloClient();
  const query = useTemplatesQuoteSetsSearchModalQuery({
    client,
    variables: {
      ...searchVariables,
      filters: {
        sources: ["available"],
        ...omit(searchVariables.filters, []),
      },
      searchTerm,
      sorting: initialSorting,
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
    },
  });

  const resetFilters = () => {
    setSearchTerm("");
    setFilters(FILTERS_DEFAULT_VALUE);
  };

  const [activeView, setActiveView] = useLocalStorageAsState<CollectionView>(
    "msys-productMultipleListViewType",
    "table"
  );

  React.useEffect(() => {
    const totalCount = getDataOrNull(query.data?.searchTemplates)?.totalCount;
    if (
      !isUndefined(totalCount) &&
      totalCount < paginationModel.pageSize * paginationModel.page &&
      paginationModel.page > 0
    ) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }
  }, [query.data, paginationModel, setPaginationModel]);

  const items =
    getDataOrNull(
      (query.data ?? query.previousData)?.searchTemplates
    )?.edges.map(e => e.node) ?? [];
  const totalCount =
    getDataOrNull((query.data ?? query.previousData)?.searchTemplates)
      ?.totalCount ?? 0;

  const renderTemplateSelectButton = (
    product: TemplatesQuoteSetsSearchModal_TemplateFragment
  ) => {
    return (
      <IconButton
        color={"primary"}
        size={"small"}
        onClick={() => {
          handleTemplateChoice(product);
          handleClose();
        }}
        title={t("Select", {
          ns: "ProductSearch",
        })}
        aria-label={t("Select", {
          ns: "ProductSearch",
        })}
      >
        <TouchAppIcon />
      </IconButton>
    );
  };

  const renderItems = (
    items: TemplatesQuoteSetsSearchModal_TemplateFragment[]
  ) => {
    switch (activeView) {
      case "list":
        if (query.loading) return <LoadingIndicator />;
        return items.length > 0 ? (
          <Stack
            direction="column"
            spacing={1}
            flex={1}
            overflow="auto"
            minHeight={0}
            sx={theme => ({
              marginRight: `-${theme.spacing(2)} !important`,
            })}
          >
            <Stack
              direction="column"
              spacing={1}
              overflow="auto"
              flex={1}
              minHeight={0}
              pr={2}
            >
              {items.map((template, index) => {
                const pictures = getPictures(template.attachments);
                return (
                  <MediaListItem
                    key={template.id}
                    onClick={() => {
                      if (!template.resolvedAsReadModelVersionNumber) {
                        throw new Error("Version missing");
                      }
                      setSelectedDoc(template);
                    }}
                    title={template.title}
                    description={template.publicDescription}
                    price={getFormattedPrice(
                      template.proposedCalculation?.priceNetTotal ?? 0
                    )}
                    imageUrl={pictures[0]?.url}
                    ActionButton={
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        {/*{renderTemplateBookmarkButton(template)}*/}
                        {renderTemplateSelectButton(template)}
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Rows per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        ) : (
          <Box padding={2} display="flex" justifyContent="center">
            {t("There seems to be nothing here", {
              ns: "Global",
            })}
          </Box>
        );

      case "gallery":
        if (query.loading) return <LoadingIndicator />;
        return (
          <Stack
            direction="column"
            spacing={1}
            flex={1}
            overflow="auto"
            minHeight={0}
            sx={theme => ({
              marginRight: `-${theme.spacing(2)} !important`,
            })}
          >
            <Stack
              direction="column"
              spacing={1}
              overflow="auto"
              flex={1}
              minHeight={0}
              pr={2}
            >
              <Grid container spacing={isMinTablet ? 2 : 1}>
                {items.map((template, index) => {
                  const pictures = getPictures(template.attachments);
                  return (
                    <Grid key={template.id} item xs={6} md={6} lg={4}>
                      <MediaCardItem
                        key={template.id}
                        onClick={() => {
                          if (!template.resolvedAsReadModelVersionNumber) {
                            throw new Error("Version missing");
                          }
                          setSelectedDoc(template);
                        }}
                        title={template.title}
                        description={template.description}
                        price={getFormattedPrice(
                          template.proposedCalculation?.priceNetTotal || 0
                        )}
                        imageUrl={pictures[0]?.url}
                        ActionButton={
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            {/*<ButtonCircleWrapper>*/}
                            {/*  {renderTemplateBookmarkButton(template, "medium")}*/}
                            {/*</ButtonCircleWrapper>*/}
                            <ButtonCircleWrapper>
                              {renderTemplateSelectButton(template)}
                            </ButtonCircleWrapper>
                          </Stack>
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
            <TablePagination
              component="div"
              count={totalCount}
              page={paginationModel.page}
              onPageChange={(event, newPage) => {
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: newPage,
                });
              }}
              rowsPerPage={paginationModel.pageSize}
              onRowsPerPageChange={event => {
                setPaginationModel({
                  pageSize: parseInt(event.target.value, 10),
                  page: 0,
                });
              }}
              labelRowsPerPage={t("Items per page:", {
                ns: "Global",
              })}
            />
          </Stack>
        );

      case "table":
        return (
          <DataGrid
            density="compact"
            loading={query.loading}
            hideFooter={totalCount === 0}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (!params.row.resolvedAsReadModelVersionNumber) {
                throw new Error("Version missing");
              }
              if (
                [
                  "title",
                  "category",
                  "createdAt",
                  "publishingStatus",
                  "discountedPrice",
                ].includes(params.field)
              )
                setSelectedDoc(params.row);
            }}
            localeText={{
              noRowsLabel: t("There are no items to display", {
                ns: "Global",
              }),
              MuiTablePagination: {
                labelRowsPerPage: t("Rows per page", {
                  ns: "Global",
                }),
              },
            }}
            columns={[
              {
                field: "checkbox",
                headerName: "",
                width: 50,
                sortable: false,
                resizable: false,
                renderCell: ({ row: template }) =>
                  renderTemplateSelectButton(template),
              },
              {
                field: "title",
                headerName: t("Title", {
                  ns: "Templates",
                }),
                flex: 3,
                minWidth: 150,
                renderCell: ({
                  row: template,
                }: {
                  row: TemplatesQuoteSetsSearchModal_TemplateFragment;
                }) => template.title,
              },
              {
                field: "publishingStatus",
                headerName: t("Publishing", {
                  ns: "Templates",
                }),
                flex: 0.75,
                minWidth: 100,
                sortable: false,
                renderCell: ({
                  row: template,
                }: {
                  row: TemplatesQuoteSetsSearchModal_TemplateFragment;
                }) => (
                  <TemplateQuotePublishingStatus
                    doc={template}
                    variant="body2"
                    IconProps={{ fontSize: "small" }}
                  />
                ),
              },
              {
                field: "createdAt",
                headerName: t("Created", {
                  ns: "Templates",
                }),
                width: 120,
                renderCell: ({
                  row: template,
                }: {
                  row: TemplatesQuoteSetsSearchModal_TemplateFragment;
                }) => getFormattedDate(template.createdAt),
              },
              {
                field: "discountedPrice",
                headerName: t("Price", {
                  ns: "Templates",
                }),
                width: 120,
                headerAlign: "right",
                align: "right",
                renderCell: ({
                  row: template,
                }: {
                  row: TemplatesQuoteSetsSearchModal_TemplateFragment;
                }) =>
                  getFormattedPrice(
                    template.proposedCalculation?.priceNetTotal || 0
                  ),
              },
              {
                field: "bookmark",
                headerName: "",
                width: 120,
                sortable: false,
                renderCell: ({
                  row: template,
                }: {
                  row: TemplatesQuoteSetsSearchModal_TemplateFragment;
                }) => (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    {renderTemplateSelectButton(template)}
                  </Stack>
                ),
              },
            ]}
            rows={items}
            // sortModel={sorting.map(s => ({
            //   field: s.column,
            //   sort: s.direction,
            // }))}
            // onSortModelChange={newModel => {
            //   setSorting(
            //     newModel.map(({ field, sort }) => ({
            //       column: field as OrganisationQuoteTemplatesSortBy,
            //       direction: sort ?? "asc",
            //     }))
            //   );
            // }}
            paginationModel={paginationModel}
            onPaginationModelChange={newPaginationModel => {
              setPaginationModel(newPaginationModel);
            }}
            disableColumnFilter
            pageSizeOptions={RESULTS_PER_PAGE_OPTIONS}
            rowCount={totalCount}
          />
        );
    }
  };

  return (
    <Stack alignItems={"stretch"} height="100%" spacing={2} direction="row">
      <Stack
        direction={"column"}
        height="100%"
        spacing={1}
        flex={1}
        minWidth={0}
      >
        <TemplatesQuoteSetsSearchModalListHeader
          initialFilterState={FILTERS_DEFAULT_VALUE}
          filters={filters}
          setFilters={setFilters}
          resetFilters={resetFilters}
          searchTypeOptions={productSearchTypeOptions}
          searchType={productSearchType}
          setSearchType={setProductSearchType}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          activeView={activeView}
          setActiveView={setActiveView}
          handleSearchValueChange={handleSearchValueChange}
        />
        {query.error && <ErrorMessage message={query.error.message} />}
        {renderItems(items)}
      </Stack>
      {selectedDoc && selectedDoc.resolvedAsReadModelVersionNumber && (
        <TemplatesQuoteOverviewModal
          docId={selectedDoc.id}
          versionNumber={selectedDoc.resolvedAsReadModelVersionNumber}
          handleClose={() => setSelectedDoc(null)}
          // headerActions={renderTemplateBookmarkButton(
          //   selectedDoc,
          //   "medium"
          // )}
          dialogActionButton={
            <Button
              onClick={async e => {
                e.stopPropagation();
                setSelectedDoc(null);
                await handleTemplateChoice(selectedDoc);
                handleClose();
              }}
              color="primary"
              variant="contained"
              startIcon={<AddShoppingCartIcon />}
            >
              {t("Add to list", {
                ns: "TemplatesSearch",
              })}
            </Button>
          }
        />
      )}
    </Stack>
  );
};

function TemplatesQuoteSetsSearchModalListHeader({
  initialFilterState,
  filters,
  setFilters,
  resetFilters,
  searchTypeOptions,
  searchType,
  setSearchType,
  searchTerm,
  setSearchTerm,
  handleSearchValueChange,
  activeView,
  setActiveView,
}: {
  initialFilterState: SearchTemplatesFiltersInput;
  filters: SearchTemplatesFiltersInput;
  setFilters: React.Dispatch<React.SetStateAction<SearchTemplatesFiltersInput>>;
  resetFilters: () => void;
  searchTypeOptions: ProductSearchTypeOption[];
  searchType: ProductSearchType;
  setSearchType: (value: ProductSearchType) => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchValueChange?: (value: string) => void;
  activeView: CollectionView;
  setActiveView: (newValue: CollectionView | undefined) => void;
}) {
  const viewer = useUserData().currentUser;
  const { isMinTablet } = useScreenWidth();
  const { t } = useTranslate(["Product", "Global", "ProductSearch"]);

  const formikProps = useFormik({
    initialValues: filters,
    validationSchema: null,
    validateOnMount: true,
    onSubmit: async (newValues, formikHelpers) => {
      setFilters(value => ({
        ...value,
        ...newValues,
      }));
      formikHelpers.resetForm({ values: newValues });
    },
  });
  const formId = React.useMemo(() => uniqueId(), []);
  const isResetDisabled = isEqual(formikProps.values, FILTERS_DEFAULT_VALUE);

  return (
    <>
      <ListHeader
        elementsOrder="separate-rows"
        SwitchViewButton={
          <SwitchCollectionViewButton
            allowedViews={ALLOWED_VIEWS}
            activeView={activeView}
            setActiveView={setActiveView}
          />
        }
        SearchField={
          <Stack direction={"row"} flex={1} spacing={1}>
            <Select
              label={t("Search In", {
                ns: "ProductSearch",
              })}
              options={searchTypeOptions}
              value={searchType}
              onChange={setSearchType}
              style={{ maxWidth: 150 }}
              size="extra-small"
            />
            <DebouncedSearchInput
              defaultValue={searchTerm}
              placeholder={t("Search", {
                ns: "Global",
              })}
              onChangeSearchTerm={setSearchTerm}
              onImmediateChangeSearchTerm={handleSearchValueChange}
              autoFocus
              style={{ flexGrow: 1 }}
            />
          </Stack>
        }
        // QuickFilter={
        //   <FavouritesQuickFilter
        //     isActive={filters.organisationFavourite === true}
        //     toggleActive={active => {
        //       formikProps.setFieldValue(
        //         "organisationFavourite",
        //         active ? true : null
        //       );
        //       formikProps.submitForm();
        //     }}
        //   />
        // }
        // FilterButton={
        //   !viewer?.organisation.isSupplierOrganisation ? (
        //     <ModalOpenButton
        //       Modal={ProductFilterModal}
        //       modalProps={{
        //         formId,
        //         formikProps,
        //         productSearchVariables,
        //         resetFilters: () => {
        //           resetFilters();
        //           formikProps.resetForm({ values: initialFilterState });
        //         },
        //         isResetDisabled,
        //       }}
        //     >
        //       <FilterButton />
        //     </ModalOpenButton>
        //   ) : undefined
        // }
        // InlineFilters={
        //   !viewer?.organisation.isSupplierOrganisation && isMinTablet ? (
        //     <ProductFilters
        //       formId={formId}
        //       formikProps={formikProps}
        //       productSearchVariables={productSearchVariables}
        //     />
        //   ) : undefined
        // }
        // ResetApplyButtons={
        //   !viewer?.organisation.isSupplierOrganisation && isMinTablet ? (
        //     <Stack
        //       direction="row"
        //       spacing={1}
        //       justifyContent="flex-end"
        //       alignSelf={"flex-end"}
        //       alignItems="center"
        //     >
        //       <Button
        //         variant="text"
        //         color="primary"
        //         size={"small"}
        //         disabled={isResetDisabled}
        //         onClick={() => {
        //           resetFilters();
        //           formikProps.resetForm({ values: initialFilterState });
        //         }}
        //       >
        //         {t("Reset", {
        //           ns: "Global",
        //         })}
        //       </Button>
        //       <Button
        //         type="submit"
        //         variant="contained"
        //         color="primary"
        //         size={"small"}
        //         disabled={!formikProps.isValid || !formikProps.dirty}
        //         form={formId}
        //       >
        //         {t("Apply", {
        //           ns: "Global",
        //         })}
        //       </Button>
        //     </Stack>
        //   ) : undefined
        // }
        FilterChips={
          canShowChips(filters) ? (
            <TemplateTypeFilterChips
              filters={filters}
              setFilters={setFilters}
              onReset={resetFilters}
            />
          ) : undefined
        }
      />
    </>
  );
}

function canShowChips(filters: SearchTemplatesFiltersInput) {
  return (
    (filters.templateTypeIds && filters.templateTypeIds.length > 0) ||
    (filters.propertiesBool && filters.propertiesBool.length > 0) ||
    (filters.propertiesNumber && filters.propertiesNumber.length > 0) ||
    (filters.propertiesText && filters.propertiesText.length > 0) ||
    (filters.propertiesTextArray && filters.propertiesTextArray.length > 0) ||
    (filters.propertiesNumberArray && filters.propertiesNumberArray.length > 0)
  );
}

const TemplateTypeFilterChips = ({
  filters,
  setFilters,
  onReset,
}: {
  filters: SearchTemplatesFiltersInput;
  setFilters: React.Dispatch<React.SetStateAction<SearchTemplatesFiltersInput>>;
  onReset?(): void;
}) => {
  const { t } = useTranslate(["TemplateTypes", "Global"]);

  const client = useApolloClient();
  const templateTypeQuery = useTemplatesQuoteSetsSearchModal_TemplateTypeQuery({
    client,
    variables: {
      id: filters.templateTypeIds?.at(0) ?? "",
    },
    skip: !filters.templateTypeIds || filters.templateTypeIds.length < 1,
  });

  const getTemplateTypeName = (id: string) => {
    return getDataOrNull(templateTypeQuery.data?.templateType)?.title ?? id;
  };

  return (
    <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
      {filters.templateTypeIds ? (
        <FilterChipGroup
          label={t("Template Type", { ns: "TemplateTypes" })}
          values={filters.templateTypeIds}
          getValueLabel={getTemplateTypeName}
          setValues={newValues => {
            setFilters(filters => ({
              ...filters,
              templateTypeIds:
                newValues && newValues.length > 0 ? newValues : undefined,
            }));
          }}
        />
      ) : null}
      {filters.propertiesBool ? (
        <PropertiesFilterChips
          values={filters.propertiesBool}
          setValues={newValue => {
            setFilters(filters => ({
              ...filters,
              propertiesBool: newValue,
            }));
          }}
        />
      ) : null}
      {filters.propertiesNumber ? (
        <PropertiesFilterChips
          values={filters.propertiesNumber}
          setValues={newValue => {
            setFilters(filters => ({
              ...filters,
              propertiesNumber: newValue,
            }));
          }}
        />
      ) : null}
      {filters.propertiesNumberArray ? (
        <PropertiesFilterChips
          values={filters.propertiesNumberArray}
          setValues={newValue => {
            setFilters(filters => ({
              ...filters,
              propertiesNumberArray: newValue,
            }));
          }}
        />
      ) : null}
      {filters.propertiesText ? (
        <PropertiesFilterChips
          values={filters.propertiesText}
          setValues={newValue => {
            setFilters(filters => ({
              ...filters,
              propertiesText: newValue,
            }));
          }}
        />
      ) : null}
      {filters.propertiesTextArray ? (
        <PropertiesFilterChips
          values={filters.propertiesTextArray}
          setValues={newValue => {
            setFilters(filters => ({
              ...filters,
              propertiesTextArray: newValue,
            }));
          }}
        />
      ) : null}
      {onReset && (
        <Typography variant="body2">
          <Link onClick={onReset} color="secondary" sx={{ cursor: "pointer" }}>
            {t("Clear all", { ns: "Global" })}
          </Link>
        </Typography>
      )}
    </Stack>
  );
};
