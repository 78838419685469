import CalculateIcon from "@mui/icons-material/Calculate";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import PaletteIcon from "@mui/icons-material/Palette";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { useTranslate } from "@tolgee/react";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { RestrictedByCapability } from "../../auth/RestrictedByCapability";
import { RestrictedByOrganisationPermission } from "../../auth/RestrictedByOrganisationPermission";
import { useUserData } from "../../auth/useUserData";
import { PageNotFound } from "../../commons/PageNotFound";
import { PageLeftSidebarGroup } from "../../commons/layout/PageLeftSidebar";
import { PageTopbarItem } from "../../commons/layout/PageSubmenu";
import { ChatIcon } from "../../features/channels/ChatIcon";
import { DocumentIcon } from "../../features/documents/DocumentIcon";
import { useM1Flows } from "../../features/m1-calculators/useM1Integration";
import { OrganisationIcon } from "../../features/organisations/OrganisationIcon";
import { PlanningIcon } from "../../features/planning/PlanningIcon";
import { useSidebarGroups } from "../../global/SidebarProvider";
import { OrganisationBranding } from "./OrganisationBranding";
import { OrganisationCalculator } from "./OrganisationCalculator";
import { OrganisationChats } from "./OrganisationChats";
import { OrganisationProfile } from "./OrganisationProfile";
import { OrganisationSettingsDocuments } from "./OrganisationSettingsDocuments";
import { OrganisationSettingsIntegrations } from "./OrganisationSettingsIntegrations";
import { OrganisationSettingsPlanning } from "./OrganisationSettingsPlanning";
import { OrganisationSettingsPricesAndCalculations } from "./OrganisationSettingsPricesAndCalculations";
import { OrganisationTemplateOverrides } from "./OrganisationTemplateOverrides";
import { MyOrganisationUserProfile } from "./OrganisationUserProfile";

const EMPTY_ARRAY: any[] = [];

type PageSubmenuItem = PageTopbarItem & { isHidden?: boolean };

export const OrganisationRoutes = () => {
  const viewer = useUserData().currentUser!;
  const { t } = useTranslate([
    "OrganisationPageTopbar",
    "OrganisationBranding",
  ]);

  const { meister1Flows } = useM1Flows();

  const meister1FlowsIds = meister1Flows
    .map(f => [f.id, f.identName, f.label].join("-"))
    .join("/");

  const sidebarGroups = React.useMemo(() => {
    if (!viewer.organisation) return [];

    const groups: PageLeftSidebarGroup<PageSubmenuItem>[] = [];
    const adminItems: PageSubmenuItem[] = [
      {
        name: "organisation-profile",
        label: t("Profile & Members", { ns: "OrganisationPageTopbar" }),
        path: `/organisation/profile`,
        icon: <OrganisationIcon />,
      },
      {
        name: "organisation-branding",
        label: t("Branding", { ns: "OrganisationPageTopbar" }),
        path: `/organisation/branding`,
        icon: <PaletteIcon />,
        capability: "BRANDING",
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "organisation-chats",
        label: t("Internal chat", { ns: "OrganisationPageTopbar" }),
        path: `/organisation/chats`,
        icon: <ChatIcon />,
      },
    ];
    groups.push({
      name: t("Organisation Admin", { ns: "OrganisationPageTopbar" }),
      items: adminItems,
    });

    const advancedSettingsItems: PageSubmenuItem[] = [
      {
        name: "organisation-settings-prices-and-calculations",
        label: t("Prices and Calculations", {
          ns: "OrganisationPageTopbar",
        }),
        path: `/organisation/settings/prices-and-calculations`,
        icon: <EuroSymbolIcon />,
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "organisation-settings-documents",
        label: t("Documents", { ns: "OrganisationPageTopbar" }),
        path: `/organisation/settings/documents`,
        icon: <DocumentIcon />,
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "organisation-settings-integrations",
        label: t("Data & Integrations", {
          ns: "OrganisationPageTopbar",
        }),
        path: `/organisation/settings/integrations`,
        icon: <IntegrationInstructionsIcon />,
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "organisation-settings-planning",
        label: t("Planning & Execution", {
          ns: "OrganisationPageTopbar",
        }),
        path: `/organisation/settings/planning`,
        icon: <PlanningIcon />,
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
    ];
    groups.push({
      name: t("Settings", { ns: "OrganisationPageTopbar" }),
      items: advancedSettingsItems,
      capability: "ADVANCED_SETTINGS",
      permission: "MANAGE_ORG",
    });

    groups.push({
      name: t("Calculators", { ns: "OrganisationPageTopbar" }),
      items: meister1Flows.map(flow => ({
        name: "organisation-settings-calculator",
        label: flow.label,
        path: `/organisation/calculators/${flow.id}`,
        icon: <CalculateIcon />,
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      })),
      capability: "ADVANCED_SETTINGS",
      permission: "MANAGE_ORG",
    });

    return groups.map(group => ({
      ...group,
      items: group.items.filter(item => !item.isHidden),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, viewer.organisation, meister1FlowsIds]);

  useSidebarGroups(sidebarGroups);

  const brandingSubmenuItems = React.useMemo(() => {
    if (!viewer.organisation) return [];

    const items: PageSubmenuItem[] = [
      {
        name: "organisation-branding-own",
        label: t("Own", {
          ns: "OrganisationBranding",
        }),
        path: `/organisation/branding/own`,
        icon: <SupervisedUserCircleIcon />,
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      {
        name: "organisation-branding-referral",
        label: t("Referral", {
          ns: "OrganisationBranding",
        }),
        path: `/organisation/branding/referral`,
        icon: <SwitchAccountIcon />,
        capability: "REFERRAL",
        permission: "MANAGE_ORG",
        isHidden: !viewer.organisation.isCraftsmanOrganisation,
      },
      // {
      //   name: "organisation-branding-sponsoring",
      //   label: t("Sponsoring", { ns: "OrganisationBranding" }),
      //   type: "link",
      //   path: `/organisation/branding/sponsoring`,
      //   organisation,
      // },
    ];

    return items.filter(item => !item?.isHidden);
  }, [t, viewer.organisation]);

  return (
    <Routes>
      <Route index element={<Navigate to="profile" replace />} />
      <Route
        path="profile"
        element={<OrganisationProfile submenuItems={EMPTY_ARRAY} />}
      />
      <Route
        path="branding"
        element={
          <RestrictedByCapability capability="BRANDING">
            <RestrictedByOrganisationPermission permission="MANAGE_ORG">
              <Outlet />
            </RestrictedByOrganisationPermission>
          </RestrictedByCapability>
        }
      >
        <Route index element={<Navigate to="own" replace />} />
        <Route
          path="own"
          element={
            <OrganisationBranding
              submenuItems={brandingSubmenuItems}
              section="own"
            />
          }
        />
        <Route
          path="referral"
          element={
            <RestrictedByCapability capability="REFERRAL">
              <OrganisationBranding
                submenuItems={brandingSubmenuItems}
                section="referral"
              />
            </RestrictedByCapability>
          }
        />
      </Route>
      <Route
        path="chats"
        element={
          <OrganisationChats
            organisationId={viewer.organisation.id}
            submenuItems={EMPTY_ARRAY}
          />
        }
      />
      <Route
        path={"settings/*"}
        element={
          <RestrictedByCapability capability="ADVANCED_SETTINGS">
            <RestrictedByOrganisationPermission permission="MANAGE_ORG">
              <Outlet />
            </RestrictedByOrganisationPermission>
          </RestrictedByCapability>
        }
      >
        <Route index element={<Navigate to="documents" replace />} />
        <Route
          path="prices-and-calculations"
          element={
            <OrganisationSettingsPricesAndCalculations
              submenuItems={EMPTY_ARRAY}
            />
          }
        />
        <Route
          path="documents"
          element={<OrganisationSettingsDocuments submenuItems={EMPTY_ARRAY} />}
        />
        <Route
          path="integrations"
          element={
            <OrganisationSettingsIntegrations submenuItems={EMPTY_ARRAY} />
          }
        />
        <Route
          path="planning"
          element={<OrganisationSettingsPlanning submenuItems={EMPTY_ARRAY} />}
        />
      </Route>
      <Route
        path="calculators/:calculatorId"
        element={
          <RestrictedByCapability capability="ADVANCED_SETTINGS">
            <RestrictedByOrganisationPermission permission="MANAGE_ORG">
              <OrganisationCalculator
                organisationId={viewer.organisation.id}
                submenuItems={EMPTY_ARRAY}
              />
            </RestrictedByOrganisationPermission>
          </RestrictedByCapability>
        }
      />
      <Route path={"users/:userId/*"}>
        <Route index element={<Navigate to="profile" replace />} />
        <Route
          path="profile"
          element={<MyOrganisationUserProfile submenuItems={EMPTY_ARRAY} />}
        />
      </Route>

      <Route
        path="template-overrides"
        element={<OrganisationTemplateOverrides />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
